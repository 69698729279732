import React from "react";
import PropTypes from "prop-types";
import DataComponent from "../../../../DataComponent";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";

const DetailComponent = ({ ...props }) => {
  const { investment_category, dol, fund_group, dec_of_trust, dda, inv_nmbr, mandate, large_trade_threshold } = props;
  return (
    <>
      <Col data-testid={"DetailComponent"}>
        <Card heading={"Detail"}>
          <Row>
            <Col>
              <DataComponent
                id={`investment_category`}
                data-testid={`investment_category`}
                label="Investment Category"
                data={investment_category}
              />
            </Col>
            <Col>
              <DataComponent id={`dol`} data-testid={`dol`} label="DOL Number" data={dol} />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataComponent id={`fund_group`} data-testid={`fund_group`} label="Fund Group" data={fund_group} />
            </Col>
            <Col>
              <DataComponent
                id={`dec_of_trust`}
                data-testid={`dec_of_trust`}
                label="Dec of Trust"
                data={dec_of_trust}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataComponent id={`dda`} data-testid={`dda`} label="DDA" data={dda} />
            </Col>
            <Col>
              <DataComponent
                id={`inv_nmbr`}
                data-testid={`inv_nmbr`}
                label="Investment Number (INV_NMBR)"
                data={inv_nmbr}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DataComponent id={`mandate`} data-testid={`mandate`} label="Mandate" data={mandate} />
            </Col>
            <Col>
              <DataComponent
                id={`large_trade_threshold`}
                data-testid={`large_trade_threshold`}
                label="Large Trade Threshold"
                data={large_trade_threshold}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default DetailComponent;

DetailComponent.propTypes = {
  investment_category: PropTypes.string,
  dol: PropTypes.string,
  fund_group: PropTypes.string,
  dec_of_trust: PropTypes.string,
  dda: PropTypes.string,
  inv_nmbr: PropTypes.string,
  mandate: PropTypes.string,
  large_trade_threshold: PropTypes.string,
};
