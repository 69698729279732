import React from "react";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Card from "../../../../Card";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const AdditionalDetails = ({ ...props }) => {
  const { termination_reason, account_transferred_to, exchanged_to } = props;
  return (
    <>
      <Card heading={"Additional Information"}>
        <Row>
          <Col>
            <DataComponent
              data-testid={"termination_reason"}
              id={"termination_reason"}
              label="Termination Reason"
              data={termination_reason}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {exchanged_to ? (
              <DataComponent
                data-testid={"exchanged_to"}
                id={"exchanged_to"}
                label="Exchanged To"
                data={
                  <Link to={`/investment-details/${exchanged_to}`} reloadDocument>
                    Click To See Investment
                  </Link>
                }
              />
            ) : account_transferred_to ? (
              <DataComponent
                data-testid={"account_transferred_to"}
                id={"account_transferred_to"}
                label="Account Transferred To"
                data={
                  <Link to={`/investment-details/${account_transferred_to}`} reloadDocument>
                    Click To See Investment
                  </Link>
                }
              />
            ) : (
              <DataComponent
                data-testid={"account_transferred_to"}
                id={"account_transferred_to"}
                label="Account Status"
                data={"No Exchange or Transfer"}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default AdditionalDetails;

AdditionalDetails.propTypes = {
  termination_reason: PropTypes.string,
  account_transferred_to: PropTypes.string,
  exchanged_to: PropTypes.string,
};
