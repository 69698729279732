import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import "../NewParticipationAgreement.scss";
import Tab from "@pds-react/tab";
import { TabPanel } from "@pds-react/tab";
import Button from "@pds-react/button";
import ReactModal from "react-modal";
import { HandlePreventSubmit } from "./Helpers/FormHelpers";
import { Validate, ValidateRequiredSelect } from "../../Utilities/ValidationHelpers";
import UserContext from "../../../context/User.context";
import Config from "../../../config";
import { fetchService } from "../../../services/fetchService";
import Select from "react-select";
import HeaderAndButtons from "./Shared/HeaderAndButtons";
import ValidationErrorMessage from "../../Utilities/ValidationErrorMessage";
import selectStyler from "../../Utilities/selectStyler";
import modalStyles from "../../Utilities/Modal/ModalSyles";
import { useNavigate } from "react-router-dom";

const Groupings = ({ setForm, formData, navigation }) => {
  const { previous, next } = navigation;
  const { groupings } = formData;
  const [modalOpen, setModalOpen] = useState(false);
  const [groupCategory, setGroupCategory] = useState();
  const [currentGroupNames, setGroupNames] = useState();
  const [isLoading, setLoading] = useState(false);
  const [groupingAdded, setGroupingAdded] = useState("tab 0");
  const { accessToken } = useContext(UserContext);
  const { baseUrl } = Config.api;
  const navigate = useNavigate();

  const initialValidation = {
    groupings: [],
  };

  (() => {
    if (groupings.length === 0) {
      groupings.push({
        group_type: { label: "", value: "" },
        group_name: { label: "", value: "" },
      });
      initialValidation.groupings.push({});
    } else {
      if (groupings.length > 1) {
        for (let i = 0; i < groupings.length; i++) {
          initialValidation.groupings.push({
            group_type: ValidateRequiredSelect,
            group_name: ValidateRequiredSelect,
          });
        }
      }
    }
  })();

  const [validationErrors, setValidationError] = useState({ groupings: [] });
  const [fieldsToValidate, setFieldsToValidate] = useState(initialValidation);

  const HandleSelectChangeGroupName = (e, index) => {
    const newFormData = { ...formData };
    let value = e;
    if (value === null) {
      value = { label: "", value: "" };
    }
    let newGroupings = groupings.map((grouping) => Object.assign({}, grouping));
    newGroupings[index]["group_name"] = value;
    newFormData.groupings = newGroupings;
    setForm(newFormData);
  };

  const HandleChangeGroupType = (e, index) => {
    const newFormData = { ...formData };
    const newFieldsToValidate = { ...fieldsToValidate };
    let newGroupings = groupings.map((grouping) => Object.assign({}, grouping));
    let value = e;
    if (value === null) {
      value = { label: "", value: "" };
      newGroupings[index]["group_name"] = { value: "", label: "" };
    }
    newGroupings[index]["group_type"] = value;

    if (index === 0 && newFormData.groupings.length === 1) {
      if (newFormData.groupings[0].group_type.value !== "" && newFormData.groupings[0].group_type.label !== "") {
        newFieldsToValidate.groupings[index] = {
          group_name: ValidateRequiredSelect,
        };
      } else {
        newFieldsToValidate.groupings[index] = {};
      }
    } else {
      newFieldsToValidate.groupings[index] = {
        group_name: ValidateRequiredSelect,
        group_type: ValidateRequiredSelect,
      };
    }
    setFieldsToValidate(newFieldsToValidate);
    newFormData.groupings = newGroupings;
    setForm(newFormData);
  };

  const handleAddGroupings = () => {
    const newFormData = { ...formData };
    let newGrouping = {
      group_type: {
        label: "",
        value: "",
      },
      group_name: {
        label: "",
        value: "",
      },
    };
    newFormData.groupings.push(newGrouping);
    const newFieldsToValidate = { ...fieldsToValidate };
    const newGroupingFieldsToValidate = [];

    newFormData.groupings.forEach(() => {
      newGroupingFieldsToValidate.push({
        group_type: ValidateRequiredSelect,
        group_name: ValidateRequiredSelect,
      });
    });
    newFieldsToValidate.groupings = newGroupingFieldsToValidate;
    setFieldsToValidate(newFieldsToValidate);

    setValidationError({ groupings: [] });
    setForm(newFormData);
    setGroupingAdded(`tab ${groupings.length - 1}`);
  };

  const handleUpdate = (groupings) => {
    const timeout = setTimeout(() => {
      if (groupings.length >= 2) {
        document.getElementById("tab 0").click();
      }
    }, 100);
    return () => clearTimeout(timeout);
  };

  const handleDeleteModal = (groupings, index) => {
    const newFormData = { ...formData };
    newFormData.groupings.splice(index, 1);
    const newFieldsToValidate = { ...fieldsToValidate };

    if (newFormData.groupings.length === 1) {
      if (newFormData.groupings[0].group_type.value !== "" && newFormData.groupings[0].group_type.label !== "") {
        newFieldsToValidate.groupings = {
          groupings: [
            {
              group_name: ValidateRequiredSelect,
            },
          ],
        };
      } else {
        newFieldsToValidate.groupings = {
          groupings: [],
        };
      }
    } else {
      newFieldsToValidate.groupings.splice(index, 1);
    }

    setFieldsToValidate(newFieldsToValidate);
    setValidationError({ groupings: {} });
    setForm(newFormData);
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getGroupCategoryData = async () => {
    try {
      let endpoint = "/v1/aggregation-group-category";
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      setGroupCategory(
        resp.data.map((result) => {
          return { label: result.aggregation_group_category_name, value: result.aggregation_group_category_id };
        })
      );
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };
  const groupNameDropdown = (options) => {
    return options.map((opt) => ({
      value: opt.aggregation_group_id,
      label: opt.aggregation_group_name,
      ...opt,
    }));
  };

  const loadGroupNameOptions = async (currentGroupCat) => {
    setGroupNames([]);
    setLoading(true);
    if (currentGroupCat === null || currentGroupCat === undefined || currentGroupCat === "") {
      setLoading(false);
    } else {
      try {
        let endpoint = `/v1/aggregation-group?aggregation-group-category-id=${currentGroupCat}`;
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp["requestId"];
          throw new Error(requestId);
        }
        const res = await resp["data"];
        setGroupNames(groupNameDropdown(res));
        setLoading(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const goToNextPageCheck = () => {
    let [formIsValid, errors] = Validate(formData, fieldsToValidate);
    setValidationError(errors);
    if (formIsValid) {
      if (groupings.length === 1) {
        if (groupings[0].group_type.value === "" && groupings[0].group_type.label === "") {
          const newFormData = { ...formData };
          newFormData.groupings = [];
          setForm(newFormData);
        }
      }
      next();
    }
  };

  useEffect(() => {
    const newValidationErrors = { ...validationErrors };
    const groupingValidationErrors = [];
    groupings.forEach((grouping, index) => {
      const fieldsToValidateForGrouping = fieldsToValidate.groupings[index];
      const individualGroupingErrors = {};
      for (const groupingKey in fieldsToValidateForGrouping) {
        const validationFunct = fieldsToValidateForGrouping[groupingKey];
        if (groupingKey in grouping && grouping[groupingKey].value !== "") {
          individualGroupingErrors[groupingKey] = validationFunct(grouping[groupingKey]);
        }
      }
      groupingValidationErrors.push(individualGroupingErrors);
    });
    newValidationErrors.groupings = groupingValidationErrors;
    setValidationError(newValidationErrors);
  }, [groupings]);

  useEffect(() => {
    (async () => {
      await getGroupCategoryData();
    })();
  }, []);

  function GetGroupingsWithErrors() {
    let errorMessage = "";
    const groupingErrorIndexes = [];
    if (Array.isArray(validationErrors["groupings"])) {
      validationErrors["groupings"].forEach((grouping, index) => {
        if ("group_type" in grouping) {
          if (
            grouping["group_type"] !== undefined &&
            grouping["group_type"] !== null &&
            grouping["group_type"] !== ""
          ) {
            if (!groupingErrorIndexes.includes(index + 1)) {
              groupingErrorIndexes.push(index + 1);
            }
          }
        }
        if ("group_name" in grouping) {
          if (
            grouping["group_name"] !== undefined &&
            grouping["group_name"] !== null &&
            grouping["group_name"] !== ""
          ) {
            if (!groupingErrorIndexes.includes(index + 1)) {
              groupingErrorIndexes.push(index + 1);
            }
          }
        }
      });
    }
    if (groupingErrorIndexes.length > 0) {
      errorMessage += "The following ";
      if (groupingErrorIndexes.length === 1) {
        errorMessage += `grouping needs to be corrected: [ ${groupingErrorIndexes[0]} ]`;
      } else {
        errorMessage += "groupingss need to be corrected: [ ";
        groupingErrorIndexes.forEach((tabNumber, index) => {
          errorMessage += `${tabNumber}`;
          if (index < groupingErrorIndexes.length - 1) {
            errorMessage += ", ";
          }
        });
        errorMessage += " ]";
      }
      return errorMessage;
    }
  }
  useEffect(() => {
    if (groupings.length > 1) {
      const timeout = setTimeout(() => {
        document.getElementById(groupingAdded).click();
      });
      return () => clearTimeout(timeout);
    }
  }, [groupingAdded]);

  return (
    <form
      data-testid="groupings"
      onSubmit={(e) => {
        HandlePreventSubmit(e);
      }}
    >
      <HeaderAndButtons
        prev_label={"Investments"}
        prev_funct={previous}
        next_label={"Review"}
        next_funct={goToNextPageCheck}
        header_label={"Groupings"}
      />
      <Row>
        <Col lg={12} sm={12}>
          <p style={{ color: "indianred" }}>{GetGroupingsWithErrors()}</p>
        </Col>
      </Row>
      <Row>
        {groupings.length > 1 ? (
          <Col lg={9}>
            <Tab data-testid={`tab_layout`}>
              {groupings.map((grouping, index) => (
                <TabPanel
                  data-testid={`tab_panel-${index}`}
                  key={`grouping-${index}`}
                  buttonId={`tab ${index}`}
                  label={`Grouping ${index + 1}`}
                  panelId={`panel_${index}`}
                >
                  <Row>
                    <Col sm={8} lg={8} className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <div data-testid={`group_type_${index}`}>
                        <label>Group Category</label>
                        <Select
                          isClearable={true}
                          id={`group_type_${index}`}
                          inputId={`input_group_type_${index}`}
                          options={groupCategory}
                          value={grouping["group_type"]["label"] !== "" ? grouping["group_type"] : "undefined"}
                          onChange={(e) => {
                            HandleChangeGroupType(e, index);
                          }}
                          styles={selectStyler({
                            validationErrorsValue:
                              validationErrors["groupings"][index] !== undefined
                                ? validationErrors["groupings"][index]["group_type"]
                                : "",
                          })}
                        ></Select>
                      </div>

                      <ValidationErrorMessage
                        paramId={`groupings[${index}].group_type`}
                        validationError={
                          validationErrors["groupings"][index] !== undefined
                            ? validationErrors["groupings"][index]["group_type"]
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={8} lg={8}>
                      <label className={"util-margin-top-sm-15 util-margin-top-xs-30"}>Group Name</label>
                      <Select
                        isLoading={isLoading}
                        isClearable={true}
                        data-testid={`group_name_${index}`}
                        id={`group_name_${index}`}
                        onFocus={async () => {
                          await loadGroupNameOptions(grouping["group_type"]["value"]);
                        }}
                        value={grouping["group_name"]["label"] !== "" ? grouping["group_name"] : "undefined"}
                        onChange={(e) => HandleSelectChangeGroupName(e, index)}
                        options={currentGroupNames}
                        styles={selectStyler({
                          validationErrorsValue:
                            validationErrors["groupings"][index] !== undefined
                              ? validationErrors["groupings"][index]["group_name"]
                              : "",
                        })}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={`groupings[${index}].group_name`}
                        validationError={
                          validationErrors["groupings"][index] !== undefined
                            ? validationErrors["groupings"][index]["group_name"]
                            : ""
                        }
                      />
                    </Col>
                  </Row>

                  <Col style={{ textAlign: "right" }} className="util-margin-top-sm-50 util-margin-top-xs-50">
                    {groupings.length > 1 ? (
                      <Button data-testid={`ext-delete-button-${index}`} variant="primary" onClick={handleOpenModal}>
                        Remove Group
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                  <ReactModal
                    testId={`modal_testid_${index}`}
                    id={`modal_${index}`}
                    isOpen={modalOpen}
                    contentLabel="Minimal Modal Example"
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                    onRequestClose={() => handleCloseModal()}
                    parentSelector={() => document.getElementById(`panel_${index}`)}
                    onAfterClose={() => {
                      handleUpdate(groupings);
                    }}
                    style={modalStyles({ center: true })}
                  >
                    <div className={"container"}>
                      <Row>
                        <Col sm={12} lg={12}>
                          <p>Are you sure you want to delete this Grouping?</p>
                          <Button
                            data-testid={`modal_delete_yes_button_${index}`}
                            style={{ marginRight: 15 }}
                            variant="primary"
                            onClick={() => handleDeleteModal(groupings, index)}
                          >
                            Yes
                          </Button>
                          <Button
                            data-testid={`modal_no_button_${index}`}
                            variant="primary"
                            onClick={() => handleCloseModal()}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </ReactModal>
                </TabPanel>
              ))}
            </Tab>
          </Col>
        ) : (
          groupings.map((grouping, index) => (
            <Col key={`one_grouping_${index}`} lg={9}>
              <Row>
                <Col sm={8} lg={8} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <div data-testid={`group_type_${index}`}>
                    <label>Group Category</label>
                    <Select
                      isClearable={true}
                      datatestid={`group_type_${index}`}
                      id={`group_type_${index}`}
                      inputId={`input_group_type_${index}`}
                      options={groupCategory}
                      value={grouping["group_type"]["label"] !== "" ? grouping["group_type"] : "undefined"}
                      onChange={(e) => {
                        HandleChangeGroupType(e, index);
                      }}
                      styles={selectStyler({
                        validationErrorsValue:
                          validationErrors["groupings"][index] !== undefined
                            ? validationErrors["groupings"][index]["group_type"]
                            : "",
                      })}
                    ></Select>
                    <ValidationErrorMessage
                      paramId={`groupings[${index}].group_type`}
                      validationError={
                        validationErrors["groupings"][index] !== undefined
                          ? validationErrors["groupings"][index]["group_type"]
                          : ""
                      }
                    />
                  </div>
                </Col>
                <Col sm={8} lg={8}>
                  <div data-testid={`group_name_${index}`}>
                    <label className={"util-margin-top-sm-15 util-margin-top-xs-30"}>Group Name</label>
                    <Select
                      isLoading={isLoading}
                      isClearable={true}
                      id={`group_name_${index}`}
                      onFocus={() => {
                        loadGroupNameOptions(grouping["group_type"]["value"]);
                      }}
                      value={grouping["group_name"]["label"] !== "" ? grouping["group_name"] : "undefined"}
                      onChange={(e) => HandleSelectChangeGroupName(e, index)}
                      options={currentGroupNames}
                      styles={selectStyler({
                        validationErrorsValue:
                          validationErrors["groupings"][index] !== undefined
                            ? validationErrors["groupings"][index]["group_name"]
                            : "",
                      })}
                    ></Select>
                  </div>
                  <ValidationErrorMessage
                    paramId={`groupings[${index}].group_name`}
                    validationError={
                      validationErrors["groupings"][index] !== undefined
                        ? validationErrors["groupings"][index]["group_name"]
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>
          ))
        )}
      </Row>
      <Row>
        <Col
          lg={9}
          style={{ textAlign: "right" }}
          className="util-margin-top-sm-15 util-margin-top-xs-50 util-flex-align-end"
        >
          <Button
            data-testid="add_group"
            variant="primary"
            onClick={() => {
              handleAddGroupings();
            }}
          >
            Add Group
          </Button>
        </Col>
      </Row>
    </form>
  );
};
Groupings.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
};
export default Groupings;
