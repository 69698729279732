import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Details from "./CardComponents/Details";
import CompanyDetails from "./CardComponents/CompanyDetails";
import ContactDetails from "./CardComponents/ContactDetails";
import Specifications from "./CardComponents/Specifications";
import Card from "./../../../Card";
import Loading from "../../../../Loading/Loading";
import { useParams, useNavigate } from "react-router-dom";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import { validateGuid } from "../../../../Utilities/ValidationHelpers";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";

const ServiceProviderDetails = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);

  const initialData = {
    service_provider_name: "",
    trading_nscc_number: "",
    service_provider_type: "",
    service_provider_description: "",
    first_name: "",
    last_name: "",
    contact_phone: "",
    contact_email: "",
    contact_extension: "",
    company_name: "",
    phone_number: "",
    phone_extension: "",
    company_mailing_address: "",
    company_mailing_address_2: "",
    company_mailing_city: "",
    company_mailing_state: "",
    company_mailing_zip: "",
    company_mailing_country: "",
    reason_for_change: "",
  };

  const [serviceProviderData, setData] = useState(initialData);
  const [historyVersions, setHistoryVersions] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [loadingData, setLoadingData] = useState(true);

  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["service_provider_id"] = validateGuid(params.service_provider_id);
  }
  const {
    service_provider_name,
    trading_nscc_number,
    service_provider_type,
    contact_id,
    first_name,
    last_name,
    contact_phone,
    contact_email,
    contact_extension,
    contact_company_id,
    company_name,
    phone_number,
    phone_extension,
    company_mailing_address,
    company_mailing_address_2,
    company_mailing_city,
    company_mailing_state,
    company_mailing_zip,
    company_mailing_country,
    service_provider_description,
    reason_for_change,
    inserted_by,
    inserted_at,
  } = serviceProviderData;

  const handleVersionChange = async (serviceProvider, index) => {
    try {
      setLoadingData(true);
      let contactDetails = await getContactDetails(serviceProvider.contact_id);
      contactDetails = set_nulls_to_empty_strings(contactDetails[0]);

      let companyDetails = await getCompanyDetails(contactDetails.contact_company_id);
      companyDetails = set_nulls_to_empty_strings(companyDetails[0]);

      let serviceTypeDetails = await getServiceProviderTypeDetails(serviceProvider.service_provider_type_id);
      serviceTypeDetails = set_nulls_to_empty_strings(serviceTypeDetails[0]);

      let updateData = {
        service_provider_name: serviceProvider.service_provider_name,
        trading_nscc_number: serviceProvider.trading_nscc_number,
        service_provider_contact_name: serviceProvider.service_provider_contact_name,
        service_provider_type: serviceTypeDetails.service_provider_type,
        contact_id: contactDetails.contact_id,
        first_name: contactDetails.first_name,
        last_name: contactDetails.last_name,
        contact_phone: contactDetails.contact_phone,
        contact_email: contactDetails.contact_email,
        contact_extension: contactDetails.contact_extension,
        contact_company_id: companyDetails.contact_company_id,
        company_name: companyDetails.company_name,
        phone_number: companyDetails.phone_number,
        phone_extension: companyDetails.phone_extension,
        company_mailing_address: companyDetails.company_mailing_address,
        company_mailing_city: companyDetails.company_mailing_city,
        company_mailing_state: companyDetails.company_mailing_state,
        company_mailing_zip: companyDetails.company_mailing_zip,
        company_mailing_country: companyDetails.company_mailing_country,
        service_provider_description: serviceProvider.service_provider_description,
        reason_for_change: serviceProvider.reason_for_change,
        inserted_by: serviceProvider.inserted_by,
        inserted_at: serviceProvider.inserted_at,
      };
      setData(updateData);
      setCurrentIndex(index);
      setLoadingData(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getServiceProviderDetails = async () => {
    let endpoint = `/v1/service-provider-history?service-provider-id=${
      params.service_provider_id
    }&inserted-at=${historyTimeNow(new Date())}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  const getCompanyDetails = async (result) => {
    let endpoint = `/v1/contact-company?contact-company-id=${result}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  const getContactDetails = async (result) => {
    let endpoint = `/v1/contact?contact-id=${result}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  const getServiceProviderTypeDetails = async (result) => {
    let endpoint = `/v1/service-provider-type?service-provider-type-id=${result}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let serviceProviderDetails = await getServiceProviderDetails();
        serviceProviderDetails = set_nulls_to_empty_strings(serviceProviderDetails);

        let serviceProviderDetail = serviceProviderDetails[0];

        let contactDetails = await getContactDetails(serviceProviderDetail.contact_id);
        contactDetails = set_nulls_to_empty_strings(contactDetails[0]);

        let companyDetails = await getCompanyDetails(contactDetails.contact_company_id);
        companyDetails = set_nulls_to_empty_strings(companyDetails[0]);

        let serviceTypeDetails = await getServiceProviderTypeDetails(serviceProviderDetail.service_provider_type_id);
        serviceTypeDetails = set_nulls_to_empty_strings(serviceTypeDetails[0]);

        let updateData = {
          service_provider_name: serviceProviderDetail.service_provider_name,
          trading_nscc_number: serviceProviderDetail.trading_nscc_number,
          service_provider_contact_name: serviceProviderDetail.service_provider_contact_name,
          service_provider_type: serviceTypeDetails.service_provider_type,
          contact_id: contactDetails.contact_id,
          first_name: contactDetails.first_name,
          last_name: contactDetails.last_name,
          contact_phone: contactDetails.contact_phone,
          contact_email: contactDetails.contact_email,
          contact_extension: contactDetails.contact_extension,
          contact_company_id: companyDetails.contact_company_id,
          company_name: companyDetails.company_name,
          phone_number: companyDetails.phone_number,
          phone_extension: companyDetails.phone_extension,
          company_mailing_address: companyDetails.company_mailing_address,
          company_mailing_city: companyDetails.company_mailing_city,
          company_mailing_state: companyDetails.company_mailing_state,
          company_mailing_zip: companyDetails.company_mailing_zip,
          company_mailing_country: companyDetails.company_mailing_country,
          service_provider_description: serviceProviderDetail.service_provider_description,
          reason_for_change: serviceProviderDetail.reason_for_change,
          inserted_by: serviceProviderDetail.inserted_by,
          inserted_at: serviceProviderDetail.inserted_at,
        };
        setHistoryVersions(serviceProviderDetails);
        setData(updateData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  if (!loadingData) {
    return (
      <div data-testid={`service_provider_view`}>
        <Col lg={12}>
          <Row>
            <Col sm={5}>
              <h3>Service Provider Details</h3>
            </Col>
            <Col>
              <button
                data-testid={"edit_button"}
                id={"edit_button"}
                type="button"
                className="btn btn-primary progress-btn"
                onClick={() => {
                  navigate(`/service-provider-details/edit/${validateGuid(params.service_provider_id)}`);
                }}
              >
                Edit
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Row>
                <Col sm={12}>
                  <Details
                    service_provider_name={service_provider_name}
                    trading_nscc_number={trading_nscc_number}
                    service_provider_type={service_provider_type}
                  ></Details>
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={12}>
                  <CompanyDetails
                    company_name={company_name}
                    contact_company_id={contact_company_id}
                    phone_number={phone_number}
                    phone_extension={phone_extension}
                    company_mailing_address={company_mailing_address}
                    company_mailing_address_2={company_mailing_address_2}
                    company_mailing_city={company_mailing_city}
                    company_mailing_state={company_mailing_state}
                    company_mailing_zip={company_mailing_zip}
                    company_mailing_country={company_mailing_country}
                    currentIndex={currentIndex}
                  ></CompanyDetails>
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={12}>
                  <ContactDetails
                    first_name={first_name}
                    last_name={last_name}
                    contact_id={contact_id}
                    contact_email={contact_email}
                    contact_phone={contact_phone}
                    contact_extension={contact_extension}
                    currentIndex={currentIndex}
                  ></ContactDetails>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Specifications service_provider_description={service_provider_description}></Specifications>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Row style={{ height: "100%" }}>
                <Col sm={12}>
                  <Card heading={"History"}>
                    <VersionHistory
                      historyVersions={historyVersions}
                      handleVersionChange={handleVersionChange}
                      currentIndex={currentIndex}
                    ></VersionHistory>
                    <ReasonForChange
                      reason_for_change={reason_for_change}
                      inserted_by={inserted_by}
                      inserted_at={inserted_at}
                    ></ReasonForChange>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default ServiceProviderDetails;
