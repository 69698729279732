import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import BasicTable from "../../../../BasicTable";
import Button from "@pds-react/button";
import { useNavigate } from "react-router-dom";

const ContactsComponent = ({ ...props }) => {
  const { contacts, contact_company_id } = props;

  const contactColumns = [
    {
      accessorKey: "first_name",
      header: () => <span>First Name</span>,
    },
    {
      accessorKey: "last_name",
      header: () => <span>Last Name</span>,
    },
    {
      accessorKey: "contact_title",
      header: () => <span>Title</span>,
    },
    {
      accessorKey: "contact_mailing_city",
      header: () => <span>City</span>,
    },
    {
      accessorKey: "contact_mailing_state",
      header: () => <span>State</span>,
    },
    {
      accessorKey: "actions",
      header: () => "Actions",
      cell: (info) => <p>{info.getValue()}</p>,
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={12} lg={12}>
            <Card heading={"Associated Contacts"}>
              <BasicTable id={"contacts_table"} data={contacts} columns={contactColumns}></BasicTable>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30 util-margin-top-lg-15">
                <Col sm={12} style={{ textAlign: "right" }}>
                  <Button
                    data-testid={"new_contact"}
                    id={"new_contact"}
                    onClick={() => {
                      navigate(`/contact-details/new/${contact_company_id}`);
                    }}
                  >
                    New Contact
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ContactsComponent;

ContactsComponent.propTypes = {
  contacts: PropTypes.array,
  contact_company_id: PropTypes.string,
};
