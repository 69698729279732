import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Card from "./../../../Card";
import Loading from "../../../../Loading/Loading";
import Button from "@pds-react/button";
import Input from "@pds-react/input";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Select, { createFilter } from "react-select";
import { handleSelectChange, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import {
  FormatNSCCnumber,
  getTextAreaStyles,
  Validate,
  validateGuid,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../../Utilities/ValidationHelpers";
import selectStyler from "../../../../Utilities/selectStyler";

const ServiceProviderDetailsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({
    service_provider_id: "",
    service_provider_name: "",
    trading_nscc_number: "",
    reason_for_change: "",
    service_provider_description: "",
    service_provider_type: { label: "", value: "" },
    company_name: { label: "", value: "" },
    contact_name: { label: "", value: "" },
  });
  const [loadingData, setLoadingData] = useState(true);
  const [currentCompany, setCurrentCompany] = useState();
  const [isSelectLoading, setSelectLoading] = useState(true);
  const [asyncOptions, setOptions] = useState([]);
  const [validationErrors, setValidationError] = useState({});
  const validatedParams = {};

  if (Object.keys(params).length !== 0) {
    validatedParams["service_provider_id"] = validateGuid(params.service_provider_id);
  }

  const fieldsToValidate = {
    service_provider_name: ValidateRequired,
    company_name: ValidateRequiredSelect,
    service_provider_type: ValidateRequiredSelect,
    contact_name: ValidateRequiredSelect,
    reason_for_change: ValidateRequired,
    service_provider_description: ValidateRequired,
  };
  const handleChange = (e, id) => {
    setData((data) => ({ ...data, [id]: e.target.value }));
  };

  const {
    service_provider_name,
    trading_nscc_number,
    service_provider_description,
    reason_for_change,
    service_provider_type,
    contact_name,
    company_name,
  } = data;

  const handleSave = async () => {
    let [formIsValid, errors] = Validate(data, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = "/v1/service-provider";
        let contact_data = {
          ...data,
        };
        contact_data.contact_id = data.contact_name["value"];
        contact_data.service_provider_type_id = data.service_provider_type["value"];
        let formattedData = JSON.stringify(contact_data);
        if (Object.keys(validatedParams).length !== 0) {
          let response = await fetchService(baseUrl + endpoint, {
            method: "PUT",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          setLoadingData(false);
          navigate(`/service-provider-details/${validatedParams.service_provider_id}`);
        } else {
          let response = await fetchService(baseUrl + endpoint, {
            method: "POST",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          setLoadingData(false);
          navigate(`/service-provider-details/${resp.data.service_provider_id}`);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const buildCompanyOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_company_id,
      label: opt.company_name,
      ...opt,
    }));
  };

  const loadCompanies = async (inputValue, callback) => {
    const endpoint = `/v1/contact-company?company-name=${inputValue}`;

    if (inputValue.length > 2) {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      return callback(buildCompanyOptions(resp.data));
    } else {
      return callback([]);
    }
  };

  const buildContactOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_id,
      label: `${opt.first_name} ${opt.last_name}`,
    }));
  };

  const loadContacts = async (inputValue, callback) => {
    const endpoint = `/v1/contact?first-name=${inputValue}&contact-company-id=${currentCompany}`;

    if (inputValue.length > 2) {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      return callback(buildContactOptions(resp.data));
    } else {
      return callback([]);
    }
  };

  const getServiceProviderDetails = async () => {
    let endpoint = `/v1/service-provider?service-provider-id=${validatedParams.service_provider_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getCompanyDetails = async (result) => {
    let endpoint = `/v1/contact-company?contact-company-id=${result}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getContactDetails = async (result) => {
    let endpoint = `/v1/contact?contact-id=${result}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getServiceProviderTypeDetails = async (result) => {
    let endpoint = `/v1/service-provider-type?service-provider-type-id=${result}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        setLoadingData(true);
        let serviceProviderDetails = await getServiceProviderDetails();

        let contactDetails = await getContactDetails(serviceProviderDetails.data[0].contact_id);

        let companyDetails = await getCompanyDetails(contactDetails.data[0].contact_company_id);
        let serviceTypeDetails = await getServiceProviderTypeDetails(
          serviceProviderDetails.data[0].service_provider_type_id
        );

        let service = set_nulls_to_empty_strings(serviceProviderDetails.data[0]);
        let serviceType = set_nulls_to_empty_strings(serviceTypeDetails.data[0]);
        let contact = set_nulls_to_empty_strings(contactDetails.data[0]);
        let company = set_nulls_to_empty_strings(companyDetails.data[0]);

        let updateData = {};

        updateData.service_provider_name = service.service_provider_name;
        updateData.trading_nscc_number = service.trading_nscc_number;
        updateData.service_provider_id = service.service_provider_id;
        updateData.contact_name = { label: contact.first_name + " " + contact.last_name, value: contact.contact_id };
        updateData.company_name = company.company_name
          ? { label: company.company_name, value: company.contact_company_id }
          : { label: "", value: "" };
        updateData.service_provider_type = serviceType.service_provider_type
          ? { label: serviceType.service_provider_type, value: serviceType.service_provider_type_id }
          : { label: "", value: "" };
        updateData.service_provider_description = service.service_provider_description;
        updateData.reason_for_change = "";

        setData(updateData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    if (Object.keys(validatedParams).length !== 0) {
      setupAPIData();
    } else {
      setLoadingData(false);
    }
  }, []);

  useEffect(() => {
    const buildSelectOptions = (options) => {
      return options.map((opt) => ({
        value: opt.service_provider_type_id || opt.value,
        label: opt.service_provider_type || opt.value,
        ...opt,
      }));
    };
    const fetchOptions = async (endpoint) => {
      try {
        if (!isSelectLoading) setSelectLoading(true);
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        if (resp.data.length === 0) {
          throw new Error(resp.requestId);
        }
        setOptions(buildSelectOptions(resp.data));
        setSelectLoading(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    fetchOptions("/v1/service-provider-type");
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "service_provider_name",
      service_provider_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [service_provider_name]);

  useEffect(() => {
    validationUseEffectHelper("company_name", company_name, fieldsToValidate, validationErrors, setValidationError);
  }, [company_name]);

  useEffect(() => {
    validationUseEffectHelper("contact_name", contact_name, fieldsToValidate, validationErrors, setValidationError);
  }, [contact_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "service_provider_description",
      service_provider_description,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [service_provider_description]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  if (!loadingData) {
    return (
      <div>
        <Col lg={12}>
          <Row>
            <Col sm={12}>
              <h3>
                {Object.keys(validatedParams).length !== 0
                  ? "Edit Service Provider Details"
                  : "New Service Provider Details"}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Card heading={"Service Provider Detail"}>
                    <Row>
                      <Col sm={6}>
                        <Input
                          data-testid={"service_provider_name"}
                          id={"service_provider_name"}
                          type="text"
                          label="Service Provider Name"
                          value={service_provider_name}
                          onChange={(e) => {
                            handleChange(e, "service_provider_name");
                          }}
                          errorMessage={validationErrors["service_provider_name"]}
                        ></Input>
                      </Col>
                      <Col sm={5}>
                        <div data-testid={`service_provider_type`}>
                          <label htmlFor={`service_provider_type`}>Agreement Type</label>
                          <Select
                            id={`service_provider_type`}
                            styles={selectStyler({
                              validationErrorsValue: validationErrors["service_provider_type"],
                            })}
                            value={service_provider_type["label"] !== "" ? service_provider_type : "undefined"}
                            options={asyncOptions}
                            isLoading={isSelectLoading}
                            onChange={(e) => {
                              handleSelectChange("service_provider_type", e, setData);
                            }}
                            filterOption={createFilter({ ignoreAccents: false })}
                          ></Select>
                          <ValidationErrorMessage
                            paramId={"service_provider_type"}
                            validationError={validationErrors["service_provider_type"]}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <Col sm={5}>
                        <Input
                          data-testid={`trading_nscc_number`}
                          id={`trading_nscc_number`}
                          type="text"
                          label="NSCC Number"
                          value={FormatNSCCnumber(trading_nscc_number)}
                          onChange={(e) => {
                            handleChange(e, "trading_nscc_number");
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={12}>
                  <Card heading={"Company Details"}>
                    <Row>
                      <Col sm={5}>
                        <label>Company Name</label>
                        <span data-testid={`company_name`} id={`company_name`}>
                          <AsyncSelect
                            value={company_name["label"] !== "" ? company_name : "undefined"}
                            placeholder={"Search..."}
                            isClearable={true}
                            styles={selectStyler({
                              validationErrorsValue: validationErrors["company_name"],
                            })}
                            loadOptions={loadCompanies}
                            onChange={(e) => {
                              handleSelectChange("company_name", e, setData);
                              handleSelectChange("contact_name", (e = null), setData);
                            }}
                          ></AsyncSelect>
                          <ValidationErrorMessage
                            paramId={"company_name"}
                            validationError={validationErrors["company_name"]}
                          />
                        </span>
                      </Col>
                      <Col sm={5}>
                        <div data-testid={`contact_name`} id={`contact_name`}>
                          <label htmlFor={`contact_name`}>Contact Name</label>
                          <AsyncSelect
                            value={contact_name["label"] !== "" ? contact_name : "undefined"}
                            isClearable={true}
                            placeholder={"Search..."}
                            openMenuOnClick={true}
                            styles={selectStyler({
                              validationErrorsValue: validationErrors["contact_name"],
                            })}
                            loadOptions={loadContacts}
                            onFocus={() => {
                              setCurrentCompany(company_name.value);
                            }}
                            onChange={(e) => {
                              handleSelectChange("contact_name", e, setData);
                            }}
                          ></AsyncSelect>
                          <ValidationErrorMessage
                            paramId={"contact_name"}
                            validationError={validationErrors["contact_name"]}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Card heading={"Specifications"}>
                    <Col sm={12}>
                      <label>Description</label>
                      <div>
                        <textarea
                          data-testid={`service_provider_description`}
                          id={`service_provider_description`}
                          //disabled={true}
                          style={getTextAreaStyles(validationErrors["service_provider_description"])}
                          value={service_provider_description}
                          onChange={(e) => {
                            handleChange(e, "service_provider_description");
                          }}
                        ></textarea>
                        <ValidationErrorMessage
                          paramId={"service_provider_description"}
                          validationError={validationErrors["service_provider_description"]}
                        />
                      </div>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Card heading={"Notes"}>
                    <Col sm={12}>
                      <label>Reason For Change</label>
                      <div>
                        <textarea
                          data-testid={`reason_for_change`}
                          id={`reason_for_change`}
                          //disabled={true}
                          style={getTextAreaStyles(validationErrors["reason_for_change"])}
                          value={reason_for_change}
                          onChange={(e) => {
                            handleChange(e, "reason_for_change");
                          }}
                        ></textarea>
                        <ValidationErrorMessage
                          paramId={"reason_for_change"}
                          validationError={validationErrors["reason_for_change"]}
                        />
                      </div>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Button
                    style={{ marginRight: 15 }}
                    data-testid={"save_button"}
                    id={"save_button"}
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    data-testid={"discard_button"}
                    id={"discard_button"}
                    onClick={() => {
                      if (validatedParams.service_provider_id !== undefined) {
                        navigate(`/service-provider-details/${validatedParams.service_provider_id}`);
                      } else {
                        navigate(`/service-provider-trading-account-management/`);
                      }
                    }}
                  >
                    Discard Changes
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default ServiceProviderDetailsEdit;
