import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Card from "./../../../Card";
import Loading from "../../../../Loading/Loading";
import { useParams, useNavigate } from "react-router-dom";
import { multiFetch } from "../../../../../services/multiFetch";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import DetailComponent from "./CardComponents/DetailComponent";
import AdditionalDetailsComponent from "./CardComponents/AdditionalDetailsComponent";
import DatesComponent from "./CardComponents/DatesComponent";
import ExpenseDetailsComponent from "./CardComponents/ExpenseDetailsComponent";
import PortfolioManagersComponent from "./CardComponents/PortfolioManagersComponent";
import OverviewComponent from "./CardComponents/OverviewComponent";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";
import { validateGuid } from "../../../../Utilities/ValidationHelpers";

const PortfolioDetails = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);

  const [portfolioESDL, setPortfolioESDL] = useState({});
  const [portfolioAddlDetail, setPortfolioAddlDetail] = useState({});
  const [historyVersions, setHistoryVersions] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["portfolio_id"] = validateGuid(params.additional_details_portfolio_id);
  }

  const handleVersionChange = async (portfolioAddlDetail, index) => {
    try {
      setLoadingData(true);
      const remData = await multiFetch(
        [
          `/v1/fund-group?fund-group-id=${portfolioAddlDetail.fund_group_id}`,
          `/v1/dec-of-trust?dec-of-trust-id=${portfolioAddlDetail.dec_of_trust_id}`,
        ],
        accessToken
      );
      if (remData.error) {
        throw new Error(remData.requestId);
      }

      const fundGroup = set_nulls_to_empty_strings(remData[0].data[0]);
      const decOfTrust = set_nulls_to_empty_strings(remData[1].data[0]);

      portfolioAddlDetail.fund_group_name = fundGroup.fund_group_name;
      portfolioAddlDetail.dec_of_trust = decOfTrust.dec_of_trust_name;
      portfolioAddlDetail.annual_operating_expense_date = getDates(portfolioAddlDetail.annual_operating_expense_date);

      setPortfolioAddlDetail(portfolioAddlDetail);
      setCurrentIndex(index);
      setLoadingData(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const arrayToString = (values) => {
    let res = "";
    if (values.length === 0) {
      return res;
    }
    const arr = values.split(",");
    arr.map((managerName, index) => {
      if (arr.length === 1) {
        res = arr[0];
      } else {
        if (index === arr.length - 1) {
          res = res + managerName;
        } else {
          res = res + managerName + ", ";
        }
      }
    });
    return res;
  };

  const getDates = (date) => {
    if (date === "") {
      return date;
    }
    const arr = date.split("-");
    const res = arr[1] + "/" + arr[2] + "/" + arr[0];
    return res;
  };

  const getPortfolioESDLDetails = async () => {
    const endpoint = `/v1/portfolio-esdl?portfolio-id=${validatedParams.portfolio_id}`;
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    const resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getPorfolioManagerDetails = async () => {
    const endpoint = `/v1/portfolio-manager?portfolio-id=${validatedParams.portfolio_id}`;
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    const resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getPortfolioAdditionalDetails = async () => {
    const endpoint = `/v1/portfolio-additional-detail-history?portfolio-id=${
      validatedParams.portfolio_id
    }&inserted-at=${historyTimeNow(new Date())}`;
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    const resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        const portfoliosAddlDetails = set_nulls_to_empty_strings((await getPortfolioAdditionalDetails()).data);
        const portfolioAddlDetail = portfoliosAddlDetails[0];
        const portfolioESDL = set_nulls_to_empty_strings((await getPortfolioESDLDetails()).data[0]);
        const portfolioManager = set_nulls_to_empty_strings(
          await getPorfolioManagerDetails(validatedParams.portfolio_id)
        );

        const remData = await multiFetch(
          [
            `/v1/fund-group?fund-group-id=${portfolioAddlDetail.fund_group_id}`,
            `/v1/dec-of-trust?dec-of-trust-id=${portfolioAddlDetail.dec_of_trust_id}`,
          ],
          accessToken
        );
        if (remData.error) {
          throw new Error(remData.requestId);
        }

        const fundGroup = set_nulls_to_empty_strings(remData[0].data[0]);
        const decOfTrust = set_nulls_to_empty_strings(remData[1].data[0]);

        portfolioAddlDetail.fund_group_name = fundGroup.fund_group_name;
        portfolioAddlDetail.dec_of_trust = decOfTrust.dec_of_trust_name;
        portfolioAddlDetail.annual_operating_expense_date = getDates(portfolioAddlDetail.annual_operating_expense_date);

        portfolioESDL.soft_close_date = getDates(portfolioESDL.soft_close_date);
        portfolioESDL.hard_close_date = getDates(portfolioESDL.hard_close_date);
        portfolioESDL.performance_begin_date = getDates(portfolioESDL.performance_begin_date);
        portfolioESDL.inception_date = getDates(portfolioESDL.inception_date);

        portfolioESDL.emir = portfolioESDL.emir.toString();
        portfolioESDL.portfolio_manager = arrayToString(portfolioManager.data.portfolio_managers.toString());
        portfolioESDL.hedge_fund = portfolioESDL.hedge_fund.toString();
        portfolioESDL.registered_investment_company = portfolioESDL.registered_investment_company.toString();

        setPortfolioESDL(portfolioESDL);
        setPortfolioAddlDetail(portfolioAddlDetail);
        setHistoryVersions(portfoliosAddlDetails);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <h3>Portfolio Details</h3>
          </Col>
          <Col>
            <button
              data-testid={"edit_button"}
              id={"edit_button"}
              type="button"
              className="btn btn-primary progress-btn"
              onClick={() => {
                navigate(`/portfolio-details/edit/${validatedParams.portfolio_id}`);
              }}
            >
              Edit
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <OverviewComponent
                portfolio_name={portfolioESDL.portfolio_name}
                tax_identification_number={portfolioESDL.tax_identification_number}
                business_code={portfolioESDL.business_code}
                custodian_name={portfolioESDL.custodian_name}
                custodian_account={portfolioESDL.custodian_account}
                lei_number={portfolioESDL.lei_number}
                currentIndex={currentIndex}
              />
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <DetailComponent
                investment_category={portfolioAddlDetail.investment_category}
                dol={portfolioAddlDetail.dol}
                fund_group={portfolioAddlDetail.fund_group_name}
                dec_of_trust={portfolioAddlDetail.dec_of_trust}
                dda={portfolioAddlDetail.dda}
                inv_nmbr={portfolioAddlDetail.inv_nmbr}
                mandate={portfolioAddlDetail.mandate}
                large_trade_threshold={portfolioAddlDetail.large_trade_threshold}
              />
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <PortfolioManagersComponent
                firm_manager_name={portfolioESDL.firm_manager_name}
                portfolio_manager={portfolioESDL.portfolio_manager}
                pm_contact_email={portfolioAddlDetail.pm_contact_email}
                currentIndex={currentIndex}
              />
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <DatesComponent
                soft_close_date={portfolioESDL.soft_close_date}
                hard_close_date={portfolioESDL.hard_close_date}
                inception_date={portfolioESDL.inception_date}
                performance_begin_date={portfolioESDL.performance_begin_date}
                fiscal_year_end={portfolioESDL.fiscal_year_end}
                currentIndex={currentIndex}
              />
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <AdditionalDetailsComponent
                abor_valuation_point={portfolioESDL.abor_valuation_point}
                emir={portfolioESDL.emir}
                marketing_name={portfolioESDL.marketing_name}
                base_currency={portfolioESDL.base_currency}
                fund_of_fund={portfolioESDL.fund_of_fund}
                managed_type={portfolioESDL.managed_type}
                benchmarks={portfolioESDL.benchmarks}
                fund_type={portfolioESDL.fund_type}
                portfolio_type={portfolioESDL.portfolio_type}
                dealing_day={portfolioESDL.dealing_day}
                hedge_fund={portfolioESDL.hedge_fund}
                product={portfolioESDL.product}
                discretionary_investment_type={portfolioESDL.discretionary_investment_type}
                investment_vehicle={portfolioESDL.investment_vehicle}
                registered_investment_company={portfolioESDL.registered_investment_company}
                domicile_country={portfolioESDL.domicile_country}
                investment_vehicle_group={portfolioESDL.investment_vehicle_group}
                sub_investment_vehicle={portfolioESDL.sub_investment_vehicle}
                domicile_state={portfolioESDL.domicile_state}
                legal_structure={portfolioESDL.legal_structure}
                currentIndex={currentIndex}
              />
            </Row>
          </Col>
          <Col sm={3} lg={3}>
            <Row>
              <Col>
                <Card heading={"History"}>
                  <VersionHistory
                    historyVersions={historyVersions}
                    handleVersionChange={handleVersionChange}
                    currentIndex={currentIndex}
                  ></VersionHistory>
                  <ReasonForChange
                    reason_for_change={portfolioAddlDetail.reason_for_change}
                    inserted_at={portfolioAddlDetail.inserted_at}
                    inserted_by={portfolioAddlDetail.inserted_by}
                  ></ReasonForChange>
                </Card>
              </Col>
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <ExpenseDetailsComponent
                operating_expense_cap={portfolioAddlDetail.operating_expense_cap}
                annual_operating_expense={portfolioAddlDetail.annual_operating_expense}
                annual_operating_expense_date={portfolioAddlDetail.annual_operating_expense_date}
              />
            </Row>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default PortfolioDetails;
