const aws_region = "us-east-1";

const config = {
  appName: "Trust UI - Sandbox",
  region: aws_region,
  cognito: {
    userPoolId: `${aws_region}_nR2pfZa9L`,
    domain: `ispp-trust-co-token-dev.auth.${aws_region}.amazoncognito.com`,
    tokenUrl: `https://ispp-trust-co-token-dev.auth.${aws_region}.amazoncognito.com/oauth2/authorize`,
    providerId: "ispp-trust-co-identity-provider",
    redirectUrl: "http://localhost:3000",
    clientId: "7rufngi6dad6g0hv1946og8kjb",
    scope: "ispp-trust-co-resource-server/read-write",
    readOnlyGroup: "trust-co-read-only-client",
    updateGroup: "ispp-trust-co-read-write-client",
  },
  api: {
    baseUrl: `https://euwg32mxo7.execute-api.${aws_region}.amazonaws.com/prod/api`,
  },
};

export default config;
