import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const ContactDetails = ({ ...props }) => {
  const { first_name, last_name, contact_id, contact_email, contact_phone, contact_extension, currentIndex } = props;

  return (
    <>
      <Card heading={"Contact Details"}>
        <Row>
          <Col>
            <DataComponent
              id={`first_name`}
              label="Contact Name"
              data={first_name + " " + last_name}
              data-testid={`first_name`}
              editLink={contact_id !== "" ? `/contact-details/edit/${contact_id}` : ""}
              detailsLink={contact_id !== "" ? `/contact-details/${contact_id}` : ""}
            />
          </Col>
          <Col>
            {currentIndex === 0 ? (
              <DataComponent id={`contact_email`} label="Email" data={contact_email} data-testid={`contact_email`} />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {currentIndex === 0 ? (
          <Row>
            <Col>
              <DataComponent
                id={`contact_phone`}
                label="Phone Number"
                data={contact_phone}
                data-testid={`contact_phone`}
              />
            </Col>
            <Col>
              <DataComponent
                id={`contact_extension`}
                label="Ext"
                data={contact_extension}
                data-testid={`contact_extension`}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default ContactDetails;

ContactDetails.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  contact_id: PropTypes.string,
  contact_email: PropTypes.string,
  contact_phone: PropTypes.string,
  contact_extension: PropTypes.string,
  currentIndex: PropTypes.number,
};
