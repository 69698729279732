export const customStyles = {
  input: (provided) => ({
    ...provided,
    border: "none !important",
    height: "42px !important",
    boxShadow: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 8px 0px 0px",
  }),
  control: (provided, state) => ({
    ...provided,
    marginBottom: "2px",
    border: state.isFocused || state.menuIsOpen ? "1px solid #0091da !important" : "1px solid #E6E6E6",
    boxShadow: state.isFocused || state.menuIsOpen ? "0 0 8px #035fa4 !important" : "none",
    color: "#333",
    backgroundColor: "#fff",
    fontSize: "1.5rem",
    display: "flex",
    width: "100%",
    height: "50px",
    paddingLeft: "12px",
    paddingRight: "12px",
    marginTop: "8px",
    marginLeft: "4px",
    marginRight: "4px",
    borderRadius: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "2px",
  }),
};

export const tableHeaderSelectStyle = {
  input: (provided) => ({
    ...provided,
    border: "none !important",
    height: "42px !important",
    boxShadow: "none",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 8px 0px 0px",
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused || state.menuIsOpen ? "1px solid #0091da !important" : "1px solid #E6E6E6",
    boxShadow: state.isFocused || state.menuIsOpen ? "0 0 8px #035fa4 !important" : "none",
    color: "#333",
    backgroundColor: "#fff",
    fontSize: "1.5rem",
    display: "flex",
    width: "100%",
    height: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "2px",
  }),
};
