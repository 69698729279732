import React from "react";
import PropTypes from "prop-types";
import DataComponent from "../../../../DataComponent";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";

const DatesComponent = ({ ...props }) => {
  const { soft_close_date, hard_close_date, inception_date, performance_begin_date, fiscal_year_end, currentIndex } =
    props;
  return (
    <>
      <Col data-testid={"DatesComponent"}>
        <Card heading={"Dates"}>
          {currentIndex === 0 ? (
            <>
              <Row>
                <Col>
                  <DataComponent
                    id={`inception_date`}
                    data-testid={`inception_date`}
                    label="Inception Date"
                    data={inception_date}
                  />
                </Col>
                <Col>
                  <DataComponent
                    id={`soft_close_date`}
                    data-testid={`soft_close_date`}
                    label="Soft Close Date"
                    data={soft_close_date}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataComponent
                    id={`performance_begin_date`}
                    data-testid={`performance_begin_date`}
                    label="Performance Begin Date"
                    data={performance_begin_date}
                  />
                </Col>
                <Col>
                  <DataComponent
                    id={`hard_close_date`}
                    data-testid={`hard_close_date`}
                    label="Hard Close Date"
                    data={hard_close_date}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataComponent
                    id={`fiscal_year_end`}
                    data-testid={`fiscal_year_end`}
                    label="Fiscal Year End"
                    data={fiscal_year_end}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Card>
      </Col>
    </>
  );
};

export default DatesComponent;

DatesComponent.propTypes = {
  soft_close_date: PropTypes.string,
  hard_close_date: PropTypes.string,
  inception_date: PropTypes.string,
  performance_begin_date: PropTypes.string,
  fiscal_year_end: PropTypes.string,
  currentIndex: PropTypes.number,
};
