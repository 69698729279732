import React from "react";

function Loading() {
  return (
    <div data-testid={"loading_spinner"}>
      <h2 className="text-center">
        <i className="fa fa-spinner fa-spin"></i> Loading...
      </h2>
    </div>
  );
}

export default Loading;
