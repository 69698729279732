import React, { useState } from "react";
import "./VersionHistory.scss";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";

const VersionHistory = ({ historyVersions, handleVersionChange, currentIndex }) => {
  const [activeVersion, setActiveVersion] = useState(currentIndex);

  const getDates = (date) => {
    if (date === "") {
      return date;
    }
    let dmy = date.split(" ");
    let arr = dmy[0].split("-");
    let res = `${arr[1]}/${arr[2]}/${arr[0]}`;
    return res;
  };

  return (
    <>
      <Col sm={12}>
        <Row>
          <label>Versions</label>
        </Row>
        <Row>
          <Col data-testid={`version-history`} className="version-history">
            <ul data-testid={`version-ul`} id="buttons-list" className="version-history-ul">
              {historyVersions.map((historyVersion, index) => (
                <li
                  data-testid={`list-item-${index}`}
                  className={activeVersion === index ? "active" : ""}
                  key={index}
                  onClick={() => {
                    setActiveVersion(index);
                    handleVersionChange(historyVersion, index);
                  }}
                >
                  {index === 0 ? "Current Version" : getDates(historyVersion.inserted_at)}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default VersionHistory;

VersionHistory.propTypes = {
  historyVersions: PropTypes.array,
  handleVersionChange: PropTypes.func,
  currentIndex: PropTypes.number,
};
