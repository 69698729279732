import React, { useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { new_pa } from "./Data";
import { useStep } from "react-hooks-helper";
import TrustInfo from "./Steps/TrustInfo";
import PlanSponsor from "./Steps/PlanSponsor";
import FiduciaryInfo from "./Steps/FiduciaryInfo";
import InvestmentInfo from "./Steps/InvestmentInfo";
import TradingAccount from "./Steps/TradingAccount";
import Groupings from "./Steps/Groupings";
import Review from "./Steps/Review";
import ParticipationAgreementNav from "./ParticipationAgreementNav";
import "./NewParticipationAgreement.scss";
import PropTypes from "prop-types";
import Confirmation from "./Steps/Confirmation";

const steps = [
  { id: "trust-info" },
  { id: "plan-sponsor" },
  { id: "fiduciary-info" },
  { id: "trading-account" },
  { id: "investment-info" },
  { id: "groupings" },
  { id: "review" },
  { id: "confirmation" },
];

const MultiStepForm = ({ id, props }) => {
  switch (id) {
    case "trust-info":
      return <TrustInfo {...props} />;
    case "plan-sponsor":
      return <PlanSponsor {...props} />;
    case "fiduciary-info":
      return <FiduciaryInfo {...props} />;
    case "trading-account":
      return <TradingAccount {...props} />;
    case "investment-info":
      return <InvestmentInfo {...props} />;
    case "groupings":
      return <Groupings {...props} />;
    case "review":
      return <Review {...props} />;
    case "confirmation":
      return <Confirmation />;
    default:
      return <TrustInfo {...props} />;
  }
};

const NewParticipationAgreement = () => {
  const initialFormData = JSON.parse(JSON.stringify(new_pa));
  const [formData, setForm] = useState(initialFormData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { formData, step, setForm, navigation };
  return (
    <div data-testid="NewParticipationAgreement">
      <Row>
        <Col sm={12}>
          <Col className="util-margin-top-60">
            <Row>
              {id === "confirmation" ? (
                <></>
              ) : (
                <Col sm={3}>
                  <ParticipationAgreementNav step={step} />
                </Col>
              )}
              <Col sm={9}>{<MultiStepForm id={id} props={props} />}</Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

MultiStepForm.propTypes = {
  id: PropTypes.string,
  props: PropTypes.object,
};

export default NewParticipationAgreement;
