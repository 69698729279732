import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../Card";
import PropTypes from "prop-types";
import Select from "react-select";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import Loading from "../../../Loading/Loading";
import ValidationErrorMessage from "../../../Utilities/ValidationErrorMessage";
import selectStyler from "../../../Utilities/selectStyler.js";
import { fetchService } from "../../../../services/fetchService";
import Config from "../../../../config";
import { multiFetch } from "../../../../services/multiFetch";
import UserContext from "../../../../context/User.context";
import { useNavigate } from "react-router-dom";
import {
  //Validate,
  //ValidateRequired,
  //ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../Utilities/ValidationHelpers";

const NewInvestmentDetailsEdit = ({
  investmentData,
  validationErrors,
  handleChange,
  setInvestmentData,
  handleSelectChange,
  handleSubmit,
  handleDiscard,
  investmentFieldsToValidate,
  setValidationError,
  loadingData,
  setLoadingData,
}) => {
  const { fund_group_name, share_class_name, sales_region, approval_date } = investmentData;
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();

  const [funds, setFundOptions] = useState();
  const [shareClasses, setShareClasses] = useState();
  const [isShareLoading, setShareLoading] = useState(false);
  const [salesRegionOptions, setSalesRegionOptions] = useState();

  const buildShareClassOptions = (options) => {
    return options.map((result) => ({
      label: result.share_class_name,
      value: result.share_class_name,
    }));
  };

  const buildSalesRegionOptions = (options) => {
    return options.map((opt) => {
      return { label: opt.sales_region, value: opt.sales_region_id };
    });
  };

  const buildFundOptions = (options) => {
    return options.map((opt) => {
      return { label: opt.fund_group_name, value: opt.fund_group_id };
    });
  };

  const getShareClassOptions = async (currentFund) => {
    try {
      setShareClasses([]);
      setShareLoading(true);
      if (currentFund === null || currentFund === undefined || currentFund === "") {
        setShareLoading(false);
      } else {
        let endpoint = `/v1/investment-account-to-share-classes?fund-group-id=${currentFund}`;
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        setShareClasses(buildShareClassOptions(resp.data));
        setShareLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        let urls = ["/v1/sales-region", "/v1/fund-group"];
        let results = await multiFetch(urls, accessToken);

        if (results.error) {
          throw new Error(results.requestId);
        }
        setSalesRegionOptions(buildSalesRegionOptions(results[0].data));
        setFundOptions(buildFundOptions(results[1].data));
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    getOptions();
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "approval_date",
      approval_date,
      investmentFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [approval_date]);

  useEffect(() => {
    validationUseEffectHelper(
      "fund_group_name",
      fund_group_name,
      investmentFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [fund_group_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "share_class_name",
      share_class_name,
      investmentFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [share_class_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "sales_region",
      sales_region,
      investmentFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [sales_region]);

  if (!loadingData) {
    return (
      <div data-testid={"page"}>
        <Row>
          <Col sm={12}>
            <Card heading={"Investment"}>
              <Row>
                <Col sm={6}>
                  <Input
                    data-testid={`approval_date`}
                    id={`approval_date`}
                    type="date"
                    label="Approval Date"
                    maxLength="10"
                    value={approval_date}
                    style={{
                      borderColor:
                        validationErrors["approval_date"] === null || !validationErrors["approval_date"]
                          ? "inherit"
                          : "indianRed",
                    }}
                    onChange={(e) => {
                      handleChange(e, "approval_date", setInvestmentData);
                    }}
                  ></Input>
                  <ValidationErrorMessage paramId="approval_date" validationError={validationErrors["approval_date"]} />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="util-margin-top-sm-15 util-margin-top-xs-30" data-testid={`fund_group_name`}>
                    <label>Fund</label>
                    <Select
                      id={`fund_group_name`}
                      inputId={`fund_group_name`}
                      options={funds}
                      value={fund_group_name["label"] !== "" ? fund_group_name : "undefined"}
                      styles={selectStyler({ validationErrorsValue: validationErrors["fund_group_name"] })}
                      onChange={(e) => {
                        handleSelectChange("fund_group_name", e, setInvestmentData);
                        handleSelectChange("share_class_name", (e = null), setInvestmentData);
                      }}
                      isClearable={true}
                    ></Select>
                    <ValidationErrorMessage
                      paramId={"fund_group_name"}
                      validationError={validationErrors["fund_group_name"]}
                    />
                  </div>
                </Col>
                <Col className="util-margin-top-sm-15 util-margin-top-xs-30" sm={6} data-testid={`share_class_name`}>
                  <label>Share Class</label>
                  <Select
                    id={`share_class_name`}
                    inputId={`share_class_name`}
                    options={shareClasses}
                    value={share_class_name["label"] !== "" ? share_class_name : "undefined"}
                    styles={selectStyler({ validationErrorsValue: validationErrors["share_class_name"] })}
                    isLoading={isShareLoading}
                    isClearable={true}
                    onFocus={() => {
                      getShareClassOptions(fund_group_name.value);
                    }}
                    onChange={(e) => {
                      handleSelectChange("share_class_name", e, setInvestmentData);
                    }}
                  ></Select>
                  <ValidationErrorMessage
                    paramId={"share_class_name"}
                    validationError={validationErrors["share_class_name"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="util-margin-top-sm-15 util-margin-top-xs-30" sm={6} data-testid={`sales_region`}>
                  <label>Sales Region</label>
                  <Select
                    id={`sales_region`}
                    inputId={`sales_region`}
                    options={salesRegionOptions}
                    value={sales_region["label"] !== "" ? sales_region : "undefined"}
                    styles={selectStyler({ validationErrorsValue: validationErrors["sales_region"] })}
                    onChange={(e) => handleSelectChange("sales_region", e, setInvestmentData)}
                  ></Select>
                  <ValidationErrorMessage paramId={"sales_region"} validationError={validationErrors["sales_region"]} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Button style={{ marginRight: 15 }} onClick={handleSubmit} data-testid={"saveChanges"}>
              Save Changes
            </Button>
            <Button onClick={handleDiscard} data-testid={"discardChanges"}>
              Discard Changes
            </Button>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default NewInvestmentDetailsEdit;

NewInvestmentDetailsEdit.propTypes = {
  investmentData: PropTypes.object,
  handleChange: PropTypes.func,
  setInvestmentData: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDiscard: PropTypes.func,
  getShareClassOptions: PropTypes.func,
  validationErrors: PropTypes.object,
  funds: PropTypes.array,
  shareClasses: PropTypes.array,
  salesRegionOptions: PropTypes.array,
  isShareLoading: PropTypes.bool,
  investmentFieldsToValidate: PropTypes.object,
  setValidationError: PropTypes.func,
  loadingData: PropTypes.bool,
  setLoadingData: PropTypes.func,
};
