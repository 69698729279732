import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import BasicTable from "../../../../BasicTable";

const ParticipatingTrustsComponent = ({ ...props }) => {
  const { participatingTrusts } = props;

  const participatingTrustsColumns = [
    {
      accessorKey: "participating_trust_name",
      header: () => <span>Name Of Participating Trust</span>,
    },
    {
      accessorKey: "plan_type",
      header: "Plan Type",
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: (info) => <p>{info.getValue()}</p>,
    },
  ];

  return (
    <>
      <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={12} lg={12}>
            <Card heading={"Associated Plans"}>
              <BasicTable
                id={"participating_trusts_table"}
                data={participatingTrusts}
                columns={participatingTrustsColumns}
              ></BasicTable>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ParticipatingTrustsComponent;

ParticipatingTrustsComponent.propTypes = {
  participatingTrusts: PropTypes.array,
};
