const aws_region = "us-west-2";

const config = {
  appName: "Trust UI - Prod",
  region: aws_region,
  cognito: {
    userPoolId: `${aws_region}_gbb5AVTJx`,
    domain: `ispp-trust-co-token-prd.auth.${aws_region}.amazoncognito.com`,
    tokenUrl: `https://ispp-trust-co-token-prd.auth.${aws_region}.amazoncognito.com/oauth2/authorize`,
    providerId: "ispp-trust-co-identity-provider",
    redirectUrl: "https://citplanmanager.am.principal.com",
    clientId: "7vilgrms22q8n107mdrgucl051",
    scope: "ispp-trust-co-resource-server/read-write",
    readOnlyGroup: "trust-co-read-only-client",
    updateGroup: "ispp-trust-co-read-write-client",
  },
  api: {
    baseUrl: `https://p67kk3ed4j.execute-api.${aws_region}.amazonaws.com/prod/api`,
  },
};

export default config;
