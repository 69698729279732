import React from "react";
import { Row, Col } from "@pds-react/grid";
import Card from "../../../../Card";
import PropTypes from "prop-types";
import DataComponent from "../../../../DataComponent";

const AdditionalDetailsComponent = ({ ...props }) => {
  const {
    abor_valuation_point,
    emir,
    marketing_name,
    base_currency,
    fund_of_fund,
    managed_type,
    benchmarks,
    fund_type,
    portfolio_type,
    dealing_day,
    hedge_fund,
    product,
    discretionary_investment_type,
    investment_vehicle,
    registered_investment_company,
    domicile_country,
    investment_vehicle_group,
    sub_investment_vehicle,
    domicile_state,
    legal_structure,
    currentIndex,
  } = props;
  return (
    <>
      <Col data-testid={"AdditionalDetailsComponent"}>
        <Card heading={"Additional Details"}>
          {currentIndex === 0 ? (
            <>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`abor`}
                    label="ABOR Valuation Point"
                    data={abor_valuation_point}
                    data-testid={"abor"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent id={`emir`} label="EMIR" data={emir.toString()} data-testid={"emir"}></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`marketing_name`}
                    label="Marketing Name"
                    data={marketing_name}
                    data-testid={"marketing_name"}
                  ></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`base_currency`}
                    label="Base Currency"
                    data={base_currency}
                    data-testid={"base_currency"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`fund_of_fund`}
                    label="Fund of Fund flag"
                    data={fund_of_fund}
                    data-testid={"fund_of_fund"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`managed_type`}
                    label="Managed Type"
                    data={managed_type}
                    data-testid={"managed_type"}
                  ></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`benchmark`}
                    label="Benchmark"
                    data={benchmarks}
                    data-testid={"benchmark"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`fund_type`}
                    label="Fund Type"
                    data={fund_type}
                    data-testid={"fund_type"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`portfolio_type`}
                    label="Portfolio Type"
                    data={portfolio_type}
                    data-testid={"portfolio_type"}
                  ></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`dealing_day`}
                    label="Dealing Day"
                    data={dealing_day}
                    data-testid={"dealing_day"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`hedge_fund_flag`}
                    label="Hedge Fund Flag"
                    data={hedge_fund.toString()}
                    data-testid={"hedge_fund_flag"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent id={`product`} label="Product" data={product} data-testid={"product"}></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`discretionary_investment_type`}
                    label="Discretionary Investment Type"
                    data={discretionary_investment_type}
                    data-testid={"discretionary_investment_type"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`investment_vehicle`}
                    label="Investment Vehicle"
                    data={investment_vehicle}
                    data-testid={"investment_vehicle"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`registered_investment_company`}
                    label="Registered Investment Company"
                    data={registered_investment_company}
                    data-testid={"registered_investment_company"}
                  ></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`domicile_country`}
                    label="Domicile Country"
                    data={domicile_country}
                    data-testid={"domicile_country"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`investment_vehicle_group`}
                    label="Investment Vehicle Group"
                    data={investment_vehicle_group}
                    data-testid={"investment_vehicle_group"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`sub_investment_vehicle`}
                    label="Sub Investment Vehicle"
                    data={sub_investment_vehicle}
                    data-testid={"sub_investment_vehicle"}
                  ></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <DataComponent
                    id={`domicile_state`}
                    label="Domicile State"
                    data={domicile_state}
                    data-testid={"domicile_state"}
                  ></DataComponent>
                </Col>
                <Col sm={3}>
                  <DataComponent
                    id={`legal_structure`}
                    label="Legal Structure"
                    data={legal_structure}
                    data-testid={"legal_structure"}
                  ></DataComponent>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Card>
      </Col>
    </>
  );
};

export default AdditionalDetailsComponent;

AdditionalDetailsComponent.propTypes = {
  abor_valuation_point: PropTypes.string,
  emir: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  marketing_name: PropTypes.string,
  base_currency: PropTypes.string,
  fund_of_fund: PropTypes.string,
  managed_type: PropTypes.string,
  benchmarks: PropTypes.string,
  fund_type: PropTypes.string,
  portfolio_type: PropTypes.string,
  dealing_day: PropTypes.string,
  hedge_fund: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  product: PropTypes.string,
  discretionary_investment_type: PropTypes.string,
  investment_vehicle: PropTypes.string,
  registered_investment_company: PropTypes.string,
  domicile_country: PropTypes.string,
  investment_vehicle_group: PropTypes.string,
  sub_investment_vehicle: PropTypes.string,
  domicile_state: PropTypes.string,
  legal_structure: PropTypes.string,
  currentIndex: PropTypes.number,
};
