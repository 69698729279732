import React from "react";
import Card from "../../../../Card";
import { Row, Col } from "@pds-react/grid";
import PropTypes from "prop-types";
import DataComponent from "../../../../DataComponent";

const InvestmentDates = (props) => {
  return (
    <>
      <Card heading={"Dates"}>
        <Row>
          <Col>
            <DataComponent
              id={"investment_approval_date"}
              label="Approval Date"
              type="date"
              data={props.investment_approval_date}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataComponent
              id={"investment_actual_liquidation_date"}
              label="Actual Liquidation Date"
              type="date"
              data={props.investment_actual_liquidation_date}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataComponent
              id={"investment_approved_liquidation_date"}
              label="Approved Liquidation Date"
              type="date"
              data={props.investment_approved_liquidation_date}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataComponent
              id={"investment_termination_date"}
              label="Termination Notice Date"
              type="date"
              data={props.investment_termination_date}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InvestmentDates;

InvestmentDates.propTypes = {
  investment_approval_date: PropTypes.string,
  investment_actual_liquidation_date: PropTypes.string,
  investment_approved_liquidation_date: PropTypes.string,
  investment_termination_date: PropTypes.string,
};
