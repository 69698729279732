import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const ShareClassExpense = ({ ...props }) => {
  return (
    <Card heading={"Expense Detail"}>
      <Row>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <DataComponent
            id={`fee_report_grouping`}
            data-testid={`fee_report_grouping`}
            label={"Fee Reporting Grouping"}
            data={props.fee_report_grouping}
          />
        </Col>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <DataComponent
            id={`advisor_fee`}
            data-testid={`advisor_fee`}
            label={"Advisor Fee"}
            data={props.advisor_fee}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          {props.currentIndex === 0 ? (
            <>
              <DataComponent
                id={`management_headline_fee_bps`}
                data-testid={`management_headline_fee_bps`}
                label={"Mgmt Headline Fee BPS"}
                data={props.management_headline_fee_bps}
              />
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <DataComponent id={`fee_bps`} data-testid={`fee_bps`} label={"Fee BPS"} data={props.fee_bps} />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          {props.currentIndex === 0 ? (
            <>
              <DataComponent
                id={`admin_fee_bps`}
                data-testid={`admin_fee_bps`}
                label={"Recordkeeping Fee BPS"}
                data={props.admin_fee_bps}
              />
            </>
          ) : (
            <></>
          )}
        </Col>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <DataComponent id={`other_fee`} data-testid={`other_fee`} label={"Other Fee"} data={props.other_fee} />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <DataComponent id={`ubt_fee`} data-testid={`ubt_fee`} label={"Trustee Fee"} data={props.ubt_fee} />
        </Col>
      </Row>
    </Card>
  );
};

export default ShareClassExpense;

ShareClassExpense.propTypes = {
  fee_report_grouping: PropTypes.string,
  advisor_fee: PropTypes.string,
  management_headline_fee_bps: PropTypes.number,
  fee_bps: PropTypes.string,
  admin_fee_bps: PropTypes.string,
  other_fee: PropTypes.string,
  ubt_fee: PropTypes.string,
  currentIndex: PropTypes.number,
};
