import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const PlanSponsorCompanyDetail = ({ ...props }) => {
  const { planSponsorCompanyName, planSponsorCompanyId, planSponsorContactName, planSponsorContactId } = props;
  return (
    <>
      <Card heading={"Plan Sponsor Company"}>
        <Col sm={12}>
          <DataComponent
            data-testid={"sponsor_company"}
            id={"sponsor_company"}
            label="Company Name"
            data={planSponsorCompanyName}
            editLink={planSponsorCompanyId !== "" ? `/company-details/edit/${planSponsorCompanyId}` : ""}
            detailsLink={planSponsorCompanyId !== "" ? `/company-details/${planSponsorCompanyId}` : ""}
          />
          <DataComponent
            data-testid={"sponsor_contact"}
            id={"sponsor_contact"}
            label="Contact Name"
            data={planSponsorContactName}
            editLink={planSponsorContactId !== "" ? `/contact-details/edit/${planSponsorContactId}` : ""}
            detailsLink={planSponsorContactId !== "" ? `/contact-details/${planSponsorContactId}` : ""}
          />
        </Col>
      </Card>
    </>
  );
};

export default PlanSponsorCompanyDetail;

PlanSponsorCompanyDetail.propTypes = {
  planSponsorCompanyName: PropTypes.string,
  planSponsorCompanyId: PropTypes.string,
  planSponsorContactName: PropTypes.string,
  planSponsorContactId: PropTypes.string,
};
