import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const ShareClassCodes = ({ ...props }) => {
  return (
    <>
      <Card heading={"Misc Codes"}>
        <Row>
          <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`ml_code`} data-testid={`ml_code`} label={"ML Code"} data={props.ml_code} />
          </Col>
          <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`alt_nav_source`}
              data-testid={`alt_nav_source`}
              label={"Alt NAV Source"}
              data={props.alt_nav_source}
            />
          </Col>
          <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`root_cusip`} data-testid={`root_cusip`} label={"Root CUSIP"} data={props.root_cusip} />
          </Col>
          <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`fidelity_fund_id`}
              data-testid={`fidelity_fund_id`}
              label={"Fidelity Fund ID"}
              data={props.fidelity_fund_id}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ShareClassCodes;

ShareClassCodes.propTypes = {
  ml_code: PropTypes.string,
  alt_nav_source: PropTypes.string,
  root_cusip: PropTypes.string,
  fidelity_fund_id: PropTypes.string,
};
