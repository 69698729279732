import React from "react";
import UserProvider from "../UserProvider/UserProvider";
import AppRouter from "../AppRouter/AppRouter";
import "./App.scss";

function App() {
  return (
    <div data-testid="App" className="App">
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </div>
  );
}

export default App;
