import React, { useContext, useEffect } from "react";
import Config from "../../../config";
import UserContext from "../../../context/User.context";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import { fetchService } from "../../../services/fetchService";
import { new_pa } from "../Data";
import { FormatContractId, FormatTaxIdOnLoad, validationUseEffectHelper } from "../../Utilities/ValidationHelpers";
import { HandleChange } from "./Helpers/FormHelpers";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";

const WsrsImport = ({
  setForm,
  formData,
  validationErrors,
  setValidationError,
  fieldsToValidate,
  setLoading,
  wsrsContractNumber,
  planTypeOptions,
}) => {
  const { accessToken } = useContext(UserContext);

  const { wsrsStatus } = formData;
  const { baseUrl } = Config.api;
  const [usStates, setUsStates] = React.useState([]);
  const [planTypesByNameUpperCase, setPlanTypesByNameNameUpperCase] = React.useState({});
  const [planTypesById, setPlanTypesById] = React.useState({});

  const handleWsrsClear = () => {
    const clearData = JSON.parse(JSON.stringify(new_pa));
    clearData.wsrsStatus = wsrsStatus;
    setForm(clearData);
    const newValidationErrors = { ...validationErrors };
    delete newValidationErrors.wsrsContractNumber;
    delete newValidationErrors.service_provider_sub_acct;
    setValidationError(newValidationErrors);
  };

  const fetchStateData = async (endpoint) => {
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    let res = set_nulls_to_empty_strings(resp["data"]);
    let formattedStates = {};
    res.forEach((state) => {
      if (!Object.prototype.hasOwnProperty.call(formattedStates, `${state.state_code}`)) {
        formattedStates[`${state.state_code}`] = state.state_code;
      }
    });
    setUsStates(formattedStates);
  };

  const getCompanyData = async (companyName) => {
    const endpoint = `/v1/contact-company?company-name=${companyName}`;
    const response = await fetchService(baseUrl + endpoint, {
      method: "GET",
      token: accessToken,
    });
    let resp = await response.json();
    if (200 !== response.status) {
      setLoading(false);
      const requestId = resp["requestId"];
      throw new Error(requestId);
    } else {
      return set_nulls_to_empty_strings(resp.data);
    }
  };

  const getCountryCode = () => {
    return { label: "US", value: "US" };
  };

  const getContactData = async (firstName, lastName, companyId) => {
    const endpoint = `/v1/contact?first-name=${firstName}&last-name=${lastName}&contact-company-id=${companyId}`;
    const response = await fetchService(baseUrl + endpoint, {
      method: "GET",
      token: accessToken,
    });
    let resp = await response.json();
    if (200 !== response.status) {
      setLoading(false);
      const requestId = resp["requestId"];
      throw new Error(requestId);
    } else {
      return set_nulls_to_empty_strings(resp.data);
    }
  };

  const formattedCompanyData = async (wsrsCompanyData, formCompanyData) => {
    if (wsrsCompanyData.company_name) {
      const dBCompanyData = await getCompanyData(wsrsCompanyData.company_name);

      if (dBCompanyData.length === 1) {
        for (const property in formCompanyData) {
          if (property in dBCompanyData[0]) {
            if (property.includes("state")) {
              formCompanyData[property] =
                dBCompanyData[0][property] in usStates
                  ? { label: dBCompanyData[0][property], value: dBCompanyData[0][property] }
                  : { label: "", value: "" };
            } else if (property.includes("country")) {
              formCompanyData[property] = getCountryCode();
            } else if (property.includes("name")) {
              formCompanyData[property] = {
                label: dBCompanyData[0][property],
                value: dBCompanyData[0]["contact_company_id"],
              };
            } else {
              formCompanyData[property] = dBCompanyData[0][property];
            }
          }
        }
      } else {
        for (const property in formCompanyData) {
          if (property in wsrsCompanyData) {
            if (property.includes("state")) {
              formCompanyData[property] =
                wsrsCompanyData[property] in usStates
                  ? { label: wsrsCompanyData[property], value: wsrsCompanyData[property] }
                  : { label: "", value: "" };
            } else if (property.includes("country")) {
              formCompanyData[property] = getCountryCode();
            } else if (property.includes("name")) {
              formCompanyData[property] = { label: wsrsCompanyData[property], value: null };
            } else {
              formCompanyData[property] = wsrsCompanyData[property];
            }
          }
        }
      }
    }
    return formCompanyData;
  };

  const formattedContactData = async (wsrsContactData, formContactData, companyContactId) => {
    if (wsrsContactData.first_name && wsrsContactData.last_name && companyContactId) {
      const dBContactData = await getContactData(
        wsrsContactData.first_name,
        wsrsContactData.last_name,
        companyContactId
      );

      if (dBContactData.length === 1) {
        for (const property in formContactData) {
          if (property in dBContactData[0]) {
            if (property.includes("state")) {
              formContactData[property] =
                dBContactData[0][property] in usStates
                  ? { label: dBContactData[0][property], value: dBContactData[0][property] }
                  : { label: "", value: "" };
            } else if (property.includes("country")) {
              formContactData[property] = getCountryCode();
            } else {
              formContactData[property] = dBContactData[0][property];
            }
          }
        }
        formContactData["contact_name"] = {
          label: `${dBContactData[0].first_name} ${dBContactData[0].last_name}`,
          value: dBContactData[0].contact_id,
        };
        return formContactData;
      }
    }

    if (wsrsContactData.first_name && wsrsContactData.last_name) {
      for (const property in formContactData) {
        if (property in wsrsContactData) {
          if (property.includes("state")) {
            formContactData[property] =
              wsrsContactData[property] in usStates
                ? { label: wsrsContactData[property], value: wsrsContactData[property] }
                : { label: "", value: "" };
          } else if (property.includes("country")) {
            formContactData[property] = getCountryCode();
          } else {
            formContactData[property] = wsrsContactData[property];
          }
        }
      }

      formContactData["contact_name"] = {
        label: `${wsrsContactData.first_name} ${wsrsContactData.last_name}`,
        value: null,
      };
    }

    return formContactData;
  };

  const formattedTrustInfoData = (wsrsTrustInfoData, formTrustInfoData) => {
    for (const property in formTrustInfoData) {
      if (property === "plan_type_id") {
        if (wsrsTrustInfoData.plan_type) {
          if (wsrsTrustInfoData.plan_type.toUpperCase() in planTypesByNameUpperCase) {
            formTrustInfoData[property] = {
              label: planTypesById[planTypesByNameUpperCase[wsrsTrustInfoData.plan_type.toUpperCase()]],
              value: planTypesByNameUpperCase[wsrsTrustInfoData.plan_type.toUpperCase()],
            };
          }
        }
      } else {
        if (wsrsTrustInfoData[property]) {
          formTrustInfoData[property] = wsrsTrustInfoData[property];
        }
      }
    }
    return formTrustInfoData;
  };

  const handleContractLookup = async () => {
    let newFormData = JSON.parse(JSON.stringify(new_pa));
    newFormData.wsrsStatus = true;
    newFormData.wsrsContractNumber = wsrsContractNumber;
    setForm(newFormData);
    setLoading(true);
    const newValidationErrors = { ...validationErrors };
    await fetchService(baseUrl + `/v1/wsrs?wsrs-contract-number=${wsrsContractNumber}`, {
      method: "GET",
      token: accessToken,
    }).then(async (response) => {
      let resp = await response.json();
      if (response.status === 400) {
        setLoading(false);
        const requestId = resp["requestId"];
        throw new Error(requestId);
      } else if (response.status === 404) {
        newValidationErrors.wsrsContractNumber = "Contract number not found";
      } else {
        if (resp.error) {
          newValidationErrors.wsrsContractNumber = resp.error;
        } else {
          const wsrsData = resp.data;

          let wsrsTrustInfoData = formattedTrustInfoData(wsrsData.trust_info, newFormData.trust_info);
          let wsrsPlanCompanyData = await formattedCompanyData(
            wsrsData.plan_sponsor.company,
            newFormData.plan_sponsor.company
          );
          let wsrsFidCompanyData = await formattedCompanyData(
            wsrsData.fiduciary.company,
            newFormData.fiduciary.company
          );
          let wsrsPlanContactData = await formattedContactData(
            wsrsData.plan_sponsor.contact,
            newFormData.plan_sponsor.contact,
            wsrsPlanCompanyData.company_name.value
          );

          let wsrsFidContactData = await formattedContactData(
            wsrsData.fiduciary.contact,
            newFormData.fiduciary.contact,
            wsrsFidCompanyData.company_name.value
          );

          wsrsTrustInfoData.plan_tax_id = wsrsTrustInfoData.plan_tax_id
            ? FormatTaxIdOnLoad(wsrsTrustInfoData.plan_tax_id)
            : wsrsTrustInfoData.plan_tax_id;

          newFormData.wsrsContractNumber = wsrsContractNumber;
          newFormData.service_provider_sub_acct = wsrsContractNumber;

          newFormData.trust_info = wsrsTrustInfoData;
          newFormData.plan_sponsor.company = wsrsPlanCompanyData;
          newFormData.plan_sponsor.contact = wsrsPlanContactData;
          newFormData.fiduciary.company = wsrsFidCompanyData;
          newFormData.fiduciary.contact = wsrsFidContactData;
          delete newValidationErrors.wsrsContractNumber;
          delete newValidationErrors.service_provider_sub_acct;

          setForm(newFormData);
        }
      }
      setValidationError(newValidationErrors);
      setLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      await fetchStateData("/v1/us-states");
    })();
  }, []);

  useEffect(() => {
    const formattingPlanTypesOptions = () => {
      let formattedPlanTypesByName = {};
      let formattedPlanTypesById = {};
      planTypeOptions.forEach((planType) => {
        if (!Object.prototype.hasOwnProperty.call(formattedPlanTypesByName, `${planType.plan_type}`)) {
          formattedPlanTypesByName[`${planType.plan_type}`.toUpperCase()] = planType.plan_type_id;
          formattedPlanTypesById[`${planType.plan_type_id}`] = `${planType.plan_type}`;
        }
      });
      setPlanTypesByNameNameUpperCase(formattedPlanTypesByName);
      setPlanTypesById(formattedPlanTypesById);
    };

    formattingPlanTypesOptions();
  }, []);

  useEffect(() => {
    if (
      wsrsContractNumber !== "" &&
      validationErrors["wsrsContractNumber"] !== "Contract number not found" &&
      validationErrors["wsrsContractNumber"] !== "Already imported, see Participating Trust Management"
    ) {
      validationUseEffectHelper(
        "wsrsContractNumber",
        wsrsContractNumber,
        fieldsToValidate,
        validationErrors,
        setValidationError
      );
    }
  }, [wsrsContractNumber]);

  return (
    <Row data-testid="WsrsImport" id="WsrsImport">
      <Col sm={6} lg={4}>
        <Input
          data-testid={`wsrs_contract_number`}
          id={`wsrs_contract_number`}
          type="text"
          label="WSRS Contract Number"
          className="util-margin-top-sm-15 util-margin-top-xs-30"
          value={wsrsContractNumber}
          onChange={(e) => {
            FormatContractId(e);
            HandleChange(e, "wsrsContractNumber", formData, setForm);
          }}
          disabled={false}
          errorMessage={
            validationErrors["wsrsContractNumber"]
              ? validationErrors["wsrsContractNumber"]
              : validationErrors["service_provider_sub_acct"]
          }
        ></Input>
      </Col>
      <Col sm={12} lg={8} className="util-margin-top-sm-40 util-margin-top-xs-30">
        <Row>
          <Button
            data-testid={"search_button"}
            type="button"
            style={{ marginRight: 15 }}
            onClick={async () => {
              await handleContractLookup();
            }}
            disabled={wsrsContractNumber.length !== 6}
          >
            Search
          </Button>
          <Button data-testid={"clear_button"} id={"clear_button"} type="button" onClick={handleWsrsClear}>
            Clear
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

WsrsImport.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  setValidationError: PropTypes.func,
  validationErrors: PropTypes.object,
  fieldsToValidate: PropTypes.object,
  setLoading: PropTypes.func,
  wsrsContractNumber: PropTypes.string,
  planTypeOptions: PropTypes.array,
};

export default WsrsImport;
