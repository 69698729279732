import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../services/fetchService";
import Config from "../../../config";
import UserContext from "../../../context/User.context";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import Select from "react-select";
import { Link } from "react-router-dom";
import Button from "@pds-react/button";
import Input from "@pds-react/input";
import "./ContactAndCompanyManagement.scss";

import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { OnEnterKeyPress } from "../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";
import { customStyles, tableHeaderSelectStyle } from "../helpers";
import Pagination from "../Pagination";

let columns = [];
const columnHelper = createColumnHelper();

const columnsLong = [
  columnHelper.accessor("company_name", {
    header: () => "Company Name",
  }),
  columnHelper.accessor("city", {
    header: () => "City",
  }),
  columnHelper.accessor("state", {
    header: () => "State",
  }),
  columnHelper.accessor("contact_name", {
    header: "Contact Name",
  }),
  columnHelper.accessor("contact_email", {
    header: "Contact Email",
  }),
  columnHelper.accessor("contact_mailing_address", {
    header: "Contact Mailing Address",
  }),
];

const columnsShort = [
  columnHelper.accessor("company_name", {
    header: () => "Company Name",
  }),
  columnHelper.accessor("city", {
    header: () => "City",
  }),
  columnHelper.accessor("state", {
    header: () => "State",
  }),
];

const ContactAndCompanyManagement = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const [isloading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [contactsType, setContactType] = useState({
    label: "Contacts and Companies",
    value: "contacts and companies",
  });
  if (contactsType.value === "contacts and companies") {
    columns = columnsLong;
  } else {
    columns = columnsShort;
  }

  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);

  const [pageViewNum, setPageViewNum] = useState({ value: "", label: "" });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const initialFilters = {
    company_name_input: "",
    city_input: "",
    state_input: { value: "", label: "" },
    tax_id_ein_input: "",
    contact_name_input: "",
    contact_email_input: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const { company_name_input, city_input, state_input, contact_name_input, contact_email_input } = filters;

  const getTableData = async () => {
    try {
      setLoading(true);
      let endpoint = "";
      let params;
      if (contactsType.value === "contacts and companies") {
        if (
          company_name_input.trim() !== "" ||
          city_input.trim() !== "" ||
          state_input.value.trim() !== "" ||
          contact_name_input.trim() !== "" ||
          contact_email_input.trim() !== ""
        ) {
          params = new URLSearchParams({
            "company-name": company_name_input,
            "company-mailing-city": city_input,
            "company-mailing-state": state_input.value,
            "contact-name": contact_name_input,
            "contact-email": contact_email_input,
          });
          endpoint = `/v1/management-contact-company?${params}`;
        } else {
          setLoading(false);
        }
      } else if (contactsType.value === "companies") {
        if (company_name_input.trim() !== "" || city_input.trim() !== "" || state_input.value.trim() !== "") {
          params = new URLSearchParams({
            "company-name": company_name_input,
            "company-mailing-city": city_input,
            "company-mailing-state": state_input.value,
          });
          endpoint = `/v1/management-company?${params}`;
        } else {
          setData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      if (endpoint) {
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        resp.data = set_nulls_to_empty_strings(resp.data);
        setData(
          resp.data.map((result) => {
            return {
              company_name: result.company_name,
              city: result.company_mailing_city,
              state: result.company_mailing_state,
              contact_email: result.contact_email,
              contact_name: result.contact_name,
              contact_mailing_address: result.contact_mailing_address,
              contact_id: result.contact_id,
              company_id: result.contact_company_id,
            };
          })
        );
        setLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const handleViewChange = (e) => {
    setContactType((prevGroupings) => ({ ...prevGroupings, label: e.label, value: e.value }));
  };

  const getPageViewOption = (numberOfPages) => {
    let choices = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      choices.push({ value: i - 1, label: i });
    }
    return choices;
  };

  const handleClear = () => {
    setFilters(initialFilters);
  };

  const handleChange = (e, id) => {
    const value = e.target.value;
    setFilters((filter) => ({ ...filter, [id]: value }));
  };

  const handleSelectChange = (e, id) => {
    if (e === null) {
      e = { value: "", label: "" };
    }
    const value = e.value;
    const label = e.label;
    setFilters({
      ...filters,
      [id]: {
        label: label,
        value: value,
      },
    });
  };

  const buildSelectOptions = (options) => {
    return options.map((opt) => ({
      value: opt.name || opt.state_code,
      label: opt.name || opt.state_code,
      ...opt,
    }));
  };
  const fetchOptions = async (endpoint) => {
    try {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      const res = resp["data"];
      setStates(buildSelectOptions(res));
      setLoading(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    fetchOptions("/v1/us-states");
  }, []);

  useEffect(() => {
    if (
      !(
        company_name_input === "" &&
        city_input === "" &&
        state_input.value === "" &&
        contact_name_input === "" &&
        contact_email_input === ""
      )
    ) {
      (async () => {
        await getTableData();
      })();
    }
  }, [contactsType]);

  return (
    <div data-testid={"contact-and-company-management-view"}>
      <Col>
        <Row>
          <Col data-testid={"header"} id={"header"}>
            <h2>Contact and Company Management</h2>
          </Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={6}>
            <Button
              data-testid={"create_button"}
              id={"create_button"}
              onClick={() => {
                navigate("/company-details/new");
              }}
            >
              Create Company
            </Button>
          </Col>
          <Col sm={3} style={{ textAlign: "right", marginTop: "2rem", paddingRight: "0px" }}>
            <span>View</span>
          </Col>
          <Col sm={3} id={"View"} data-testid={"View"}>
            <Select
              styles={customStyles}
              value={contactsType}
              onChange={(e) => {
                handleViewChange(e);
              }}
              options={[
                { label: "Contacts and Companies", value: "contacts and companies" },
                { label: "Companies", value: "companies" },
              ]}
            ></Select>
          </Col>
        </Row>
        {!isloading ? (
          <>
            <Row className={"util-padding-top-20"}>
              <Col>
                <table data-testid={"management_table"} id={"management_table"}>
                  {contactsType["label"] === "Contacts and Companies" ? (
                    <colgroup>
                      <col style={{ width: 15 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 25 }} />
                      <col style={{ width: 20 }} />
                      <col style={{ width: 10 }} />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col style={{ width: 25 }} />
                      <col style={{ width: 25 }} />
                      <col style={{ width: 25 }} />
                      <col style={{ width: 25 }} />
                    </colgroup>
                  )}
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                        <th>Actions</th>
                      </tr>
                    ))}
                    <tr>
                      <th>
                        <Input
                          id="company_name_input"
                          data-testid={"company_name_input"}
                          label=""
                          value={company_name_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "company_name_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      <th>
                        <Input
                          id="city_input"
                          data-testid={"city_input"}
                          label=""
                          value={city_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "city_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      <th>
                        <div data-testid={"state_input"}>
                          <Select
                            isClearable={true}
                            id={"state_input"}
                            inputId={"state_input"}
                            name={"state_input"}
                            value={state_input["label"] !== "" ? state_input : "undefined"}
                            className="state_input"
                            options={states}
                            styles={tableHeaderSelectStyle}
                            onChange={(e) => {
                              handleSelectChange(e, "state_input");
                            }}
                            onKeyDown={(event) => {
                              OnEnterKeyPress(event, getTableData);
                            }}
                          ></Select>
                        </div>
                      </th>
                      {contactsType["label"] === "Contacts and Companies" ? (
                        <>
                          <th>
                            <Input
                              id="contact_name_input"
                              data-testid={"contact_name_input"}
                              label=""
                              value={contact_name_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "contact_name_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="contact_email_input"
                              data-testid={"contact_email_input"}
                              label=""
                              value={contact_email_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "contact_email_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th></th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"filter_button"}
                          id={"filter_button"}
                          onClick={async () => {
                            await getTableData();
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"clear_button"}
                          id={"clear_button"}
                          onClick={() => {
                            handleClear();
                            setData([]);
                          }}
                        >
                          Clear
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length < 1 ? (
                      <>
                        <tr>
                          {columns.map((col, index) => (
                            <td key={index}>&#8205;</td>
                          ))}
                        </tr>
                      </>
                    ) : (
                      <>
                        {table.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                            <td key={row.id} style={{ textAlign: "right" }}>
                              {data[row.id].contact_id ? (
                                <>
                                  {" "}
                                  Contact:{" "}
                                  <Link
                                    id={"contact_details_edit_link"}
                                    data-testid={"contact_details_edit_link"}
                                    to={`/contact-details/edit/${data[row.id].contact_id}`}
                                  >
                                    Edit
                                  </Link>{" "}
                                  |{" "}
                                  <Link
                                    id={"contact_details_link"}
                                    data-testid={"contact_details_link"}
                                    to={`/contact-details/${data[row.id].contact_id}`}
                                  >
                                    Details
                                  </Link>
                                  <br />
                                </>
                              ) : (
                                <></>
                              )}
                              <>
                                {" "}
                                Company:{" "}
                                <Link
                                  id={"company_details_edit_link"}
                                  data-testid={"company_details_edit_link"}
                                  to={`/company-details/edit/${data[row.id].company_id}`}
                                >
                                  Edit
                                </Link>{" "}
                                |{" "}
                                <Link
                                  id={"company_details_link"}
                                  data-testid={"company_details_link"}
                                  to={`/company-details/${data[row.id].company_id}`}
                                >
                                  Details
                                </Link>
                              </>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Pagination
              data={data}
              table={table}
              setPageViewNum={setPageViewNum}
              pageViewNum={pageViewNum}
              getPageViewOption={getPageViewOption}
            ></Pagination>
          </>
        ) : (
          <>
            <h2 className="text-center"> Application is starting</h2>
            <Loading></Loading>
          </>
        )}
      </Col>
    </div>
  );
};

export default ContactAndCompanyManagement;
