import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../services/fetchService";
import Config from "../../../config";
import UserContext from "../../../context/User.context";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import Button from "@pds-react/button";
import Input from "@pds-react/input";
import "./PortfolioAndShareClassManagement.scss";
import Loading from "../../Loading/Loading";
import { OnEnterKeyPress } from "../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";
import { FormatTaxId, FormatTaxIdOnLoad, UnFormatTaxId } from "../../Utilities/ValidationHelpers";
import { customStyles, tableHeaderSelectStyle } from "../helpers";
import Pagination from "../Pagination";

let columns = [];
const columnHelper = createColumnHelper();

const columnsLong = [
  columnHelper.accessor("portfolio_name", {
    header: () => "Portfolio Name",
  }),
  columnHelper.accessor("tax_identification_number", {
    header: () => "Tax ID",
  }),
  columnHelper.accessor("dec_of_trust_name", {
    header: () => "Dec of Trust",
  }),
  columnHelper.accessor("abor_id", {
    header: "ABOR ID",
  }),
  columnHelper.accessor("cusip", {
    header: "CUSIP",
  }),
  columnHelper.accessor("accounting_basis", {
    header: "Accounting Basis",
  }),
  columnHelper.accessor("transfer_agent_identifier", {
    header: "Transfer Agent ID",
  }),
  columnHelper.accessor("fsa_cit_share_class_code", {
    header: "FSA  CIT Share Class Code",
  }),
  columnHelper.accessor("share_class_name", {
    header: "Share Class",
  }),
];

const columnsShort = [
  columnHelper.accessor("portfolio_name", {
    header: () => "Portfolio Name",
  }),
  columnHelper.accessor("tax_identification_number", {
    header: () => "Tax ID",
  }),
  columnHelper.accessor("dec_of_trust_name", {
    header: () => "Dec of Trust",
  }),
  columnHelper.accessor("abor_id", {
    header: "ABOR ID",
  }),
];

const PortfolioAndShareClassManagement = () => {
  const { baseUrl } = Config.api;
  const navigate = useNavigate();
  const { accessToken } = useContext(UserContext);
  const [isloading, setLoading] = useState(true);

  const [viewType, setViewType] = useState({
    label: "Portfolio and Share Class",
    value: "Portfolio and Share Class",
  });
  if (viewType.value === "Portfolio and Share Class") {
    columns = columnsLong;
  } else {
    columns = columnsShort;
  }

  const [data, setTableData] = useState([]);
  const [decOfTrusts, setDecOfTrusts] = useState([]);

  const [pageViewNum, setPageViewNum] = useState({ value: "", label: "" });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const initialFilters = {
    portfolio_name_input: "",
    tax_identification_number_input: "",
    dec_of_trust_input: { label: "", value: "" },
    abor_id_input: "",
    cusip_input: "",
    accounting_basis_input: "",
    transfer_agent_id_input: "",
    fsa_cit_share_class_code_input: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const {
    portfolio_name_input,
    tax_identification_number_input,
    dec_of_trust_input,
    abor_id_input,
    cusip_input,
    accounting_basis_input,
    transfer_agent_id_input,
    fsa_cit_share_class_code_input,
  } = filters;

  const getTableData = async () => {
    try {
      setLoading(true);
      let endpoint = "";
      let params;
      if (viewType.value === "Portfolio and Share Class") {
        if (
          portfolio_name_input.trim() !== "" ||
          tax_identification_number_input.trim() !== "" ||
          dec_of_trust_input.value.trim() !== "" ||
          abor_id_input.trim() !== "" ||
          cusip_input.trim() !== "" ||
          accounting_basis_input.trim() !== "" ||
          transfer_agent_id_input.trim() !== "" ||
          fsa_cit_share_class_code_input.trim() !== ""
        ) {
          params = new URLSearchParams({
            "portfolio-name": portfolio_name_input,
            "tax-identification-number": UnFormatTaxId(tax_identification_number_input),
            "dec-of-trust-name": dec_of_trust_input.label,
            "abor-id": abor_id_input,
            cusip: cusip_input,
            "accounting-basis": accounting_basis_input,
            "transfer-agent-identifier": transfer_agent_id_input,
            "fsa-cit-share-class-code": fsa_cit_share_class_code_input,
          });
          endpoint = `/v1/management-portfolio-share-class?${params}`;
        } else {
          setLoading(false);
        }
      } else if (viewType.value === "portfolio") {
        if (
          portfolio_name_input.trim() !== "" ||
          tax_identification_number_input.trim() !== "" ||
          dec_of_trust_input.value.trim() !== "" ||
          abor_id_input.trim() !== ""
        ) {
          params = new URLSearchParams({
            "portfolio-name": portfolio_name_input,
            "tax-identification-number": UnFormatTaxId(tax_identification_number_input),
            "dec-of-trust-name": dec_of_trust_input.label,
            "abor-id": abor_id_input,
          });
          endpoint = `/v1/management-portfolio?${params}`;
        } else {
          setTableData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      if (endpoint !== "") {
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        resp.data = set_nulls_to_empty_strings(resp.data);
        const tableData = resp.data.map((tableRow) => ({
          ...tableRow,
          tax_identification_number: FormatTaxIdOnLoad(tableRow.tax_identification_number),
        }));
        setTableData(tableData);
        setLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const handleViewChange = (e) => {
    setViewType((prevGroupings) => ({ ...prevGroupings, label: e.label, value: e.value }));
  };

  const getPageViewOption = (numberOfPages) => {
    let choices = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      choices.push({ value: i - 1, label: i });
    }
    return choices;
  };

  const handleChange = (e, id) => {
    const value = e.target.value;
    setFilters((filter) => ({ ...filter, [id]: value }));
  };

  const handleSelectChange = (e, id) => {
    if (e === null) {
      e = { value: "", label: "" };
    }
    const value = e.value;
    const label = e.label;
    setFilters({
      ...filters,
      [id]: {
        label: label,
        value: value,
      },
    });
  };

  const buildSelectOptions = (options) => {
    return options.map((opt) => ({
      value: opt.dec_of_trust_id,
      label: opt.dec_of_trust_name,
      ...opt,
    }));
  };

  useEffect(() => {
    const getDecOfTrust = async () => {
      try {
        const endpoint = "/v1/dec-of-trust";
        const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        const resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        const res = resp.data;
        setDecOfTrusts(buildSelectOptions(set_nulls_to_empty_strings(res)));
        setLoading(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    getDecOfTrust();
  }, []);

  useEffect(() => {
    if (
      !(
        portfolio_name_input === "" &&
        tax_identification_number_input === "" &&
        dec_of_trust_input.value === "" &&
        abor_id_input === "" &&
        cusip_input === "" &&
        accounting_basis_input === "" &&
        transfer_agent_id_input === "" &&
        fsa_cit_share_class_code_input === ""
      )
    ) {
      getTableData();
    }
  }, [viewType]);

  return (
    <div data-testid={"portfolio-and-share-class-management-view"}>
      <Col>
        <Row>
          <Col data-testid={"header"} id={"header"}>
            <h2>Portfolio And Share-Class Management</h2>
          </Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={9} style={{ textAlign: "right", marginTop: "2rem", paddingRight: "0px" }}>
            <span>View</span>
          </Col>
          <Col sm={3} id={"View"} data-testid={"View"}>
            <Select
              styles={customStyles}
              value={viewType}
              onChange={(e) => {
                handleViewChange(e);
              }}
              options={[
                { label: "Portfolio and Share Class", value: "Portfolio and Share Class" },
                { label: "Portfolio", value: "portfolio" },
              ]}
            ></Select>
          </Col>
        </Row>
        {!isloading ? (
          <>
            <Row className={"util-padding-top-20"}>
              <Col>
                <table data-testid={"management_table"} id={"management_table"}>
                  {viewType.label === "Portfolio and Share Class" ? (
                    <colgroup>
                      <col style={{ width: 20 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 15 }} />
                      <col style={{ width: 8 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 8 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 5 }} />
                      <col style={{ width: 10 }} />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col style={{ width: 20 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 30 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 20 }} />
                    </colgroup>
                  )}
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                        <th>Actions</th>
                      </tr>
                    ))}
                    <tr>
                      <th>
                        <Input
                          id="portfolio_name_input"
                          data-testid={"portfolio_name_input"}
                          label=""
                          value={portfolio_name_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "portfolio_name_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      <th>
                        <Input
                          id="tax_identification_number"
                          data-testid={"tax_identification_number"}
                          label=""
                          value={tax_identification_number_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            FormatTaxId(e);
                            handleChange(e, "tax_identification_number_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      <th>
                        <div data-testid={"dec_of_trust"}>
                          <Select
                            isClearable={true}
                            id={"dec_of_trust"}
                            inputId={"dec_of_trust"}
                            name={"dec_of_trust"}
                            value={dec_of_trust_input["label"] !== "" ? dec_of_trust_input : "undefined"}
                            className="state_input"
                            options={decOfTrusts}
                            styles={tableHeaderSelectStyle}
                            onChange={(e) => {
                              handleSelectChange(e, "dec_of_trust_input");
                            }}
                            onKeyDown={(event) => {
                              OnEnterKeyPress(event, getTableData);
                            }}
                          ></Select>
                        </div>
                      </th>
                      <th>
                        <Input
                          id="abor_id_input"
                          data-testid={"abor_id_input"}
                          label=""
                          value={abor_id_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "abor_id_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      {viewType.label === "Portfolio and Share Class" ? (
                        <>
                          <th>
                            <Input
                              id="cusip"
                              data-testid={"cusip"}
                              label=""
                              value={cusip_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "cusip_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="accounting_basis"
                              data-testid={"accounting_basis"}
                              label=""
                              value={accounting_basis_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "accounting_basis_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="transfer_agent_id_input"
                              data-testid={"transfer_agent_id_input"}
                              label=""
                              value={transfer_agent_id_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "transfer_agent_id_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="fsa_cit_share_class_code"
                              data-testid={"fsa_cit_share_class_code"}
                              label=""
                              value={fsa_cit_share_class_code_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "fsa_cit_share_class_code_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th></th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"filter_button"}
                          id={"filter_button"}
                          onClick={async () => {
                            await getTableData();
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"clear_button"}
                          id={"clear_button"}
                          onClick={() => {
                            setFilters(initialFilters);
                            setTableData([]);
                          }}
                        >
                          Clear
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length < 1 ? (
                      <>
                        <tr>
                          {columns.map((col, index) => (
                            <td key={index}>&#8205;</td>
                          ))}
                        </tr>
                      </>
                    ) : (
                      <>
                        {table.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                            <td key={row.id} style={{ textAlign: "right" }}>
                              {viewType.label === "Portfolio and Share Class" ? (
                                <>
                                  Portfolio:{" "}
                                  {data[row.id].portfolio_additional_detail_portfolio_id ? (
                                    <>
                                      <Link
                                        id={"portfolio_details_edit_link"}
                                        data-testid={"portfolio_details_edit_link"}
                                        to={`/portfolio-details/edit/${
                                          data[row.id].portfolio_additional_detail_portfolio_id
                                        }`}
                                      >
                                        Edit
                                      </Link>{" "}
                                      |{" "}
                                      <Link
                                        id={"portfolio_details_link"}
                                        data-testid={"portfolio_details_link"}
                                        to={`/portfolio-details/${
                                          data[row.id].portfolio_additional_detail_portfolio_id
                                        }`}
                                      >
                                        Details
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        id={"portfolio_details_new_link"}
                                        data-testid={"portfolio_details_new_link"}
                                        to={`/portfolio-details/new/${data[row.id].portfolio_id}`}
                                      >
                                        Create
                                      </Link>
                                    </>
                                  )}
                                  <br />
                                  {data[row.id].share_class_additional_detail_share_class_id ? (
                                    <>
                                      Share Class:{" "}
                                      <Link
                                        id={"share_class_details_edit_link"}
                                        data-testid={"share_class_details_edit_link"}
                                        to={`/share-class-details/edit/${
                                          data[row.id].share_class_additional_detail_share_class_id
                                        }`}
                                        aria-disabled={true}
                                      >
                                        Edit
                                      </Link>{" "}
                                      |{" "}
                                      <Link
                                        id={"share_class_details_link"}
                                        data-testid={"share_class_details_link"}
                                        to={`/share-class-details/${
                                          data[row.id].share_class_additional_detail_share_class_id
                                        }`}
                                      >
                                        Details
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      Share Class:{" "}
                                      <Link
                                        id={"share_class_details_new_link"}
                                        data-testid={"share_class_details_new_link"}
                                        to={`/share-class-details/new/${data[row.id].share_class_id}`}
                                        aria-disabled={true}
                                      >
                                        Create
                                      </Link>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  Portfolio:{" "}
                                  {data[row.id].portfolio_additional_detail_portfolio_id ? (
                                    <>
                                      <Link
                                        id={"portfolio_details_edit_link"}
                                        data-testid={"portfolio_details_edit_link"}
                                        to={`/portfolio-details/edit/${
                                          data[row.id].portfolio_additional_detail_portfolio_id
                                        }`}
                                      >
                                        Edit
                                      </Link>{" "}
                                      |{" "}
                                      <Link
                                        id={"portfolio_details_link"}
                                        data-testid={"portfolio_details_link"}
                                        to={`/portfolio-details/${
                                          data[row.id].portfolio_additional_detail_portfolio_id
                                        }`}
                                      >
                                        Details
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <Link
                                        id={"portfolio_details_new_link"}
                                        data-testid={"portfolio_details_new_link"}
                                        to={`/portfolio-details/new/${data[row.id].portfolio_id}`}
                                      >
                                        Create
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Pagination
              data={data}
              table={table}
              setPageViewNum={setPageViewNum}
              pageViewNum={pageViewNum}
              getPageViewOption={getPageViewOption}
            ></Pagination>
          </>
        ) : (
          <>
            <h2 className="text-center"> Application is starting</h2>
            <Loading></Loading>
          </>
        )}
      </Col>
    </div>
  );
};

export default PortfolioAndShareClassManagement;
