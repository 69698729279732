import Config from "../config";
import { Auth } from "aws-amplify";

export const fetchService = async (url, options = {}) => {
  const {
    api: { baseUrl },
  } = Config;
  if (!url) throw new Error("Must include URL for fetch");
  if (!options) options = { mode: "no-cors" };
  if (!options.headers) options.headers = {};
  if (!options.method) options.method = "GET";

  if (options.token) {
    options.headers.Authorization = `Bearer ${options.token.jwtToken}`;
  }

  let builtURL;

  if (!url.includes("http")) {
    builtURL = baseUrl + url;

    try {
      new URL(builtURL);
    } catch (error) {
      throw new Error(`Invalid URL provided to fetch: ${builtURL}`);
    }
  }
  if (options.token.payload.exp * 1000 - 120000 <= new Date().getTime()) {
    Auth.signOut();
  }

  let max_retries = 3;
  if (options.method !== "GET") {
    const activeCheck = await fetchService(baseUrl + "/v1/active-check", { method: "GET", token: options.token });
    if (activeCheck.status !== 200) {
      return activeCheck;
    }
    // Only allow the request to be sent once. and will not retry
    max_retries = 1;
  }
  let numberOfTries = 1;
  let successfulCall = false;
  let response;
  while (!successfulCall && numberOfTries <= max_retries) {
    try {
      const res = await fetch(builtURL || url, {
        method: options.method,
        headers: {
          ...options.headers,
        },
        body: options.body,
      });
      numberOfTries += 1;
      if (res.status === 200) {
        successfulCall = true;
      }
      response = res;
    } catch (err) {
      console.error("fetch err", err);
      console.log(err.message);
      response = new Response(JSON.stringify({ error: err.message }));
    }
  }
  return response;
};
