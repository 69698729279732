import React, { useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import UserContext from "../../../../context/User.context";
import Config from "../../../../config";
import { fetchService } from "../../../../services/fetchService";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@pds-react/button";
import NewInvestmentDetailsEdit from "./NewInvestmentDetailsEdit";
import { Validate, ValidateDate, validateGuid, ValidateRequiredSelect } from "../../../Utilities/ValidationHelpers";
import { handleSelectChange, handleChange } from "../../../Utilities/FormHelpers";
import NewTradingDetailsEdit from "./NewTradingDetailsEdit";

const NewAccountNewInvestment = () => {
  const d = new Date();
  const initialInvestmentData = {
    sales_region: { label: "", value: "" },
    approval_date: `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`,
    fund_group_name: { label: "", value: "" },
    share_class_name: { label: "", value: "" },
  };

  const initialTradingData = {
    service_provider: {
      label: "",
      value: "",
      nsccNumber: "",
    },
    service_provider_same_for_settlement: false,
    service_provider_for_settlement: {
      label: "",
      value: "",
      nsccNumber: "",
    },
    service_provider_bin: "",
    service_provider_sub_acct: "",
    trading_account_relationship: {
      label: "",
      value: "",
    },
    fif: false,
    trading_account_name: {
      label: "",
      value: "",
    },
    trading_wire_instructions: "",
  };

  const { accessToken } = useContext(UserContext);
  const { baseUrl } = Config.api;
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};

  //TRADING ACCOUNT STATE
  const [tradingData, setTradingData] = React.useState(initialTradingData);
  const [tradingAccountVisible, setTradingAccountVisible] = React.useState(true);

  //INVESTMENT STATE
  const [investmentData, setInvestmentData] = React.useState(initialInvestmentData);
  const [loadingData, setLoadingData] = React.useState(true);

  if (Object.keys(params).length !== 0) {
    validatedParams["participating_trust_id"] = validateGuid(params.participating_trust_id);
  }

  const initialInvestmentValidation = {
    fund_group_name: ValidateRequiredSelect,
    approval_date: ValidateDate,
    share_class_name: ValidateRequiredSelect,
    sales_region: ValidateRequiredSelect,
  };

  const initialTradingValidation = {
    trading_account_relationship: ValidateRequiredSelect,
    service_provider: ValidateRequiredSelect,
    service_provider_for_settlement: ValidateRequiredSelect,
  };

  const [validationErrors, setValidationError] = React.useState({});
  const [investmentFieldsToValidate] = React.useState(initialInvestmentValidation);
  const [tradingFieldsToValidate, setTradingFieldsToValidate] = React.useState(initialTradingValidation);

  const handleSubmit = async () => {
    const [formIsValid, errors] = Validate(investmentData, investmentFieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = `/v1/investment-with-trading-account`;

        let mappedData = {
          investment_account: {
            participating_trust_id: validatedParams.participating_trust_id,
            approval_date: investmentData.approval_date,
            invested_without_pa: false,
            reason_for_change: "Initial Creation",
            share_class_name: investmentData.share_class_name.value,
            sales_region_id: investmentData.sales_region.value,
            fund_group_id: investmentData.fund_group_name.value,
          },
        };

        if (tradingData.trading_account_name.value) {
          //omnibus
          mappedData.investment_account.trading_account_id = tradingData.trading_account_name.value;
          mappedData.trading_account = {
            trading_account_id: tradingData.trading_account_name.value,
          };
          mappedData.linked_investor = {
            participating_trust_id: validatedParams.participating_trust_id,
            trading_account_id: tradingData.trading_account_name.value,
            sub_account_number: tradingData.service_provider_sub_acct,
          };
        } else {
          //direct
          mappedData.investment_account.trading_account_id = null;
          mappedData.trading_account = {
            trading_account_id: null,
            account_type_id: tradingData.trading_account_relationship.value,
            settlement_service_provider_id: tradingData.service_provider_for_settlement.value,
            service_provider_id: tradingData.service_provider.value,
            reason_for_change: "Initial Creation",
            bin_number: tradingData.service_provider_bin,
            wire_instructions: tradingData.trading_wire_instructions,
          };
          mappedData.linked_investor = {};
        }

        let formattedData = JSON.stringify(mappedData);

        let response = await fetchService(baseUrl + endpoint, {
          method: "POST",
          body: formattedData,
          token: accessToken,
        });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        setLoadingData(false);
        navigate(`/trust-details/${validatedParams.participating_trust_id}`);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const handleComponentSwitch = () => {
    const [tradeAccountValid, errors] = Validate(tradingData, tradingFieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (tradeAccountValid) {
      setTradingAccountVisible(false);
    }
  };

  const handleReturnToInvestment = () => {
    setTradingAccountVisible(true);
  };

  const handleDiscard = () => {
    navigate(`/trust-details/${validatedParams.participating_trust_id}`);
  };
  return (
    <div>
      <Row data-testid={"NewAccountNewInvestment"}>
        <Col sm={6}>
          <h2>Add New Investment and Trading Account</h2>
        </Col>
        <Col sm={6} style={{ textAlign: "right" }}>
          {tradingAccountVisible ? (
            <Button data-testid="new-investment-button" onClick={handleComponentSwitch}>
              Next to Add New Investment
            </Button>
          ) : (
            <Button data-testid="trading-button" onClick={handleReturnToInvestment}>
              Return to Trading Account Screen
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {tradingAccountVisible ? (
            <NewTradingDetailsEdit
              initialTradingData={initialTradingData}
              tradingData={tradingData}
              setTradingData={setTradingData}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              setValidationError={setValidationError}
              validationErrors={validationErrors}
              tradingFieldsToValidate={tradingFieldsToValidate}
              setTradingFieldsToValidate={setTradingFieldsToValidate}
              loadingData={loadingData}
              setLoadingData={setLoadingData}
            ></NewTradingDetailsEdit>
          ) : (
            <NewInvestmentDetailsEdit
              handleChange={handleChange}
              setInvestmentData={setInvestmentData}
              handleSelectChange={handleSelectChange}
              handleSubmit={handleSubmit}
              handleDiscard={handleDiscard}
              investmentFieldsToValidate={investmentFieldsToValidate}
              setValidationError={setValidationError}
              investmentData={investmentData}
              validationErrors={validationErrors}
              loadingData={loadingData}
              setLoadingData={setLoadingData}
            ></NewInvestmentDetailsEdit>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NewAccountNewInvestment;
