import React from "react";
import "./ReasonForChange.scss";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";

const ReasonForChange = ({ reason_for_change, inserted_by, inserted_at }) => {
  const getDates = (date) => {
    if (date === "") {
      return date;
    }

    let newDate = date.concat("Z");

    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let gmtDate = new Date(newDate);
    let formattedLocalDateTime = gmtDate.toLocaleString("en-US", { timeZone: currentTimezone });
    return formattedLocalDateTime;
  };

  return (
    <>
      <label className="util-margin-top-sm-15 util-margin-top-xs-30">Prior Change Information</label>
      <p className="small-label">Updated By:</p>
      <p>{inserted_by}</p>
      <p className="small-label">Updated At:</p>
      <p>{getDates(inserted_at)}</p>
      <p className="small-label">Reason For Change:</p>
      <Col sm={12}>
        <Row>
          <Col style={{ marginTop: "5px" }} className="version-history">
            <p data-testid={`reason-for-change`} className="reason-box">
              {reason_for_change}
            </p>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ReasonForChange;

ReasonForChange.propTypes = {
  reason_for_change: PropTypes.string,
  inserted_by: PropTypes.string,
  inserted_at: PropTypes.string,
};
