import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import "../NewParticipationAgreement.scss";
import { HandleCheckboxChange, HandlePreventSubmit } from "./Helpers/FormHelpers";
import {
  FormatTaxId,
  Validate,
  ValidateDolPlanNumber,
  ValidatePlanTax,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
  ValidateWsrsContractNumber,
  FormatDol,
} from "../../Utilities/ValidationHelpers";
import Input from "@pds-react/input";
import Checkbox from "@pds-react/checkbox";
import Select from "react-select";
import Switch from "@pds-react/switch";
import Config from "../../../config";
import { fetchService } from "../../../services/fetchService";
import UserContext from "../../../context/User.context";
import ValidationErrorMessage from "../../Utilities/ValidationErrorMessage";
import Loading from "../../Loading/Loading";
import selectStyler from "../../Utilities/selectStyler";
import { new_pa } from "../Data";
import WsrsImport from "./WsrsImport";

const TrustInfoForm = ({ setForm, formData, navigation }) => {
  const { trust_info, wsrsStatus, wsrsContractNumber, service_provider_sub_acct } = formData;

  const {
    participating_trust_name,
    plan_type_id,
    plan_tax_id,
    dol_plan_number,
    agrees_to_email,
    agrees_to_third_party_disclosure,
    allows_statements,
    invested_without_pa,
  } = trust_info;

  const initialFieldsToValidate = {
    participating_trust_name: ValidateRequired,
    plan_tax_id: ValidatePlanTax,
    dol_plan_number: ValidateDolPlanNumber,
    plan_type_id: ValidateRequiredSelect,
  };

  const { accessToken } = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const [planTypeOptions, setPlanTypes] = useState([]);
  const [validationErrors, setValidationError] = useState({});
  const [fieldsToValidate, setFieldsToValidate] = useState(initialFieldsToValidate);
  const { next } = navigation;
  const { baseUrl } = Config.api;

  const goToNextPageCheck = () => {
    let allValidations = {};
    if (wsrsStatus) {
      allValidations = { ...trust_info, wsrsContractNumber, service_provider_sub_acct };
    } else {
      allValidations = { ...trust_info };
    }
    let [formIsValid, errors] = Validate(allValidations, fieldsToValidate);
    const a = { ...validationErrors, ...errors };
    setValidationError(a);
    if (formIsValid) {
      next();
    }
  };

  function handleNestedChange(e, id) {
    let value;
    if (e === null) {
      value = { label: "", value: "" };
    } else {
      if ("target" in e) {
        if (e.target.type === "checkbox") {
          value = e.target.checked;
        } else {
          value = e.target.value;
        }
      } else {
        value = e;
      }
    }
    const newFormData = { ...formData };
    newFormData["trust_info"][id] = value;
    setForm(newFormData);
  }

  useEffect(() => {
    const buildSelectOptions = (options) => {
      return options.map((opt) => ({
        value: opt.plan_type_id,
        label: opt.plan_type,
        ...opt,
      }));
    };
    const fetchOptions = async (endpoint, inputValue) => {
      setLoading(true);

      await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken }).then(async (response) => {
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp["requestId"];
          throw new Error(requestId);
        }
        const res = await resp["data"];
        setPlanTypes(buildSelectOptions(res, inputValue));
        setLoading(false);
      });
    };

    (async () => {
      await fetchOptions("/v1/plan-type");
    })();
  }, []);

  useEffect(() => {
    let newFieldsToValidate = { ...fieldsToValidate };
    if (wsrsStatus) {
      newFieldsToValidate["wsrsContractNumber"] = ValidateWsrsContractNumber;
      newFieldsToValidate["service_provider_sub_acct"] = ValidateWsrsContractNumber;
    } else {
      delete newFieldsToValidate.wsrsContractNumber;
      delete newFieldsToValidate.service_provider_sub_acct;
      setValidationError({});
    }
    setFieldsToValidate(newFieldsToValidate);
  }, [wsrsStatus]);

  useEffect(() => {
    validationUseEffectHelper(
      "participating_trust_name",
      participating_trust_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [participating_trust_name]);

  useEffect(() => {
    validationUseEffectHelper("plan_type_id", plan_type_id, fieldsToValidate, validationErrors, setValidationError);
  }, [plan_type_id]);

  useEffect(() => {
    validationUseEffectHelper("plan_tax_id", plan_tax_id, fieldsToValidate, validationErrors, setValidationError);
  }, [plan_tax_id]);

  useEffect(() => {
    validationUseEffectHelper(
      "dol_plan_number",
      dol_plan_number,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [dol_plan_number]);

  if (!isLoading) {
    return (
      <form
        data-testid="TrustInfo"
        onSubmit={(e) => {
          HandlePreventSubmit(e);
        }}
        style={{ marginBottom: "15%" }}
      >
        <Row>
          <Col sm={6} />
          <Col sm={6}>
            <div data-testid="next_button">
              <button type="button" className="btn btn-primary progress-btn" onClick={goToNextPageCheck}>
                Next - Plan Sponsor
                <span className="util-margin-left-15">
                  <i className="fa fa-angle-right" />
                </span>
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={2} />
          <Col sm={6}>
            <h2 className="text-center">Participating Trust Information</h2>
          </Col>
          <Col sm={2} />
        </Row>
        <Row>
          <Col sm={12} lg={2}>
            <Switch
              id={"switch-checked"}
              role="switch"
              label="WSRS Import"
              className={"pds-util-margin-bottom-md-46 util-margin-top-sm-15"}
              defaultChecked={wsrsStatus}
              onChange={(e) => {
                HandleCheckboxChange(e, "wsrsStatus", formData, setForm);
                if (!e.target.checked) {
                  const newFormData = JSON.parse(JSON.stringify(new_pa));
                  setForm(newFormData);
                }
              }}
            ></Switch>
          </Col>
          {wsrsStatus ? (
            <Col sm={12} lg={10}>
              <WsrsImport
                setForm={setForm}
                formData={formData}
                setValidationError={setValidationError}
                validationErrors={validationErrors}
                fieldsToValidate={fieldsToValidate}
                setLoading={setLoading}
                wsrsContractNumber={wsrsContractNumber}
                planTypeOptions={planTypeOptions}
              ></WsrsImport>
            </Col>
          ) : (
            <>
              <Col sm={12} lg={9}>
                <div className={"util-margin-top-lg-30 util-margin-bottom-lg-30"}></div>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <div className="util-margin-top-sm-15 util-margin-top-xs-30">
              <label>Plan Type</label>
              <Select
                datatestid={`plan_type_id`}
                id={`plan_type_id`}
                inputId={`plan_type_id`}
                options={planTypeOptions}
                value={plan_type_id["value"] !== "" ? plan_type_id : "undefined"}
                styles={selectStyler({
                  validationErrorsValue: validationErrors[`plan_type_id`],
                })}
                onChange={(e) => handleNestedChange(e, "plan_type_id")}
                isClearable={true}
                isLoading={isLoading}
              ></Select>
              <ValidationErrorMessage paramId={"plan_type_id"} validationError={validationErrors["plan_type_id"]} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="util-margin-top-sm-30 util-margin-top-xs-30">
              <p>
                Defined benefit, non-Govt 457(b) and 403(b) plans (except as provided above) are not eligible to invest
                in the Stable Value Products.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Input
              data-testid={`participating_trust_name`}
              id={`participating_trust_name`}
              type="text"
              label="Name of Participating Trust"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              value={participating_trust_name}
              onChange={(e) => handleNestedChange(e, "participating_trust_name")}
              disabled={false}
              errorMessage={validationErrors["participating_trust_name"]}
            ></Input>
          </Col>
          <Col sm={4} lg={4}></Col>
        </Row>
        <Row>
          <Col sm={4} lg={4}>
            <Input
              data-testid={`plan_tax_id`}
              id={`plan_tax_id`}
              type="text"
              label="Plan Tax ID (EIN)"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              value={plan_tax_id}
              onChange={(e) => {
                FormatTaxId(e);
                handleNestedChange(e, "plan_tax_id");
              }}
              disabled={false}
              errorMessage={validationErrors["plan_tax_id"]}
            ></Input>
          </Col>
          <Col sm={4} lg={4}>
            <Input
              data-testid={`dol_plan_number`}
              id={`dol_plan_number`}
              type="text"
              label="DOL Plan #"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              value={dol_plan_number}
              onChange={(e) => {
                FormatDol(e);
                handleNestedChange(e, "dol_plan_number");
              }}
              disabled={false}
              errorMessage={validationErrors["dol_plan_number"]}
            ></Input>
          </Col>
        </Row>
        <div className={"util-margin-top-sm-15 util-margin-top-xs-30"}></div>
        <Row>
          <Col sm={4} lg={4}>
            <Checkbox
              data-testid={`agrees_to_email`}
              id={`agrees_to_email`}
              label="Consents to email communications"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              checked={agrees_to_email || false}
              onChange={(e) => handleNestedChange(e, "agrees_to_email")}
              disabled={false}
              errorMessage={""}
              name={"agrees_to_email"}
            ></Checkbox>
          </Col>
          <Col sm={4} lg={4}>
            <Checkbox
              data-testid={`allows_statements`}
              id={`allows_statements`}
              label="Allows Statements"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              checked={allows_statements}
              onChange={(e) => handleNestedChange(e, "allows_statements")}
              errorMessage={""}
              name={"allows_statements"}
            ></Checkbox>
          </Col>
        </Row>
        <Row>
          <Col sm={4} lg={4}>
            <Checkbox
              data-testid={`agrees_to_third_party_disclosure`}
              id={`agrees_to_third_party_disclosure`}
              label="Consents to third party disclosure"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              checked={agrees_to_third_party_disclosure}
              onChange={(e) => handleNestedChange(e, "agrees_to_third_party_disclosure")}
              disabled={false}
              errorMessage={""}
            ></Checkbox>
          </Col>
          <Col sm={4} lg={4}>
            <Checkbox
              data-testid={`invested_without_pa`}
              id={`invested_without_pa`}
              label="Missing Documentation"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              checked={invested_without_pa}
              onChange={(e) => handleNestedChange(e, "invested_without_pa")}
              errorMessage={""}
              name={"invested_without_pa"}
            ></Checkbox>
          </Col>
        </Row>
      </form>
    );
  } else {
    return (
      <>
        <div data-testid="TrustInfo">
          <h2 className="text-center"> Application is starting</h2>
          <Loading />
        </div>
      </>
    );
  }
};

TrustInfoForm.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
};

export default TrustInfoForm;
