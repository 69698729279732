import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../../context/User.context";

const ProtectedRoutes = () => {
  const { loggedIn, scope } = useContext(UserContext);

  if (!loggedIn) {
    return <Navigate to="/login" replace />;
  }
  if (scope !== "ReadWrite") {
    return <Navigate to={"/unauthorized"} />;
  }
  return <Outlet />;
};

export default ProtectedRoutes;
