import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const FiduciaryCompanyDetail = ({ ...props }) => {
  const { fiduciaryCompanyName, fiduciaryCompanyId, fiduciaryContactName, fiduciaryContactId } = props;
  return (
    <>
      <Card heading={"Fiduciary Company"}>
        <Col sm={12}>
          <DataComponent
            data-testid={"fiduciary_company"}
            id={"fiduciary_company"}
            label="Company Name"
            data={fiduciaryCompanyName}
            editLink={fiduciaryCompanyId !== "" ? `/company-details/edit/${fiduciaryCompanyId}` : ""}
            detailsLink={fiduciaryCompanyId !== "" ? `/company-details/${fiduciaryCompanyId}` : ""}
          />
          <DataComponent
            data-testid={"fiduciary_contact"}
            id={"fiduciary_contact"}
            label="Contact Name"
            data={fiduciaryContactName}
            editLink={fiduciaryContactId !== "" ? `/contact-details/edit/${fiduciaryContactId}` : ""}
            detailsLink={fiduciaryContactId !== "" ? `/contact-details/${fiduciaryContactId}` : ""}
          />
        </Col>
      </Card>
    </>
  );
};

export default FiduciaryCompanyDetail;

FiduciaryCompanyDetail.propTypes = {
  fiduciaryCompanyName: PropTypes.string,
  fiduciaryCompanyId: PropTypes.string,
  fiduciaryContactName: PropTypes.string,
  fiduciaryContactId: PropTypes.string,
};
