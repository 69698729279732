import React from "react";
import ErrorPages from "@pds-react/errorPages";
import Button from "@pds-react/button";
import { useNavigate, useLocation } from "react-router-dom";

function ServerError() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="container" data-testid="ServerError">
      <div className="text-center panel panel-primary" style={{ borderRadius: "0px 0px 10px 10px", height: "100%" }}>
        <ErrorPages
          body={
            <>
              <br></br>
              <span>Please submit a ticket and include this request ID:</span>
              <br></br>
              <span style={{ fontWeight: 800 }}>{location.state.error.message}</span>
              <br></br>
              <br></br>
              <a
                className="pds-button pds-button-primary"
                href="https://servicemgmt.principal.com/CherwellPortal/ServiceDeskOnline?_=3bd096b6#0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Submit
              </a>
            </>
          }
          title="We're sorry, but something went wrong."
          variant="server"
        />
        <Button
          className={"util-margin-bottom-sm-15 util-margin-bottom-xs-15 util-margin-top-sm-15 util-margin-top-xs-15"}
          onClick={() => {
            navigate("/");
          }}
        >
          {"Head back to the home page"}
        </Button>
      </div>
    </div>
  );
}
ServerError.propTypes = {};
export default ServerError;
