import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Checkbox from "@pds-react/checkbox";
import Input from "@pds-react/input";
import Select from "react-select";
import Button from "@pds-react/button";
import Loading from "../../../../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import {
  HandleSameAsMultiFillCheckBox,
  HandleSameStreetAndMailingAddress,
} from "../../../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import { handleChange, handleSelectChange, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import {
  FormatPhoneNumber,
  PhoneNumberFormatOnLoad,
  PhoneNumberFormatOnSubmit,
  getTextAreaStyles,
  Validate,
  validateGuid,
  ValidatePhoneNumber,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../../Utilities/ValidationHelpers";
import selectStyler from "../../../../Utilities/selectStyler";
const CompanyDetailsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const [asyncOptions, setOptions] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["contact_company_id"] = validateGuid(params.contact_company_id);
  }

  const initialData = {
    company_name: "",
    phone_number: "",
    phone_extension: "",
    company_mailing_address: "",
    company_mailing_address_2: "",
    company_mailing_city: "",
    company_mailing_state: { value: "", label: "" },
    company_mailing_zip: "",
    company_mailing_country: { value: "", label: "" },
    street_address_same_as_mailing: false,
    company_street_address: "",
    company_street_address_2: "",
    company_street_city: "",
    company_street_state: { value: "", label: "" },
    company_street_zip: "",
    company_street_country: { value: "", label: "" },
    reason_for_change: "",
  };

  const [companyData, setCompany] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);

  const [validationErrors, setValidationError] = useState({});

  const fieldsToValidate = {
    company_name: ValidateRequired,
    company_mailing_address: ValidateRequired,
    company_mailing_city: ValidateRequired,
    company_mailing_state: ValidateRequiredSelect,
    company_mailing_zip: ValidateRequired,
    company_mailing_country: ValidateRequiredSelect,
    reason_for_change: ValidateRequired,
    phone_number: ValidatePhoneNumber,
  };

  const {
    company_id,
    company_name,
    phone_number,
    phone_extension,
    email,
    company_mailing_address,
    company_mailing_address_2,
    company_mailing_city,
    company_mailing_state,
    company_mailing_zip,
    company_mailing_country,
    street_address_same_as_mailing,
    company_street_address,
    company_street_address_2,
    company_street_city,
    company_street_state,
    company_street_zip,
    company_street_country,
    reason_for_change,
  } = companyData;

  const handleSubmit = async () => {
    let [formIsValid, errors] = Validate(companyData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        // validation happens here, then if valid
        setLoadingData(true);
        let company = {
          ...companyData,
        };
        company.phone_number = company.phone_number
          ? PhoneNumberFormatOnSubmit(company.phone_number)
          : company.phone_number;
        company.company_mailing_state = company.company_mailing_state.value;
        company.company_street_state = company.company_street_state.value;
        company.company_mailing_country = company.company_mailing_country.value;
        company.company_street_country = company.company_street_country.value;
        let endpoint = `/v1/contact-company`;
        let formattedData = JSON.stringify(company);
        let httpMethod = "PUT";
        if (Object.keys(validatedParams).length === 0) httpMethod = "POST";
        let response = await fetchService(baseUrl + endpoint, {
          method: httpMethod,
          body: formattedData,
          token: accessToken,
        });
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp.requestId;
          throw new Error(requestId);
        } else {
          setLoadingData(false);
          if (Object.keys(validatedParams).length === 0) {
            navigate(`/company-details/${resp.data.contact_company_id}`);
          } else {
            navigate(`/company-details/${validatedParams.contact_company_id}`);
          }
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const getCompany = async () => {
    let endpoint = `/v1/contact-company?contact-company-id=${validatedParams.contact_company_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  useEffect(() => {
    if (Object.keys(validatedParams).length === 0) {
      setLoadingData(false);
    } else {
      const setupAPIData = async () => {
        try {
          let companyDetails = await getCompany();
          let company = companyDetails.data[0];
          company = set_nulls_to_empty_strings(company);
          company.phone_number = PhoneNumberFormatOnLoad(company.phone_number);
          company.street_address_same_as_mailing =
            company.company_street_address === company.company_mailing_address &&
            company.company_street_address_2 === company.company_mailing_address_2 &&
            company.company_street_city === company.company_mailing_city &&
            company.company_street_state === company.company_mailing_state &&
            company.company_street_zip === company.company_mailing_zip &&
            company.company_street_country === company.company_mailing_country;
          company.company_mailing_state = {
            value: company.company_mailing_state,
            label: company.company_mailing_state,
          };
          company.company_street_state = { value: company.company_street_state, label: company.company_street_state };
          company.company_mailing_country = {
            value: company.company_mailing_country,
            label: company.company_mailing_country,
          };
          company.company_street_country = {
            value: company.company_street_country,
            label: company.company_street_country,
          };
          company.reason_for_change = "";

          setCompany(company);
          setLoadingData(false);
        } catch (error) {
          navigate("/error", { state: { error } });
        }
      };
      setupAPIData();
    }
  }, []);

  useEffect(() => {
    validationUseEffectHelper("company_name", company_name, fieldsToValidate, validationErrors, setValidationError);
  }, [company_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_address",
      company_mailing_address,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_address]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_city",
      company_mailing_city,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_city]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_state",
      company_mailing_state,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_state]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_zip",
      company_mailing_zip,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_zip]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_country",
      company_mailing_country,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_country]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  useEffect(() => {
    validationUseEffectHelper("phone_number", phone_number, fieldsToValidate, validationErrors, setValidationError);
  }, [phone_number]);

  useEffect(() => {
    const buildSelectOptions = (options) => {
      return options.map((opt) => ({
        value: opt.name || opt.state_code,
        label: opt.name || opt.state_code,
        ...opt,
      }));
    };
    const fetchOptions = async (endpoint) => {
      try {
        if (!loadingData) setLoadingData(true);

        const { baseUrl } = Config.api;

        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp["requestId"];
          throw new Error(requestId);
        }
        const res = await resp["data"];
        setOptions(buildSelectOptions(res));
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };

    fetchOptions("/v1/us-states");
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={9}>
              <h2>
                {Object.keys(validatedParams).length === 0
                  ? "New Contact Company Detail"
                  : "Edit Contact Company Detail"}
              </h2>
              <p>{company_id}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card heading={"Company Detail"}>
                <Row>
                  <Col sm={8}>
                    <Input
                      data-testid={"company_name"}
                      id={"company_name"}
                      type="text"
                      label="Company Name"
                      value={company_name}
                      onChange={(e) => {
                        handleChange(e, "company_name", setCompany);
                      }}
                      errorMessage={validationErrors["company_name"]}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5}>
                    <Input
                      data-testid={"phone_number"}
                      id={"phone_number"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={phone_number}
                      label="Phone Number"
                      onChange={(e) => {
                        FormatPhoneNumber(e);
                        handleChange(e, "phone_number", setCompany);
                      }}
                      errorMessage={validationErrors["phone_number"]}
                    ></Input>
                  </Col>
                  <Col sm={3}>
                    <Input
                      data-testid={"phone_extension"}
                      id={"phone_extension"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={phone_extension}
                      label="Ext"
                      onChange={(e) => {
                        handleChange(e, "phone_extension", setCompany);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={9} lg={9}>
                    <Input
                      data-testid={"email"}
                      id={"email"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      label="Email"
                      value={email}
                      onChange={(e) => {
                        handleChange(e, "email", setCompany);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Company Address"}>
                <Row>
                  <Col sm={12}>
                    <Input
                      data-testid={"company_mailing_address"}
                      id={"company_mailing_address"}
                      type="text"
                      value={company_mailing_address}
                      label="Mailing Address"
                      onChange={(e) => {
                        HandleSameStreetAndMailingAddress(
                          "company_mailing_address",
                          e.target.value,
                          "company_street_address",
                          street_address_same_as_mailing,
                          companyData,
                          setCompany
                        );
                      }}
                      errorMessage={validationErrors["company_mailing_address"]}
                    ></Input>
                    <Input
                      data-testid={"company_mailing_address_2"}
                      id={"company_mailing_address_2"}
                      type="text"
                      label=""
                      value={company_mailing_address_2}
                      onChange={(e) => {
                        HandleSameStreetAndMailingAddress(
                          "company_mailing_address_2",
                          e.target.value,
                          "company_street_address_2",
                          street_address_same_as_mailing,
                          companyData,
                          setCompany
                        );
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={5}>
                    <Input
                      data-testid={"company_mailing_city"}
                      id={"company_mailing_city"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      label="City"
                      value={company_mailing_city}
                      onChange={(e) => {
                        HandleSameStreetAndMailingAddress(
                          "company_mailing_city",
                          e.target.value,
                          "company_street_city",
                          street_address_same_as_mailing,
                          companyData,
                          setCompany
                        );
                      }}
                      errorMessage={validationErrors["company_mailing_city"]}
                    ></Input>
                  </Col>
                  <Col sm={3}>
                    <div data-testid={"company_mailing_state"}>
                      <label className="util-margin-top-sm-15 util-margin-top-xs-30">State</label>
                      <Select
                        isClearable={true}
                        value={company_mailing_state["label"] !== "" ? company_mailing_state : "undefined"}
                        options={asyncOptions}
                        id={"company_mailing_state"}
                        styles={selectStyler({
                          validationErrorsValue: validationErrors["company_mailing_state"],
                        })}
                        onChange={(e) => {
                          handleSelectChange("company_mailing_state", e, setCompany);
                          if (e === null) {
                            handleSelectChange("company_mailing_country", e, setCompany);
                          } else {
                            let countryValue = { label: "US", value: "US" };
                            handleSelectChange("company_mailing_country", countryValue, setCompany);
                          }
                          HandleSameStreetAndMailingAddress(
                            "company_mailing_state",
                            e,
                            "company_street_state",
                            street_address_same_as_mailing,
                            companyData,
                            setCompany
                          );
                        }}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"company_mailing_state"}
                        validationError={validationErrors["company_mailing_state"]}
                      />
                    </div>
                  </Col>
                  <Col sm={2}>
                    <Input
                      data-testid={"company_mailing_zip"}
                      id={"company_mailing_zip"}
                      type="text"
                      value={company_mailing_zip}
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      label="Zip"
                      onChange={(e) => {
                        HandleSameStreetAndMailingAddress(
                          "company_mailing_zip",
                          e.target.value,
                          "company_street_zip",
                          street_address_same_as_mailing,
                          companyData,
                          setCompany
                        );
                      }}
                      errorMessage={validationErrors["company_mailing_zip"]}
                    ></Input>
                  </Col>
                  <Col sm={2}>
                    <div data-testid={"company_mailing_country"}>
                      <label className="util-margin-top-sm-15 util-margin-top-xs-30">Country</label>
                      <Select
                        isClearable={true}
                        data-testid={"company_mailing_country"}
                        value={company_mailing_country["label"] !== "" ? company_mailing_country : "undefined"}
                        options={[
                          { value: "US", label: "US" },
                          { value: "CA", label: "CA" },
                        ]}
                        id={"company_mailing_country"}
                        styles={selectStyler({
                          validationErrorsValue: validationErrors["company_mailing_country"],
                        })}
                        onChange={(e) => {
                          handleSelectChange("company_mailing_country", e, setCompany);
                          HandleSameStreetAndMailingAddress(
                            "company_mailing_country",
                            e,
                            "company_street_country",
                            street_address_same_as_mailing,
                            companyData,
                            setCompany
                          );
                        }}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"company_mailing_country"}
                        validationError={validationErrors["company_mailing_country"]}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Checkbox
                      data-testid={"street_address_same_as_mailing"}
                      id={"street_address_same_as_mailing"}
                      label="Street Address is same as Mailing Address"
                      checked={street_address_same_as_mailing}
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      onChange={(e) => {
                        const companyStreetAddress = {
                          company_mailing_address: "company_street_address",
                          company_mailing_address_2: "company_street_address_2",
                          company_mailing_city: "company_street_city",
                          company_mailing_state: "company_street_state",
                          company_mailing_zip: "company_street_zip",
                          company_mailing_country: "company_street_country",
                        };
                        HandleSameAsMultiFillCheckBox(
                          "street_address_same_as_mailing",
                          e.target.checked,
                          companyStreetAddress,
                          companyData,
                          setCompany
                        );
                      }}
                    ></Checkbox>
                  </Col>
                </Row>
                {street_address_same_as_mailing === false ? (
                  <div>
                    <Row>
                      <Col sm={12}>
                        <Input
                          data-testid={"company_street_address"}
                          id={"company_street_address"}
                          type="text"
                          label="Street Address"
                          value={company_street_address}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          disabled={street_address_same_as_mailing}
                          onChange={(e) => {
                            handleChange(e, "company_street_address", setCompany);
                          }}
                        ></Input>
                        <Input
                          data-testid={"company_street_address_2"}
                          id={"company_street_address_2"}
                          type="text"
                          label=""
                          value={company_street_address_2}
                          disabled={street_address_same_as_mailing}
                          onChange={(e) => {
                            handleChange(e, "company_street_address_2", setCompany);
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={5}>
                        <Input
                          data-testid={"company_street_city"}
                          id={"company_street_city"}
                          type="text"
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          label="City"
                          value={company_street_city}
                          disabled={street_address_same_as_mailing}
                          onChange={(e) => {
                            handleChange(e, "company_street_city", setCompany);
                          }}
                        ></Input>
                      </Col>
                      <Col sm={3}>
                        <div data-testid={"company_street_state"}>
                          <label className="util-margin-top-sm-15 util-margin-top-xs-30">State</label>
                          <Select
                            isClearable={true}
                            data-testid={"company_street_state"}
                            value={company_street_state["label"] !== "" ? company_street_state : "undefined"}
                            disabled={street_address_same_as_mailing}
                            options={asyncOptions}
                            id={"company_street_state"}
                            styles={selectStyler({})}
                            onChange={(e) => {
                              handleSelectChange("company_street_state", e, setCompany);
                              if (e === null) {
                                handleSelectChange("company_street_country", e, setCompany);
                              } else {
                                let countryValue = { label: "US", value: "US" };
                                handleSelectChange("company_street_country", countryValue, setCompany);
                              }
                            }}
                          ></Select>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <Input
                          data-testid={"company_street_zip"}
                          id={"company_street_zip"}
                          type="text"
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          value={company_street_zip}
                          label="Zip"
                          disabled={street_address_same_as_mailing}
                          onChange={(e) => {
                            handleChange(e, "company_street_zip", setCompany);
                          }}
                        ></Input>
                      </Col>
                      <Col sm={2}>
                        <div data-testid={"company_street_country"}>
                          <label className="util-margin-top-sm-15 util-margin-top-xs-30">Country</label>
                          <Select
                            isClearable={true}
                            data-testid={"company_street_country"}
                            id={"company_street_country"}
                            value={company_street_country["label"] !== "" ? company_street_country : "undefined"}
                            options={[
                              { value: "US", label: "US" },
                              { value: "CA", label: "CA" },
                            ]}
                            disabled={street_address_same_as_mailing}
                            styles={selectStyler({})}
                            onChange={(e) => {
                              handleSelectChange("company_street_country", e, setCompany);
                            }}
                          ></Select>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Notes"}>
                <Col sm={12}>
                  <label>Reason For Change</label>
                  <div>
                    <textarea
                      data-testid={"reason_for_change"}
                      style={getTextAreaStyles(validationErrors["reason_for_change"])}
                      value={reason_for_change}
                      onChange={(e) => {
                        handleChange(e, "reason_for_change", setCompany);
                      }}
                      // disabled
                    ></textarea>
                  </div>
                  <ValidationErrorMessage
                    paramId={"reason_for_change"}
                    validationError={validationErrors["reason_for_change"]}
                  />
                </Col>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button
                data-testid={"saveChanges"}
                style={{ marginRight: 15 }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Changes
              </Button>
              <Button
                data-testid={"discardChanges"}
                onClick={() => {
                  if (Object.keys(validatedParams).length === 0) {
                    navigate(`/contact-company-management`);
                  } else {
                    navigate(`/company-details/${validatedParams.contact_company_id}`);
                  }
                }}
              >
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default CompanyDetailsEdit;
