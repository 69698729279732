import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../services/fetchService";
import Config from "../../../../config";
import UserContext from "../../../../context/User.context";
import Card from "./../../Card";
import Loading from "../../../Loading/Loading";
import Button from "@pds-react/button";
import Input from "@pds-react/input";
import { handleChange, set_nulls_to_empty_strings } from "../../../Utilities/FormHelpers";
import { useParams, useNavigate } from "react-router-dom";
import Select, { createFilter } from "react-select";
import {
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
  Validate,
  getTextAreaStyles,
  validateGuid,
} from "../../../Utilities/ValidationHelpers";
import ValidationErrorMessage from "../../../Utilities/ValidationErrorMessage";
import selectStyler from "../../../Utilities/selectStyler";

const GroupingsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const [groupCategory, setGroupCategory] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [validationErrors, setValidationError] = useState({});
  const validatedParams = {};

  if (Object.keys(params).length !== 0) {
    validatedParams["aggregation_group_id"] = validateGuid(params.aggregation_group_id);
  }
  const [groupingsData, setData] = useState({
    aggregation_group_control_number: "",
    aggregation_group_category_name: { label: "", value: "" },
    aggregation_group_name: "",
    reason_for_change: "",
  });

  let initialFieldsToValidate = {
    aggregation_group_category_name: ValidateRequiredSelect,
    aggregation_group_name: ValidateRequired,
    reason_for_change: ValidateRequired,
  };
  const [fieldsToValidate] = useState(initialFieldsToValidate);

  const {
    aggregation_group_control_number,
    aggregation_group_category_name,
    aggregation_group_name,
    reason_for_change,
  } = groupingsData;

  const getGroupingsDetails = async () => {
    let endpoint = `/v1/groupings?aggregation-group-id=${validatedParams.aggregation_group_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getGroupCategoryData = async () => {
    try {
      let endpoint = "/v1/aggregation-group-category";
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      resp.data = set_nulls_to_empty_strings(resp.data);
      setGroupCategory(
        resp.data.map((result) => {
          return { label: result.aggregation_group_category_name, value: result.aggregation_group_category_id };
        })
      );
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const handleSave = async () => {
    let [formIsValid, errors] = Validate(groupingsData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = "/v1/aggregation-group";

        groupingsData.aggregation_group_category_id = aggregation_group_category_name.value;

        let formattedData = JSON.stringify(groupingsData);
        if (Object.keys(validatedParams).length !== 0) {
          let response = await fetchService(baseUrl + endpoint, {
            method: "PUT",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          setLoadingData(false);
          navigate(`/groupings-details/${validatedParams.aggregation_group_id}`);
        } else {
          let response = await fetchService(baseUrl + endpoint, {
            method: "POST",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          setLoadingData(false);
          navigate(`/groupings-details/${resp.data.aggregation_group_id}`);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const handleSelectChange = (id, value) => {
    if (value === null) {
      value = { value: "", label: "" };
    }
    setData({
      ...groupingsData,
      [id]: {
        label: value.label,
        value: value.value,
      },
    });
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let groupingDetails = await set_nulls_to_empty_strings(getGroupingsDetails());
        let groupings = groupingDetails.data[0];
        groupings = set_nulls_to_empty_strings(groupings);

        let updateData = {};
        updateData.aggregation_group_id = groupings.aggregation_group_id;
        updateData.aggregation_group_category_name = groupings.aggregation_group_category_name
          ? {
              label: groupings.aggregation_group_category_name,
              value: groupings.aggregation_group_category_id,
            }
          : { label: "", value: "" };
        updateData.aggregation_group_control_number = groupings.aggregation_group_control_number;
        updateData.aggregation_group_name = groupings.aggregation_group_name;
        updateData.reason_for_change = "";
        setData(updateData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    if (Object.keys(validatedParams).length !== 0) {
      setupAPIData();
    } else {
      setLoadingData(false);
    }
  }, []);

  useEffect(() => {
    getGroupCategoryData();
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "aggregation_group_category_name",
      aggregation_group_category_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [aggregation_group_category_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "aggregation_group_name",
      aggregation_group_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [aggregation_group_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  if (!loadingData) {
    return (
      <div data-testid={`groupings_edit`} id={`groupings_edit`}>
        <Col lg={12}>
          <Row>
            <Col sm={12}>
              <h3>{Object.keys(validatedParams).length !== 0 ? "Edit Groupings Details" : "New Groupings Details"}</h3>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Card heading={"Groupings"}>
                    <Row>
                      <Col sm={4}>
                        <div data-testid={`group_type`}>
                          <label className="util-margin-top-sm-15 util-margin-top-xs-30" htmlFor={`group_type`}>
                            Grouping Category
                          </label>
                          <Select
                            id={`groupCategory`}
                            label={`groupCategory`}
                            styles={selectStyler({
                              validationErrorsValue: validationErrors["aggregation_group_category_name"],
                            })}
                            options={groupCategory}
                            onChange={(e) => {
                              handleSelectChange("aggregation_group_category_name", e);
                            }}
                            filterOption={createFilter({ ignoreAccents: false })}
                            isDisabled={!!validatedParams.aggregation_group_id}
                            value={
                              aggregation_group_category_name["label"] !== ""
                                ? aggregation_group_category_name
                                : "undefined"
                            }
                          ></Select>
                        </div>
                        <ValidationErrorMessage
                          paramId={"aggregation_group_category_name"}
                          validationError={validationErrors["aggregation_group_category_name"]}
                        />
                      </Col>
                      <Col>
                        <div className="util-margin-top-sm-15 util-margin-top-xs-30">
                          <Input
                            data-testid={"aggregation_group_control_number"}
                            id={"aggregation_group_control_number"}
                            type="text"
                            label="Control Number"
                            value={aggregation_group_control_number}
                            onChange={(e) => {
                              handleChange(e, "aggregation_group_control_number", setData);
                            }}
                          ></Input>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8} className="util-margin-top-sm-15 util-margin-top-xs-30">
                        <Input
                          data-testid={"aggregation_group_name"}
                          id={"aggregation_group_name"}
                          type="text"
                          label="Grouping Name"
                          value={aggregation_group_name}
                          onChange={(e) => {
                            handleChange(e, "aggregation_group_name", setData);
                          }}
                          errorMessage={validationErrors["aggregation_group_name"]}
                        ></Input>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Notes"}>
                <Row>
                  <Col sm={12}>
                    <label>Reason For Change</label>
                    <div id={"notes"}>
                      <textarea
                        data-testid={"notes"}
                        style={getTextAreaStyles(validationErrors["reason_for_change"])}
                        value={reason_for_change}
                        onChange={(e) => {
                          handleChange(e, "reason_for_change", setData);
                        }}
                      ></textarea>
                    </div>
                    <ValidationErrorMessage
                      paramId={"reason_for_change"}
                      validationError={validationErrors["reason_for_change"]}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button
                style={{ marginRight: 15 }}
                data-testid={"saveChanges"}
                id={"saveChanges"}
                onClick={() => {
                  handleSave();
                }}
              >
                Save Changes
              </Button>
              <Button
                data-testid={"discardChanges"}
                id={"discardChanges"}
                onClick={() => {
                  Object.keys(validatedParams).length !== 0
                    ? navigate(`/groupings-details/${validatedParams.aggregation_group_id}`)
                    : navigate(`/groupings-management`);
                }}
              >
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default GroupingsEdit;
