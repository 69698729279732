import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import { Amplify } from "aws-amplify";
import Config from "./config";
import { BrowserRouter } from "react-router-dom";
import "@pds-react/button/dist/button.min.css";
import "@pds-react/switch/dist/switch.min.css";
import "@pds-react/input/dist/input.min.css";
import "@pds-react/grid/dist/grid.min.css";
import "@pds-react/tab/dist/tab.min.css";
import "@pds-react/checkbox/dist/checkbox.min.css";
import "@pds-react/errorPages/dist/unauthorizedError.min.css";
import "@pds-react/errorPages/dist/maintenanceError.min.css";
import "@pds-react/errorPages/dist/serverError.min.css";
import "@pds-react/errorPages/dist/notFoundError.min.css";
import "@pds-react/core/dist/global.min.css";
import "@pds-react/core/dist/icon.min.css";
import "@pds-react/core/dist/color.min.css";
import "@pds-react/core/dist/util.min.css";
import "@pds/table/table.min.css";

const {
  cognito: { userPoolId, domain, clientId, redirectUrl },
  region: region,
} = Config;
Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: region,
    userPoolId: userPoolId,
    userPoolWebClientId: clientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  oauth: {
    domain: domain,
    scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
    responseType: "token",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
