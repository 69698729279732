export const HandleSameAsMultiFillCheckBox = (id, value, valuesToChange, formData, setForm) => {
  setForm((formData) => ({ ...formData, [id]: value }));

  HandleMultiStateUpdate(value, valuesToChange, formData, setForm);
};

export const HandleMultiStateUpdate = (value, valuesToChange, formData, setForm) => {
  for (const property in valuesToChange) {
    let update = { value: "", label: "" };
    if (value) {
      update = formData[property];
    } else {
      if (typeof formData[property] === "string") {
        update = "";
      } else {
        if (typeof formData[property] === "boolean") {
          update = false;
        }
      }
    }
    setForm((formData) => ({ ...formData, [valuesToChange[property]]: update }));
  }
};

export const HandleSameStreetAndMailingAddress = (id, value, secondId, check, formData, setForm) => {
  if (value === null) {
    value = { label: "", value: "" };
  }
  setForm((formData) => ({ ...formData, [id]: value }));
  if (check) {
    setForm((formData) => ({ ...formData, [secondId]: value }));
  }
};

export const HandleSelectChange = (id, value, formData, setForm) => {
  if (value === null) {
    value = { value: "", label: "" };
  }
  setForm((formData) => ({
    ...formData,
    [id]: {
      label: value.label,
      value: value.value,
    },
  }));
};

export const HandleChangeValidate = (
  e,
  id,
  formData,
  setForm,
  setValidationError,
  validationErrors,
  validationFunction
) => {
  HandleChange(e, id, formData, setForm);
  const error = validationFunction(e.target.value);
  setValidationError((validationErrors) => ({ ...validationErrors, [id]: error }));
};

export const HandleChange = (e, id, formData, setForm) => {
  setForm((formData) => ({ ...formData, [id]: e.target.value }));
};

export const HandleCheckboxChange = (e, id, formData, setForm) => {
  setForm((formData) => ({ ...formData, [id]: e.target.checked }));
};

export const HandlePreventSubmit = (e) => {
  e.preventDefault();
};

export const OnEnterKeyPress = (e, handler) => {
  let key = e.keyCode || e.which;
  if (key === 13) {
    handler();
  }
};
