import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import "../NewParticipationAgreement.scss";
import Tab from "@pds-react/tab";
import { TabPanel } from "@pds-react/tab";
import Select from "react-select";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import ReactModal from "react-modal";
import { HandleChangeValidate, HandlePreventSubmit, HandleSelectChange } from "./Helpers/FormHelpers";
import {
  getSelectValidationStyle,
  Validate,
  ValidateDate,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../Utilities/ValidationHelpers";
import Config from "../../../config";
import { fetchService } from "../../../services/fetchService";
import UserContext from "../../../context/User.context";
import ValidationErrorMessage from "../../Utilities/ValidationErrorMessage";
import HeaderAndButtons from "./Shared/HeaderAndButtons";
import selectStyler from "../../Utilities/selectStyler";
import modalStyles from "../../Utilities/Modal/ModalSyles";

if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

const InvestmentInfoForm = ({ setForm, formData, navigation }) => {
  const { investments, investment_approval_date, investment_sales_region, wsrsStatus, service_provider_sub_acct } =
    formData;

  const [open, setOpen] = useState(false);
  const [currentShareClass, setShareClass] = useState();
  const [isLoading, setLoading] = useState(false);
  const [funds, setFundOptions] = useState();
  const [salesRegionOptions, setSalesRegionOptions] = useState();
  const [investmentAdded, setInvestmentAdded] = useState("tab 0");
  const { accessToken } = useContext(UserContext);
  const { baseUrl } = Config.api;

  const initialValidation = {
    investments: [],
    investment_approval_date: ValidateDate,
    investment_sales_region: ValidateRequiredSelect,
  };

  (() => {
    for (let i = 0; i < investments.length; i++) {
      initialValidation.investments.push({
        fund: ValidateRequiredSelect,
        share_class: ValidateRequiredSelect,
      });
    }
  })();

  const [validationErrors, setValidationError] = useState({ investments: [] });
  const [fieldsToValidate, setFieldsToValidate] = useState(initialValidation);

  const { previous, next } = navigation;

  //Dropdown to get Fund Options
  const getFundOptions = async () => {
    let endpoint = "/v1/fund-group";
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }

    const res = await resp["data"];

    setFundOptions(
      res.map((result) => {
        return { label: result.fund_group_name, value: result.fund_group_id };
      })
    );
  };

  const buildShareClassOptions = (options) => {
    return options.map((result) => ({
      label: result.share_class_name,
      value: result.share_class_id,
      displayClass: result.cusip,
    }));
  };

  //Dropdown to get Shareclass data
  const getShareClassOptions = async (currentFund) => {
    setShareClass([]);
    setLoading(true);
    if (currentFund === null || currentFund === undefined || currentFund === "") {
      setLoading(false);
    } else {
      let endpoint = `/v1/investment-account-to-share-classes?fund-group-id=${currentFund}`;
      const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        const requestId = resp["requestId"];
        throw new Error(requestId);
      }
      const res = await resp["data"];
      setShareClass(buildShareClassOptions(res));
      setLoading(false);
    }
  };

  const buildSalesRegionOptions = (options) => {
    return options.map((result) => ({
      label: result.sales_region,
      value: result.sales_region_id,
    }));
  };

  const getSalesRegionOptions = async () => {
    let endpoint = "/v1/sales-region";
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    const res = resp["data"];
    if (investment_sales_region.label === "") {
      if (wsrsStatus && service_provider_sub_acct) {
        for (const index in res) {
          if (res[index].sales_region === "WSRS") {
            setForm((formData) => ({
              ...formData,
              investment_sales_region: {
                label: res[index].sales_region,
                value: res[index].sales_region_id,
              },
            }));
          }
        }
      } else {
        for (const index in res) {
          if (res[index].sales_region === "Principal Asset Management") {
            setForm((formData) => ({
              ...formData,
              investment_sales_region: {
                label: res[index].sales_region,
                value: res[index].sales_region_id,
              },
            }));
          }
        }
      }
    }
    setSalesRegionOptions(buildSalesRegionOptions(res));
  };

  const handleInvestmentSelectChange = (e, investments, index, id) => {
    const newFormData = { ...formData };
    let value = e;
    if (e === null) {
      value = { label: "", value: "" };
    }
    if (id === "fund" || id === "share_class") {
      let newInvestments = investments.map((investment) => Object.assign({}, investment));
      newInvestments[index][id] = value;
      if (id === "fund") {
        newInvestments[index].share_class = { value: "", label: "" };
      }
      newFormData.investments = newInvestments;
    }
    if (id !== "fund" && id !== "share_class") {
      newFormData[id] = value;
    }
    setForm(newFormData);
  };

  const handleAddInvestment = (investments) => {
    const newFormData = { ...formData };
    //set new investments
    let newInvestments = investments.map((investment) => Object.assign({}, investment));
    let newInvestment = {
      fund: {
        label: "",
        value: "",
      },
      share_class: {
        label: "",
        value: "",
      },
    };
    newInvestments.push(newInvestment);
    newFormData.investments = newInvestments;

    //set new fieldsToValidate
    let newFieldsToValidate = { ...fieldsToValidate };
    let newInvestValidations = newFieldsToValidate.investments.map((investment) => Object.assign({}, investment));
    let newInvestValidation = { fund: ValidateRequiredSelect, share_class: ValidateRequiredSelect };
    newInvestValidations.push(newInvestValidation);
    newFieldsToValidate.investments = newInvestValidations;
    setFieldsToValidate(newFieldsToValidate);
    setForm(newFormData);
    setInvestmentAdded(`tab ${investments.length}`);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDeleteModal = (investments, index) => {
    const newFormData = { ...formData };
    let newInvestments = investments.map((investment) => Object.assign({}, investment));
    newInvestments.splice(index, 1);
    newFormData.investments = newInvestments;

    const newValidationErrors = { ...validationErrors };
    let newInvestErrors = newValidationErrors.investments.map((investment) => Object.assign({}, investment));
    newInvestErrors.splice(index, 1);
    newValidationErrors.investments = newInvestErrors;
    setValidationError(newValidationErrors);

    const newFieldsToValidate = { ...fieldsToValidate };
    let newInvestValidations = newFieldsToValidate.investments.map((investment) => Object.assign({}, investment));
    newInvestValidations.splice(index, 1);
    newFieldsToValidate.investments = newInvestValidations;
    setFieldsToValidate(newFieldsToValidate);

    setForm(newFormData);
    setOpen(false);
  };

  const handleUpdate = (investments) => {
    const timeout = setTimeout(() => {
      if (investments.length > 2) {
        document.getElementById("tab 0").click();
      }
    }, 100);
    return () => clearTimeout(timeout);
  };

  const goToNextPageCheck = () => {
    let [formIsValid, errors] = Validate(formData, fieldsToValidate);
    setValidationError(errors);
    if (formIsValid) {
      next();
    }
  };

  useEffect(() => {
    const newValidationErrors = { ...validationErrors };
    const investmentValidationErrors = [];
    investments.forEach((investment, index) => {
      const fieldsToValidateForInvestment = fieldsToValidate.investments[index];
      const individualInvestmentErrors = {};
      for (const investmentKey in fieldsToValidateForInvestment) {
        const validationFunct = fieldsToValidateForInvestment[investmentKey];
        if (investmentKey in investment && investment[investmentKey].value !== "") {
          individualInvestmentErrors[investmentKey] = validationFunct(investment[investmentKey]);
        }
      }
      investmentValidationErrors.push(individualInvestmentErrors);
    });
    newValidationErrors.investments = investmentValidationErrors;
    setValidationError(newValidationErrors);
  }, [investments]);

  useEffect(() => {
    validationUseEffectHelper(
      "investment_sales_region",
      investment_sales_region,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [investment_sales_region]);

  useEffect(() => {
    if (investments.length > 1) {
      const timeout = setTimeout(() => {
        document.getElementById(investmentAdded).click();
      });
      return () => clearTimeout(timeout);
    }
  }, [investmentAdded]);

  useEffect(() => {
    (async () => {
      await getFundOptions();
      await getSalesRegionOptions();
    })();
  }, []);

  useEffect(() => {
    if (investment_approval_date === "") {
      setFieldsToValidate((fieldsToValidate) => ({
        ...fieldsToValidate,
        investment_approval_date: ValidateRequired,
      }));
    }
  }, [investment_approval_date]);

  function GetInvestmentsWithErrors() {
    let errorMessage = "";
    const investmentErrorIndexes = [];
    if (Array.isArray(validationErrors["investments"])) {
      validationErrors["investments"].forEach((investment, index) => {
        if ("fund" in investment) {
          if (investment["fund"] !== undefined && investment["fund"] !== null && investment["fund"] !== "") {
            if (!investmentErrorIndexes.includes(index + 1)) {
              investmentErrorIndexes.push(index + 1);
            }
          }
        }
        if ("share_class" in investment) {
          if (
            investment["share_class"] !== undefined &&
            investment["share_class"] !== null &&
            investment["share_class"] !== ""
          ) {
            if (!investmentErrorIndexes.includes(index + 1)) {
              investmentErrorIndexes.push(index + 1);
            }
          }
        }
      });
    }
    if (investmentErrorIndexes.length > 0) {
      errorMessage += "The following ";
      if (investmentErrorIndexes.length === 1) {
        errorMessage += `investment needs to be corrected: [ ${investmentErrorIndexes[0]} ]`;
      } else {
        errorMessage += "investments need to be corrected: [ ";
        investmentErrorIndexes.forEach((tabNumber, index) => {
          errorMessage += `${tabNumber}`;
          if (index < investmentErrorIndexes.length - 1) {
            errorMessage += ", ";
          }
        });
        errorMessage += " ]";
      }
      return errorMessage;
    }
  }

  return (
    <form
      data-testid="investment_info"
      onSubmit={(e) => {
        HandlePreventSubmit(e);
      }}
    >
      <HeaderAndButtons
        prev_label={"Trading Account"}
        prev_funct={previous}
        next_label={"Groupings"}
        next_funct={goToNextPageCheck}
        header_label={"Investments"}
      />
      <Row>
        <Col lg={12} sm={12}>
          <p style={{ color: "indianred" }}>{GetInvestmentsWithErrors()}</p>
        </Col>
      </Row>
      <Row>
        {investments.length > 1 ? (
          <Col lg={12} sm={12}>
            <Tab data-testid={`tab_layout`}>
              {investments.map((investment, index) => (
                <TabPanel
                  data-testid={`tab_panel-${index}`}
                  key={`investment-${index}`}
                  buttonId={`tab ${index}`}
                  label={`Investment ${index + 1}`}
                  panelId={`panel_${index}`}
                >
                  <Row>
                    <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <Input
                        data-testid={`approval_date_${index}`}
                        id={`approval_date_${index}`}
                        type="date"
                        label="Approval Date"
                        maxLength="10"
                        style={getSelectValidationStyle(validationErrors["investment_approval_date"])}
                        value={investment_approval_date}
                        onChange={(e) => {
                          HandleChangeValidate(
                            e,
                            "investment_approval_date",
                            formData,
                            setForm,
                            setValidationError,
                            validationErrors,
                            ValidateDate
                          );
                        }}
                        disabled={index > 0}
                      ></Input>
                      <ValidationErrorMessage
                        paramId="investment_approval_date"
                        validationError={validationErrors.investment_approval_date}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <label>Fund</label>
                      <Select
                        isClearable={true}
                        data-testid={`fund_${index}`}
                        id={`fund_${index}`}
                        inputId={`input_fund_${index}`}
                        options={funds}
                        value={investment["fund"]["label"] !== "" ? investment["fund"] : "undefined"}
                        onChange={(e) => handleInvestmentSelectChange(e, investments, index, "fund")}
                        styles={selectStyler({
                          validationErrorsValue:
                            validationErrors["investments"][index] !== undefined
                              ? validationErrors["investments"][index]["fund"]
                              : "",
                        })}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={`investments[${index}].fund`}
                        validationError={
                          validationErrors["investments"][index] !== undefined
                            ? validationErrors["investments"][index]["fund"]
                            : ""
                        }
                      />
                    </Col>
                    <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <label>Share Class</label>
                      <Select
                        isLoading={isLoading}
                        isClearable={true}
                        data-testid={`share_${index}`}
                        id={`share_${index}`}
                        onFocus={() => {
                          (async () => {
                            await getShareClassOptions(investment["fund"]["value"]);
                          })();
                        }}
                        options={currentShareClass}
                        value={investment["share_class"]["label"] !== "" ? investment["share_class"] : "undefined"}
                        onChange={(e) => handleInvestmentSelectChange(e, investments, index, "share_class")}
                        styles={selectStyler({
                          validationErrorsValue:
                            validationErrors["investments"][index] !== undefined
                              ? validationErrors["investments"][index]["share_class"]
                              : "",
                        })}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={`investments[${index}].share_class`}
                        validationError={
                          validationErrors["investments"][index] !== undefined
                            ? validationErrors["investments"][index]["share_class"]
                            : ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <label>Sales Region</label>
                      <Select
                        isClearable={true}
                        data-testid={`sales_region_${index}`}
                        id={`sales_region_${index}`}
                        inputId={`input_sales_region_${index}`}
                        options={salesRegionOptions}
                        value={investment_sales_region["label"] !== "" ? investment_sales_region : "undefined"}
                        onChange={(e) => {
                          HandleSelectChange("investment_sales_region", e, formData, setForm);
                        }}
                        isDisabled={index > 0 || (wsrsStatus && service_provider_sub_acct)}
                        styles={selectStyler({
                          validationErrorsValue: validationErrors.investment_sales_region,
                          disabled: wsrsStatus && service_provider_sub_acct,
                        })}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"investment_sales_region"}
                        validationError={validationErrors.investment_sales_region}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col small={12} lg={12} style={{ textAlign: "right" }}>
                      <Button
                        id={`ext-delete-button-${index}`}
                        data-testid={`ext-delete-button-${index}`}
                        variant="primary"
                        onClick={handleOpenModal}
                      >
                        Delete Investment
                      </Button>
                      <ReactModal
                        testId={`modal_testid_${index}`}
                        id={`modal_${index}`}
                        isOpen={open}
                        contentLabel="Minimal Modal Example"
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                        onRequestClose={() => handleCloseModal()}
                        parentSelector={() => document.getElementById(`panel_${index}`)}
                        onAfterClose={() => {
                          handleUpdate(investments);
                        }}
                        style={modalStyles({ center: true })}
                      >
                        <div className={"container"}>
                          <Row>
                            <Col sm={12} lg={12}>
                              <p>Are you sure you want to delete this investment?</p>
                              <Button
                                data-testid={`modal_delete_yes_button_${index}`}
                                style={{ marginRight: 15 }}
                                variant="primary"
                                onClick={() => handleDeleteModal(investments, index)}
                              >
                                Yes
                              </Button>
                              <Button
                                data-testid={`modal_no_button_${index}`}
                                variant="primary"
                                onClick={() => handleCloseModal()}
                              >
                                No
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </ReactModal>
                    </Col>
                  </Row>
                </TabPanel>
              ))}
            </Tab>
          </Col>
        ) : (
          investments.map((investment, index) => (
            <Col key={`one_invest_${index}`} lg={12}>
              <Row>
                <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Input
                    data-testid={`approval_date_${index}`}
                    id={`approval_date_${index}`}
                    type="date"
                    label="Approval Date"
                    maxLength="10"
                    value={investment_approval_date}
                    onChange={(e) => {
                      HandleChangeValidate(
                        e,
                        "investment_approval_date",
                        formData,
                        setForm,
                        setValidationError,
                        validationErrors,
                        ValidateDate
                      );
                    }}
                  ></Input>
                  <ValidationErrorMessage
                    paramId="investment_approval_date"
                    validationError={validationErrors.investment_approval_date}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  sm={6}
                  lg={6}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  data-testid={`fund_${index}`}
                >
                  <label>Fund</label>
                  <Select
                    isClearable={true}
                    datatestid={`fund_${index}`}
                    id={`fund_${index}`}
                    inputId={`input_fund_${index}`}
                    options={funds}
                    value={investment["fund"]["label"] !== "" ? investment["fund"] : "undefined"}
                    styles={selectStyler({
                      validationErrorsValue:
                        validationErrors["investments"][index] !== undefined
                          ? validationErrors["investments"][index]["fund"]
                          : "",
                    })}
                    onChange={(e) => handleInvestmentSelectChange(e, investments, index, "fund")}
                  ></Select>
                  <ValidationErrorMessage
                    paramId={`investments[${index}].fund`}
                    validationError={
                      validationErrors["investments"][index] !== undefined
                        ? validationErrors["investments"][index]["fund"]
                        : ""
                    }
                  />
                </Col>
                <Col
                  sm={6}
                  lg={6}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  data-testid={`share_${index}`}
                >
                  <label>Share Class</label>
                  <Select
                    isLoading={isLoading}
                    isClearable={true}
                    id={`share_${index}`}
                    inputId={`input_share_${index}`}
                    onFocus={() => {
                      getShareClassOptions(investment["fund"]["value"]);
                    }}
                    options={currentShareClass}
                    value={investment["share_class"]["label"] !== "" ? investment["share_class"] : "undefined"}
                    onChange={(e) => handleInvestmentSelectChange(e, investments, index, "share_class")}
                    styles={selectStyler({
                      validationErrorsValue:
                        validationErrors["investments"][index] !== undefined
                          ? validationErrors["investments"][index]["share_class"]
                          : "",
                    })}
                  ></Select>
                  <ValidationErrorMessage
                    paramId={`investments[${index}].share_class`}
                    validationError={
                      validationErrors["investments"][index] !== undefined
                        ? validationErrors["investments"][index]["share_class"]
                        : ""
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  sm={6}
                  lg={6}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  data-testid={`sales_region_${index}`}
                >
                  <label>Sales Region</label>
                  <Select
                    isClearable={true}
                    id={`sales_region_${index}`}
                    inputId={`input_sales_region_${index}`}
                    options={salesRegionOptions}
                    value={investment_sales_region["label"] !== "" ? investment_sales_region : "undefined"}
                    onChange={(e) => {
                      HandleSelectChange("investment_sales_region", e, formData, setForm);
                    }}
                    styles={selectStyler({
                      validationErrorsValue: validationErrors.investment_sales_region,
                      disabled: wsrsStatus && service_provider_sub_acct,
                    })}
                    isDisabled={wsrsStatus && service_provider_sub_acct}
                  ></Select>
                  <ValidationErrorMessage
                    paramId={"investment_sales_region"}
                    validationError={validationErrors.investment_sales_region}
                  />
                </Col>
              </Row>
            </Col>
          ))
        )}
      </Row>
      <Row>
        <Col
          lg={12}
          style={{ textAlign: "right" }}
          className="util-margin-top-sm-15 util-margin-top-xs-30 util-flex-align-end"
        >
          <Button
            data-testid="add_investment"
            variant="primary"
            onClick={() => {
              handleAddInvestment(investments);
            }}
          >
            Add Another Investment
          </Button>
        </Col>
      </Row>
    </form>
  );
};

InvestmentInfoForm.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
};
export default InvestmentInfoForm;
