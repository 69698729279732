import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import Loading from "../../../../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { handleChange, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import {
  getTextAreaStyles,
  Validate,
  validateGuid,
  ValidateRequired,
  validationUseEffectHelper,
} from "../../../../Utilities/ValidationHelpers";
import { multiFetch } from "../../../../../services/multiFetch";

const ShareClassDetailsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    if (params.additional_details_share_class_id !== undefined) {
      validatedParams["additional_details_share_class_id"] = validateGuid(params.additional_details_share_class_id);
    }
    if (params.share_class_id !== undefined) {
      validatedParams["share_class_id"] = validateGuid(params.share_class_id);
    }
  }

  const initialData = {
    portfolio_name: "",
    cusip: "",
    share_class_name: "",
    description: "",
    inv_rtlclss_cd: "",
    display_class: "",
    class_id: "",
    tier_name: "",
    short_name: "",
    vintage: "",
    accounting_basis: "",
    accounting_basis_2: "",
    price_precision: "",
    price_tracking: "",
    share_precision: "",
    fee_report_grouping: "",
    ubt_fee: "",
    advisor_fee: "",
    fee_bps: "",
    other_fee: "",
    ml_code: "",
    alt_nav_source: "",
    root_cusip: "",
    fidelity_fund_id: "",
    reason_for_change: "",
  };

  const [shareClassData, setShareClass] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);

  const [validationErrors, setValidationError] = useState({});

  const fieldsToValidate = {
    reason_for_change: ValidateRequired,
  };

  const {
    portfolio_name,
    cusip,
    share_class_name,
    description,
    inv_rtlclss_cd,
    display_class,
    class_id,
    tier_name,
    short_name,
    vintage,
    accounting_basis,
    accounting_basis_2,
    price_precision,
    price_tracking,
    share_precision,
    fee_report_grouping,
    ubt_fee,
    advisor_fee,
    fee_bps,
    other_fee,
    ml_code,
    alt_nav_source,
    root_cusip,
    fidelity_fund_id,
    reason_for_change,
  } = shareClassData;

  const handleSubmit = async () => {
    let [formIsValid, errors] = Validate(shareClassData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        // validation happens here, then if valid
        setLoadingData(true);
        let endpoint = `/v1/share-class-additional-detail`;

        if (validatedParams.share_class_id) {
          let formattedData = JSON.stringify(shareClassData);
          const response = await fetchService(baseUrl + endpoint, {
            method: "POST",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/share-class-details/${validatedParams.share_class_id}`);
        } else {
          let formattedData = JSON.stringify(shareClassData);
          const response = await fetchService(baseUrl + endpoint, {
            method: "PUT",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/share-class-details/${validatedParams.additional_details_share_class_id}`);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const getShareClassESDLDetails = async (dataURL) => {
    let endpoint = `/v1/share-class-esdl?share-class-id=${dataURL}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        if (validatedParams.share_class_id) {
          const shareClassDetail = await getShareClassESDLDetails(validatedParams.share_class_id);
          setShareClass((data) => ({
            ...data,
            ["share_class_id"]: validatedParams.share_class_id,
            ["share_class_name"]: shareClassDetail.data[0].share_class_name,
            ["portfolio_name"]: shareClassDetail.data[0].portfolio_name,
            ["cusip"]: shareClassDetail.data[0].cusip,
          }));
        } else {
          let shareClassData = await multiFetch(
            [
              `/v1/share-class-esdl?share-class-id=${validatedParams.additional_details_share_class_id}`,
              `/v1/share-class-additional-detail?share-class-id=${validatedParams.additional_details_share_class_id}`,
            ],
            accessToken
          );
          if (shareClassData.error) {
            throw new Error(shareClassData.requestId);
          }
          if (shareClassData[0].data.length === 0) {
            throw new Error(shareClassData[0].requestId);
          }
          if (shareClassData[1].data.length === 0) {
            throw new Error(shareClassData[1].requestId);
          }
          const shareClassESDLData = set_nulls_to_empty_strings(shareClassData[0].data[0]);
          const shareClassAddlDetails = set_nulls_to_empty_strings(shareClassData[1].data[0]);

          let updateData = { ...shareClassAddlDetails };
          updateData.portfolio_name = shareClassESDLData.portfolio_name;
          updateData.cusip = shareClassESDLData.cusip;
          updateData.share_class_name = shareClassESDLData.share_class_name;
          updateData.share_class_id = validatedParams.additional_details_share_class_id;
          updateData.reason_for_change = "";
          setShareClass(updateData);
        }
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={9}>
              <h2>
                {validatedParams.additional_details_share_class_id
                  ? " Edit Share Class Details"
                  : "New Share Class Details"}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card heading={"Share Class Overview"}>
                <Row>
                  <Col sm={12}>
                    <Input
                      data-testid={"portfolio_name"}
                      id={"portfolio_name"}
                      type="text"
                      label="Portfolio Name"
                      value={portfolio_name}
                      disabled={true}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"share_class_name"}
                      id={"share_class_name"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={share_class_name}
                      label="Share Class Name"
                      disabled={true}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"cusip"}
                      id={"cusip"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={cusip}
                      label="Cusip"
                      disabled={true}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Share Class Detail"}>
                <Row>
                  <Col sm={12}>
                    <Input
                      data-testid={"description"}
                      id={"description"}
                      type="text"
                      value={description}
                      label="Description"
                      onChange={(e) => {
                        handleChange(e, "description", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"inv_rtlclss_cd"}
                      id={"inv_rtlclss_cd"}
                      type="text"
                      value={inv_rtlclss_cd}
                      label="Investment Rate Class Code"
                      onChange={(e) => {
                        handleChange(e, "inv_rtlclss_cd", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"display_class"}
                      id={"display_class"}
                      type="text"
                      value={display_class}
                      label="Display Class"
                      onChange={(e) => {
                        handleChange(e, "display_class", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"class_id"}
                      id={"class_id"}
                      type="text"
                      value={class_id}
                      label="Class ID"
                      onChange={(e) => {
                        handleChange(e, "class_id", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"tier_name"}
                      id={"tier_name"}
                      type="text"
                      value={tier_name}
                      label="Tier Name"
                      onChange={(e) => {
                        handleChange(e, "tier_name", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"short_name"}
                      id={"short_name"}
                      type="text"
                      value={short_name}
                      label="Short Name"
                      onChange={(e) => {
                        handleChange(e, "short_name", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"vintage"}
                      id={"vintage"}
                      type="text"
                      value={vintage}
                      label="Vintage"
                      onChange={(e) => {
                        handleChange(e, "vintage", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"accounting_basis"}
                      id={"accounting_basis"}
                      type="text"
                      value={accounting_basis}
                      label="Accounting Basis"
                      onChange={(e) => {
                        handleChange(e, "accounting_basis", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"accounting_basis_2"}
                      id={"accounting_basis_2"}
                      type="text"
                      value={accounting_basis_2}
                      label="Accounting Basis 2"
                      onChange={(e) => {
                        handleChange(e, "accounting_basis_2", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"price_precision"}
                      id={"price_precision"}
                      type="text"
                      value={price_precision}
                      label="Price Precision"
                      onChange={(e) => {
                        handleChange(e, "price_precision", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"price_tracking"}
                      id={"price_tracking"}
                      type="text"
                      value={price_tracking}
                      label="Price Tracking"
                      onChange={(e) => {
                        handleChange(e, "price_tracking", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"share_precision"}
                      id={"share_precision"}
                      type="text"
                      value={share_precision}
                      label="Share Precision"
                      onChange={(e) => {
                        handleChange(e, "share_precision", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card heading={"Expense Detail"}>
                <Row>
                  <Col sm={3}>
                    <Input
                      data-testid={"fee_report_grouping"}
                      id={"fee_report_grouping"}
                      type="text"
                      label="Fee Reporting Grouping"
                      value={fee_report_grouping}
                      onChange={(e) => {
                        handleChange(e, "fee_report_grouping", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={3}>
                    <Input
                      data-testid={"ubt_fee"}
                      id={"ubt_fee"}
                      type="text"
                      label="Ubt Fee"
                      value={ubt_fee}
                      onChange={(e) => {
                        handleChange(e, "ubt_fee", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={3}>
                    <Input
                      data-testid={"advisor_fee"}
                      id={"advisor_fee"}
                      type="text"
                      label="Advisor Fee"
                      value={advisor_fee}
                      onChange={(e) => {
                        handleChange(e, "advisor_fee", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Input
                      data-testid={"fee_bps"}
                      id={"fee_bps"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={fee_bps}
                      label="Fee Bps"
                      onChange={(e) => {
                        handleChange(e, "fee_bps", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={3}>
                    <Input
                      data-testid={"other_fee"}
                      id={"other_fee"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={other_fee}
                      label="Other Fee"
                      onChange={(e) => {
                        handleChange(e, "other_fee", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card heading={"Misc Codes"}>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"ml_code"}
                      id={"ml_code"}
                      type="text"
                      label="ML Code"
                      value={ml_code}
                      onChange={(e) => {
                        handleChange(e, "ml_code", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"root_cusip"}
                      id={"root_cusip"}
                      type="text"
                      label="Root Cusip"
                      value={root_cusip}
                      onChange={(e) => {
                        handleChange(e, "root_cusip", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      data-testid={"alt_nav_source"}
                      id={"alt_nav_source"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={alt_nav_source}
                      label="Alt NAV Source"
                      onChange={(e) => {
                        handleChange(e, "alt_nav_source", setShareClass);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    <Input
                      data-testid={"fidelity_fund_id"}
                      id={"fidelity_fund_id"}
                      type="text"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={fidelity_fund_id}
                      label="Fidelity Fund ID"
                      onChange={(e) => {
                        handleChange(e, "fidelity_fund_id", setShareClass);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Notes"}>
                <Col sm={12}>
                  <label>Reason For Change</label>
                  <div>
                    <textarea
                      data-testid={"reason_for_change"}
                      style={getTextAreaStyles(validationErrors["reason_for_change"])}
                      value={reason_for_change}
                      onChange={(e) => {
                        handleChange(e, "reason_for_change", setShareClass);
                      }}
                    ></textarea>
                  </div>
                  <ValidationErrorMessage
                    paramId={"reason_for_change"}
                    validationError={validationErrors["reason_for_change"]}
                  />
                </Col>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button
                data-testid={"saveChanges"}
                style={{ marginRight: 15 }}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Changes
              </Button>
              <Button
                data-testid={"discardChanges"}
                onClick={() => {
                  if (validatedParams.additional_details_share_class_id) {
                    navigate(`/share-class-details/${validatedParams.additional_details_share_class_id}`);
                  }
                  if (validatedParams.share_class_id) {
                    navigate(`/portfolio-and-share-class-management`);
                  }
                }}
              >
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default ShareClassDetailsEdit;
