import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Card from "../../../../Card";
import React from "react";
import PropTypes from "prop-types";

const ExpenseDetailsComponent = ({ ...props }) => {
  const { operating_expense_cap, annual_operating_expense, annual_operating_expense_date } = props;

  return (
    <>
      <Col data-testid={"ExpenseDetailsComponent"}>
        <Card heading={"Expense Detail"}>
          <Row>
            <Col>
              <DataComponent
                id={`operating_expense_cap`}
                data-testid={"operating_expense_cap"}
                label="Operating Expense Cap"
                data={operating_expense_cap}
              ></DataComponent>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataComponent
                id={`annual_operating_expense`}
                data-testid={"annual_operating_expense"}
                label="Annual Operating Expense"
                data={annual_operating_expense}
              ></DataComponent>
            </Col>
          </Row>
          <Row>
            <Col>
              <DataComponent
                id={`annual_operating_expense_date`}
                data-testid={"annual_operating_expense_date"}
                label="Annual Operating Expense Date"
                data={annual_operating_expense_date}
              ></DataComponent>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};
export default ExpenseDetailsComponent;

ExpenseDetailsComponent.propTypes = {
  operating_expense_cap: PropTypes.string,
  annual_operating_expense: PropTypes.string,
  annual_operating_expense_date: PropTypes.string,
};
