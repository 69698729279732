import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const CompanyDetail = ({ ...props }) => {
  const { company_name, phone_number, phone_extension, email } = props;
  return (
    <>
      <Card heading={"Company Detail"}>
        <Row>
          <Col sm={9}>
            <DataComponent id={"company_name"} label="Company Name" data={company_name} />
          </Col>
          <Col sm={7}>
            <DataComponent id={"phone_number"} label="Phone" data={phone_number} />
          </Col>
          <Col sm={2}>
            <DataComponent id={"phone_extension"} label="Ext" data={phone_extension} />
          </Col>
        </Row>
        <Row>
          <Col sm={9} lg={9}>
            <DataComponent id={"email"} label="Email" data={email} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CompanyDetail;

CompanyDetail.propTypes = {
  company_name: PropTypes.string,
  phone_number: PropTypes.string,
  phone_extension: PropTypes.string,
  email: PropTypes.string,
};
