import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import Checkbox from "@pds-react/checkbox";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const TradingAccountDetail = ({ ...props }) => {
  return (
    <>
      <Card heading={"Trading Account Detail"}>
        <Row>
          <Col sm={3}>
            <DataComponent id={`account_type`} data-testid={`account_type`} label={"Type"} data={props.account_type} />
          </Col>
          <Col sm={9}>
            <DataComponent
              id={`omnibus_name`}
              data-testid={`omnibus_name`}
              label={"Trading Account Name"}
              data={props.omnibus_name}
              editLink={
                props.participating_trust_id === "" ? "" : `/trust-details/edit/${props.participating_trust_id}`
              }
              detailsLink={props.participating_trust_id === "" ? "" : `/trust-details/${props.participating_trust_id}`}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <DataComponent
              id={`contact_company`}
              data-testid={`contact_company`}
              label={"Contact Company"}
              data={props.contact_company}
            />
          </Col>
          <Col sm={6}>
            <DataComponent
              id={`plan_tax_id`}
              data-testid={`plan_tax_id`}
              label={"Tax ID EIN"}
              data={props.plan_tax_id}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <DataComponent
              id={`bin_number`}
              data-testid={`bin_number`}
              label={"Bank Identification Number (BIN)"}
              data={props.bin_number}
            />
          </Col>
          <Col sm={6}>
            <DataComponent
              id={`transfer_agent_account_number`}
              data-testid={`transfer_agent_account_number`}
              label={"Transfer Agent Account Number"}
              data={props.transfer_agent_account_number}
            />
          </Col>
          <Col sm={12}>
            <DataComponent
              id={`wire_instructions`}
              data-testid={`wire_instructions`}
              label={"Wire Instructions"}
              data={props.wire_instructions}
            />
          </Col>
        </Row>
        <Checkbox
          data-testid={`fif`}
          id={`fif`}
          label="Fund in/of Funds (0bps) Trading Account"
          className="util-margin-top-sm-15 util-margin-top-xs-30"
          defaultChecked={props.fif || false}
          errorMessage={""}
          disabled={true}
        ></Checkbox>
      </Card>
    </>
  );
};

export default TradingAccountDetail;

TradingAccountDetail.propTypes = {
  participating_trust_id: PropTypes.string,
  account_type: PropTypes.string,
  omnibus_name: PropTypes.string,
  trading_account: PropTypes.string,
  fif: PropTypes.bool,
  contact_company: PropTypes.string,
  plan_tax_id: PropTypes.string,
  bin_number: PropTypes.string,
  transfer_agent_account_number: PropTypes.string,
  wire_instructions: PropTypes.string,
};
