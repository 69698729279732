import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const CompanyDetails = ({ ...props }) => {
  const {
    company_name,
    contact_company_id,
    phone_number,
    phone_extension,
    company_mailing_address,
    company_mailing_address_2,
    company_mailing_city,
    company_mailing_state,
    company_mailing_zip,
    company_mailing_country,
    currentIndex,
  } = props;

  return (
    <>
      <Card heading={"Company Details"} data-testid={"card"} sm={12}>
        <Row>
          <Col>
            <DataComponent
              id={`company_name`}
              label="Company Name"
              data={company_name}
              data-testid={`company_name`}
              editLink={contact_company_id !== "" ? `/company-details/edit/${contact_company_id}` : ""}
              detailsLink={contact_company_id !== "" ? `/company-details/${contact_company_id}` : ""}
            />
          </Col>
        </Row>
        {currentIndex === 0 ? (
          <>
            <Row>
              <Col>
                <DataComponent
                  id={`phone_number`}
                  label="Phone Number"
                  data={phone_number}
                  data-testid={`phone_number`}
                />
              </Col>
              <Col>
                <DataComponent
                  id={`phone_extension`}
                  label="Ext"
                  data={phone_extension}
                  data-testid={`phone_extension`}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataComponent
                  id={`company_mailing_address`}
                  label="Mailing Address"
                  data={company_mailing_address}
                  data-testid={`company_mailing_address`}
                />
              </Col>
              <Col>
                <DataComponent
                  id={`company_mailing_address_2`}
                  data={company_mailing_address_2}
                  data-testid={`company_mailing_address_2`}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DataComponent
                  id={`company_mailing_city`}
                  label="City"
                  data={company_mailing_city}
                  data-testid={`company_mailing_city`}
                />
              </Col>
              <Col>
                <DataComponent
                  id={`company_mailing_state`}
                  label="State"
                  data={company_mailing_state}
                  data-testid={`company_mailing_state`}
                />
              </Col>
              <Col>
                <DataComponent
                  id={`company_mailing_zip`}
                  label="Zip"
                  data={company_mailing_zip}
                  data-testid={`company_mailing_zip`}
                />
              </Col>
              <Col>
                <DataComponent
                  id={`company_mailing_country`}
                  label="Country"
                  data={company_mailing_country}
                  data-testid={`company_mailing_country`}
                />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default CompanyDetails;

CompanyDetails.propTypes = {
  company_name: PropTypes.string,
  contact_company_id: PropTypes.string,
  phone_number: PropTypes.string,
  phone_extension: PropTypes.string,
  company_mailing_address: PropTypes.string,
  company_mailing_address_2: PropTypes.string,
  company_mailing_city: PropTypes.string,
  company_mailing_state: PropTypes.string,
  company_mailing_zip: PropTypes.string,
  company_mailing_country: PropTypes.string,
  currentIndex: PropTypes.number,
};
