import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import BasicTable from "../../../../BasicTable";

const CompaniesComponent = ({ ...props }) => {
  const { companies } = props;

  const companiesColumns = [
    {
      accessorKey: "company_name",
      header: () => <span>Company Name</span>,
    },
    {
      accessorKey: "company_mailing_city",
      header: "City",
    },
    {
      accessorKey: "company_mailing_state",
      header: "State",
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: (info) => <p>{info.getValue()}</p>,
    },
  ];

  return (
    <>
      <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={12} lg={12}>
            <Card heading={"Associated Companies"}>
              <BasicTable id={"companies_table"} data={companies} columns={companiesColumns}></BasicTable>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CompaniesComponent;

CompaniesComponent.propTypes = {
  companies: PropTypes.array,
};
