import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Card from "../../../Card";
import Loading from "../../../../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { validateGuid } from "../../../../Utilities/ValidationHelpers";
import ParticipatingTrustsComponent from "./CardComponents/ParticipatingTrustsComponent";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ContactsComponent from "./CardComponents/ContactsComponent";
import CompanyDetail from "./CardComponents/CompanyDetail";
import CompanyAddress from "./CardComponents/CompanyAddress";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";

const CompanyDetails = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["contact_company_id"] = validateGuid(params.contact_company_id);
  }
  const [participatingTrusts, setParticipatingTrusts] = useState([
    {
      participating_trust_name: "",
      plan_type: "",
      actions: <></>,
    },
  ]);

  const [contacts, setContacts] = useState([
    {
      participating_trust_name: "",
      plan_type: "",
      actions: <></>,
    },
  ]);

  const initialData = {
    company_mailing_address: null,
    company_mailing_address_2: "",
    company_mailing_city: "",
    company_mailing_state: "",
    company_mailing_country: "",
    company_mailing_zip: "",
    company_name: "",
    company_street_address: "",
    company_street_address_2: "",
    company_street_city: "",
    company_street_state: "",
    company_street_country: "",
    company_street_zip: "",
    phone_number: "",
    phone_extension: "",
    street_address_same_as_mailing: false,
  };

  const [companyData, setCompany] = useState(initialData);
  const [historyVersions, setHistoryVersions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(true);

  const {
    company_name,
    phone_number,
    phone_extension,
    email,
    company_mailing_address,
    company_mailing_address_2,
    company_mailing_city,
    company_mailing_state,
    company_mailing_zip,
    company_mailing_country,
    street_address_same_as_mailing,
    company_street_address,
    company_street_address_2,
    company_street_city,
    company_street_state,
    company_street_zip,
    company_street_country,
    reason_for_change,
    inserted_by,
    inserted_at,
  } = companyData;

  const handleVersionChange = (company, index) => {
    company.street_address_same_as_mailing =
      company.company_street_address === company.company_mailing_address &&
      company.company_street_address_2 === company.company_mailing_address_2 &&
      company.company_street_city === company.company_mailing_city &&
      company.company_street_state === company.company_mailing_state &&
      company.company_street_zip === company.company_mailing_zip &&
      company.company_street_country === company.company_mailing_country;
    setCurrentIndex(index);
    setCompany(company);
  };

  const getCompany = async () => {
    let endpoint = `/v1/contact-company-history?contact-company-id=${
      validatedParams.contact_company_id
    }&inserted-at=${historyTimeNow(new Date())}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getParticipatingTrusts = async () => {
    let endpoint = `/v1/participating-trust-by-contact?contact-company-id=${validatedParams.contact_company_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    resp.data = set_nulls_to_empty_strings(resp.data);
    const trusts = resp.data.map((trust, index) => ({
      actions: (
        <span>
          <Link
            id={`trust_details_edit_${index}_link`}
            data-testid={`trust_details_edit_${index}_link`}
            to={`/trust-details/edit/${trust.participating_trust_id}`}
          >
            Edit
          </Link>
          <span> | </span>
          <Link
            id={`trust_details_${index}_link`}
            data-testid={`trust_details_${index}_link`}
            to={`/trust-details/${trust.participating_trust_id}`}
          >
            Details
          </Link>
        </span>
      ),
      ...trust,
    }));
    setParticipatingTrusts(trusts);
  };

  const getContacts = async () => {
    let endpoint = `/v1/contact?contact-company-id=${validatedParams.contact_company_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    resp.data = set_nulls_to_empty_strings(resp.data);
    const contactsData = resp.data.map((contact, index) => ({
      actions: (
        <span>
          <Link
            id={`contact_details_edit_${index}_link`}
            data-testid={`contact_details_edit_${index}_link`}
            to={`/contact-details/edit/${contact.contact_id}`}
          >
            Edit
          </Link>
          <span> | </span>
          <Link
            id={`contact_details_${index}_link`}
            data-testid={`contact_details_${index}_link`}
            to={`/contact-details/${contact.contact_id}`}
          >
            Details
          </Link>
        </span>
      ),
      ...contact,
    }));
    setContacts(contactsData);
  };

  useEffect(() => {
    const setupCompanyData = async () => {
      try {
        const companyDetails = await getCompany();
        let company = companyDetails.data[0];
        company.street_address_same_as_mailing =
          company.company_street_address === company.company_mailing_address &&
          company.company_street_address_2 === company.company_mailing_address_2 &&
          company.company_street_city === company.company_mailing_city &&
          company.company_street_state === company.company_mailing_state &&
          company.company_street_zip === company.company_mailing_zip &&
          company.company_street_country === company.company_mailing_country;
        setHistoryVersions(companyDetails.data);
        setCompany(company);
        await getContacts();
        await getParticipatingTrusts();
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupCompanyData();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={6}>
              <h2>Company Details</h2>
            </Col>
            <Col>
              <button
                type="button"
                className="btn btn-primary progress-btn"
                data-testid={"edit_button"}
                id={"edit_button"}
                onClick={() => {
                  navigate(`/company-details/edit/${validateGuid(validatedParams.contact_company_id)}`);
                }}
              >
                Edit
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Row>
                <Col sm={12}>
                  <CompanyDetail
                    company_name={company_name}
                    phone_number={phone_number}
                    phone_extension={phone_extension}
                    email={email}
                  />
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={12}>
                  <CompanyAddress
                    company_mailing_address={company_mailing_address}
                    company_mailing_address_2={company_mailing_address_2}
                    company_mailing_city={company_mailing_city}
                    company_mailing_state={company_mailing_state}
                    company_mailing_zip={company_mailing_zip}
                    company_mailing_country={company_mailing_country}
                    street_address_same_as_mailing={street_address_same_as_mailing}
                    company_street_address={company_street_address}
                    company_street_address_2={company_street_address_2}
                    company_street_city={company_street_city}
                    company_street_state={company_street_state}
                    company_street_zip={company_street_zip}
                    company_street_country={company_street_country}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Row style={{ height: "100%" }}>
                <Col sm={12}>
                  <Card heading={"History"}>
                    <VersionHistory
                      historyVersions={historyVersions}
                      handleVersionChange={handleVersionChange}
                      currentIndex={currentIndex}
                    ></VersionHistory>
                    <ReasonForChange
                      reason_for_change={reason_for_change}
                      inserted_by={inserted_by}
                      inserted_at={inserted_at}
                    ></ReasonForChange>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {currentIndex === 0 ? (
            <>
              <Row>
                <ContactsComponent contacts={contacts} contact_company_id={validatedParams.contact_company_id} />
              </Row>
              <Row>
                <ParticipatingTrustsComponent participatingTrusts={participatingTrusts} />
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default CompanyDetails;
