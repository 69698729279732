import { Container } from "@pds-react/grid";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import UserContext from "../../context/User.context";
import Header from "../Header/Header";
import Loading from "../Loading/Loading";

const MainPageLayout = () => {
  const { loading } = useContext(UserContext);
  return (
    <div>
      <Header />
      <Container as="div" fluid>
        {!loading ? <Outlet /> : <Loading />}
      </Container>
      <div className={"util-padding-bottom-50"}></div>
    </div>
  );
};

export default MainPageLayout;
