import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../services/fetchService";
import Config from "../../../config";
import UserContext from "../../../context/User.context";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import Select from "react-select";
import { Link } from "react-router-dom";
import Button from "@pds-react/button";
import Input from "@pds-react/input";
import "./GroupingsManagement.scss";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { OnEnterKeyPress } from "../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";
import { customStyles, tableHeaderSelectStyle } from "../helpers";
import Pagination from "../Pagination";

let columns = [];
const columnHelper = createColumnHelper();

const columnsLong = [
  columnHelper.accessor("category", {
    header: () => "Category",
  }),
  columnHelper.accessor("grouping_name", {
    header: () => "Grouping Name",
  }),
  columnHelper.accessor("control_number", {
    header: () => "Control Number",
  }),
  columnHelper.accessor("participating_trust", {
    header: "Participating Trust",
  }),
];

const columnsShort = [
  columnHelper.accessor("category", {
    header: () => "Category",
  }),
  columnHelper.accessor("grouping_name", {
    header: () => "Grouping Name",
  }),
];

const GroupingsManagement = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const [isloading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [groupingsType, setGroupingsType] = useState({ label: "Groupings and Trusts", value: "Groupings and Trusts" });
  if (groupingsType.label == "Groupings and Trusts") {
    columns = columnsLong;
  } else {
    columns = columnsShort;
  }

  const [categories, setCategories] = useState([]);

  const [data, setData] = React.useState(() => []);
  const [pageViewNum, setPageViewNum] = useState({ value: "", label: "" });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const initialFilters = {
    category_input: { value: "", label: "" },
    control_number_input: "",
    grouping_name_input: "",
    participating_trust_input: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const { category_input, control_number_input, grouping_name_input, participating_trust_input } = filters;

  const getTableData = async () => {
    try {
      setLoading(true);
      let endpoint = "";
      let params;
      if (groupingsType.value === "Groupings and Trusts") {
        if (
          category_input.value.trim() !== "" ||
          control_number_input.trim() !== "" ||
          grouping_name_input.trim() !== "" ||
          participating_trust_input.trim() !== ""
        ) {
          params = new URLSearchParams({
            "aggregation-group-category-id": category_input.value,
            "aggregation-group-name": grouping_name_input,
            "aggregation-group-control-number": control_number_input,
            "participating-trust-name": participating_trust_input,
          });
          endpoint = `/v1/management-grouping-trust?${params}`;
        } else {
          setLoading(false);
        }
      } else if (groupingsType.value === "Groupings") {
        if (category_input.value.trim() !== "" || grouping_name_input.trim() !== "") {
          params = new URLSearchParams({
            "aggregation-group-category-id": category_input.value,
            "aggregation-group-name": grouping_name_input,
          });
          endpoint = `/v1/management-grouping?${params}`;
        } else {
          setData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      if (endpoint) {
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        resp.data = set_nulls_to_empty_strings(resp.data);
        const grouping_and_trust = resp.data.map((result) => ({
          category: result.aggregation_group_category_name,
          grouping_name: result.aggregation_group_name,
          groupID: result.aggregation_group_id,
          control_number: result.aggregation_group_control_number,
          participating_trust: result.participating_trust_name,
          participating_trust_id: result.participating_trust_id,
        }));
        setData(grouping_and_trust);
        setLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getPageViewOption = (numberOfPages) => {
    let choices = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      choices.push({ value: i - 1, label: i });
    }
    return choices;
  };

  const handleClear = () => {
    setFilters(initialFilters);
  };

  const handleChange = (e, id) => {
    const value = e.target.value;
    setFilters((filter) => ({ ...filter, [id]: value }));
  };

  const handleSelectChange = (e, id) => {
    if (e === null) {
      e = { value: "", label: "" };
    }
    setFilters({
      ...filters,
      [id]: {
        label: e.label,
        value: e.value,
      },
    });
  };

  const getGroupCategoryData = async () => {
    try {
      let endpoint = "/v1/aggregation-group-category";
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      setCategories(
        resp.data.map((result) => {
          return { label: result.aggregation_group_category_name, value: result.aggregation_group_category_id };
        })
      );
      setLoading(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    getGroupCategoryData();
  }, []);

  useEffect(() => {
    if (
      !(
        category_input.value === "" &&
        control_number_input === "" &&
        grouping_name_input === "" &&
        participating_trust_input === ""
      )
    ) {
      (async () => {
        await getTableData();
      })();
    }
  }, [groupingsType]);

  return (
    <div data-testid={"groupings-management-view"}>
      <Col>
        <Row>
          <Col data-testid="header" id="header">
            <h2>Groupings Management</h2>
          </Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={7}>
            <Button
              data-testid={"create_button"}
              id="create_button"
              onClick={() => {
                navigate(`/groupings-details/new`);
              }}
            >
              Create Grouping
            </Button>
          </Col>
          <Col sm={3} style={{ textAlign: "right", marginTop: "2rem", paddingRight: "0px" }}>
            <span>View</span>
          </Col>
          <Col sm={2} data-testid={"View"}>
            <Select
              value={{ label: groupingsType.label, value: groupingsType.value }}
              onChange={(e) => {
                setGroupingsType(e);
              }}
              styles={customStyles}
              id={"View"}
              options={[
                { value: "Groupings and Trusts", label: "Groupings and Trusts" },
                { value: "Groupings", label: "Groupings" },
              ]}
            ></Select>
          </Col>
        </Row>
        {!isloading ? (
          <>
            <Row className={"util-padding-top-20"}>
              <Col>
                <table data-testid={"management_table"} id={"management_table"}>
                  {groupingsType.label === "Groupings and Trusts" ? (
                    <colgroup>
                      <col style={{ width: 20 }} />
                      <col style={{ width: 20 }} />
                      <col style={{ width: 20 }} />
                      <col style={{ width: 20 }} />
                      <col style={{ width: 20 }} />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col style={{ width: 25 }} />
                      <col style={{ width: 30 }} />
                      <col style={{ width: 20 }} />
                    </colgroup>
                  )}
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                        <th>Actions</th>
                      </tr>
                    ))}
                    <tr>
                      <th id={"category_input"} data-testid={"category_input"}>
                        <Select
                          isClearable={true}
                          id={"category_input"}
                          value={category_input["label"] !== "" ? category_input : "undefined"}
                          options={categories}
                          className="category_input"
                          styles={tableHeaderSelectStyle}
                          onChange={(e) => {
                            handleSelectChange(e, "category_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Select>
                      </th>
                      <th>
                        <Input
                          id="grouping_name_input"
                          data-testid={"grouping_name_input"}
                          label=""
                          value={grouping_name_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "grouping_name_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      {groupingsType.label === "Groupings and Trusts" ? (
                        <>
                          <th>
                            <Input
                              id="control_number_input"
                              data-testid={"control_number_input"}
                              label=""
                              value={control_number_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "control_number_input");
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="participating_trust_input"
                              data-testid={"participating_trust_input"}
                              label=""
                              value={participating_trust_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "participating_trust_input");
                              }}
                            ></Input>
                          </th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th style={{ textAlign: "center" }}>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"filter_button"}
                          id={"filter_button"}
                          onClick={() => {
                            getTableData();
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"clear_button"}
                          id={"clear_button"}
                          onClick={() => {
                            handleClear();
                            setData([]);
                          }}
                        >
                          Clear
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length < 1 ? (
                      <>
                        <tr>
                          {columns.map((col, index) => (
                            <td key={index}>&#8205;</td>
                          ))}
                        </tr>
                      </>
                    ) : (
                      <>
                        {table.getRowModel().rows.map((row, index) => (
                          <tr key={index}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                            <td key={index} style={{ textAlign: "right" }}>
                              {groupingsType.value === "Groupings and Trusts" && data[row.id].participating_trust_id ? (
                                <>
                                  Trust:{" "}
                                  <Link
                                    id={"participating_trust_edit_link"}
                                    data-testid={"participating_trust_edit_link"}
                                    to={`/trust-details/edit/${data[row.id].participating_trust_id}`}
                                  >
                                    Edit
                                  </Link>{" "}
                                  |{" "}
                                  <Link
                                    id={"participating_trust_link"}
                                    data-testid={"participating_trust_link"}
                                    to={`/trust-details/${data[row.id].participating_trust_id}`}
                                  >
                                    Details
                                  </Link>{" "}
                                  <br />
                                </>
                              ) : (
                                <></>
                              )}
                              <>
                                Grouping:{" "}
                                <Link
                                  id={"groupings_details_edit_link"}
                                  data-testid={"groupings_details_edit_link"}
                                  to={`/groupings-details/edit/${data[row.id].groupID}`}
                                >
                                  Edit
                                </Link>{" "}
                                |{" "}
                                <Link
                                  id={"groupings_details_link"}
                                  data-testid={"groupings_details_link"}
                                  to={`/groupings-details/${data[row.id].groupID}`}
                                >
                                  Details
                                </Link>{" "}
                              </>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Pagination
              data={data}
              table={table}
              setPageViewNum={setPageViewNum}
              pageViewNum={pageViewNum}
              getPageViewOption={getPageViewOption}
            ></Pagination>
          </>
        ) : (
          <>
            <h2 className="text-center"> Application is starting</h2>
            <Loading></Loading>
          </>
        )}
      </Col>
    </div>
  );
};

export default GroupingsManagement;
