import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Checkbox from "@pds-react/checkbox";

const ServiceProviderDetail = ({ ...props }) => {
  return (
    <>
      <Card heading={"Service Provider Detail"}>
        <Row>
          <Col sm={12}>
            <DataComponent
              id={`service_provider`}
              data-testid={`service_provider`}
              label={"Service Provider"}
              data={props.service_provider}
              editLink={
                props.service_provider_id === "" ? "" : `/service-provider-details/edit/${props.service_provider_id}`
              }
              detailsLink={
                props.service_provider_id === "" ? "" : `/service-provider-details/${props.service_provider_id}`
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Checkbox
              id={`service_provider_same`}
              checked={props.service_provider === props.settlement_service_provider ? true : false}
              data-testid={`service_provider_same`}
              label={"Service Provider is the same for Settlement"}
              disabled={true}
            ></Checkbox>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DataComponent
              id={`settlement_service_provider`}
              data-testid={`settlement_service_provider`}
              label={"Service Provider for Settlement"}
              data={props.settlement_service_provider}
              editLink={
                props.settlement_service_provider_id === ""
                  ? ""
                  : `/service-provider-details/edit/${props.settlement_service_provider_id}`
              }
              detailsLink={
                props.settlement_service_provider_id === ""
                  ? ""
                  : `/service-provider-details/${props.settlement_service_provider_id}`
              }
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ServiceProviderDetail;

ServiceProviderDetail.propTypes = {
  settlement_service_provider: PropTypes.string,
  service_provider: PropTypes.string,
  service_provider_id: PropTypes.string,
  settlement_service_provider_id: PropTypes.string,
};
