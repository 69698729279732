import { Col, Row } from "@pds-react/grid";
import Checkbox from "@pds-react/checkbox";
import Card from "../../../../Card";
import React from "react";
import PropTypes from "prop-types";
import DataComponent from "../../../../DataComponent";

const InvestmentAccount = ({ ...props }) => {
  return (
    <>
      <Card heading={"Investment Account"}>
        <Row>
          <Col sm={6} lg={6}>
            <DataComponent id={"fund"} label="Fund" data={props.fund_group_name} />
          </Col>
          <Col sm={6} lg={6}>
            <DataComponent id={"share_class"} label="Share Class" data={props.share_class} />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataComponent id={"sales_region"} label="Sales Region" data={props.sales_region} />
          </Col>
          <Col>
            <DataComponent id={"client_id"} label="Client ID" data={props.client_id} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox
              data-testid={`invested_without_pa`}
              id={`invested_without_pa`}
              label="Missing Documentation"
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              defaultChecked={props.invested_without_pa}
              errorMessage={""}
              disabled={true}
            ></Checkbox>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default InvestmentAccount;

InvestmentAccount.propTypes = {
  // account_type: PropTypes.string,
  fund_group_name: PropTypes.string,
  share_class: PropTypes.string,
  sales_region: PropTypes.string,
  invested_without_pa: PropTypes.bool,
  client_id: PropTypes.string,
  participating_trust_id: PropTypes.string,
};
