import React, { useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../Management/Card";
import Input from "@pds-react/input";
import Config from "../../../src/config/index";
import { fetchService } from "../../../src/services/fetchService";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../src/context/User.context";
import { Validate, ValidateDate } from "../../../src/components/Utilities/ValidationHelpers";
import Loading from "../Loading/Loading";
import Button from "@pds-react/button";
import { handleChange } from "../Utilities/FormHelpers";
import ValidationErrorMessage from "../Utilities/ValidationErrorMessage";

const FsaAumReport = () => {
  const d = new Date();
  const navigate = useNavigate();
  const { accessToken } = useContext(UserContext);
  //   let show_message = false;

  const initialData = {
    report_date: `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`,
  };

  const [loadingData, setLoadingData] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [reportData, setReportDate] = useState(initialData);

  const { report_date } = reportData;

  const initialValidation = {
    report_date: ValidateDate,
  };

  const [validationErrors, setValidationError] = useState({});
  const [fieldsToValidate] = useState(initialValidation);

  const handleSubmit = async () => {
    let [formIsValid, errors] = Validate(reportData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        const { baseUrl } = Config.api;
        let endpoint = "/v1/generate-fsa-aum-report";
        let reportDate = {
          ...reportData,
        };
        reportDate.report_date = reportData.report_date;

        let formattedData = JSON.stringify(reportDate);
        const response = await fetchService(baseUrl + endpoint, {
          method: "POST",
          body: formattedData,
          token: accessToken,
        });
        console.log(response);
        let resp = await response.json();

        if (200 !== response.status) {
          throw new Error(resp.requestId);
        } else {
          setShowMessage(true);
          setLoadingData(false);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col>
              <h2>Generate FSA AUM Report</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Row>
                  <Col>
                    <Input
                      data-testid={`report_date`}
                      id={`report_date`}
                      type="date"
                      label="Report Date"
                      maxLength="10"
                      value={report_date}
                      onChange={(e) => {
                        handleChange(e, "report_date", setReportDate);
                        setShowMessage(false);
                      }}
                    ></Input>
                    <ValidationErrorMessage paramId={"report_date"} validationError={validationErrors["report_date"]} />
                    {showMessage ? (
                      <span>
                        The report has been triggered successfully. You should receive an email once it has completed.
                      </span>
                    ) : (
                      <div></div>
                    )}
                  </Col>
                </Row>
                <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Col style={{ textAlign: "right" }}>
                    <Button onClick={handleSubmit} data-testid={"submit"}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default FsaAumReport;
