import { Col, Row } from "@pds-react/grid";
import Card from "../../../../Card";
import React from "react";
import DataComponent from "../../../../DataComponent";
import PropTypes from "prop-types";

const OverviewComponent = ({ ...props }) => {
  const {
    portfolio_name,
    tax_identification_number,
    business_code,
    custodian_name,
    custodian_account,
    lei_number,
    currentIndex,
  } = props;
  return (
    <>
      <Col data-testid={"OverviewComponent"}>
        <Card heading={"Overview"}>
          <Row>
            <Col>
              <DataComponent
                id={`portfolio_name`}
                label="Portfolio Name"
                data={portfolio_name}
                data-testid={`portfolio_name`}
              />
            </Col>
          </Row>
          {currentIndex === 0 ? (
            <>
              <Row>
                <Col>
                  <DataComponent
                    id={`tax_identification_number`}
                    label="Tax ID(EIN)"
                    data={tax_identification_number}
                    data-testid={`tax_identification_number`}
                  />
                </Col>
                <Col>
                  <DataComponent
                    id={`business_code`}
                    label="Business Code"
                    data={business_code}
                    data-testid={`business_code`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataComponent
                    id={`custodian_name`}
                    label="Custodian Name"
                    data={custodian_name}
                    data-testid={`custodian_name`}
                  />
                </Col>
                <Col>
                  <DataComponent
                    id={`custodian_account`}
                    label="Custodian Account(ABOR ID)"
                    data={custodian_account}
                    data-testid={`custodian_account`}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataComponent id={`lei_number`} label="LEI Number" data={lei_number} data-testid={`lei_number`} />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Card>
      </Col>
    </>
  );
};
export default OverviewComponent;
OverviewComponent.propTypes = {
  portfolio_name: PropTypes.string,
  tax_identification_number: PropTypes.string,
  business_code: PropTypes.string,
  custodian_name: PropTypes.string,
  custodian_account: PropTypes.string,
  lei_number: PropTypes.string,
  currentIndex: PropTypes.number,
};
