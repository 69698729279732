import React from "react";
import PropTypes from "prop-types";

const Card = (props) => {
  return (
    <div
      className="panel panel-primary"
      style={{ borderRadius: "10px 10px 10px 10px", height: "100%" }}
      data-testid="card"
    >
      <div className="panel-heading" style={{ borderRadius: "10px 10px 0px 0px" }}>
        {props.heading}
      </div>
      <div className="panel-body">{props.children}</div>
    </div>
  );
};

export default Card;

Card.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
};
