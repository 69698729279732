import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import UserContext from "../../../../../context/User.context";
import Config from "../../../../../config";
import { fetchService } from "../../../../../services/fetchService";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../Loading/Loading";
import Select from "react-select";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import Checkbox from "@pds-react/checkbox";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import selectStyler from "../../../../Utilities/selectStyler.js";
import {
  getTextAreaStyles,
  Validate,
  ValidateDate,
  validateGuid,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../../Utilities/ValidationHelpers";
import { multiFetch } from "../../../../../services/multiFetch";
import { handleSelectChange, handleChange } from "../../../../Utilities/FormHelpers";
import ExchangeInvestment from "./InvestmentActions/Exchange/ExchangeInvestment";
import TransferInvestment from "./InvestmentActions/Transfer/TransferInvestment";

const InvestmentDetailsEdit = () => {
  const d = new Date();
  const initialData = {
    approval_date: `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`,
    actual_liquidation_date: "",
    approved_liquidation_date: "",
    termination_notice_date: "",
    invested_without_pa: "",
    client_id: "",
    sales_region: { label: "", value: "" },
    fund_group_name: { label: "", value: "" },
    termination_code_id: { label: "", value: "" },
    share_class_name: { label: "", value: "" },
    sub_account_number: "",
    reason_for_change: "",
  };
  const [investmentData, setInvestmentData] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);
  const [accountOptions, setAccountOptions] = useState();
  const [funds, setFundOptions] = useState();
  const [shareClasses, setShareClasses] = useState();
  const [terminationOptions, setTerminationOptions] = useState();
  const [isShareLoading, setShareLoading] = useState(false);
  const [currentComponent, setCurrentComponent] = useState("");
  const [investmentIds, setInvestmentIds] = useState({});
  const [fundGroupInfo, setFundGroupInfo] = useState({});
  const [investmentToExchange, setInvestmentToExchange] = useState({ label: "", value: "" });
  const [investmentToTransfer, setInvestmentToTransfer] = useState({ label: "", value: "" });
  const [investmentStatus, setInvestmentStatus] = useState(false);

  const { accessToken } = useContext(UserContext);
  const { baseUrl } = Config.api;
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};

  if (Object.keys(params).length !== 0) {
    validatedParams["investment_account_id"] = validateGuid(params.investment_account_id);
  }

  const initialValidation = {
    fund_group_name: ValidateRequiredSelect,
    approval_date: ValidateDate,
    share_class_name: ValidateRequiredSelect,
    reason_for_change: ValidateRequired,
  };

  const [validationErrors, setValidationError] = useState({});
  const [fieldsToValidate, setFieldsToValidate] = useState(initialValidation);

  const {
    approval_date,
    actual_liquidation_date,
    approved_liquidation_date,
    termination_notice_date,
    invested_without_pa,
    client_id,
    sales_region,
    fund_group_name,
    share_class_name,
    termination_code_id,
    sub_account_number,
    reason_for_change,
  } = investmentData;

  const handleCheckboxChange = (e, id) => {
    setInvestmentData((investmentData) => ({ ...investmentData, [id]: e.target.checked }));
  };

  const handleComponentSwitch = (component) => {
    setCurrentComponent(component);
  };

  const handleExchangeTransferRemoval = () => {
    setInvestmentToExchange({ label: "", value: "" });
    setInvestmentToTransfer({ label: "", value: "" });
    setInvestmentData((investmentData) => ({ ...investmentData, ["termination_code_id"]: { label: "", value: "" } }));
    setInvestmentStatus(false);
  };

  const handleDiscard = () => {
    navigate(`/investment-details/${validatedParams.investment_account_id}`);
  };

  const handleSubmit = async () => {
    const [formIsValid, errors] = Validate(investmentData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = `/v1/investment-account`;

        let mappedData = {
          investment_account_id: validatedParams.investment_account_id,
          fund_group_id: investmentData.fund_group_name["value"],
          actual_liquidation_date: investmentData.actual_liquidation_date,
          client_id: investmentData.client_id,
          approved_liquidation_date: investmentData.approved_liquidation_date,
          approval_date: investmentData.approval_date,
          termination_notice_date: investmentData.termination_notice_date,
          sales_region_id: investmentData.sales_region["value"],
          share_class_name: investmentData.share_class_name["value"],
          termination_code_id: investmentData.termination_code_id["value"],
          invested_without_pa: investmentData.invested_without_pa,
          reason_for_change: investmentData.reason_for_change,
        };

        if (investmentToExchange.value) {
          mappedData.exchanged_to = investmentToExchange.value;
        } else {
          mappedData.exchanged_to = null;
        }

        if (investmentToTransfer.value) {
          mappedData.account_transferred_to = investmentToTransfer.value;
        } else {
          mappedData.account_transferred_to = null;
        }

        let formattedData = JSON.stringify(mappedData);

        let response = await fetchService(baseUrl + endpoint, {
          method: "PUT",
          body: formattedData,
          token: accessToken,
        });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        setLoadingData(false);
        navigate(`/investment-details/${validatedParams.investment_account_id}`);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const buildShareClassOptions = (options) => {
    return options.map((result) => ({
      label: result.share_class_name,
      value: result.share_class_name,
    }));
  };

  //Dropdown to get Shareclass data
  const getShareClassOptions = async (currentFund) => {
    try {
      setShareClasses([]);
      setShareLoading(true);
      if (currentFund === null || currentFund === undefined || currentFund === "") {
        setShareLoading(false);
      } else {
        let endpoint = `/v1/investment-account-to-share-classes?fund-group-id=${currentFund}`;
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        setShareClasses(buildShareClassOptions(resp.data));
        setShareLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getDates = (date) => {
    let dmy = date.split("T");
    let result = dmy.splice(0);
    let res = result[0];
    return res;
  };

  const getInvestment = async () => {
    let endpoint = `/v1/investment-account?investment-account-id=${validatedParams.investment_account_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getRemainingData = async (ids) => {
    let urls = [
      `/v1/linked-investor?trading-account-id=${ids.trading_account_id}&participating-trust-id=${ids.participating_trust_id}`,
      `/v1/fund-group?fund-group-id=${ids.fund_group_id}`,
      `/v1/sales-region?sales-region-id=${ids.sales_region_id}`,
    ];

    if (ids.termination_code_id) {
      urls.push(`/v1/termination-code?termination-code-id=${ids.termination_code_id}`);
    }

    let multiData = await multiFetch(urls, accessToken);
    if (multiData.error) {
      throw new Error(multiData.requestId);
    }

    return {
      subAccountData: multiData[0].data[0],
      fundData: multiData[1].data[0],
      salesRegionData: multiData[2].data[0],
      termCodeData: multiData[3] ? multiData[3].data[0] : null,
    };
  };

  useEffect(() => {
    const setupInvestmentData = async () => {
      try {
        let result = await getInvestment();

        let investmentDetail = result.data[0];

        let otherIDs = {
          participating_trust_id: investmentDetail.participating_trust_id,
          trading_account_id: investmentDetail.trading_account_id,
          fund_group_id: investmentDetail.fund_group_id,
          sales_region_id: investmentDetail.sales_region_id,
          termination_code_id: investmentDetail.termination_code_id,
        };

        let remainingData = await getRemainingData(otherIDs);
        if (remainingData.error) {
          throw new Error(remainingData.requestId);
        }

        let updateData = {};

        updateData.approval_date = investmentDetail.approval_date ? getDates(investmentDetail.approval_date) : "";
        updateData.actual_liquidation_date = investmentDetail.actual_liquidation_date
          ? getDates(investmentDetail.actual_liquidation_date)
          : "";
        updateData.approved_liquidation_date = investmentDetail.approved_liquidation_date
          ? getDates(investmentDetail.approved_liquidation_date)
          : "";
        updateData.termination_notice_date = investmentDetail.termination_notice_date
          ? investmentDetail.termination_notice_date
          : "";
        updateData.sales_region = {
          label: remainingData.salesRegionData.sales_region,
          value: remainingData.salesRegionData.sales_region_id,
        };
        updateData.invested_without_pa = investmentDetail.invested_without_pa;
        updateData.client_id = investmentDetail.client_id;
        updateData.fund_group_name = {
          label: remainingData.fundData.fund_group_name,
          value: remainingData.fundData.fund_group_id,
        };
        updateData.share_class_name = investmentDetail.share_class_name
          ? { label: investmentDetail.share_class_name, value: investmentDetail.share_class_name }
          : { label: "", value: "" };
        updateData.sub_account_number =
          remainingData.subAccountData !== undefined ? remainingData.subAccountData.sub_account_number : "";
        updateData.termination_code_id = {
          label: investmentDetail.termination_code_id ? remainingData.termCodeData.termination_reason : "",
          value: investmentDetail.termination_code_id ? investmentDetail.termination_code_id : "",
        };
        updateData.reason_for_change = "";

        if (investmentDetail.account_transferred_to || investmentDetail.exchanged_to) {
          setInvestmentStatus(true);
        }

        setFundGroupInfo({
          fundGroupName: remainingData.fundData.fund_group_name,
          fundGroupId: remainingData.fundData.fund_group_id,
        });

        setInvestmentIds({ investment_id: validatedParams.investment_account_id, ...otherIDs });
        setInvestmentData(updateData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };

    setupInvestmentData();
  }, []);

  const buildSalesRegionOptions = (options) => {
    return options.map((opt) => {
      return { label: opt.sales_region, value: opt.sales_region_id };
    });
  };

  const buildFundOptions = (options) => {
    return options.map((opt) => {
      return { label: opt.fund_group_name, value: opt.fund_group_id };
    });
  };

  const buildTerminationOptions = (options) => {
    return options.map((opt) => {
      return { label: opt.termination_reason, value: opt.termination_code_id };
    });
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        let urls = ["/v1/sales-region", "/v1/fund-group", "/v1/termination-code"];
        let results = await multiFetch(urls, accessToken);
        if (results.error) {
          throw new Error(results.requestId);
        }
        setAccountOptions(buildSalesRegionOptions(results[0].data));
        setFundOptions(buildFundOptions(results[1].data));
        setTerminationOptions(buildTerminationOptions(results[2].data));
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    getOptions();
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "fund_group_name",
      fund_group_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [fund_group_name]);

  useEffect(() => {
    validationUseEffectHelper("approval_date", approval_date, fieldsToValidate, validationErrors, setValidationError);
  }, [approval_date]);

  useEffect(() => {
    validationUseEffectHelper(
      "share_class_name",
      share_class_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [share_class_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  useEffect(() => {
    if (investmentToExchange.value || investmentToTransfer.value) {
      let newFieldsToValidate = { ...fieldsToValidate };
      newFieldsToValidate.termination_code_id = ValidateRequiredSelect;
      setFieldsToValidate(newFieldsToValidate);
    } else {
      let newFieldsToValidate = { ...fieldsToValidate };
      let newValidationErrors = { ...validationErrors };
      delete newFieldsToValidate.termination_code_id;
      delete newValidationErrors.termination_code_id;
      setFieldsToValidate(newFieldsToValidate);
      setValidationError(newValidationErrors);
    }
  }, [investmentToExchange, investmentToTransfer]);

  useEffect(() => {
    if (investmentToExchange.value) {
      validationUseEffectHelper(
        "termination_code_id",
        termination_code_id,
        fieldsToValidate,
        validationErrors,
        setValidationError
      );
    }
  }, [termination_code_id]);

  if (!loadingData) {
    return (
      <div data-testid={"page"}>
        <Col>
          <Row>
            <Col sm={12}>
              <h2>Edit Investment Account Detail For Plan</h2>
            </Col>
          </Row>
          <Col sm={12}>
            <Card heading={"Investments"}>
              <Row>
                <Col sm={6}>
                  <div data-testid={`fund_group_name`}>
                    <label>Fund</label>
                    <Select
                      id={`fund_group_name`}
                      inputId={`fund_group_name`}
                      options={funds}
                      value={fund_group_name["label"] !== "" ? fund_group_name : "undefined"}
                      styles={selectStyler({ validationErrorsValue: validationErrors["fund_group_name"] })}
                      onChange={(e) => {
                        handleSelectChange("fund_group_name", e, setInvestmentData);
                        handleSelectChange("share_class_name", (e = null), setInvestmentData);
                      }}
                      isClearable={true}
                    ></Select>
                    <ValidationErrorMessage
                      paramId={"fund_group_name"}
                      validationError={validationErrors["fund_group_name"]}
                    />
                  </div>
                </Col>
                <Col sm={6} data-testid={`share_class_name`}>
                  <label>Share Class</label>
                  <Select
                    id={`share_class_name`}
                    inputId={`share_class_name`}
                    options={shareClasses}
                    value={share_class_name["label"] !== "" ? share_class_name : "undefined"}
                    styles={selectStyler({ validationErrorsValue: validationErrors["share_class_name"] })}
                    isLoading={isShareLoading}
                    isClearable={true}
                    onFocus={() => {
                      getShareClassOptions(fund_group_name.value);
                    }}
                    onChange={(e) => {
                      handleSelectChange("share_class_name", e, setInvestmentData);
                    }}
                  ></Select>
                  <ValidationErrorMessage
                    paramId={"share_class_name"}
                    validationError={validationErrors["share_class_name"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} data-testid={`sales_region`}>
                  <label>Sales Region</label>
                  <Select
                    id={`sales_region`}
                    inputId={`sales_region`}
                    options={accountOptions}
                    value={sales_region["label"] !== "" ? sales_region : "undefined"}
                    styles={selectStyler({})}
                    onChange={(e) => handleSelectChange("sales_region", e, setInvestmentData)}
                  ></Select>
                </Col>
                <Col sm={6}>
                  <Input
                    id={`client_id`}
                    data-testid={`client_id`}
                    type="text"
                    label="Client ID"
                    value={client_id}
                    onChange={(e) => {
                      handleChange(e, "client_id", setInvestmentData);
                    }}
                    disabled={true}
                    errorMessage={validationErrors["sub_account_number"]}
                  ></Input>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Checkbox
                    data-testid={"invested_without_pa"}
                    id={`invested_without_pa`}
                    label="Missing Documentation"
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    checked={invested_without_pa}
                    onChange={(e) => {
                      handleCheckboxChange(e, "invested_without_pa");
                    }}
                    errorMessage={""}
                  ></Checkbox>
                </Col>
                <Col sm={6}>
                  <Input
                    id={`sub_account_number`}
                    data-testid={`sub_account_number`}
                    type="text"
                    label="Service Provider Sub Account Number"
                    value={sub_account_number}
                    disabled={true}
                    onChange={(e) => {
                      handleChange(e, "sub_account_number", setInvestmentData);
                    }}
                    errorMessage={validationErrors["sub_account_number"]}
                  ></Input>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col sm={12} lg={4}>
                <Card heading="Dates">
                  <Input
                    data-testid={`approval_date`}
                    id={`approval_date`}
                    type="date"
                    label="Approval Date"
                    maxLength="10"
                    value={approval_date}
                    onChange={(e) => {
                      handleChange(e, "approval_date", setInvestmentData);
                    }}
                  ></Input>
                  <ValidationErrorMessage paramId="approval_date" validationError={validationErrors["approval_date"]} />
                  <Input
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    data-testid={`actual_liquidation_date`}
                    id={`actual_liquidation_date`}
                    type="date"
                    label="Actual Liquidation Date"
                    maxLength="10"
                    value={actual_liquidation_date}
                    onChange={(e) => {
                      handleChange(e, "actual_liquidation_date", setInvestmentData);
                    }}
                  ></Input>
                  <Input
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    data-testid={`approved_liquidation_date`}
                    id={`approved_liquidation_date`}
                    type="date"
                    label="Approved Liquidation Date"
                    maxLength="10"
                    value={approved_liquidation_date}
                    onChange={(e) => {
                      handleChange(e, "approved_liquidation_date", setInvestmentData);
                    }}
                  ></Input>
                  <Input
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    data-testid={`termination_notice_date`}
                    id={`termination_notice_date`}
                    type="date"
                    label="Termination Notice Date"
                    maxLength="10"
                    value={termination_notice_date}
                    onChange={(e) => {
                      handleChange(e, "termination_notice_date", setInvestmentData);
                    }}
                  ></Input>
                </Card>
              </Col>
              <Col sm={12} lg={8}>
                <Card heading="Investment Actions">
                  {investmentStatus ? (
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <h2>
                          This investment account has been exchanged or transferred. Please refer to the investment
                          details page for more information or click below to remove tranfer or exchange.
                        </h2>
                        <Button
                          onClick={() => {
                            handleExchangeTransferRemoval();
                          }}
                          data-testid={"exchange-button"}
                        >
                          Remove Exchange/Transfer
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <>
                      {currentComponent === "" ? (
                        <Row>
                          <Col style={{ textAlign: "center" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
                            <Button
                              style={{ marginRight: 15 }}
                              onClick={() => {
                                handleComponentSwitch("exchange");
                              }}
                              data-testid={"exchange-button"}
                            >
                              Start Investment Exchange
                            </Button>
                            <Button
                              onClick={() => {
                                handleComponentSwitch("transfer");
                              }}
                              data-testid={"transfer-button"}
                            >
                              Start Investment Transfer
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {currentComponent === "exchange" ? (
                            <ExchangeInvestment
                              setCurrentComponent={setCurrentComponent}
                              otherIds={investmentIds}
                              fundGroupInfo={fundGroupInfo}
                              investmentToExchange={investmentToExchange}
                              setInvestmentToExchange={setInvestmentToExchange}
                            />
                          ) : (
                            <TransferInvestment
                              setCurrentComponent={setCurrentComponent}
                              otherIds={investmentIds}
                              fundGroupInfo={fundGroupInfo}
                              investmentToTransfer={investmentToTransfer}
                              setInvestmentToTransfer={setInvestmentToTransfer}
                            ></TransferInvestment>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Card>
              </Col>
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Col sm={12}>
                <Card heading="Additional Information">
                  <div data-testid={"termination_code_id"}>
                    <label>Termination Reason</label>
                    <Select
                      id={"termination_code_id"}
                      options={terminationOptions}
                      value={termination_code_id["label"] !== "" ? termination_code_id : "undefined"}
                      onChange={(e) => {
                        handleSelectChange("termination_code_id", e, setInvestmentData);
                      }}
                      isClearable={true}
                      styles={selectStyler({ validationErrorsValue: validationErrors["termination_code_id"] })}
                    ></Select>
                    <ValidationErrorMessage
                      paramId={"termination_code_id"}
                      validationError={validationErrors["termination_code_id"]}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Col sm={12}>
                <Card heading="Notes">
                  <Col sm={12}>
                    <label>Reason For Change</label>
                    <div>
                      <textarea
                        id={"reason_for_change"}
                        data-testid={"reason_for_change"}
                        value={reason_for_change}
                        style={getTextAreaStyles(validationErrors["reason_for_change"])}
                        onChange={(e) => {
                          handleChange(e, "reason_for_change", setInvestmentData);
                        }}
                        //disabled={true}
                      ></textarea>
                      <ValidationErrorMessage
                        paramId={"reason_for_change"}
                        validationError={validationErrors["reason_for_change"]}
                      />
                    </div>
                  </Col>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Button style={{ marginRight: 15 }} onClick={handleSubmit} data-testid={"saveChanges"}>
                  Save Changes
                </Button>
                <Button onClick={handleDiscard} data-testid={"discardChanges"}>
                  Discard Changes
                </Button>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30"></Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default InvestmentDetailsEdit;
