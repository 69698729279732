import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const Details = ({ ...props }) => {
  return (
    <>
      <Card heading={"Service Provider Detail"}>
        <Row>
          <Col sm={7}>
            <DataComponent
              id={`service_provider_name`}
              data-testid={`service_provider_name`}
              label={"Service Provider Name"}
              data={props.service_provider_name}
            />
          </Col>
          <Col sm={4}>
            <DataComponent
              id={`service_provider_type`}
              data-testid={`service_provider_type`}
              label={"Agreement Type"}
              data={props.service_provider_type}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={5}>
            <DataComponent
              id={`trading_nscc_number`}
              data-testid={`trading_nscc_number`}
              label={"NSCC Number"}
              data={props.trading_nscc_number}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Details;

Details.propTypes = {
  service_provider_name: PropTypes.string,
  trading_nscc_number: PropTypes.string,
  service_provider_type: PropTypes.string,
};
