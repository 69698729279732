import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Checkbox from "@pds-react/checkbox";

const ParticipatingTrustDetail = ({ ...props }) => {
  const {
    planDetailsName,
    planDetailsTaxId,
    planDetailsPlanType,
    planDetailsDolNumber,
    planStatus,
    checkboxesEmail,
    checkboxesThirdParty,
    checkboxesAllowsStatements,
  } = props;
  return (
    <>
      <Card heading={"Participating Trust"}>
        <Col sm={4}>
          <DataComponent id={"trust_name"} label="Name of Participating Trust" data={planDetailsName} />
          <DataComponent id={"tax_id"} label="Plan Tax ID (EIN)" data={planDetailsTaxId} />
        </Col>
        <Col sm={4}>
          <DataComponent id={"plan_type"} label="Plan Type" data={planDetailsPlanType} />
          <DataComponent id={"dol_number"} label="DOL Plan Number" data={planDetailsDolNumber} />
        </Col>
        <Col sm={4}>
          <Row>
            <DataComponent id={planStatus} label="Status" data={planStatus} />
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Checkbox
              data-testid={`agrees_to_email`}
              id={`agrees_to_email`}
              label="Consents to email communications"
              disabled={true}
              name={"agrees_to_email"}
              checked={checkboxesEmail}
              errorMessage={""}
            ></Checkbox>
          </Row>
          <Row>
            <Checkbox
              data-testid={`agrees_to_third_party_disclosure`}
              id={` agrees_to_third_party_disclosure`}
              label="Consents to third party disclosure"
              disabled={true}
              checked={checkboxesThirdParty}
              errorMessage={""}
            ></Checkbox>
          </Row>
          <Row>
            <Checkbox
              data-testid={`allows_statements`}
              id={`allows_statements`}
              label="Allows Statements"
              disabled={true}
              checked={checkboxesAllowsStatements}
              errorMessage={""}
            ></Checkbox>
          </Row>
        </Col>
      </Card>
    </>
  );
};

export default ParticipatingTrustDetail;

ParticipatingTrustDetail.propTypes = {
  planDetailsName: PropTypes.string,
  planDetailsTaxId: PropTypes.string,
  planDetailsPlanType: PropTypes.string,
  planDetailsDolNumber: PropTypes.string,
  planStatus: PropTypes.string,
  checkboxesEmail: PropTypes.bool,
  checkboxesThirdParty: PropTypes.bool,
  checkboxesAllowsStatements: PropTypes.bool,
};
