import Config from "../../config";

export const ValidatePhoneNumber = (value) => {
  const regexp = /\(\d{3}\)[ ]\d{3}[-]\d{4}$/;
  if (value.length === 0) {
    return null;
  }
  if (!regexp.test(value)) {
    return "Phone Number must be in (###) ###-#### format";
  }
  return null;
};

export const ValidatePlanTax = (value) => {
  const regexp = /^(\d{2})-\d{7}$/;
  if (value.length === 0) {
    return null;
  }

  if (!regexp.test(value)) {
    return "EIN must be in ##-####### format";
  }
  return null;
};

export const ValidateDolPlanNumber = (value) => {
  const regexp = /^\d{3}$/;
  if (value.length === 0) {
    return null;
  }
  if (!regexp.test(value)) {
    return "DOL Plan must be in ### format";
  }
  return null;
};

export const ValidateWsrsContractNumber = (value) => {
  const regexp = /^\d{6}$/;
  if (value.length === 0) {
    return "Please import Contract or toggle off";
  }
  if (!regexp.test(value)) {
    return "WSRS Contract Number must be in ###### format";
  }
  return null;
};

export const ValidateRequired = (value) => {
  if (value.length === 0) {
    return "Required";
  }
  return null;
};

export const Validate = (formData, fieldsToValidate) => {
  let errors = {};
  let isValid = true;
  for (const id in fieldsToValidate) {
    const innerParam = formData[id];
    if (Array.isArray(innerParam)) {
      errors[id] = [];
      for (let index = 0; index < innerParam.length; index++) {
        errors[id][index] = {};
        for (const key in fieldsToValidate[id][index]) {
          const validateFunction = fieldsToValidate[id][index][key];
          errors[id][index][key] = validateFunction(innerParam[index][key]);
          if (errors[id][index][key] === null) {
            continue;
          }
          isValid = false;
        }
      }
    } else {
      const validateFunction = fieldsToValidate[id];
      errors[id] = validateFunction(formData[id]);
      if (errors[id] === null) {
        continue;
      }
      isValid = false;
    }
  }
  return [isValid, errors];
};

export const ValidateRequiredSelect = (value) => {
  let valueToCheck = "";
  if (value !== null && value !== undefined) {
    valueToCheck = value.label;
  }
  return ValidateRequired(valueToCheck);
};

export const getSelectValidationStyle = (validationErrorsValue) => {
  if (validationErrorsValue === null || validationErrorsValue === "" || validationErrorsValue === undefined) {
    return {};
  }
  return {
    control: (base) => ({
      ...base,
      borderColor: "indianred",
    }),
  };
};

export const ValidateDate = (strDate) => {
  if (strDate.length === 0) {
    return "Required";
  }
  const now = Date.now();
  try {
    const selectedDate = Date.parse(strDate);
    if (selectedDate > now) {
      return "Must not be greater than today's date.";
    }
  } catch (error) {
    return "Please supply a valid date.";
  }
  return null;
};

export const FormatTaxId = (e) => {
  let val = e.target.value;
  val = val.replace(/[^\d]/g, "");
  if (val.length >= 10) {
    val = val.slice(0, 9);
  }
  if (val.length > 2) {
    let front = val.slice(0, 2);
    let back = val.slice(2, val.length);
    val = front + "-" + back;
  }
  e.target.value = val;
};

export const FormatDol = (e) => {
  let val = e.target.value;
  val = val.replace(/[^\d]/g, "");
  if (val.length >= 10) {
    val = val.slice(0, 9);
  }
  if (val.length > 2) {
    let front = val.slice(0, 3);
    val = front;
  }
  e.target.value = val;
};

export const FormatContractId = (e) => {
  let val = e.target.value;
  val = val.replace(/[^\d]/g, "");
  if (val.length >= 6) {
    val = val.slice(0, 6);
  }
  e.target.value = val;
};

export const FormatTaxIdOnLoad = (taxId) => {
  let val = taxId.replace(/[^\d]/g, "");
  if (val.length >= 10) {
    val = val.slice(0, 9);
  }
  if (val.length > 2) {
    let front = val.slice(0, 2);
    let back = val.slice(2, val.length);
    val = front + "-" + back;
  }
  return val;
};

export const UnFormatTaxId = (taxId) => {
  let val = taxId.replace("-", "");
  return val;
};

export const PhoneNumberFormatOnLoad = (phoneNumber) => {
  let formattedPhoneNumber = phoneNumber.substring(2);
  return formattedPhoneNumber;
};

export const PhoneNumberFormatOnSubmit = (phoneNumber) => {
  let formattedPhoneNumber = `1 ${phoneNumber}`;
  return formattedPhoneNumber;
};

export const FormatPhoneNumber = (e) => {
  let value = e.target.value;
  if (!value) e.target.value = value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) e.target.value = phoneNumber;
  else if (phoneNumberLength < 7) {
    e.target.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    e.target.value = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

export const FormatNSCCnumber = (nscc) => {
  let val = nscc.replace(/[^\d]/g, "");
  if (val.length >= 10) {
    val = val.slice(0, 9);
  }
  return val;
};

export const getTextAreaStyles = (validationErrorsValue) => {
  const style = {
    resize: "none",
    height: 200,
    width: "-webkit-fill-available",
  };

  if (validationErrorsValue === null || validationErrorsValue === "" || validationErrorsValue === undefined) {
    style["borderColor"] = "black";
  } else {
    style["borderColor"] = "indianred";
  }
  return style;
};

export const validationUseEffectHelper = (id, value, fieldsToValidate, validationErrors, setValidationError) => {
  let valueToCheck = "";
  if (value !== null && value !== undefined) {
    if (typeof value === "object") {
      valueToCheck = value.label;
    } else {
      valueToCheck = value;
    }
  }
  if (!(valueToCheck === "" && !(id in validationErrors)) && id in fieldsToValidate) {
    const error = fieldsToValidate[id](value);
    setValidationError((validationErrors) => ({ ...validationErrors, [id]: error }));
  }
};

export const validateGuid = (inputGuid) => {
  const { redirectUrl } = Config.cognito;
  let regexp = new RegExp(/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}?$/);
  if (regexp.test(inputGuid)) {
    return JSON.parse(JSON.stringify(inputGuid));
  } else {
    window.location.assign(`${redirectUrl}/not-found`);
  }
};
