import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import Select from "react-select";
import Button from "@pds-react/button";
import Loading from "../../../../../../Loading/Loading";
import { fetchService } from "../../../../../../../services/fetchService";
import Config from "../../../../../../../config";
import UserContext from "../../../../../../../context/User.context";
import { useNavigate } from "react-router-dom";
import selectStyler from "../../../../../../Utilities/selectStyler";

const ExchangeInvestment = ({
  setCurrentComponent,
  otherIds,
  fundGroupInfo,
  investmentToExchange,
  setInvestmentToExchange,
}) => {
  const { investment_id, participating_trust_id, trading_account_id, fund_group_id } = otherIds;
  const { fundGroupId } = fundGroupInfo;
  const [investmentToExchangeOptions, setInvestmentToExchangeOptions] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();

  const handleInvestmentToExchange = (e) => {
    setInvestmentToExchange(e);
  };

  const handleExchangeDiscard = () => {
    setCurrentComponent("");
    setInvestmentToExchange({ label: "", value: "" });
  };

  const formatExLabel = ({ fundGroup, shareClass }) => (
    <div>
      <p>
        <span style={{ color: "#0076CF" }}>{fundGroup}</span> | <span style={{ color: "#00C4D9" }}>{shareClass}</span>
      </p>
    </div>
  );

  useEffect(() => {
    const getToExchangeOptions = async () => {
      try {
        let investEndpoint = `/v1/investment-account?participating-trust-id=${participating_trust_id}&trading-account-id=${trading_account_id}&fund-group-id=${fund_group_id}`;
        let investmentResponse = await fetchService(baseUrl + investEndpoint, { method: "GET", token: accessToken });
        const investmentResp = await investmentResponse.json();
        if (200 !== investmentResponse.status) {
          throw new Error(investmentResp.requestId);
        }
        let fundEndpoint = `/v1/fund-group?fund-group-id=${fundGroupId}`;
        let fundResponse = await fetchService(baseUrl + fundEndpoint, { method: "GET", token: accessToken });
        const fundResp = await fundResponse.json();
        if (200 !== fundResponse.status) {
          throw new Error(fundResp.requestId);
        }
        let investmentOptions = [];
        investmentResp.data.forEach((investment) => {
          if (investment_id !== investment.investment_account_id && investment.actual_liquidation_date === null) {
            investmentOptions.push({
              label: `${fundResp.data[0].fund_group_name} - ${investment.share_class_name}`,
              value: investment.investment_account_id,
              fundGroup: fundResp.data[0].fund_group_name,
              shareClass: investment.share_class_name,
            });
          }
        });
        setLoadingData(false);
        setInvestmentToExchangeOptions(investmentOptions);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    getToExchangeOptions();
  }, []);

  if (!loadingData) {
    return (
      <div data-testid={"exchangeInvestment"}>
        <Row>
          <Col sm={12}>
            <div data-testid={"toExchange"}>
              <label>Current Investment Will Be Exchanged To:</label>
              <p>
                <span>Key: </span>
                <span style={{ color: "#0076CF" }}>Fund Group</span> |{" "}
                <span style={{ color: "#00C4D9" }}>Share Class</span>
              </p>
              <Select
                id={"toExchange"}
                formatOptionLabel={formatExLabel}
                styles={selectStyler({ validationErrorsValue: "", disabled: false, formattedLabel: true })}
                options={investmentToExchangeOptions}
                onChange={(e) => {
                  handleInvestmentToExchange(e);
                }}
                value={investmentToExchange["label"] !== "" ? investmentToExchange : "undefined"}
              ></Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Button style={{ marginRight: 15 }} data-testid={"discardChanges"} onClick={handleExchangeDiscard}>
              Discard Exchange
            </Button>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default ExchangeInvestment;

ExchangeInvestment.propTypes = {
  setCurrentComponent: PropTypes.func,
  otherIds: PropTypes.object,
  fundGroupInfo: PropTypes.object,
  setInvestmentToExchange: PropTypes.func,
  investmentToExchange: PropTypes.object,
};
