import PropTypes from "prop-types";
import { getSelectValidationStyle } from "./ValidationHelpers";

const selectStyler = ({ validationErrorsValue, disabled, formattedLabel }) => {
  let validationStyle = getSelectValidationStyle(validationErrorsValue);
  let styles = {
    input: (provided) => ({
      ...provided,
      border: "none !important",
      height: "42px !important",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px 0px 0px",
    }),
    control: (provided, state) => ({
      ...provided,
      marginBottom: "2px",
      border: state.isFocused || state.menuIsOpen ? "1px solid #0091da !important" : "1px solid #E6E6E6",
      boxShadow: state.isFocused || state.menuIsOpen ? "0 0 8px #035fa4 !important" : "none",
      color: "#333",
      backgroundColor: disabled ? "#f5f5f5" : "#ffffff",
      fontSize: "1.5rem",
      display: "flex",
      width: "100%",
      height: "48px",
      paddingLeft: "12px",
      paddingRight: "12px",
      borderRadius: "8px",
      borderColor: "control" in validationStyle ? validationStyle.control().borderColor + " !important" : undefined,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "2px",
    }),
    option: (provided, { isSelected, isFocused }) => {
      return {
        ...provided,
        backgroundColor: formattedLabel
          ? isSelected
            ? "lightGray"
            : isFocused
            ? "#DEEBFF"
            : "white"
          : isSelected
          ? "#2684FF"
          : isFocused
          ? "#DEEBFF"
          : "white",
        color: formattedLabel ? "white" : isSelected ? "white" : "#333",
        whiteSpace: "pre-wrap",
      };
    },
    singleValue: (provided) => ({
      ...provided,
      whiteSpace: "pre-wrap",
    }),
  };
  return styles;
};

selectStyler.propTypes = {
  validationErrorsValue: PropTypes.string,
  disabled: PropTypes.string,
};

export default selectStyler;
