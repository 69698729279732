import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Card from "../../../../Card";
import React from "react";
import PropTypes from "prop-types";

const PortfolioManagersComponent = ({ ...props }) => {
  const { firm_manager_name, portfolio_manager, pm_contact_email, currentIndex } = props;

  return (
    <>
      <Col data-testid={"PortfolioManagersComponent"}>
        <Card heading={"Portfolio Managers"}>
          {currentIndex === 0 ? (
            <>
              <Row>
                <Col>
                  <DataComponent
                    id={`firm_manager_name`}
                    label="Firm Manager Name"
                    data={firm_manager_name}
                    data-testid={"firm_manager_name"}
                  ></DataComponent>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataComponent
                    id={`portfolio_managers`}
                    label="Portfolio Managers"
                    data={portfolio_manager}
                    data-testid={"portfolio_managers"}
                  ></DataComponent>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <Row>
            <Col>
              <DataComponent
                id={`pm_contact_email`}
                label="PM Contact Email"
                data={pm_contact_email}
                data-testid={"pm_contact_email"}
              ></DataComponent>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};
export default PortfolioManagersComponent;

PortfolioManagersComponent.propTypes = {
  firm_manager_name: PropTypes.string,
  portfolio_manager: PropTypes.string,
  pm_contact_email: PropTypes.string,
  currentIndex: PropTypes.number,
};
