import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import PropTypes from "prop-types";
import Welcome from "../Welcome/Welcome";
import Confirmation from "../NewParticipationAgreement/Steps/Confirmation";
import Login from "../Login/Login";
import UserContext from "../../context/User.context";
import NewParticipationAgreement from "../NewParticipationAgreement/NewParticipationAgreement";
import "@pds-react/grid/dist/grid.min.css";
import MainPageLayout from "../MainPageLayout/MainPageLayout";
import ProtectedRoutes from "../MainPageLayout/ProtectedRoutes";
import NotFoundError from "../ErrorPages/NotFoundError/NotFoundError";
import UnauthorizedError from "../ErrorPages/UnauthorizedError/UnauthorizedError";
import TrustInvestmentManagement from "../Management/TrustAndInvestment/TrustInvestmentManagement";
import InvestmentDetailsEdit from "../Management/TrustAndInvestment/Investment/InvestmentDetailsEdit/InvestmentDetailsEdit";
import InvestmentDetails from "../Management/TrustAndInvestment/Investment/InvestmentDetails/InvestmentDetails";
import SameAccountNewInvestment from "../Management/TrustAndInvestment/InvestmentActions/SameAccountNewInvestment";
import NewAccountNewInvestment from "../Management/TrustAndInvestment/InvestmentActions/NewAccountNewInvestment";
import TrustDetails from "../Management/TrustAndInvestment/TrustAccount/TrustDetails/TrustDetails";
import TrustDetailsEdit from "../Management/TrustAndInvestment/TrustAccount/TrustDetailsEdit/TrustDetailsEdit";
import CompanyDetailsEdit from "../Management/ContactAndCompany/Company/CompanyDetailsEdit/CompanyDetailsEdit";
import ContactDetails from "../Management/ContactAndCompany/Contact/ContactDetails/ContactDetails";
import ContactDetailsEdit from "../Management/ContactAndCompany/Contact/ContactDetailsEdit/ContactDetailsEdit";
import CompanyDetails from "../Management/ContactAndCompany/Company/CompanyDetails/CompanyDetails";
import ServiceProviderDetails from "../Management/ServiceProviderAndTradingAccount/ServiceProvider/ServiceProviderDetails/ServiceProviderDetails";
import ServiceProviderDetailsEdit from "../Management/ServiceProviderAndTradingAccount/ServiceProvider/ServiceProviderDetailsEdit/ServiceProviderDetailsEdit";
import ServiceProviderTradingAccountManagement from "../Management/ServiceProviderAndTradingAccount/ServiceProviderTradingAccountManagement";
import PortfolioDetails from "../Management/PortfolioAndShareClass/Portfolio/PortfolioDetails/PortfolioDetails";
import ContactAndCompanyManagement from "../Management/ContactAndCompany/ContactAndCompanyManagement";
import GroupingsManagement from "../Management/Groupings/GroupingsManagement";
import GroupingDetails from "../Management/Groupings/GroupingsDetails/GroupingDetails";
import GroupingsEdit from "../Management/Groupings/GroupingsEdit/GroupingsEdit";
import TradingAccountDetails from "../Management/ServiceProviderAndTradingAccount/TradingAccount/TradingAccountDetails/TradingAccountDetails";
import TradingAccountDetailsEdit from "../Management/ServiceProviderAndTradingAccount/TradingAccount/TradingAccountDetailsEdit/TradingAccountDetailsEdit";
import ShareClassDetails from "../Management/PortfolioAndShareClass/ShareClass/ShareClassDetails/ShareClassDetails";
import ShareClassDetailsEdit from "../Management/PortfolioAndShareClass/ShareClass/ShareClassDetailsEdit/ShareClassDetailsEdit";
import PortfolioDetailsEdit from "../Management/PortfolioAndShareClass/Portfolio/PortfolioDetailsEdit/PortfolioDetailsEdit";
import PortfolioAndShareClassManagement from "../Management/PortfolioAndShareClass/PortfolioAndShareClassManagement";
import ServerError from "../ErrorPages/ServerError/ServerError";
import LinkedInvestorEdit from "../Management/TrustAndInvestment/Investment/LinkedInvestorEdit/LinkedInvestorEdit";
import FsaAumReport from "../FsaAumReport/FsaAumReport";

function AppRouter({ calledFromTest }) {
  const { setUser: setUserContext, accessToken } = useContext(UserContext);

  const setUser = (user) => {
    // Verify users cognito groups
    let cognitoGroups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
    let hasReadWrite = cognitoGroups.includes("ispp-trust-co-read-write-users");
    let hasReadOnly = cognitoGroups.includes("ispp-trust-co-read-only-users");

    // Set login error if no congito groups exist
    let loginError;
    if (!hasReadWrite && !hasReadOnly) {
      loginError = "You do not have access to view this page, please contact your administrator.";
    }

    // Set scope of user based on cognito groups
    let scope = hasReadWrite ? "ReadWrite" : hasReadOnly ? "ReadOnly" : "NoAccess";

    // Update user context based on gathered login details
    setUserContext({
      loading: false,
      email: user.attributes.email,
      name: user.attributes.name,
      loggedIn: true,
      accessToken: user.signInUserSession.accessToken,
      loginError,
      scope,
    });
  };

  const handleAuth = ({ payload }) => {
    switch (payload.event) {
      case "signIn":
        return setUser(payload.data);
      case "signOut":
        return setUser({ loggedIn: false, loginError: null });
    }
  };

  useEffect(() => {
    if (!calledFromTest) {
      Auth.currentAuthenticatedUser()
        .then(setUser)
        .catch((error) => {
          console.error("Auth error:", error);
          setUserContext({ loading: false });
        });

      Hub.listen("auth", handleAuth);

      return () => Hub.remove("auth", handleAuth);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!accessToken?.payload?.exp) {
        return;
      }
      console.log(accessToken.payload.exp);
      console.log(new Date().getTime() / 1000);
      const expireTimeLeft = Math.floor(accessToken.payload.exp - new Date().getTime() / 1000);
      if (expireTimeLeft < 600) {
        alert("You're less than 10 minutes away from being logged out");
      } else if (expireTimeLeft < 1800) {
        alert("You're less than 30 minutes away from being logged out");
      }
    }, 60000);

    return () => {
      clearInterval(interval);
      console.log("Clearing interval");
    };
  }, [accessToken]);

  return (
    <Routes>
      <Route element={<MainPageLayout />}>
        <Route element={<ProtectedRoutes />}>
          <Route index path={"/"} element={<Welcome />}></Route>
          <Route path="/new-pa" element={<NewParticipationAgreement />} />
          <Route path="/pa-confirmation" element={<Confirmation />} />
          <Route path="/trust-investment-management" element={<TrustInvestmentManagement />} />
          <Route path="/trust-details/:participating_trust_id" element={<TrustDetails />} />
          <Route path="/trust-details/edit/:participating_trust_id" element={<TrustDetailsEdit />} />
          <Route path="/company-details/:contact_company_id" element={<CompanyDetails />} />
          <Route path="/company-details/edit/:contact_company_id" element={<CompanyDetailsEdit />} />
          <Route path="/company-details/new" element={<CompanyDetailsEdit />} />
          <Route path="/contact-details/:contact_id" element={<ContactDetails />} />
          <Route path="/contact-details/new/:contact_company_id" element={<ContactDetailsEdit />} />
          <Route path="/contact-details/edit/:contact_id" element={<ContactDetailsEdit />} />
          <Route path="/contact-company-management" element={<ContactAndCompanyManagement />} />
          <Route path="/investment-details/edit/:investment_account_id" element={<InvestmentDetailsEdit />} />
          <Route
            path="/investment-details/new/new-trading/:participating_trust_id"
            element={<NewAccountNewInvestment />}
          />
          <Route
            path="/investment-details/new/same-trading/:participating_trust_id/:trading_account_id"
            element={<SameAccountNewInvestment />}
          />
          <Route path="/investment-details/:investment_account_id" element={<InvestmentDetails />} />
          <Route
            path="/linked-investor/edit/:participating_trust_id/:trading_account_id"
            element={<LinkedInvestorEdit />}
          />
          <Route path="/groupings-details/:aggregation_group_id" element={<GroupingDetails />} />
          <Route path="/groupings-details/edit/:aggregation_group_id" element={<GroupingsEdit />} />
          <Route path="/groupings-details/new" element={<GroupingsEdit />} />
          <Route path="/service-provider-details/:service_provider_id" element={<ServiceProviderDetails />} />
          <Route path="/service-provider-details/edit/:service_provider_id" element={<ServiceProviderDetailsEdit />} />
          <Route path="/service-provider-details/new" element={<ServiceProviderDetailsEdit />} />
          <Route
            path="/service-provider-trading-account-management"
            element={<ServiceProviderTradingAccountManagement />}
          />
          <Route path="/generate-fsa-aum-report" element={<FsaAumReport />} />
          <Route path="/portfolio-and-share-class-management" element={<PortfolioAndShareClassManagement />} />
          <Route path="/groupings-management" element={<GroupingsManagement />} />
          <Route path="/portfolio-details/:additional_details_portfolio_id" element={<PortfolioDetails />} />
          <Route path="/portfolio-details/edit/:additional_details_portfolio_id" element={<PortfolioDetailsEdit />} />
          <Route path="/portfolio-details/new/:portfolio_id" element={<PortfolioDetailsEdit />} />
          <Route path="/trading-account-details/:trading_account_id" element={<TradingAccountDetails />} />
          <Route path="/trading-account-details/edit/:trading_account_id" element={<TradingAccountDetailsEdit />} />
          <Route path="/trading-account-details/new" element={<TradingAccountDetailsEdit />} />
          <Route path="/share-class-details/:share_class_id" element={<ShareClassDetails />} />
          <Route
            path="/share-class-details/edit/:additional_details_share_class_id"
            element={<ShareClassDetailsEdit />}
          />
          <Route path="/share-class-details/new/:share_class_id" element={<ShareClassDetailsEdit />} />
        </Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/unauthorized" element={<UnauthorizedError />}></Route>
        <Route path="/not-found" element={<NotFoundError />} />
        <Route path="/error" element={<ServerError />} />
        <Route path="*" element={<NotFoundError />} />
      </Route>
    </Routes>
  );
}

AppRouter.propTypes = {
  calledFromTest: PropTypes.bool,
  newHistory: PropTypes.object,
};

export default AppRouter;
