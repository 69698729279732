import React from "react";
import PropTypes from "prop-types";

const ValidationErrorMessage = ({ paramId, validationError }) => {
  return (
    <>
      {!(validationError === null || validationError === "") ? (
        <>
          <div className={"pds-error"}>
            <span className="pds-util-flex-align-center" id={`${paramId}-error-id`}>
              {validationError}
            </span>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

ValidationErrorMessage.propTypes = {
  paramId: PropTypes.string,
  validationError: PropTypes.string,
};

export default ValidationErrorMessage;
