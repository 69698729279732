import React, { useEffect, useState, useContext } from "react";
import Loading from "../../../Loading/Loading";
import { Col, Row } from "@pds-react/grid";
import { Link } from "react-router-dom";
import Card from "../../Card";
import DataComponent from "../../DataComponent";
import { fetchService } from "../../../../services/fetchService";
import Config from "../../../../config";
import UserContext from "../../../../context/User.context";
import { useParams, useNavigate } from "react-router-dom";
import { set_nulls_to_empty_strings } from "../../../Utilities/FormHelpers";
import { validateGuid } from "../../../Utilities/ValidationHelpers";
import ParticipatingTrustTableComponent from "./CardComponents/ParticipatingTrustTableComponent";

const GroupingDetails = () => {
  const [loadingData, setLoadingData] = useState(true);
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["aggregation_group_id"] = validateGuid(params.aggregation_group_id);
  }

  const [groupData, setGroupData] = useState({
    aggregation_group_name: "",
    aggregation_group_id: "",
    aggregation_group_category_name: "",
    aggregation_group_category_id: "",
    aggregation_group_control_number: "",
  });

  const [participatingTrusts, setParticipatingTrusts] = useState([]);
  const { aggregation_group_name, aggregation_group_category_name, aggregation_group_control_number } = groupData;

  const getGrouping = async () => {
    let endpoint = `/v1/groupings?aggregation-group-id=${validatedParams.aggregation_group_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    const requestId = resp["requestId"];
    if (200 !== response.status) {
      throw new Error(requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(requestId);
    }

    const grouping = set_nulls_to_empty_strings(resp.data[0]);
    return grouping;
  };

  const getParticipatingTrusts = async (result) => {
    let endpoint = `/v1/grouping-details-account-info?aggregation-group-id=${result}&limit=400`;
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    resp.data = set_nulls_to_empty_strings(resp.data);
    const trusts = resp.data.map((trust, index) => ({
      actions: (
        <span>
          <Link
            id={`trust_details_edit_${index}_link`}
            data-testid={`trust_details_edit_${index}_link`}
            to={`/trust-details/edit/${trust.participating_trust_id}`}
          >
            Edit
          </Link>
          <span> | </span>
          <Link
            id={`trust_details_${index}_link`}
            data-testid={`trust_details_${index}_link`}
            to={`/trust-details/${trust.participating_trust_id}`}
          >
            Details
          </Link>
        </span>
      ),
      ...trust,
    }));
    setParticipatingTrusts(trusts);
  };

  useEffect(() => {
    setLoadingData(true);
    const setupGroupingData = async () => {
      try {
        const grouping = await getGrouping();
        await getParticipatingTrusts(grouping.aggregation_group_id);
        setGroupData(grouping);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };

    (async () => {
      await setupGroupingData();
    })();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={6} lg={6}>
              <h2>Grouping Details</h2>
            </Col>
            <Col>
              <button
                data-testid={"edit_button"}
                id={"edit_button"}
                type="button"
                className="btn btn-primary progress-btn"
                onClick={() => {
                  navigate(`/groupings-details/edit/${validateGuid(validatedParams.aggregation_group_id)}`);
                }}
              >
                Edit
              </button>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Card heading={"Grouping"}>
                    <Row>
                      <Col sm={3}>
                        <DataComponent
                          id={"grouping_category"}
                          label="Grouping Category"
                          data-testid={"grouping_category"}
                          data={aggregation_group_category_name}
                        ></DataComponent>
                      </Col>
                      <Col sm={4}>
                        <DataComponent
                          id={"control_numbers"}
                          label="Control Number"
                          data={aggregation_group_control_number}
                        ></DataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={7}>
                        <DataComponent
                          id={"grouping_name"}
                          label="Grouping Name"
                          data-testid={"grouping_name"}
                          data={aggregation_group_name}
                        ></DataComponent>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>{!loadingData ? <ParticipatingTrustTableComponent data={participatingTrusts} /> : <></>}</Row>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default GroupingDetails;
