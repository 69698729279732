import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import Select from "react-select";

const ParticipatingTrustsComponent = ({ ...props }) => {
  const { data } = props;
  const [pageViewNum, setPageViewNum] = useState({ value: "", label: "" });
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("participating_trust_name", {
      header: () => <span>Name of Participating Trust</span>,
    }),
    columnHelper.accessor("plan_type", {
      header: () => <span>Plan Type</span>,
    }),
    columnHelper.accessor("actions", {
      header: () => <span>Actions</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const getPageViewOption = (numberOfPages) => {
    let choices = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      choices.push({ value: i - 1, label: i });
    }
    return choices;
  };
  const customStyles = {
    input: (provided) => ({
      ...provided,
      border: "none !important",
      height: "42px !important",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px 0px 0px",
    }),
    control: (provided, state) => ({
      ...provided,
      marginBottom: "2px",
      border: state.isFocused || state.menuIsOpen ? "1px solid #0091da !important" : "1px solid #E6E6E6",
      boxShadow: state.isFocused || state.menuIsOpen ? "0 0 8px #035fa4 !important" : "none",
      color: "#333",
      backgroundColor: "#fff",
      fontSize: "1.5rem",
      display: "flex",
      width: "100%",
      height: "50px",
      paddingLeft: "12px",
      paddingRight: "12px",
      marginTop: "8px",
      marginLeft: "4px",
      marginRight: "4px",
      borderRadius: "8px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "2px",
    }),
  };

  return (
    <>
      <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={12} lg={12}>
            <Card heading={"Associated Plans"}>
              <table data-testid={"participating_trusts_table"} id={"participating_trusts_table"}>
                <colgroup>
                  <col style={{ width: 20 }} />
                  <col style={{ width: 20 }} />
                  <col style={{ width: 20 }} />
                  <col style={{ width: 20 }} />
                </colgroup>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {data.length > 0 ? (
                <Row>
                  <Col sm={6} lg={6}></Col>
                  <Col sm={6} lg={6}>
                    <Row>
                      <button
                        data-testid={"pagination-first"}
                        className="border rounded p-1"
                        onClick={() => {
                          table.setPageIndex(0);
                          setPageViewNum({
                            value: 0,
                            label: 1,
                          });
                        }}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<<"}
                      </button>
                      <button
                        data-testid={"pagination-back"}
                        className="border rounded p-1"
                        onClick={() => {
                          table.previousPage();
                          setPageViewNum({
                            value: table.getState().pagination.pageIndex - 1,
                            label: table.getState().pagination.pageIndex,
                          });
                        }}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<"}
                      </button>
                      <div data-testid={"pagination-page-select"}>
                        <Select
                          isClearable={true}
                          placeholder={"Go to page"}
                          id={"page_select"}
                          inputId={"page_select"}
                          options={getPageViewOption(table.getPageCount())}
                          class_name="page_select"
                          styles={customStyles}
                          value={pageViewNum["label"] !== "" ? pageViewNum : "undefined"}
                          onChange={(e) => {
                            let pageView = e;
                            let selectedPage = 0;
                            if (pageView === null) {
                              pageView = { value: "", label: "" };
                            } else {
                              selectedPage = pageView["value"];
                            }
                            setPageViewNum(pageView);
                            table.setPageIndex(selectedPage);
                          }}
                        />
                      </div>
                      <div className={"page_text"}>{` of ${table.getPageCount()} `}</div>
                      <button
                        data-testid={"pagination-next"}
                        className="border rounded p-1"
                        onClick={() => {
                          table.nextPage();
                          setPageViewNum({
                            value: table.getState().pagination.pageIndex + 1,
                            label: table.getState().pagination.pageIndex + 2,
                          });
                        }}
                        disabled={!table.getCanNextPage()}
                      >
                        {">"}
                      </button>
                      <button
                        data-testid={"pagination-last"}
                        className="border rounded p-1"
                        onClick={() => {
                          table.setPageIndex(table.getPageCount() - 1);
                          setPageViewNum({
                            value: table.getPageCount() - 1,
                            label: table.getPageCount(),
                          });
                        }}
                        disabled={!table.getCanNextPage()}
                      >
                        {">>"}
                      </button>
                      <div data-testid={"pagination-number-of-rows"}>
                        <select
                          value={table.getState().pagination.pageSize}
                          onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                          }}
                        >
                          {[10, 25, 50, 75, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              Show {pageSize}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ParticipatingTrustsComponent;

ParticipatingTrustsComponent.propTypes = {
  data: PropTypes.array,
};
