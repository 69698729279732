import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "@pds-react/grid";

const HeaderAndButtons = ({ prev_label, next_label, prev_funct, next_funct, header_label }) => {
  return (
    <>
      <Row>
        <Col sm={6}>
          <button type="button" className="btn btn-primary progress-btn" onClick={prev_funct} data-testid="back_button">
            <span className="util-margin-right-15">
              <i className="fa fa-angle-left"></i>
            </span>
            {`Back - ${prev_label}`}
          </button>
        </Col>
        <Col sm={6}>
          <button type="button" className="btn btn-primary progress-btn" onClick={next_funct} data-testid="next_button">
            {`Next - ${next_label}`}
            <span className="util-margin-left-15">
              <i className="fa fa-angle-right"></i>
            </span>
          </button>
        </Col>
      </Row>
      <Row>
        <Col sm={2} />
        <Col sm={6}>
          <h2 className="text-center">{`${header_label}`}</h2>
        </Col>
        <Col sm={2} />
      </Row>
    </>
  );
};

export default HeaderAndButtons;

HeaderAndButtons.propTypes = {
  prev_label: PropTypes.string,
  next_label: PropTypes.string,
  prev_funct: PropTypes.func,
  next_funct: PropTypes.func,
  header_label: PropTypes.string,
};
