import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Checkbox from "@pds-react/checkbox";

const CompanyAddress = ({ ...props }) => {
  const {
    company_mailing_address,
    company_mailing_address_2,
    company_mailing_city,
    company_mailing_state,
    company_mailing_zip,
    company_mailing_country,
    street_address_same_as_mailing,
    company_street_address,
    company_street_address_2,
    company_street_city,
    company_street_state,
    company_street_zip,
    company_street_country,
  } = props;
  return (
    <>
      <Card heading={"Company Address"}>
        <Row>
          <Col sm={12}>
            <DataComponent id={"company_mailing_address"} label="Mailing Address" data={company_mailing_address} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DataComponent id={"company_mailing_address_2"} data={company_mailing_address_2} />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <DataComponent id={"company_mailing_city"} label={"City"} data={company_mailing_city} />
          </Col>
          <Col sm={3}>
            <DataComponent id={"company_mailing_state"} label={"State"} data={company_mailing_state} />
          </Col>
          <Col sm={3}>
            <DataComponent id={"company_mailing_zip"} label={"Zip"} data={company_mailing_zip} />
          </Col>
          <Col sm={3}>
            <DataComponent id={"company_mailing_country"} label={"Country"} data={company_mailing_country} />
          </Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30 util-margin-bottom-sm-15">
          <Col sm={12}>
            <Checkbox
              data-testid={"street_address_same_as_mailing"}
              id={"street_address_same_as_mailing"}
              label="Street Address is same as Mailing Address"
              checked={street_address_same_as_mailing}
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              disabled={true}
            ></Checkbox>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DataComponent id={"company_street_address"} label="Street Address" data={company_street_address} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <DataComponent id={"company_street_address_2"} data={company_street_address_2} />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <DataComponent id={"company_street_city"} label={"City"} data={company_street_city} />
          </Col>
          <Col sm={3}>
            <DataComponent id={"company_street_state"} label={"State"} data={company_street_state} />
          </Col>
          <Col sm={3}>
            <DataComponent id={"company_street_zip"} label={"Zip"} data={company_street_zip} />
          </Col>
          <Col sm={3}>
            <DataComponent id={"company_street_country"} label={"Country"} data={company_street_country} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CompanyAddress;

CompanyAddress.propTypes = {
  company_mailing_address: PropTypes.string,
  company_mailing_address_2: PropTypes.string,
  company_mailing_city: PropTypes.string,
  company_mailing_state: PropTypes.string,
  company_mailing_zip: PropTypes.string,
  company_mailing_country: PropTypes.string,
  street_address_same_as_mailing: PropTypes.bool,
  company_street_address: PropTypes.string,
  company_street_address_2: PropTypes.string,
  company_street_city: PropTypes.string,
  company_street_state: PropTypes.string,
  company_street_zip: PropTypes.string,
  company_street_country: PropTypes.string,
};
