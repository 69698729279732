import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import UserContext from "../../../../../context/User.context";
import Config from "../../../../../config";
import { fetchService } from "../../../../../services/fetchService";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../Loading/Loading";
import Input from "@pds-react/input";
import { handleChange, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import DataComponent from "../../../DataComponent";
import { multiFetch } from "../../../../../services/multiFetch";
import Button from "@pds-react/button";
import {
  validateGuid,
  ValidateRequired,
  validationUseEffectHelper,
  Validate,
} from "../../../../Utilities/ValidationHelpers";

const LinkedInvestorEdit = () => {
  const initialData = {
    participating_trust_name: "",
    account_type: "",
    service_provider_name: "",
    omnibus_name: "",
    settlement_service_provider: "",
    sub_account_number: "",
  };

  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);

  const [linkedInvestorData, setLinkedInvestor] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["participating_trust_id"] = validateGuid(params.participating_trust_id);
    validatedParams["trading_account_id"] = validateGuid(params.trading_account_id);
  }

  const initialValidation = {
    sub_account_number: ValidateRequired,
  };

  const [validationErrors, setValidationError] = useState({});
  const [fieldsToValidate] = useState(initialValidation);

  const {
    account_type,
    participating_trust_name,
    service_provider_name,
    omnibus_name,
    settlement_service_provider,
    sub_account_number,
  } = linkedInvestorData;

  const getParticipatingTrust = async () => {
    let endpoint = `/v1/participating-trust?participating-trust-id=${validatedParams.participating_trust_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getLinkedInvestor = async () => {
    let endpoint = `/v1/linked-investor?participating-trust-id=${validatedParams.participating_trust_id}&trading-account-id=${validatedParams.trading_account_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getTradingAccount = async () => {
    let endpoint = `/v1/trading-account?trading-account-id=${validatedParams.trading_account_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const handleSubmit = async () => {
    const [formIsValid, errors] = Validate(linkedInvestorData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = `/v1/linked-investor`;
        let subAccountNumber = {
          participating_trust_id: params.participating_trust_id,
          trading_account_id: linkedInvestorData.trading_account_id,
          sub_account_number: linkedInvestorData.sub_account_number,
        };

        let formattedData = JSON.stringify(subAccountNumber);
        const response = await fetchService(baseUrl + endpoint, {
          method: "PUT",
          body: formattedData,
          token: accessToken,
        });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        navigate(`/trust-details/${params.participating_trust_id}`);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const handleDiscard = () => {
    navigate(`/trust-details/${params.participating_trust_id}`);
  };

  useEffect(() => {
    const setupAPIdata = async () => {
      try {
        let participatingTrustData = await getParticipatingTrust();
        let participatingTrust = set_nulls_to_empty_strings(participatingTrustData.data[0]);
        let linkedInvestorDetails = await getLinkedInvestor();
        let linkedInvestor = set_nulls_to_empty_strings(linkedInvestorDetails.data[0]);
        let tradingAccountData = await getTradingAccount();
        let tradingAccount = set_nulls_to_empty_strings(tradingAccountData.data[0]);

        let remainingData = await multiFetch(
          [
            `/v1/service-provider?service-provider-id=${tradingAccount.service_provider_id}`,
            `/v1/service-provider?service-provider-id=${tradingAccount.settlement_service_provider_id}`,
            `/v1/account-type?account-type-id=${tradingAccount.account_type_id}`,
          ],
          accessToken
        );

        let serviceProvider = set_nulls_to_empty_strings(remainingData[0].data[0]);
        let serviceProviderSettlement = set_nulls_to_empty_strings(remainingData[1].data[0]);
        let accountType = set_nulls_to_empty_strings(remainingData[2].data[0]);

        const updateData = {};

        updateData.account_type = accountType.account_type;
        updateData.trading_account_id = linkedInvestor.trading_account_id;
        updateData.omnibus_name = tradingAccount.omnibus_name;
        updateData.service_provider_name = serviceProvider.service_provider_name;
        updateData.settlement_service_provider = serviceProviderSettlement.service_provider_name;
        updateData.sub_account_number = linkedInvestor.sub_account_number;
        updateData.participating_trust_name = participatingTrust.participating_trust_name;

        setLinkedInvestor(updateData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIdata();
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "sub_account_number",
      sub_account_number,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [sub_account_number]);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Linked Investor"}>
                <Row>
                  <Col>
                    <DataComponent
                      id={"participating_trust_name"}
                      data-testid={"participating_trust_name"}
                      label="Name of Participating Trust"
                      data={participating_trust_name}
                    ></DataComponent>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <DataComponent
                      id={"account_type"}
                      data-testid={"account_type"}
                      label="Type"
                      data={account_type}
                    ></DataComponent>
                  </Col>
                  <Col>
                    <DataComponent
                      id={"omnibus_name"}
                      data-testid={"omnibus_name"}
                      label="Trading Account Name"
                      data={omnibus_name}
                    ></DataComponent>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DataComponent
                      id={"service_provider_name"}
                      data-testid={"account_type"}
                      label="Service Provider"
                      data={service_provider_name}
                    ></DataComponent>
                  </Col>
                  <Col>
                    <DataComponent
                      id={"settlement_service_provider"}
                      data-testid={"settlement_service_provider"}
                      label="Service Provider for Settlement"
                      data={settlement_service_provider}
                    ></DataComponent>
                  </Col>
                  <Col>
                    <Input
                      id={`sub_account_number`}
                      data-testid={`sub_account_number`}
                      type="text"
                      label="Sub Account Number"
                      value={sub_account_number}
                      onChange={(e) => {
                        handleChange(e, "sub_account_number", setLinkedInvestor);
                      }}
                      errorMessage={validationErrors["sub_account_number"]}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button onClick={handleSubmit} style={{ marginRight: 15 }} data-testid={"saveChanges"}>
                Save Changes
              </Button>
              <Button onClick={handleDiscard} data-testid={"discardChanges"}>
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default LinkedInvestorEdit;
