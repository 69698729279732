import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "@pds-react/grid";
import UserContext from "../../context/User.context";
import Button from "@pds-react/button";
import "./Welcome.css";
import { Link } from "react-router-dom";

function Welcome({ readOnly }) {
  const { scope } = useContext(UserContext);

  return (
    <div data-testid="Welcome">
      <Row>
        {scope === "ReadWrite" && !readOnly ? (
          <>
            <div className={"container"}>
              <Col sm={12} lg={12}>
                <Row>
                  <br></br>
                  <Col sm={3} lg={3}></Col>
                  <Col sm={6} lg={6}>
                    <div className={"text-center"} data-testid="Welcome">
                      <h1 className={"h1"}>Welcome</h1>
                      <br />
                      <Link to="/new-pa">
                        <Button block className={"util-padding-top-10 util-margin-right-15"}>
                          New Participating Trust
                        </Button>
                      </Link>
                    </div>
                  </Col>
                  <Col sm={3} lg={3}></Col>
                </Row>
              </Col>
            </div>
          </>
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
}

Welcome.propTypes = {
  readOnly: PropTypes.bool,
};

export default Welcome;
