import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Card from "../../../Card";
import Button from "@pds-react/button";
import BasicTable from "../../../BasicTable";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loading from "../../../../Loading/Loading";
import { multiFetch } from "../../../../../services/multiFetch";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ParticipatingTrustDetail from "./CardComponents/ParticipatingTrustDetail";
import PlanSponsorCompanyDetail from "./CardComponents/PlanSponsorCompanyDetail";
import FiduciaryCompanyDetail from "./CardComponents/FiduciaryCompanyDetail";
import TradingAccountInvestmentComponent from "./CardComponents/TradingAccountInvestmentComponent";
import { FormatTaxIdOnLoad, validateGuid } from "../../../../Utilities/ValidationHelpers";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";

const TrustDetails = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["participating_trust_id"] = validateGuid(params.participating_trust_id);
  }

  const initialData = {
    planDetailsName: "",
    planDetailsTaxId: "",
    planDetailsPlanType: "",
    planDetailsDolNumber: "",
    planStatus: "",
    checkboxesEmail: null,
    checkboxesThirdParty: null,
    checkboxesAllowsStatements: null,
    planSponsorCompanyName: "",
    planSponsorCompanyId: "",
    planSponsorContactName: "",
    planSponsorContactId: "",
    fiduciaryCompanyName: "",
    fiduciaryCompanyId: "",
    fiduciaryContactName: "",
    fiduciaryContactId: "",
    reason_for_change: "",
  };

  const initialGroupingsData = [
    {
      aggregation_group_category_name: "",
      aggregation_group_name: "",
      actions: (
        <span>
          <Link to="/">Edit</Link>
          <span> | </span>
          <Link to="/">Details</Link>
        </span>
      ),
    },
  ];

  const initialTradingAccountsAndInvestments = [
    {
      trading_account: {
        account_type: "",
        omnibus_name: "",
        service_provider_name: "",
        sub_account_number: "",
        trading_account_id: "",
      },
      investments: [
        {
          fund_group_name: "",
          share_class_name: "",
          approval_date: "",
          actual_liquidation_date: "",
          termination_reason: "",
          options: (
            <span>
              <Link to="/">Edit</Link>
              <span> | </span>
              <Link to="/">Details</Link>
            </span>
          ),
        },
      ],
    },
  ];

  const [participatingTrustData, setParticipatingTrustData] = useState(initialData);
  const [groupingsData, setGroupingsData] = useState(initialGroupingsData);
  const [historyVersions, setHistoryVersions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tradingData, setTradingData] = useState(initialTradingAccountsAndInvestments);
  const [loadingData, setLoadingData] = useState(true);

  const {
    planDetailsName,
    planDetailsTaxId,
    planDetailsPlanType,
    planDetailsDolNumber,
    planStatus,
    checkboxesEmail,
    checkboxesThirdParty,
    checkboxesAllowsStatements,
    planSponsorCompanyName,
    planSponsorCompanyId,
    planSponsorContactName,
    planSponsorContactId,
    fiduciaryCompanyName,
    fiduciaryCompanyId,
    fiduciaryContactName,
    fiduciaryContactId,
    reason_for_change,
    inserted_by,
    inserted_at,
  } = participatingTrustData;

  const handleVersionChange = async (trust, index) => {
    try {
      setLoadingData(true);
      let urls = [`/v1/plan-type?plan-type-id=${trust.plan_type_id}`];

      if (trust.fiduciary_contact_id) {
        urls.push(`/v1/contact?contact-id=${trust.fiduciary_contact_id}`);
      }

      if (trust.plan_sponsor_contact_id) {
        urls.push(`/v1/contact?contact-id=${trust.plan_sponsor_contact_id}`);
      }
      let multiData = await multiFetch(urls, accessToken);
      if (multiData.error) {
        throw new Error(multiData.requestId);
      }

      let companyURLs = [];

      if (trust.plan_sponsor_contact_id && trust.fiduciary_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[1].data[0].contact_company_id}`);
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[2].data[0].contact_company_id}`);
      } else if (trust.plan_sponsor_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[1].data[0].contact_company_id}`);
      } else if (trust.fiduciary_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[1].data[0].contact_company_id}`);
      }

      let companyData = [];
      let planCompanyData;
      let planContactData;
      let fidCompanyData;
      let fidContactData;
      if (companyURLs.length > 0) {
        companyData = await multiFetch(companyURLs, accessToken);
        if (companyURLs.length === 2) {
          fidCompanyData = set_nulls_to_empty_strings(companyData[0].data[0]);
          fidContactData = set_nulls_to_empty_strings(multiData[1].data[0]);
          planCompanyData = set_nulls_to_empty_strings(companyData[0].data[0]);
          planContactData = set_nulls_to_empty_strings(multiData[2].data[0]);
        } else if (trust.plan_sponsor_contact_id) {
          planCompanyData = set_nulls_to_empty_strings(companyData[0].data[0]);
          planContactData = set_nulls_to_empty_strings(multiData[1].data[0]);
        } else if (trust.fiduciary_contact_id) {
          fidCompanyData = set_nulls_to_empty_strings(companyData[0].data[0]);
          fidContactData = set_nulls_to_empty_strings(multiData[1].data[0]);
        }
      }
      trust = set_nulls_to_empty_strings(trust);
      let planTypeData = set_nulls_to_empty_strings(multiData[0].data[0]);

      const newParticipationData = {
        planDetailsName: trust.participating_trust_name,
        planDetailsTaxId: FormatTaxIdOnLoad(trust.plan_tax_id),
        planDetailsPlanType: planTypeData.plan_type,
        planDetailsDolNumber: trust.dol_plan_number,
        checkboxesEmail: trust.agrees_to_email,
        checkboxesThirdParty: trust.agrees_to_third_party_disclosure,
        checkboxesAllowsStatements: trust.allows_statements,
        planSponsorCompanyName: planCompanyData ? planCompanyData.company_name : "",
        planSponsorCompanyId: planCompanyData ? planCompanyData.contact_company_id : "",
        planSponsorContactName: planContactData ? planContactData.first_name + " " + planContactData.last_name : "",
        planSponsorContactId: planContactData ? planContactData.contact_id : "",
        fiduciaryCompanyName: fidCompanyData ? fidCompanyData.company_name : "",
        fiduciaryCompanyId: fidCompanyData ? fidCompanyData.contact_company_id : "",
        fiduciaryContactName: fidContactData ? fidContactData.first_name + " " + fidContactData.last_name : "",
        fiduciaryContactId: fidContactData ? fidContactData.contact_id : "",
        reason_for_change: trust.reason_for_change,
        inserted_by: trust.inserted_by,
        inserted_at: trust.inserted_at,
      };

      setParticipatingTrustData(newParticipationData);
      setCurrentIndex(index);
      setLoadingData(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getPlanDetailsData = async () => {
    let endpoint = `/v1/participating-trust-history?participating-trust-id=${
      validatedParams.participating_trust_id
    }&inserted-at=${historyTimeNow(new Date())}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  const getRemainingData = async (ids) => {
    try {
      let urls = [
        `/v1/groupings?participating-trust-id=${ids.participating_trust_id}`,
        `/v1/investments?participating-trust-id=${ids.participating_trust_id}`,
        `/v1/plan-type?plan-type-id=${ids.plan_type_id}`,
      ];

      if (ids.fiduciary_contact_id) {
        urls.push(`/v1/contact?contact-id=${ids.fiduciary_contact_id}`);
      }

      if (ids.plan_sponsor_contact_id) {
        urls.push(`/v1/contact?contact-id=${ids.plan_sponsor_contact_id}`);
      }
      let multiData = await multiFetch(urls, accessToken);
      console.log("Chisty multiData: ", multiData);
      if (multiData.error) {
        throw new Error(multiData.requestId);
      }

      let companyURLs = [];

      if (ids.plan_sponsor_contact_id && ids.fiduciary_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[3].data[0].contact_company_id}`);
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[4].data[0].contact_company_id}`);
      } else if (ids.plan_sponsor_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[3].data[0].contact_company_id}`);
      } else if (ids.fiduciary_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[3].data[0].contact_company_id}`);
      }

      let companyData = [];

      if (companyURLs.length > 0) {
        companyData = await multiFetch(companyURLs, accessToken);
        if (companyData.error) {
          throw new Error(companyData.requestId);
        }
      }

      if (ids.fiduciary_contact_id && ids.plan_sponsor_contact_id) {
        return {
          groupingsData: multiData[0].data,
          investmentsAndTradingAccountsData: multiData[1].data,
          planTypeData: multiData[2].data[0],
          fidContactData: multiData[3].data[0],
          planContactData: multiData[4].data[0],
          fidCompanyData: companyData[0].data[0],
          planCompanyData: companyData[1].data[0],
        };
      } else if (ids.fiduciary_contact_id) {
        return {
          groupingsData: multiData[0].data,
          investmentsAndTradingAccountsData: multiData[1].data,
          planTypeData: multiData[2].data[0],
          participatingTrustStatusData: multiData[3].data[0],
          fidContactData: multiData[3].data[0],
          fidCompanyData: companyData[0].data[0],
        };
      } else if (ids.plan_sponsor_contact_id) {
        return {
          groupingsData: multiData[0].data,
          investmentsAndTradingAccountsData: multiData[1].data,
          planTypeData: multiData[2].data[0],
          planContactData: multiData[3].data[0],
          planCompanyData: companyData[0].data[0],
        };
      } else {
        return {
          groupingsData: multiData[0].data,
          investmentsAndTradingAccountsData: multiData[1].data,
          planTypeData: multiData[2].data[0],
        };
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let planDetails = await getPlanDetailsData();
        let currentPlanDetails = planDetails[0];

        let otherIds = {
          fiduciary_contact_id: currentPlanDetails.fiduciary_contact_id,
          plan_sponsor_contact_id: currentPlanDetails.plan_sponsor_contact_id,
          participating_trust_id: currentPlanDetails.participating_trust_id,
          plan_type_id: currentPlanDetails.plan_type_id,
        };

        let remainingData = await getRemainingData(otherIds);

        currentPlanDetails = set_nulls_to_empty_strings(currentPlanDetails);
        let groupingsData = set_nulls_to_empty_strings(remainingData.groupingsData);
        let planTypeData = set_nulls_to_empty_strings(remainingData.planTypeData);

        let fidContactData;
        if (remainingData.fidContactData) {
          fidContactData = set_nulls_to_empty_strings(remainingData.fidContactData);
        }
        let planContactData;
        if (remainingData.planContactData) {
          planContactData = set_nulls_to_empty_strings(remainingData.planContactData);
        }
        let fidCompanyData;
        if (remainingData.fidCompanyData) {
          fidCompanyData = set_nulls_to_empty_strings(remainingData.fidCompanyData);
        }
        let planCompanyData;
        if (remainingData.planCompanyData) {
          planCompanyData = set_nulls_to_empty_strings(remainingData.planCompanyData);
        }

        remainingData.investmentsAndTradingAccountsData.forEach((tradingAccount) => {
          let disableInvestmentButton = true;
          set_nulls_to_empty_strings(tradingAccount.trading_account);
          set_nulls_to_empty_strings(tradingAccount.investments);
          tradingAccount.investments.map((investment, index) => {
            if (investment.actual_liquidation_date === "") {
              disableInvestmentButton = false;
            }
            investment.options = (
              <span>
                <Link
                  id={`investment_details_edit_${index}_link`}
                  data-testid={`investment_details_edit_${index}_link`}
                  to={`/investment-details/edit/${investment.investment_account_id}`}
                >
                  Edit
                </Link>
                <span> | </span>
                <Link
                  id={`investment_details_${index}_link`}
                  data-testid={`investment_details_${index}_link`}
                  to={`/investment-details/${investment.investment_account_id}`}
                >
                  Details
                </Link>
              </span>
            );
          });
          let addKey = tradingAccount.trading_account;
          addKey.disableInvestmentButton = disableInvestmentButton;
          tradingAccount["trading_acount"] = addKey;
        });

        groupingsData.forEach((group, index) => {
          group.actions = (
            <span>
              <Link
                id={`groupings_details_edit_${index}_link`}
                data-testid={`groupings_details_edit_${index}_link`}
                to={`/groupings-details/edit/${group.aggregation_group_id}`}
              >
                Edit
              </Link>
              <span> | </span>
              <Link
                id={`groupings_details_${index}_link`}
                data-testid={`groupings_details_${index}_link`}
                to={`/groupings-details/${group.aggregation_group_id}`}
              >
                Details
              </Link>
            </span>
          );
        });

        const newParticipationData = {
          planDetailsName: currentPlanDetails.participating_trust_name,
          planDetailsTaxId: FormatTaxIdOnLoad(currentPlanDetails.plan_tax_id),
          planDetailsPlanType: planTypeData.plan_type,
          planDetailsDolNumber: currentPlanDetails.dol_plan_number,
          checkboxesEmail: currentPlanDetails.agrees_to_email,
          checkboxesThirdParty: currentPlanDetails.agrees_to_third_party_disclosure,
          checkboxesAllowsStatements: currentPlanDetails.allows_statements,
          planSponsorCompanyName: planCompanyData ? planCompanyData.company_name : "",
          planSponsorCompanyId: planCompanyData ? planCompanyData.contact_company_id : "",
          planSponsorContactName: planContactData ? planContactData.first_name + " " + planContactData.last_name : "",
          planSponsorContactId: planContactData ? planContactData.contact_id : "",
          fiduciaryCompanyName: fidCompanyData ? fidCompanyData.company_name : "",
          fiduciaryCompanyId: fidCompanyData ? fidCompanyData.contact_company_id : "",
          fiduciaryContactName: fidContactData ? fidContactData.first_name + " " + fidContactData.last_name : "",
          fiduciaryContactId: fidContactData ? fidContactData.contact_id : "",
          reason_for_change: currentPlanDetails.reason_for_change,
          inserted_by: currentPlanDetails.inserted_by,
          inserted_at: currentPlanDetails.inserted_at,
        };

        setParticipatingTrustData(newParticipationData);
        setHistoryVersions(planDetails);
        setGroupingsData(groupingsData);
        setTradingData(remainingData.investmentsAndTradingAccountsData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  const groupingsColumns = [
    {
      accessorKey: "aggregation_group_category_name",
      header: () => <span>Category</span>,
    },
    {
      accessorKey: "aggregation_group_name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Name</span>,
    },
    {
      accessorKey: "aggregation_group_control_number",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Control Number</span>,
    },
    {
      accessorKey: "actions",
      header: () => "Actions",
      cell: (info) => <p>{info.getValue()}</p>,
    },
  ];

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={3}>
              <h2>Participating Trust Detail</h2>
            </Col>
            <Col>
              <button
                data-testid={"edit_button"}
                id={"edit_button"}
                type="button"
                className="btn btn-primary progress-btn"
                onClick={() => {
                  navigate(`/trust-details/edit/${validateGuid(validatedParams.participating_trust_id)}`);
                }}
              >
                Edit Plan
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Row>
                <Col sm={12}>
                  <ParticipatingTrustDetail
                    planDetailsName={planDetailsName}
                    planDetailsTaxId={planDetailsTaxId}
                    planDetailsPlanType={planDetailsPlanType}
                    planDetailsDolNumber={planDetailsDolNumber}
                    planStatus={planStatus}
                    checkboxesEmail={checkboxesEmail}
                    checkboxesThirdParty={checkboxesThirdParty}
                    checkboxesAllowsStatements={checkboxesAllowsStatements}
                  />
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={6}>
                  <PlanSponsorCompanyDetail
                    planSponsorCompanyName={planSponsorCompanyName}
                    planSponsorCompanyId={planSponsorCompanyId}
                    planSponsorContactName={planSponsorContactName}
                    planSponsorContactId={planSponsorContactId}
                  />
                </Col>
                <Col sm={6}>
                  <FiduciaryCompanyDetail
                    fiduciaryCompanyName={fiduciaryCompanyName}
                    fiduciaryCompanyId={fiduciaryCompanyId}
                    fiduciaryContactName={fiduciaryContactName}
                    fiduciaryContactId={fiduciaryContactId}
                  />
                </Col>
              </Row>
              {currentIndex === 0 ? (
                <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Col sm={12}>
                    <Card heading={"Groupings"}>
                      <BasicTable id={"grouping_table"} data={groupingsData} columns={groupingsColumns}></BasicTable>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={3}>
              <Row style={{ height: "100%" }}>
                <Col sm={12}>
                  <Card heading={"History"}>
                    <VersionHistory
                      historyVersions={historyVersions}
                      handleVersionChange={handleVersionChange}
                      currentIndex={currentIndex}
                    ></VersionHistory>
                    <ReasonForChange
                      reason_for_change={reason_for_change}
                      inserted_by={inserted_by}
                      inserted_at={inserted_at}
                    ></ReasonForChange>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {currentIndex === 0 ? (
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Col sm={12}>
                <Card heading={"Trading Accounts"}>
                  <>
                    {tradingData.map((tradingAccount, index) => {
                      return (
                        <TradingAccountInvestmentComponent
                          key={`trade_component_${index}`}
                          index={index}
                          account_type={tradingAccount.trading_account.account_type}
                          trading_account_name={
                            tradingAccount.trading_account.omnibus_name
                              ? tradingAccount.trading_account.omnibus_name
                              : planDetailsName
                          }
                          omnibus_name={tradingAccount.trading_account.omnibus_name}
                          trading_account_id={tradingAccount.trading_account.trading_account_id}
                          service_provider={tradingAccount.trading_account.service_provider_name}
                          sub_account_number={tradingAccount.trading_account.sub_account_number}
                          transfer_agent_account_number={tradingAccount.trading_account.transfer_agent_account_number}
                          investments={tradingAccount.investments}
                          validatedParams={validatedParams}
                          disableInvestmentButton={tradingAccount.trading_account.disableInvestmentButton}
                        />
                      );
                    })}
                  </>
                  <Row>
                    <Col sm={12} style={{ textAlign: "right", marginTop: "1%" }}>
                      <Link to={`/investment-details/new/new-trading/${validatedParams.participating_trust_id}`}>
                        <Button>Add New Investment and Trading Account</Button>
                      </Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default TrustDetails;
