import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const ShareClassOverview = ({ ...props }) => {
  return (
    <>
      <Card heading={"Overview"}>
        <Row>
          <Col sm={12}>
            <DataComponent
              id={`portfolio_name`}
              data-testid={`portfolio_name`}
              label={"Portfolio Name"}
              data={props.portfolio_name}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`cusip`} data-testid={`cusip`} label={"Cusip"} data={props.cusip} />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`share_class_name`}
              data-testid={`share_class_name`}
              label={"Share Class Name"}
              data={props.share_class_name}
            />
          </Col>
        </Row>
        {props.currentIndex === 0 ? (
          <>
            <Row>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`transfer_agent_identifier`}
                  data-testid={`transfer_agent_identifier`}
                  label={"Transfer Agent Identifier"}
                  data={props.transfer_agent_identifier}
                />
              </Col>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`share_class_type`}
                  data-testid={`share_class_type`}
                  label={"Share Class Type"}
                  data={props.share_class_type}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`fsa_cit_share_class_code`}
                  data-testid={`fsa_cit_share_class_code`}
                  label={"FSA CIT Share Class Code"}
                  data={props.fsa_cit_share_class_code}
                />
              </Col>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`minimum_initial_subscription_amount`}
                  data-testid={`minimum_initial_subscription_amount`}
                  label={"Minimum Initial Subscription Amounts"}
                  data={props.minimum_initial_subscription_amount}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`abor_share_class_id`}
                  data-testid={`abor_share_class_id`}
                  label={"ABOR Share Class ID"}
                  data={props.abor_share_class_id}
                />
              </Col>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`currency_id`}
                  data-testid={`currency_id`}
                  label={"Currency ID"}
                  data={props.currency_id}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`morning_star_sec_id`}
                  data-testid={`morning_star_sec_id`}
                  label={"Morningstar Sec ID"}
                  data={props.morning_star_sec_id}
                />
              </Col>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`morning_star_feed_profile_id`}
                  data-testid={`morning_star_feed_profile_id`}
                  label={"Morningstar Feed Profile ID"}
                  data={props.morning_star_feed_profile_id}
                />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default ShareClassOverview;

ShareClassOverview.propTypes = {
  portfolio_name: PropTypes.string,
  cusip: PropTypes.string,
  transfer_agent_identifier: PropTypes.string,
  share_class_name: PropTypes.string,
  share_class_type: PropTypes.string,
  fsa_cit_share_class_code: PropTypes.string,
  minimum_initial_subscription_amount: PropTypes.string,
  abor_share_class_id: PropTypes.string,
  currency_id: PropTypes.string,
  morning_star_sec_id: PropTypes.string,
  morning_star_feed_profile_id: PropTypes.string,
  currentIndex: PropTypes.number,
};
