const d = new Date();
export const new_pa = {
  wsrsStatus: false,
  wsrsContractNumber: "",
  trust_info: {
    participating_trust_name: "",
    plan_type_id: { label: "", value: "" },
    reason_for_change: "",
    plan_tax_id: "",
    dol_plan_number: "",
    agrees_to_email: true,
    agrees_to_third_party_disclosure: true,
    allows_statements: true,
    invested_without_pa: false,
  },
  plan_sponsor: {
    company: {
      company_name: {
        label: "",
        value: "",
      },
      phone_number: "",
      phone_extension: "",
      email: "",
      company_mailing_address: "",
      company_mailing_address_2: "",
      company_mailing_city: "",
      company_mailing_state: {
        label: "",
        value: "",
      },
      company_mailing_zip: "",
      company_mailing_country: {
        label: "",
        value: "",
      },
      company_street_address: "",
      company_street_address_2: "",
      company_street_city: "",
      company_street_state: {
        label: "",
        value: "",
      },
      company_street_zip: "",
      company_street_country: {
        label: "",
        value: "",
      },
      reason_for_change: "",
    },
    contact: {
      contact_name: {
        label: "",
        value: "",
      },
      first_name: "",
      last_name: "",
      contact_title: "",
      other_name: "",
      contact_phone: "",
      contact_extension: "",
      contact_email: "",
      contact_mailing_address: "",
      contact_mailing_address_2: "",
      contact_mailing_city: "",
      contact_mailing_state: {
        label: "",
        value: "",
      },
      contact_mailing_zip: "",
      contact_mailing_country: {
        label: "",
        value: "",
      },
      contact_street_address: "",
      contact_street_address_2: "",
      contact_street_city: "",
      contact_street_state: {
        value: "",
        label: "",
      },
      contact_street_zip: "",
      contact_street_country: {
        label: "",
        value: "",
      },
      reason_for_change: "",
    },
  },
  fiduciary: {
    company: {
      company_name: {
        label: "",
        value: "",
      },
      phone_number: "",
      phone_extension: "",
      email: "",
      company_mailing_address: "",
      company_mailing_address_2: "",
      company_mailing_city: "",
      company_mailing_state: {
        label: "",
        value: "",
      },
      company_mailing_zip: "",
      company_mailing_country: {
        label: "",
        value: "",
      },
      company_street_address: "",
      company_street_address_2: "",
      company_street_city: "",
      company_street_state: {
        label: "",
        value: "",
      },
      company_street_zip: "",
      company_street_country: {
        label: "",
        value: "",
      },
      reason_for_change: "",
    },
    contact: {
      contact_name: {
        label: "",
        value: "",
      },
      first_name: "",
      last_name: "",
      contact_title: "",
      other_name: "",
      contact_phone: "",
      contact_extension: "",
      contact_email: "",
      contact_mailing_address: "",
      contact_mailing_address_2: "",
      contact_mailing_city: "",
      contact_mailing_state: {
        label: "",
        value: "",
      },
      contact_mailing_zip: "",
      contact_mailing_country: {
        label: "",
        value: "",
      },
      contact_street_address: "",
      contact_street_address_2: "",
      contact_street_city: "",
      contact_street_state: {
        value: "",
        label: "",
      },
      contact_street_zip: "",
      contact_street_country: {
        label: "",
        value: "",
      },
      reason_for_change: "",
    },
  },
  trading_account_relationship: {
    label: "",
    value: "",
  },
  fif: false,
  trading_account_name: {
    label: "",
    value: "",
  },
  trading_wire_instructions: "",
  service_provider: {
    label: "",
    value: "",
    nsccNumber: "",
  },
  service_provider_sub_acct: "",
  service_provider_same_for_settlement: false,
  service_provider_for_settlement: {
    label: "",
    value: "",
    nsccNumber: "",
  },
  service_provider_bin: "",
  investment_approval_date: `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(
    -2
  )}`,
  investment_sales_region: { label: "", value: "" },
  investment_service_provider_sub_acct: "",
  investment_omni_acct: {
    label: "",
    value: "",
  },
  investments: [
    {
      fund: {
        label: "",
        value: "",
      },
      share_class: {
        label: "",
        value: "",
      },
    },
  ],
  groupings: [
    // {
    //   group_type: { label: "", value: "" },
    //   group_name: { label: "", value: "" },
    // },
  ],
  reason_for_change: "New Participation Agreement",
};
