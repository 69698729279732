import React from "react";
import PropTypes from "prop-types";

const ReviewDataComponent = ({ id, label, data }) => {
  return (
    <div key={id} data-testid={id} id={id} className="util-margin-top-sm-10 util-margin-top-xs-10">
      <div style={{ display: "inline" }}>
        <p style={{ display: "inline" }}>
          <b>{label}</b>
          <p className={"keep-white-space"} style={{ display: "inline" }}>
            {data}
          </p>
        </p>
      </div>
    </div>
  );
};

export default ReviewDataComponent;

ReviewDataComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.node,
};
