export const set_nulls_to_empty_strings = (apiResults, nested) => {
  if (Array.isArray(apiResults)) {
    let res = [];
    for (const index in apiResults) {
      res.push(set_nulls_to_empty_strings(apiResults[index], nested));
    }
    return res;
  } else {
    for (const key in apiResults) {
      if (apiResults[key] === null) {
        apiResults[key] = "";
      }
      if (nested && Array.isArray(apiResults[key])) {
        apiResults[key] = set_nulls_to_empty_strings(apiResults[key], nested);
      }
    }
    return apiResults;
  }
};

export const handleChange = (e, id, setData) => {
  const value = e.target.value;
  setData((data) => ({ ...data, [id]: value }));
};

export const handleSelectChange = (id, e, setData) => {
  if (e === null) {
    e = { value: "", label: "" };
  }
  const value = e.value;
  const label = e.label;
  setData((data) => ({
    ...data,
    [id]: {
      label: label,
      value: value,
    },
  }));
};

export const historyTimeNow = (nowDate) => {
  // Adding 2 seconds for buffer in call
  const d = new Date(nowDate.getTime() + 2000);
  return `${d.getUTCFullYear()}-${("0" + (d.getUTCMonth() + 1)).slice(-2)}-${("0" + d.getUTCDate()).slice(-2)} ${(
    "0" + d.getUTCHours()
  ).slice(-2)}:${("0" + d.getUTCMinutes()).slice(-2)}:${("0" + d.getUTCSeconds()).slice(-2)}`;
};
