import React, { useEffect } from "react";
import { useState, useContext } from "react";
import "../BasicTable.scss";
import "./TrustInvestmentManagement.scss";
import UserContext from "../../../context/User.context";
import Config from "../../../config";
import { fetchService } from "../../../services/fetchService";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "@pds-react/grid";
import Button from "@pds-react/button";
import Loading from "../../Loading/Loading";
import Select from "react-select";
import Input from "@pds-react/input";
import { OnEnterKeyPress } from "../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import { FormatTaxId, FormatTaxIdOnLoad, UnFormatTaxId } from "../../Utilities/ValidationHelpers";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";
import { tableHeaderSelectStyle } from "../helpers";
import Pagination from "../Pagination";

let columns = [];
const columnHelper = createColumnHelper();

const columnsLong = [
  columnHelper.accessor("participating_trust_name", {
    header: () => "Participating Trust Name",
  }),
  columnHelper.accessor("company_name", {
    header: () => "Plan Sponsor Name",
  }),
  columnHelper.accessor("plan_tax_id", {
    header: () => "Tax ID",
    cell: (props) => <span>{FormatTaxIdOnLoad(props.getValue())}</span>,
  }),
  columnHelper.accessor("transfer_agent_account_number", {
    header: "Trading Account",
  }),
  columnHelper.accessor("sub_account_number", {
    header: "Service Provider Sub Account ",
  }),
  columnHelper.accessor("account_type", {
    header: () => "Account Type",
  }),
  columnHelper.accessor("share_class_name", {
    header: () => "Share Class ",
  }),
  columnHelper.accessor("cusip", {
    header: () => "CUSIP(s)",
  }),
  columnHelper.accessor("active", {
    header: () => "Is Active",
    cell: (props) => <span>{props.getValue() ? "active" : "inactive"}</span>,
  }),
];

const columnsShort = [
  columnHelper.accessor("participating_trust_name", {
    header: () => "Participating Trust Name",
  }),
  columnHelper.accessor("company_name", {
    header: () => "Plan Sponsor Name",
  }),
  columnHelper.accessor("plan_tax_id", {
    header: () => "Tax ID",
    cell: (props) => <span>{FormatTaxIdOnLoad(props.getValue())}</span>,
  }),
];

const TrustInvestmentManagement = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const [planType, setPlanType] = useState({ label: "Trust and Account", value: "trust and account" });
  const [isloading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  if (planType.label === "Trust and Account") {
    columns = columnsLong;
  } else {
    columns = columnsShort;
  }
  const [account_type, setAccountType] = useState([]);

  const [data, setData] = React.useState(() => []);
  //const [planName, setPlanName] = useState("");
  const [pageViewNum, setPageViewNum] = useState({ value: "", label: "" });
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const initialFilters = {
    trust_name_input: "",
    plan_sponsor_name_input: "",
    tax_id_input: "",
    trading_account_input: "",
    service_provider_sub_account_input: "",
    account_type_input: { value: "", label: "" },
    share_class_input: "",
    cusips_input: "",
    is_active_type_input: { value: "", label: "" },
  };
  const [filters, setFilters] = useState(initialFilters);
  const {
    trust_name_input,
    plan_sponsor_name_input,
    tax_id_input,
    trading_account_input,
    service_provider_sub_account_input,
    account_type_input,
    share_class_input,
    cusips_input,
    is_active_type_input,
  } = filters;

  const getTableData = async () => {
    try {
      setLoading(true);
      let endpoint = "";
      let params;
      if (planType.value === "trust and account") {
        if (
          trust_name_input.trim() !== "" ||
          plan_sponsor_name_input.trim() !== "" ||
          tax_id_input.trim() !== "" ||
          trading_account_input.trim() !== "" ||
          service_provider_sub_account_input.trim() !== "" ||
          account_type_input.label.trim() !== "" ||
          share_class_input.trim() !== "" ||
          cusips_input.trim() !== "" ||
          is_active_type_input.label.trim() !== ""
        ) {
          params = new URLSearchParams({
            "participating-trust-name": trust_name_input,
            "company-name": plan_sponsor_name_input,
            "plan-tax-id": UnFormatTaxId(tax_id_input),
            "transfer-agent-account-number": trading_account_input,
            "sub-account-number": service_provider_sub_account_input,
            "account-type-id": account_type_input.value,
            "share-class-name": share_class_input,
            cusip: cusips_input,
            "active-status": is_active_type_input.label,
          });
          endpoint = `/v1/management-trust-investment?${params}`;
        } else {
          setLoading(false);
        }
      } else if (planType.value === "trust") {
        if (trust_name_input.trim() !== "" || plan_sponsor_name_input.trim() !== "" || tax_id_input.trim() !== "") {
          params = new URLSearchParams({
            "participating-trust-name": trust_name_input,
            "company-name": plan_sponsor_name_input,
            "plan-tax-id": UnFormatTaxId(tax_id_input),
          });
          endpoint = `/v1/management-trust?${params}`;
        } else {
          setData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      resp.data = set_nulls_to_empty_strings(resp.data);
      setData(resp.data);
      setWarning(true);
      setLoading(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getPageViewOption = (numberOfPages) => {
    let choices = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      choices.push({ value: i - 1, label: i });
    }
    return choices;
  };

  const handleChange = (e, id) => {
    const value = e.target.value;
    setFilters((filter) => ({ ...filter, [id]: value }));
  };

  const handleSelectChange = (e, id) => {
    if (e === null) {
      e = { value: "", label: "" };
    }
    const value = e.value;
    const label = e.label;
    setFilters({
      ...filters,
      [id]: {
        label: label,
        value: value,
      },
    });
  };

  const getAccountTypeData = async () => {
    try {
      setLoading(true);
      let endpoint = "/v1/account-type";
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      setLoading(false);
      setAccountType(
        resp.data.map((result) => {
          return { label: result.account_type, value: result.account_type_id };
        })
      );
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    getAccountTypeData();
  }, []);

  useEffect(() => {
    if (
      !(
        trust_name_input === "" &&
        plan_sponsor_name_input === "" &&
        account_type_input.value === "" &&
        tax_id_input === "" &&
        trading_account_input === "" &&
        service_provider_sub_account_input === "" &&
        share_class_input === "" &&
        cusips_input === "" &&
        is_active_type_input
      )
    ) {
      getTableData();
    }
  }, [planType]);

  return (
    <>
      <Row>
        <Col>
          <Row data-testid="trustAccountSearch">
            <Col>
              <Row>
                <Col sm={12} lg={12}>
                  <h2>Participating Trust and Investment Account Management</h2>
                </Col>
              </Row>
              <Row className={"util-padding-bottom-20"}>
                <Col sm={6} className={"util-padding-top-10"}>
                  <Button
                    className="pds-button pds-button-primary"
                    data-testid="create_button"
                    onClick={() => {
                      navigate(`/new-pa`);
                    }}
                  >
                    Create Participating Trust
                  </Button>
                </Col>
                <Col sm={3} style={{ textAlign: "right", marginTop: "2rem", paddingRight: "0px" }}>
                  <span>View</span>
                </Col>
                <Col sm={3} id={"View"}>
                  <div data-testid={"View"}>
                    <Select
                      // data-testid={"view"}
                      // id="view"
                      value={planType}
                      onChange={(e) => {
                        setPlanType((prevPlan) => ({ ...prevPlan, label: e.label, value: e.value }));
                      }}
                      options={[
                        { label: "Trust and Account", value: "trust and account" },
                        { label: "Trust", value: "trust" },
                      ]}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {!isloading ? (
        <>
          {warning ? (
            <Row>
              <Col sm={12} style={{ textAlign: "center", color: "indianred" }}>
                <p>Results are limited to first 400 matches. Please add search criteria to refine your results.</p>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row /* className={"util-padding-top-20"} */>
            <Col>
              <table data-testid="management_table" id="management_table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                      <th>Action</th>
                    </tr>
                  ))}
                  <>
                    <tr>
                      <th>
                        <span>
                          <Input
                            id="trust_name_input"
                            data-testid={"trust_name_input"}
                            label=""
                            value={trust_name_input}
                            style={{ display: "inline-block" }}
                            onChange={(e) => {
                              handleChange(e, "trust_name_input");
                            }}
                            onKeyDown={(event) => {
                              OnEnterKeyPress(event, getTableData);
                            }}
                          ></Input>
                        </span>
                      </th>
                      <th>
                        <span>
                          <Input
                            id="plan_sponsor_name_input"
                            data-testid={"plan_sponsor_name_input"}
                            label=""
                            value={plan_sponsor_name_input}
                            style={{ display: "inline-block" }}
                            onChange={(e) => {
                              handleChange(e, "plan_sponsor_name_input");
                            }}
                            onKeyDown={(event) => {
                              OnEnterKeyPress(event, getTableData);
                            }}
                          ></Input>
                        </span>
                      </th>
                      <th>
                        <span>
                          <Input
                            id="tax_id_input"
                            data-testid={"tax_id_input"}
                            label=""
                            value={tax_id_input}
                            style={{ display: "inline-block" }}
                            onChange={(e) => {
                              FormatTaxId(e);
                              handleChange(e, "tax_id_input");
                            }}
                            onKeyDown={(event) => {
                              OnEnterKeyPress(event, getTableData);
                            }}
                          ></Input>
                        </span>
                      </th>
                      {planType.label === "Trust and Account" ? (
                        <>
                          <th>
                            <span>
                              <Input
                                data-testid={"trading_account_input"}
                                id="trading_account_input"
                                label=""
                                value={trading_account_input}
                                style={{ display: "inline-block" }}
                                onChange={(e) => {
                                  handleChange(e, "trading_account_input");
                                }}
                                onKeyDown={(event) => {
                                  OnEnterKeyPress(event, getTableData);
                                }}
                              ></Input>
                            </span>
                          </th>
                          <th>
                            <span>
                              <Input
                                data-testid={"service_provider_sub_account_input"}
                                id="service_provider_sub_account_input"
                                label=""
                                value={service_provider_sub_account_input}
                                style={{ display: "inline-block" }}
                                onChange={(e) => {
                                  handleChange(e, "service_provider_sub_account_input");
                                }}
                                onKeyDown={(event) => {
                                  OnEnterKeyPress(event, getTableData);
                                }}
                              ></Input>
                            </span>
                          </th>
                          <th>
                            <span>
                              <div id={"account_type_input"} data-testid={"account_type_input"}>
                                <Select
                                  isClearable={true}
                                  value={account_type_input["label"] !== "" ? account_type_input : "undefined"}
                                  options={account_type}
                                  className="state_input"
                                  styles={tableHeaderSelectStyle}
                                  onChange={(e) => {
                                    handleSelectChange(e, "account_type_input");
                                  }}
                                  onKeyDown={(event) => {
                                    OnEnterKeyPress(event, getTableData);
                                  }}
                                ></Select>
                              </div>
                            </span>
                          </th>
                          <th>
                            <span>
                              <Input
                                id="share_class_input"
                                data-testid={"share_class_input"}
                                label=""
                                style={{ display: "inline-block" }}
                                value={share_class_input}
                                onChange={(e) => {
                                  handleChange(e, "share_class_input");
                                }}
                                onKeyDown={(event) => {
                                  OnEnterKeyPress(event, getTableData);
                                }}
                              ></Input>
                            </span>
                          </th>
                          <th>
                            <span>
                              <Input
                                data-testid={"cusips_input"}
                                id="cusips_input"
                                label=""
                                value={cusips_input}
                                style={{ display: "inline-block" }}
                                onChange={(e) => {
                                  handleChange(e, "cusips_input");
                                }}
                                onKeyDown={(event) => {
                                  OnEnterKeyPress(event, getTableData);
                                }}
                              ></Input>
                            </span>
                          </th>
                          <th>
                            <span>
                              <div id={"is_active_type_input"} data-testid={"is_active_type_input"}>
                                <Select
                                  isClearable={true}
                                  value={is_active_type_input["label"] !== "" ? is_active_type_input : "undefined"}
                                  options={[
                                    { label: "active", value: "active" },
                                    { label: "inactive", value: "inactive" },
                                  ]}
                                  className="state_input"
                                  styles={tableHeaderSelectStyle}
                                  onChange={(e) => {
                                    handleSelectChange(e, "is_active_type_input");
                                  }}
                                  onKeyDown={(event) => {
                                    OnEnterKeyPress(event, getTableData);
                                  }}
                                />
                              </div>
                            </span>
                          </th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid="filter_button"
                          id="filter_button"
                          onClick={() => {
                            getTableData();
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid="clear_button"
                          id="clear_button"
                          onClick={() => {
                            setFilters(initialFilters);
                            setData([]);
                          }}
                        >
                          Clear
                        </Button>
                      </th>
                    </tr>
                  </>
                </thead>
                <tbody>
                  {data.length < 1 ? (
                    <>
                      <tr>
                        {columns.map((col, index) => (
                          <td key={index}>&#8205;</td>
                        ))}
                      </tr>
                    </>
                  ) : (
                    <>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                          ))}
                          <td key={row.id}>
                            <span>
                              Trust{" "}
                              <Link
                                id={"trust_details_edit_link"}
                                data-testid={"trust_details_edit_link"}
                                to={`/trust-details/edit/${data[row.id].participating_trust_id}`}
                              >
                                Edit
                              </Link>
                            </span>
                            <span> | </span>
                            <span>
                              <Link
                                id={"trust_details_link"}
                                data-testid={"trust_details_link"}
                                to={`/trust-details/${data[row.id].participating_trust_id}`}
                              >
                                Details
                              </Link>
                            </span>
                            <br></br>
                            {planType.label === "Trust and Account" ? (
                              <div>
                                <span>
                                  Investment{" "}
                                  <Link
                                    id={"investment_details_edit_link"}
                                    data-testid={"investment_details_edit_link"}
                                    to={`/investment-details/edit/${data[row.id].investment_account_id}`}
                                  >
                                    Edit
                                  </Link>
                                </span>
                                <span> | </span>
                                <span>
                                  {" "}
                                  <Link
                                    id={"investment_details_link"}
                                    data-testid={"investment_details_link"}
                                    to={`/investment-details/${data[row.id].investment_account_id}`}
                                  >
                                    Details
                                  </Link>
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Pagination
            data={data}
            table={table}
            setPageViewNum={setPageViewNum}
            pageViewNum={pageViewNum}
            getPageViewOption={getPageViewOption}
          ></Pagination>
        </>
      ) : (
        <>
          <h2 className="text-center"> Application is starting</h2>
          <Loading></Loading>
        </>
      )}
    </>
  );
};

export default TrustInvestmentManagement;

TrustInvestmentManagement.propTypes = {};
