import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import "../NewParticipationAgreement.scss";
import Checkbox from "@pds-react/checkbox";
import { useNavigate } from "react-router-dom";
import { fetchService } from "../../../services/fetchService";
import UserContext from "../../../context/User.context";
import Config from "../../../config";
import ReactModal from "react-modal";
import Loading from "../../Loading/Loading";
import { HandleChange } from "./Helpers/FormHelpers";
import {
  getTextAreaStyles,
  PhoneNumberFormatOnSubmit,
  Validate,
  ValidateRequired,
  validationUseEffectHelper,
} from "../../Utilities/ValidationHelpers";
import ValidationErrorMessage from "../../Utilities/ValidationErrorMessage";
import ReviewDataComponent from "./Shared/ReviewDataComponent";

const Review = ({ formData, navigation, setForm }) => {
  const { accessToken } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [requestId, setRequestId] = useState("");
  const { previous } = navigation;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [validationErrors, setValidationError] = useState({ reason_for_change: "" });
  const [fieldsToValidate] = useState({ reason_for_change: ValidateRequired });

  const { reason_for_change } = formData;

  const customStyles = {
    content: {
      top: "35%",
      left: "50%",
      right: "30%",
      bottom: "auto",
      marginRight: "-50%",
      width: "30%",
      transform: "translate(-40%, -10%)",
      textAlign: "center",
      zIndex: 1,
    },
    overlay: {
      zIndex: 2,
    },
  };

  const GetNewPaPostPlanSponsorContact = () => {
    const planSponsorContact = JSON.parse(JSON.stringify(formData))["plan_sponsor"]["contact"];
    if (planSponsorContact.first_name === "" || planSponsorContact.last_name === "") {
      return {};
    } else if (planSponsorContact.contact_name.value !== null) {
      return { contact_id: planSponsorContact.contact_name.value };
    } else {
      delete planSponsorContact.contact_name;
      planSponsorContact.contact_phone = planSponsorContact.contact_phone
        ? PhoneNumberFormatOnSubmit(planSponsorContact.contact_phone)
        : planSponsorContact.contact_phone;
      planSponsorContact["reason_for_change"] = formData.reason_for_change;
      planSponsorContact.contact_mailing_state = planSponsorContact.contact_mailing_state.value;
      planSponsorContact.contact_mailing_country = planSponsorContact.contact_mailing_country.value;
      planSponsorContact.contact_street_state = planSponsorContact.contact_street_state.value;
      planSponsorContact.contact_street_country = planSponsorContact.contact_street_country.value;
      return planSponsorContact;
    }
  };
  const GetNewPaPostPlanSponsorCompany = () => {
    const planSponsorCompany = JSON.parse(JSON.stringify(formData))["plan_sponsor"]["company"];
    if (planSponsorCompany.company_name.label === "") {
      return {};
    } else if (planSponsorCompany.company_name.value !== null) {
      return { contact_company_id: planSponsorCompany.company_name.value };
    } else {
      planSponsorCompany.company_name = planSponsorCompany.company_name.label;
      planSponsorCompany.phone_number = planSponsorCompany.phone_number
        ? PhoneNumberFormatOnSubmit(planSponsorCompany.phone_number)
        : planSponsorCompany.phone_number;
      planSponsorCompany["reason_for_change"] = formData.reason_for_change;
      planSponsorCompany.company_mailing_state = planSponsorCompany.company_mailing_state.value;
      planSponsorCompany.company_mailing_country = planSponsorCompany.company_mailing_country.value;
      planSponsorCompany.company_street_state = planSponsorCompany.company_street_state.value;
      planSponsorCompany.company_street_country = planSponsorCompany.company_street_country.value;
      return planSponsorCompany;
    }
  };

  const GetNewPaFiduciaryCompany = () => {
    const fiduciaryCompany = JSON.parse(JSON.stringify(formData))["fiduciary"]["company"];
    if (fiduciaryCompany.company_name.label === "") {
      return {};
    } else if (fiduciaryCompany.company_name.value !== null) {
      return { contact_company_id: fiduciaryCompany.company_name.value };
    } else {
      fiduciaryCompany.company_name = fiduciaryCompany.company_name.label;
      fiduciaryCompany.phone_number = fiduciaryCompany.phone_number
        ? PhoneNumberFormatOnSubmit(fiduciaryCompany.phone_number)
        : fiduciaryCompany.phone_number;
      fiduciaryCompany["reason_for_change"] = formData.reason_for_change;
      fiduciaryCompany.company_mailing_state = fiduciaryCompany.company_mailing_state.value;
      fiduciaryCompany.company_mailing_country = fiduciaryCompany.company_mailing_country.value;
      fiduciaryCompany.company_street_state = fiduciaryCompany.company_street_state.value;
      fiduciaryCompany.company_street_country = fiduciaryCompany.company_street_country.value;
      return fiduciaryCompany;
    }
  };

  const GetNewPaFiduciaryContact = () => {
    const fiduciaryContact = JSON.parse(JSON.stringify(formData))["fiduciary"]["contact"];
    if (fiduciaryContact.first_name === "" || fiduciaryContact.last_name === "") {
      return {};
    } else if (fiduciaryContact.contact_name.value !== null) {
      return { contact_id: fiduciaryContact.contact_name.value };
    } else {
      delete fiduciaryContact.contact_name;
      fiduciaryContact.contact_phone = fiduciaryContact.contact_phone
        ? PhoneNumberFormatOnSubmit(fiduciaryContact.contact_phone)
        : fiduciaryContact.contact_phone;
      fiduciaryContact["reason_for_change"] = formData.reason_for_change;
      fiduciaryContact.contact_mailing_state = fiduciaryContact.contact_mailing_state.value;
      fiduciaryContact.contact_mailing_country = fiduciaryContact.contact_mailing_country.value;
      fiduciaryContact.contact_street_state = fiduciaryContact.contact_street_state.value;
      fiduciaryContact.contact_street_country = fiduciaryContact.contact_street_country.value;
      return fiduciaryContact;
    }
  };

  const GetNewPaInvestments = () => {
    let investments = [...formData.investments];
    return investments.map((investment) => {
      return {
        share_class_name: investment.share_class.label,
        sales_region_id: formData.investment_sales_region.value,
        fund_group_id: investment.fund.value,
        approval_date: formData.investment_approval_date,
        invested_without_pa: formData.trust_info.invested_without_pa,
        reason_for_change: formData.reason_for_change,
      };
    });
  };

  const GetNewPaGroupings = () => {
    let groupings = [...formData.groupings];
    return groupings.map((grouping) => {
      return {
        aggregation_group_id: grouping.group_name.value,
        aggregation_group_category_id: grouping.group_type.value,
      };
    });
  };

  const GetNewPaTrustInfo = () => {
    const trustInfo = JSON.parse(JSON.stringify(formData))["trust_info"];
    trustInfo.plan_type_id = trustInfo.plan_type_id.value;
    trustInfo.reason_for_change = formData.reason_for_change;
    return trustInfo;
  };

  const GetNewPaTradingAccount = () => {
    if (formData.trading_account_relationship.label === "Sub-Account") {
      return {
        account_type_id: formData.trading_account_relationship.value,
        trading_account_id: formData.trading_account_name.value,
        sub_account_number: formData.service_provider_sub_acct,
      };
    } else {
      return {
        account_type_id: formData.trading_account_relationship.value,
        settlement_service_provider_id: formData.service_provider_for_settlement.value,
        service_provider_id: formData.service_provider.value,
        reason_for_change: formData.reason_for_change,
        bin_number: formData.service_provider_bin,
        wire_instructions: formData.trading_wire_instructions,
      };
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    let [formIsValid, errors] = Validate(formData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      setLoading(true);
      const { baseUrl } = Config.api;
      let endpoint = "/v1/participation-agreement";
      let newPaPostData = {
        plan_sponsor: {
          company: GetNewPaPostPlanSponsorCompany(),
          contact: GetNewPaPostPlanSponsorContact(),
        },
        fiduciary: {
          company: GetNewPaFiduciaryCompany(),
          contact: GetNewPaFiduciaryContact(),
        },
        trust_info: GetNewPaTrustInfo(),
        trading_account: GetNewPaTradingAccount(),
        investments: GetNewPaInvestments(),
        groupings: GetNewPaGroupings(),
      };

      let formattedForm = JSON.stringify(newPaPostData);

      await fetchService(baseUrl + endpoint, { method: "POST", body: formattedForm, token: accessToken }).then(
        async (response) => {
          let resp = await response.json();
          if (200 !== response.status) {
            setRequestId(resp["requestId"]);
            setLoading(false);
            setOpen(true);
          } else {
            setLoading(false);
            let confirmInfo = { ...formData, ...resp };
            navigate("/pa-confirmation", { state: { ...confirmInfo } });
          }
        }
      );
    }
  };

  useEffect(() => {
    if ("reason_for_change" in fieldsToValidate) {
      validationUseEffectHelper(
        "reason_for_change",
        reason_for_change,
        fieldsToValidate,
        validationErrors,
        setValidationError
      );
    }
  }, [reason_for_change]);

  if (!isLoading) {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <button type="button" className="btn btn-primary progress-btn" onClick={previous}>
              <span className="util-margin-right-15">
                <i className="fa fa-angle-left"></i>
              </span>
              Back - Groupings
            </button>
          </Col>
          <Col sm={6}>
            <button
              type="button"
              className="btn btn-primary progress-btn"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
              <span className="util-margin-left-15">
                <i className="fa fa-angle-right"></i>
              </span>
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="step-top-margin">
              <Col sm={2} />
              <Col sm={6}>
                <h2 className="text-center">Review</h2>
              </Col>
              <Col sm={2} />
            </Row>
            <hr />
            <Row className="input-top-margin">
              <Col sm={12}>
                <Row>
                  <Col sm={12} lg={2}>
                    <h4>Reason For Change</h4>
                  </Col>
                  <Col sm={12} lg={8}>
                    <textarea
                      data-testid={`reason_for_change`}
                      id={`reason_for_change`}
                      style={getTextAreaStyles(validationErrors["reason_for_change"])}
                      value={reason_for_change}
                      onChange={(e) => {
                        HandleChange(e, "reason_for_change", formData, setForm);
                      }}
                    ></textarea>
                    <ValidationErrorMessage
                      paramId="reason_for_change"
                      validationError={validationErrors["reason_for_change"]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="input-top-margin">
              <Col sm={12} lg={12}>
                <Row>
                  <Col sm={12} lg={2}>
                    <h4>Trust Info</h4>
                  </Col>
                  <Col sm={12} lg={5}>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"participating_trust_name"}
                          label="Plan Name: "
                          data={formData.trust_info.participating_trust_name}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"plan_tax_id"}
                          label="Plan Tax ID: "
                          data={formData.trust_info.plan_tax_id}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"dol_plan_number"}
                          label="DOL Plan#: "
                          data={formData.trust_info.dol_plan_number}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"plan_type_id"}
                          label="Plan Type: "
                          data={formData.trust_info.plan_type_id.label}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} lg={5}>
                    <Row>
                      <Col>
                        <Checkbox
                          data-testid={"agrees_to_email_id"}
                          checked={formData.trust_info.agrees_to_email}
                          className={"util-padding-top-10 util-margin-right-15"}
                          name={"agrees_to_email_id"}
                          onChange={null}
                          onBlur={null}
                          value={formData.trust_info.agrees_to_email}
                          disabled={true}
                          id={"agrees_to_email_id"}
                          label={"Consent to email communications"}
                          errorMessage={""}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          data-testid={"agrees_to_third_party_disclosure_id"}
                          checked={formData.trust_info.agrees_to_third_party_disclosure}
                          className={"util-padding-top-10 util-margin-right-15"}
                          name={"agrees_to_third_party_disclosure_id"}
                          onChange={null}
                          onBlur={null}
                          value={formData.trust_info.agrees_to_third_party_disclosure}
                          disabled={true}
                          id={"agrees_to_third_party_disclosure_id"}
                          label={"Consent to third party disclosure"}
                          errorMessage={""}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          data-testid={"allows_statements"}
                          checked={formData.trust_info.allows_statements}
                          className={"util-padding-top-10 util-margin-right-15"}
                          name={"agrees_to_third_party_disclosure_id"}
                          onChange={null}
                          onBlur={null}
                          value={formData.trust_info.allows_statements}
                          disabled={true}
                          id={"allows_statements"}
                          label="Allows Statements"
                          errorMessage={""}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Checkbox
                          data-testid={"invested_without_pa"}
                          checked={formData.trust_info.invested_without_pa}
                          className={"util-padding-top-10 util-margin-right-15"}
                          name={"invested_without_pa"}
                          onChange={null}
                          onBlur={null}
                          value={formData.trust_info.invested_without_pa}
                          disabled={true}
                          id={"invested_without_pa"}
                          label="Missing Documentation"
                          errorMessage={""}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="input-top-margin">
              <Col sm={12} lg={12}>
                <Row>
                  <Col sm={12} lg={2}>
                    <h4>Plan Sponsor</h4>
                  </Col>
                  {formData.plan_sponsor.company.company_name.label !== "" ? (
                    <>
                      <Col sm={12} lg={5}>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"plan_sponsor_company"}
                              label="Entity Name: "
                              data={formData.plan_sponsor.company.company_name.label}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {formData.plan_sponsor.company.phone_extension &&
                            formData.plan_sponsor.company.phone_extension !== "" ? (
                              <ReviewDataComponent
                                id={"plan_sponsor_phone"}
                                label="Phone: "
                                data={
                                  formData.plan_sponsor.company.phone_number +
                                  " - " +
                                  formData.plan_sponsor.company.phone_extension
                                }
                              ></ReviewDataComponent>
                            ) : (
                              <ReviewDataComponent
                                id={"plan_sponsor_phone"}
                                label="Phone: "
                                data={""}
                              ></ReviewDataComponent>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"plan_sponsor_company_email"}
                              label="Email: "
                              data={formData.plan_sponsor.company.email}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.plan_sponsor.company.company_mailing_address +
                                " " +
                                formData.plan_sponsor.company.company_mailing_address_2 +
                                " " +
                                formData.plan_sponsor.company.company_mailing_city +
                                ", " +
                                formData.plan_sponsor.company.company_mailing_state.label +
                                " " +
                                formData.plan_sponsor.company.company_mailing_zip +
                                " " +
                                formData.plan_sponsor.company.company_street_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.plan_sponsor.company.company_street_address +
                                " " +
                                formData.plan_sponsor.company.company_street_address_2 +
                                " " +
                                formData.plan_sponsor.company.company_street_city +
                                ", " +
                                formData.plan_sponsor.company.company_street_state.label +
                                " " +
                                formData.plan_sponsor.company.company_street_zip +
                                " " +
                                formData.plan_sponsor.company.company_street_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12} lg={5}>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_first_name"}
                              label="Contact Name: "
                              data={
                                formData.plan_sponsor.contact.first_name + " " + formData.plan_sponsor.contact.last_name
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_title"}
                              label="Title: "
                              data={formData.plan_sponsor.contact.contact_title}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_other_name"}
                              label="Other Names: "
                              data={formData.plan_sponsor.contact.other_name}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="util-padding-top-10 util-margin-right-15">Phone:</label>
                            {formData.plan_sponsor.contact.contact_phone}{" "}
                            {formData.plan_sponsor.contact.contact_extension &&
                            formData.plan_sponsor.contact.contact_extension !== ""
                              ? `- ${formData.plan_sponsor.contact.contact_extension}`
                              : ``}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_email"}
                              label="Email: "
                              data={formData.plan_sponsor.contact.contact_email}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.plan_sponsor.contact.contact_mailing_address +
                                " " +
                                formData.plan_sponsor.contact.contact_mailing_address_2 +
                                " " +
                                formData.plan_sponsor.contact.contact_mailing_city +
                                ", " +
                                formData.plan_sponsor.contact.contact_mailing_state.label +
                                " " +
                                formData.plan_sponsor.contact.contact_mailing_zip +
                                " " +
                                formData.plan_sponsor.contact.contact_mailing_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.plan_sponsor.contact.contact_street_address +
                                " " +
                                formData.plan_sponsor.contact.contact_street_address_2 +
                                " " +
                                formData.plan_sponsor.contact.contact_street_city +
                                ", " +
                                formData.plan_sponsor.contact.contact_street_state.label +
                                " " +
                                formData.plan_sponsor.contact.contact_street_zip +
                                " " +
                                formData.plan_sponsor.contact.contact_street_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <Col sm={12} lg={10}>
                      <p className={"util-padding-top-10"}>No Plan Sponsor Chosen</p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="input-top-margin">
              <Col sm={12} lg={12}>
                <Row>
                  <Col sm={12} lg={2}>
                    <h4>Fiduciary Info</h4>
                  </Col>
                  {formData.fiduciary.company.company_name.label !== "" ? (
                    <>
                      <Col sm={12} lg={5}>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_name"}
                              label="Fiduciary Name: "
                              data={formData.fiduciary.company.company_name.label}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="util-padding-top-10 util-margin-right-15">Phone:</label>
                            {formData.fiduciary.company.phone_number}{" "}
                            {formData.fiduciary.company.phone_extension &&
                            formData.fiduciary.company.phone_extension !== ""
                              ? `- ${formData.fiduciary.company.phone_extension}`
                              : ``}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_email"}
                              label="Email: "
                              data={formData.fiduciary.company.email}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.fiduciary.company.company_mailing_address +
                                " " +
                                formData.fiduciary.company.company_mailing_address_2 +
                                " " +
                                formData.fiduciary.company.company_mailing_city +
                                ", " +
                                formData.fiduciary.company.company_mailing_state.label +
                                " " +
                                formData.fiduciary.company.company_mailing_zip +
                                " " +
                                formData.fiduciary.company.company_mailing_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.fiduciary.company.company_street_address +
                                " " +
                                formData.fiduciary.company.company_street_address_2 +
                                " " +
                                formData.fiduciary.company.company_street_city +
                                ", " +
                                formData.fiduciary.company.company_street_state.label +
                                " " +
                                formData.fiduciary.company.company_street_zip +
                                " " +
                                formData.fiduciary.company.company_street_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12} lg={5}>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_name"}
                              label="Contact Name: "
                              data={formData.fiduciary.contact.first_name + " " + formData.fiduciary.contact.last_name}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_title"}
                              label="Title: "
                              data={formData.fiduciary.contact.contact_title}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"other_name"}
                              label="Other Names: "
                              data={formData.fiduciary.contact.other_name}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="util-padding-top-10 util-margin-right-15">Phone:</label>
                            {formData.fiduciary.contact.contact_phone}{" "}
                            {formData.fiduciary.contact.contact_extension &&
                            formData.fiduciary.contact.contact_extension !== ""
                              ? `- ${formData.fiduciary.contact.contact_extension}`
                              : ``}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"contact_email"}
                              label="Email: "
                              data={formData.fiduciary.contact.contact_email}
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Mailing Address: "
                              data={
                                formData.fiduciary.contact.contact_mailing_address +
                                " " +
                                formData.fiduciary.contact.contact_mailing_address_2 +
                                " " +
                                formData.fiduciary.contact.contact_mailing_city +
                                ", " +
                                formData.fiduciary.contact.contact_mailing_state.label +
                                " " +
                                formData.fiduciary.contact.contact_mailing_zip +
                                " " +
                                formData.fiduciary.contact.contact_street_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ReviewDataComponent
                              id={"company_street_address"}
                              label="Street Address: "
                              data={
                                formData.fiduciary.contact.contact_street_address +
                                " " +
                                formData.fiduciary.contact.contact_street_address_2 +
                                " " +
                                formData.fiduciary.contact.contact_street_city +
                                ", " +
                                formData.fiduciary.contact.contact_street_state.label +
                                " " +
                                formData.fiduciary.contact.contact_street_zip +
                                " " +
                                formData.fiduciary.contact.contact_street_country.label
                              }
                            ></ReviewDataComponent>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    <Col sm={12} lg={10}>
                      <p className={"util-padding-top-10"}>No Fiduciary Info Chosen</p>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={12} lg={12}>
                <Row>
                  <Col sm={12} lg={2}>
                    <h4>Trading Account</h4>
                  </Col>
                  <Col sm={12} lg={10}>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"trading_account_relationship"}
                          label="Trading Account Relationship: "
                          data={formData.trading_account_relationship.label}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    {formData.trading_account_name.label !== "" ? (
                      <Row>
                        <Col>
                          <ReviewDataComponent
                            id={"trading_account_name"}
                            label="Trading Account Name: "
                            data={formData.trading_account_name.label}
                          ></ReviewDataComponent>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    {formData.service_provider_sub_acct !== "" ? (
                      <Row>
                        <Col>
                          <ReviewDataComponent
                            id={"service_provider_sub_acct"}
                            label="Service Provider Sub-Account #: "
                            data={formData.service_provider_sub_acct}
                          ></ReviewDataComponent>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"service_provider_bin"}
                          label="Bank Identification Number: "
                          data={formData.service_provider_bin}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"service_provider"}
                          label="Service Provider: "
                          data={formData.service_provider.label}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"service_provider_for_settlement"}
                          label="Service Provider for Settlement: "
                          data={formData.service_provider_for_settlement.label}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReviewDataComponent
                          id={"trading_wire_instructions"}
                          label="Wire Instructions: "
                          data={formData.trading_wire_instructions}
                        ></ReviewDataComponent>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="input-top-margin">
              <Col sm={12} lg={12}>
                <Row>
                  <Col sm={12} lg={2}>
                    <h4>Investment Info</h4>
                  </Col>
                  <Col sm={12} lg={10}>
                    {formData.investments.map((item, index) => (
                      <>
                        <Row>
                          <Col>
                            <label key={`investment-${index}`}>{`Investment ${index + 1}`}</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} lg={6}>
                            <Row>
                              <Col>
                                <ReviewDataComponent
                                  id={`trading_account_relationship_${index + 1}`}
                                  label="Account Type: "
                                  data={formData.trading_account_relationship.label}
                                ></ReviewDataComponent>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ReviewDataComponent
                                  id={`fund_label_${index + 1}`}
                                  label="Fund: "
                                  data={item["fund"]["label"]}
                                ></ReviewDataComponent>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ReviewDataComponent
                                  id={`share_class_label ${index + 1}`}
                                  label="Share Class: "
                                  data={item["share_class"]["label"]}
                                ></ReviewDataComponent>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={12} lg={6}>
                            <Row>
                              <Col>
                                <ReviewDataComponent
                                  id={`investment_approval_date_${index + 1}`}
                                  label="Approval Date: "
                                  data={formData.investment_approval_date}
                                ></ReviewDataComponent>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ReviewDataComponent
                                  id={`investment_sales_region_${index + 1}`}
                                  label="Sales Region: "
                                  data={formData.investment_sales_region.label}
                                ></ReviewDataComponent>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Checkbox
                                  key={`investment-part_agree_on_file-${index}`}
                                  data-testid={`investment-part_agree_on_file-${index + 1}`}
                                  checked={formData.trust_info.invested_without_pa}
                                  className={"util-padding-top-10 util-margin-right-15"}
                                  name={`investment-part_agree_on_file-${index + 1}`}
                                  onChange={null}
                                  onBlur={null}
                                  value={formData.trust_info.invested_without_pa}
                                  disabled={true}
                                  id={`investment-part_agree_on_file-${index + 1}`}
                                  label={"Missing Documentation"}
                                  errorMessage={""}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="input-top-margin">
              <Col sm={12} lg={12}>
                <Row>
                  <Col sm={2} lg={2}>
                    <h4>Groupings</h4>
                  </Col>
                  <Col sm={12} lg={10}>
                    {formData.groupings.length === 0 ? (
                      <>
                        <p className={"util-padding-top-10"}>No Groupings Chosen</p>
                      </>
                    ) : (
                      <>
                        {formData.groupings.map((item, index) => (
                          <>
                            <Row>
                              <Col sm={12} lg={6}>
                                <ReviewDataComponent
                                  id={`grouping_group_type-${index + 1}`}
                                  label="Group Category: "
                                  data={item["group_type"]["label"]}
                                ></ReviewDataComponent>
                              </Col>
                              <Col sm={12} lg={6}>
                                <ReviewDataComponent
                                  id={`grouping_group_name_${index + 1}`}
                                  label="Group Name: "
                                  data={item["group_name"]["label"]}
                                ></ReviewDataComponent>
                              </Col>
                            </Row>
                          </>
                        ))}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
          </Col>
        </Row>
        <br />
        <br />
        <div>
          <Row>
            <Col small={12} lg={12} style={{ textAlign: "right" }}>
              <ReactModal
                testId={`modal_testid`}
                id={`modal`}
                isOpen={open}
                contentLabel="Minimal Modal Example"
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                onRequestClose={() => handleCloseModal()}
                style={customStyles}
              >
                <p>An error occurred with your request.</p>
                <p>Please submit a ticket with IT and include this request ID:</p>
                <p>{requestId}</p>
              </ReactModal>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

Review.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
};
export default Review;
