import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import BasicTable from "../../../../BasicTable";
import { Link } from "react-router-dom";
import Button from "@pds-react/button";

const TradingAccountInvestmentComponent = ({ ...props }) => {
  const {
    index,
    account_type,
    trading_account_name,
    omnibus_name,
    trading_account_id,
    service_provider,
    sub_account_number,
    transfer_agent_account_number,
    investments,
    validatedParams,
    disableInvestmentButton,
  } = props;

  const investmentColumns = [
    {
      accessorKey: "fund_group_name",
      header: () => <span>Fund Group</span>,
    },
    {
      accessorKey: "share_class_name",
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Share Class</span>,
    },
    {
      accessorKey: "approval_date",
      header: "Approval Date",
    },
    {
      accessorKey: "actual_liquidation_date",
      header: "Actual Liquidation Date",
    },
    {
      accessorKey: "approved_liquidation_date",
      header: "Approved Liquidation Date",
    },
    {
      accessorKey: "options",
      header: "Options",
      cell: (info) => <p>{info.getValue()}</p>,
    },
  ];

  return (
    <>
      <Col sm={12} style={{ border: ".5px solid #035fa4" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
        <Row>
          <Col sm={12} lg={8}>
            <Row>
              <Col sm={4}>
                <DataComponent
                  id={`account_type_${index}`}
                  data-testid={`account_type_${index}`}
                  label={"Type"}
                  data={account_type}
                />
              </Col>
              <Col sm={4}>
                <DataComponent
                  id={`trading_account_name_${index}`}
                  data-testid={`trading_account_name_${index}`}
                  label={"Trading Account Name"}
                  data={trading_account_name}
                  editLink={`/trading-account-details/edit/${trading_account_id}`}
                  detailsLink={`/trading-account-details/${trading_account_id}`}
                />
              </Col>
              <Col sm={4}>
                <DataComponent
                  id={`trading_number_${index}`}
                  data-testid={`trading_number_${index}`}
                  label={"Trading Account Number"}
                  data={transfer_agent_account_number}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <DataComponent
                  id={`service_provider_${index}`}
                  data-testid={`service_provider_${index}`}
                  label={"Service Provider"}
                  data={service_provider}
                />
              </Col>
              <Col sm={4}>
                <div
                  data-testid={`sub_account_number_${index}`}
                  id={`sub_account_number_${index}`}
                  className="util-margin-top-sm-10 util-margin-top-xs-10"
                >
                  <div style={{ display: "inline" }}>
                    <p style={{ display: "inline" }}>
                      <b>Sub Account Number</b>
                    </p>

                    {!omnibus_name ? (
                      <></>
                    ) : (
                      <p style={{ display: "inline" }}>
                        {" - "}
                        <span>
                          <Link
                            data-testid={`sub_account_number_${index}` + "_Edit"}
                            to={`/linked-investor/edit/${validatedParams.participating_trust_id}/${trading_account_id}`}
                          >
                            Edit
                          </Link>
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="util-margin-top-sm-10 util-margin-top-xs-10">
                    <p>{sub_account_number}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={6}></Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={12} className="util-padding-bottom-sm-15">
            <Card heading={"Investment Accounts"}>
              <BasicTable id={"investment_table"} data={investments} columns={investmentColumns}></BasicTable>
              <Row>
                <Col sm={12} style={{ textAlign: "right", marginTop: "1%" }}>
                  <Link
                    to={`/investment-details/new/same-trading/${validatedParams.participating_trust_id}/${trading_account_id}`}
                  >
                    <Button disabled={disableInvestmentButton} /* style={{ margin: " 1% 0% 0% 87%" }} */>
                      New Investment
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default TradingAccountInvestmentComponent;

TradingAccountInvestmentComponent.propTypes = {
  index: PropTypes.number,
  account_type: PropTypes.string,
  trading_account_name: PropTypes.string,
  omnibus_name: PropTypes.string,
  trading_account_id: PropTypes.string,
  service_provider: PropTypes.string,
  sub_account_number: PropTypes.string,
  transfer_agent_account_number: PropTypes.string,
  investments: PropTypes.array,
  validatedParams: PropTypes.object,
  disableInvestmentButton: PropTypes.bool,
};
