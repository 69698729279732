import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "@pds-react/grid";
import Select from "react-select";
import { customStyles } from "./helpers";

const Pagination = ({ data, table, setPageViewNum, pageViewNum, getPageViewOption }) => {
  return data.length > 0 ? (
    <Row>
      <Col sm={6} lg={6}></Col>
      <Col sm={4} lg={4}>
        <Row>
          <button
            data-testid={"pagination-first"}
            className="border rounded p-1"
            onClick={() => {
              table.setPageIndex(0);
              setPageViewNum({
                value: 0,
                label: 1,
              });
            }}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            data-testid={"pagination-back"}
            className="border rounded p-1"
            onClick={() => {
              table.previousPage();
              setPageViewNum({
                value: table.getState().pagination.pageIndex - 1,
                label: table.getState().pagination.pageIndex,
              });
            }}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <div data-testid={"pagination-page-select"}>
            <Select
              isClearable={true}
              placeholder={"Go to page"}
              id={"page_select"}
              inputId={"page_select"}
              options={getPageViewOption(table.getPageCount())}
              class_name="page_select"
              styles={customStyles}
              value={pageViewNum["label"] !== "" ? pageViewNum : "undefined"}
              onChange={(e) => {
                let pageView = e;
                let selectedPage = 0;
                if (pageView === null) {
                  pageView = { value: "", label: "" };
                } else {
                  selectedPage = pageView["value"];
                }
                setPageViewNum(pageView);
                table.setPageIndex(selectedPage);
              }}
            />
          </div>
          <div className={"page_text"}>{` of ${table.getPageCount()} `}</div>
          <button
            data-testid={"pagination-next"}
            className="border rounded p-1"
            onClick={() => {
              table.nextPage();
              setPageViewNum({
                value: table.getState().pagination.pageIndex + 1,
                label: table.getState().pagination.pageIndex + 2,
              });
            }}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            data-testid={"pagination-last"}
            className="border rounded p-1"
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
              setPageViewNum({
                value: table.getPageCount() - 1,
                label: table.getPageCount(),
              });
            }}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <div data-testid={"pagination-number-of-rows"}>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 25, 50, 75, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Row>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default Pagination;

Pagination.propTypes = {
  data: PropTypes.array,
  table: PropTypes.object,
  setPageViewNum: PropTypes.func,
  pageViewNum: PropTypes.object,
  getPageViewOption: PropTypes.func,
};
