import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../services/fetchService";
import Config from "../../../config";
import UserContext from "../../../context/User.context";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
} from "@tanstack/react-table";
import Select from "react-select";
import { Link } from "react-router-dom";
import Button from "@pds-react/button";
import Input from "@pds-react/input";
import "./ServiceProviderTradingAccountManagement.scss";
import { useNavigate } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { OnEnterKeyPress } from "../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";
import { FormatTaxId, FormatTaxIdOnLoad, UnFormatTaxId } from "../../Utilities/ValidationHelpers";
import { customStyles, tableHeaderSelectStyle } from "../helpers";
import Pagination from "../Pagination";

let columns = [];
const columnHelper = createColumnHelper();

const columnsLong = [
  columnHelper.accessor("trading_nscc_number", {
    header: () => "NSCC Number",
  }),
  columnHelper.accessor("service_provider_name", {
    header: () => "Service Provider Name",
  }),
  columnHelper.accessor("service_provider_type", {
    header: () => "Agreement Type",
  }),
  columnHelper.accessor("transfer_agent_account_number", {
    header: () => "Trading Account Number",
  }),
  columnHelper.accessor("trading_account_name", {
    header: () => "Trading Account Name",
  }),
  columnHelper.accessor("plan_tax_id", {
    header: () => "Tax ID",
  }),
  columnHelper.accessor("account_type", {
    header: () => "Account Type",
  }),
  columnHelper.accessor("bin_number", {
    header: () => "Bin",
  }),
];

const columnsShort = [
  columnHelper.accessor("trading_nscc_number", {
    header: () => "NSCC Number",
  }),
  columnHelper.accessor("service_provider_name", {
    header: () => "Service Provider Name",
  }),
  columnHelper.accessor("service_provider_type", {
    header: () => "Agreement Type",
  }),
];

const ServiceProviderTradingAccountManagement = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const [isLoading, setLoading] = useState(false);
  const [areAccountsLoaded, setAccountsLoaded] = useState(true);
  const [areAgreementsLoaded, setAgreementsLoaded] = useState(true);
  const navigate = useNavigate();

  const [serviceProviderType, setServiceProviderType] = useState({
    label: "Service Providers and Trading Accounts",
    value: "service providers and trading accounts",
  });
  if (serviceProviderType.value === "service providers and trading accounts") {
    columns = columnsLong;
  } else {
    columns = columnsShort;
  }

  const [data, setData] = React.useState(() => []);
  const [accountTypeOptions, setAccountTypeOptions] = useState();
  const [agreementTypeOptions, setAgreementTypeOptions] = useState();

  const [pageViewNum, setPageViewNum] = useState({ value: "", label: "" });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const initialFilters = {
    trading_nscc_number_input: "",
    service_provider_name_input: "",
    agreement_type_input: { label: "", value: "" },
    trading_account_number_input: "",
    trading_account_name_input: "",
    tax_id_input: "",
    account_type_input: { label: "", value: "" },
    bin_input: "",
  };
  const [filters, setFilters] = useState(initialFilters);
  const {
    trading_nscc_number_input,
    service_provider_name_input,
    agreement_type_input,
    trading_account_number_input,
    trading_account_name_input,
    tax_id_input,
    account_type_input,
    bin_input,
  } = filters;

  const getTableData = async () => {
    try {
      setLoading(true);
      let endpoint = "";
      let params;
      if (serviceProviderType.value === "service providers and trading accounts") {
        if (
          trading_nscc_number_input.trim() !== "" ||
          service_provider_name_input.trim() !== "" ||
          agreement_type_input.value.trim() !== "" ||
          trading_account_number_input.trim() !== "" ||
          trading_account_name_input.trim() !== "" ||
          tax_id_input.trim() !== "" ||
          account_type_input.value.trim() !== "" ||
          bin_input.trim() !== ""
        ) {
          params = new URLSearchParams({
            "trading-nscc-number": trading_nscc_number_input,
            "service-provider-name": service_provider_name_input,
            "service-provider-type-id": agreement_type_input.value,
            "transfer-agent-account-number": trading_account_number_input,
            "omnibus-name": trading_account_name_input,
            "tax-id": UnFormatTaxId(tax_id_input),
            "account-type-id": account_type_input.value,
            "bin-number": bin_input,
          });
          endpoint = `/v1/management-service-provider-trading-account?${params}`;
        } else {
          setLoading(false);
        }
      } else if (serviceProviderType.value === "service providers") {
        if (
          trading_nscc_number_input.trim() !== "" ||
          service_provider_name_input.trim() !== "" ||
          agreement_type_input.value.trim() !== ""
        ) {
          params = new URLSearchParams({
            "trading-nscc-number": trading_nscc_number_input,
            "service-provider-name": service_provider_name_input,
            "service-provider-type-id": agreement_type_input.value,
          });
          endpoint = `/v1/management-service-provider?${params}`;
        } else {
          setData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }

      if (endpoint !== "") {
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        resp.data = set_nulls_to_empty_strings(resp.data);
        setData(
          resp.data.map((result) => {
            return {
              trading_account_id: result.trading_account_id,
              trading_nscc_number: result.trading_nscc_number,
              transfer_agent_account_number: result.transfer_agent_account_number,
              trading_account_name: result.trading_account_name,
              bin_number: result.bin_number,
              account_type: result.account_type,
              service_provider_id: result.service_provider_id,
              service_provider_name: result.service_provider_name,
              service_provider_type: result.service_provider_type,
              plan_tax_id: result.plan_tax_id ? FormatTaxIdOnLoad(result.plan_tax_id) : undefined,
            };
          })
        );
        setLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const handleViewChange = (e) => {
    setServiceProviderType((prevServiceProvider) => ({ ...prevServiceProvider, label: e.label, value: e.value }));
  };

  const getPageViewOption = (numberOfPages) => {
    let choices = [];
    for (let i = 1; i < numberOfPages + 1; i++) {
      choices.push({ value: i - 1, label: i });
    }
    return choices;
  };

  const handleClear = () => {
    setFilters(initialFilters);
  };

  const handleChange = (e, id) => {
    const value = e.target.value;
    setFilters((filter) => ({ ...filter, [id]: value }));
  };

  const handleSelectChange = (e, id) => {
    if (e === null) {
      e = { value: "", label: "" };
    }
    setFilters({
      ...filters,
      [id]: {
        label: e.label,
        value: e.value,
      },
    });
  };

  const buildAccountTypeOptions = (options) => {
    return options.map((opt) => ({
      value: opt.account_type_id,
      label: opt.account_type,
      ...opt,
    }));
  };

  const fetchAccountTypes = async (endpoint) => {
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    setAccountTypeOptions(buildAccountTypeOptions(resp.data));
    setAccountsLoaded(false);
  };

  const buildAgreementTypeOptions = (options) => {
    return options.map((opt) => ({
      value: opt.service_provider_type_id,
      label: opt.service_provider_type,
      ...opt,
    }));
  };

  const fetchAgreementTypes = async (endpoint) => {
    try {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      setAgreementTypeOptions(buildAgreementTypeOptions(resp.data));
      setAgreementsLoaded(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAgreementTypes("/v1/service-provider-type");
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchAccountTypes("/v1/account-type");
    })();
  }, []);

  useEffect(() => {
    if (
      !(
        trading_nscc_number_input === "" &&
        service_provider_name_input === "" &&
        agreement_type_input.value === "" &&
        trading_account_number_input === "" &&
        trading_account_name_input === "" &&
        tax_id_input === "" &&
        account_type_input.value === "" &&
        bin_input === ""
      )
    ) {
      getTableData();
    }
  }, [serviceProviderType]);

  return (
    <div data-testid={"service-provider-management-view"}>
      <Col>
        <Row>
          <Col>
            <h2 id="header" data-testid="header">
              Service Provider and Trading Account Management
            </h2>
          </Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={2} data-testid={"new_serviceProvider"} id={"new_serviceProvider"}>
            <Button
              data-testid={"create_button"}
              id={"create_button"}
              onClick={() => {
                navigate("/service-provider-details/new");
              }}
            >
              Create New Service Provider
            </Button>
          </Col>
          <Col sm={2} data-testid={"new_tradingAccount"} id={"new_tradingAccount"}>
            <Button
              data-testid={"create_button"}
              id={"create_button"}
              onClick={() => {
                navigate("/trading-account-details/new");
              }}
            >
              Create New Trading Account
            </Button>
          </Col>
          <Col sm={5} style={{ textAlign: "right", marginTop: "2rem", paddingRight: "0px" }}>
            <span>View</span>
          </Col>
          <Col sm={3} id={"View"} data-testid={"View"}>
            <Select
              styles={customStyles}
              value={serviceProviderType}
              onChange={(e) => {
                handleViewChange(e);
              }}
              options={[
                { label: "Service Providers and Trading Accounts", value: "service providers and trading accounts" },
                { label: "Service Providers", value: "service providers" },
              ]}
            ></Select>
          </Col>
        </Row>
        {!isLoading && !areAgreementsLoaded && !areAccountsLoaded ? (
          <>
            <Row className={"util-padding-top-20"}>
              <Col>
                <table data-testid={"management_table"} id={"management_table"}>
                  {serviceProviderType["label"] === "Service Providers and Trading Accounts" ? (
                    <colgroup>
                      <col style={{ width: 10 }} />
                      <col style={{ width: 20 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 25 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 15 }} />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col style={{ width: 20 }} />
                      <col style={{ width: 60 }} />
                      <col style={{ width: 10 }} />
                      <col style={{ width: 20 }} />
                    </colgroup>
                  )}
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </th>
                        ))}
                        <th>Actions</th>
                      </tr>
                    ))}
                    <tr>
                      <th>
                        <Input
                          data-testid="trading_nscc_number_input"
                          id="trading_nscc_number_input"
                          label=""
                          value={trading_nscc_number_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "trading_nscc_number_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      <th>
                        <Input
                          id="service_provider_name_input"
                          data-testid="service_provider_name_input"
                          label=""
                          value={service_provider_name_input}
                          style={{ display: "inline-block" }}
                          onChange={(e) => {
                            handleChange(e, "service_provider_name_input");
                          }}
                          onKeyDown={(event) => {
                            OnEnterKeyPress(event, getTableData);
                          }}
                        ></Input>
                      </th>
                      <th>
                        <div data-testid={"agreement_type_input"}>
                          <Select
                            isClearable={true}
                            id={"agreement_type_input"}
                            value={agreement_type_input["label"] !== "" ? agreement_type_input : "undefined"}
                            options={agreementTypeOptions}
                            className="agreement_type_input"
                            styles={tableHeaderSelectStyle}
                            onChange={(e) => {
                              handleSelectChange(e, "agreement_type_input");
                            }}
                            onKeyDown={(event) => {
                              OnEnterKeyPress(event, getTableData);
                            }}
                          ></Select>
                        </div>
                      </th>
                      {serviceProviderType["label"] === "Service Providers and Trading Accounts" ? (
                        <>
                          <th>
                            <Input
                              id="trading_account_number_input"
                              data-testid={"trading_account_number_input"}
                              label=""
                              value={trading_account_number_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "trading_account_number_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="trading_account_name_input"
                              data-testid={"trading_account_name_input"}
                              label=""
                              value={trading_account_name_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "trading_account_name_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <Input
                              id="tax_id_input"
                              data-testid={"tax_id_input"}
                              label=""
                              value={tax_id_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                FormatTaxId(e);
                                handleChange(e, "tax_id_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                          <th>
                            <div data-testid={"account_type_input"}>
                              <Select
                                isClearable={true}
                                id={"account_type_input"}
                                value={account_type_input["label"] !== "" ? account_type_input : "undefined"}
                                options={accountTypeOptions}
                                className="account_type_input"
                                styles={tableHeaderSelectStyle}
                                onChange={(e) => {
                                  handleSelectChange(e, "account_type_input");
                                }}
                                onKeyDown={(event) => {
                                  OnEnterKeyPress(event, getTableData);
                                }}
                              ></Select>
                            </div>
                          </th>
                          <th>
                            <Input
                              id="bin_input"
                              data-testid={"bin_input"}
                              label=""
                              value={bin_input}
                              style={{ display: "inline-block" }}
                              onChange={(e) => {
                                handleChange(e, "bin_input");
                              }}
                              onKeyDown={(event) => {
                                OnEnterKeyPress(event, getTableData);
                              }}
                            ></Input>
                          </th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th>
                        <Button
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          data-testid={"filter_button"}
                          id={"filter_button"}
                          onClick={() => {
                            getTableData();
                          }}
                        >
                          Filter
                        </Button>
                        <Button
                          data-testid={"clear_button"}
                          id={"clear _button"}
                          style={{
                            borderRadius: 10,
                            margin: "5%",
                            width: "40%",
                            height: "40px",
                            display: "inline-block",
                            padding: "0px",
                          }}
                          className="pds-button pds-button-secondary-ghost"
                          onClick={() => {
                            handleClear();
                            setData([]);
                          }}
                        >
                          Clear
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length < 1 ? (
                      <>
                        <tr>
                          {columns.map((col, index) => (
                            <td key={index}>&#8205;</td>
                          ))}
                        </tr>
                      </>
                    ) : (
                      <>
                        {table.getRowModel().rows.map((row) => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                            <td key={row.id} style={{ textAlign: "center" }}>
                              {serviceProviderType["label"] === "Service Providers and Trading Accounts" ? (
                                <>
                                  Service Provider:{" "}
                                  <Link
                                    id={"service_provider_details_edit_link"}
                                    data-testid={"service_provider_details_edit_link"}
                                    to={`/service-provider-details/edit/${data[row.id].service_provider_id}`}
                                  >
                                    Edit
                                  </Link>{" "}
                                  |{" "}
                                  <Link
                                    id={"service_provider_details_link"}
                                    data-testid={"service_provider_details_link"}
                                    to={`/service-provider-details/${data[row.id].service_provider_id}`}
                                  >
                                    Details
                                  </Link>
                                  <br />
                                  {data[row.id].trading_account_id ? (
                                    <>
                                      Trading Account:{" "}
                                      <Link
                                        id={"trading_account_details_edit_link"}
                                        data-testid={"trading_account_details_edit_link"}
                                        to={`/trading-account-details/edit/${data[row.id].trading_account_id}`}
                                      >
                                        Edit
                                      </Link>{" "}
                                      |{" "}
                                      <Link
                                        id={"trading_account_details_link"}
                                        data-testid={"trading_account_details_link"}
                                        to={`/trading-account-details/${data[row.id].trading_account_id}`}
                                      >
                                        Details
                                      </Link>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  Service Provider:{" "}
                                  <Link
                                    id={"service_provider_details_edit_link"}
                                    data-testid={"service_provider_details_edit_link"}
                                    to={`/service-provider-details/edit/${data[row.id].service_provider_id}`}
                                  >
                                    Edit
                                  </Link>{" "}
                                  |{" "}
                                  <Link
                                    id={"service_provider_details_link"}
                                    data-testid={"service_provider_details_link"}
                                    to={`/service-provider-details/${data[row.id].service_provider_id}`}
                                  >
                                    Details
                                  </Link>{" "}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Pagination
              data={data}
              table={table}
              setPageViewNum={setPageViewNum}
              pageViewNum={pageViewNum}
              getPageViewOption={getPageViewOption}
            ></Pagination>
          </>
        ) : (
          <>
            <h2 className="text-center"> Application is starting</h2>
            <Loading></Loading>
          </>
        )}
      </Col>
    </div>
  );
};

export default ServiceProviderTradingAccountManagement;
