import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import UserContext from "../../context/User.context";
import Button from "@pds-react/button";
import ReactModal from "react-modal";
import "./Header.scss";
import "../Utilities/Modal/ModalStylesBuiltIn.css";
import modalStyles from "../Utilities/Modal/ModalSyles";
import { Col, Row } from "@pds-react/grid";

//if (process.env.NODE_ENV !== "test") ReactModal.setAppElement("#root");

function Header() {
  const { loggedIn, name } = useContext(UserContext);
  const [modalOpen, setModal] = useState(false);

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleTicket = () => {
    const ticketUrl = "https://servicemgmt.principal.com/CherwellPortal/ServiceDeskOnline?_=3bd096b6#0";
    window.open(ticketUrl, "_blank", "noreferrer");
  };

  return (
    <nav data-testid="Header" className="navbar navbar-default">
      <div className="container-fluid">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#nav-collapse"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>

          <Link className="navbar-principal-brand" to="/"></Link>
        </div>
        <div className="collapse navbar-collapse" id="nav-collapse">
          <ul className="nav navbar-nav">
            <li>
              <Link to="/">Trust Company Dashboard</Link>
            </li>
            {loggedIn && (
              <>
                <li className="dropdown">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    Trusts and Accounts <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/trust-investment-management">
                        Participating Trust and Investment Account Management
                      </Link>
                    </li>
                    <li>
                      <Link to="/new-pa">New Participating Trust</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    Contacts and Companies <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/contact-company-management">Contact and Company Management</Link>
                    </li>
                    <li className="util-margin-top-sm-5">
                      <Link to="/company-details/new">New Company</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    Service Providers <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/service-provider-trading-account-management">Service Provider Management</Link>
                    </li>
                    <li className="util-margin-top-sm-5">
                      <Link to="/service-provider-details/new">New Service Provider</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    Groupings <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/groupings-management">Groupings Management</Link>
                    </li>
                    <li className="util-margin-top-sm-5">
                      <Link to="/groupings-details/new">New Grouping</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    Portfolio and Share Class <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/portfolio-and-share-class-management">Portfolio and Share Class Management</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    WSRS AUM Import <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li>
                      <Link to="/generate-fsa-aum-report">Generate Report</Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
          {loggedIn && (
            <>
              <ul className="nav navbar-nav navbar-right">
                <li className="dropdown" data-testid={"modal-main"}>
                  <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                    {name} <i className="fa fa-angle-down"></i>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    <li data-toggle="modal" data-target=".bs-example-modal-std" data-testid="HelpLink">
                      <a
                        href="#"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        Help
                      </a>
                    </li>
                    <li role="separator" className="divider"></li>
                    <li>
                      <a
                        data-testid="SignOut"
                        onClick={() => {
                          Auth.signOut();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
              <ReactModal
                data-testid={`modal_testid`}
                id={`modal`}
                isOpen={modalOpen}
                //ariaHideApp={false}
                //setAppElement={"root"}
                contentLabel="Minimal Modal Example"
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
                onRequestClose={() => handleCloseModal()}
                style={modalStyles({ center: false })}
                //parentSelector={() => document.querySelector("#root")}
              >
                <div className={"container"}>
                  <Row>
                    <Col sm={12} lg={12}>
                      <div className={"text-center"}>
                        <h3 className="modal-title" id="static-modal-label">
                          Help
                        </h3>
                      </div>
                      <hr />
                      <h5>For all issues please submit a ticket:</h5>
                      <div className={"text-center"}>
                        <Row>
                          <Col sm={12} lg={12}>
                            <Button data-testid={`modal_help_button`} variant="primary" onClick={handleTicket}>
                              Create ticket
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </ReactModal>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;
