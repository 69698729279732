import { createContext } from "react";

const UserContext = createContext({
  loggedIn: false,
  loginError: null,
  email: null,
  name: null,
  accessToken: {
    jwtToken: null,
    payload: { exp: null },
  },
  scope: null,
  setUser: () => {},
});

export default UserContext;
