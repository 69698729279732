import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Card from "../../../Card";
import Loading from "../../../../Loading/Loading";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import Checkbox from "@pds-react/checkbox";
import Select, { createFilter } from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { handleChange, handleSelectChange, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import {
  FormatPhoneNumber,
  PhoneNumberFormatOnLoad,
  PhoneNumberFormatOnSubmit,
  getTextAreaStyles,
  Validate,
  validateGuid,
  ValidatePhoneNumber,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../../Utilities/ValidationHelpers";
import selectStyler from "../../../../Utilities/selectStyler";

const ContactDetailsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const validatedParams = {};
  if (Object.keys(params).length > 0) {
    if (params.contact_id !== undefined) {
      validatedParams["contact_id"] = validateGuid(params.contact_id);
    }
    if (params.contact_company_id !== undefined) {
      validatedParams["contact_company_id"] = validateGuid(params.contact_company_id);
    }
  }

  const initialData = {
    contact_company_id: "",
    contact_id: "",
    first_name: "",
    last_name: "",
    contact_title: "",
    other_name: "",
    contact_phone: "",
    contact_extension: "",
    contact_email: "",
    contact_mailing_address: "",
    contact_mailing_address_2: "",
    contact_mailing_city: "",
    contact_mailing_state: { label: "", value: "" },
    contact_mailing_zip: "",
    contact_mailing_country: { label: "", value: "" },
    street_address_same_as_mailing: false,
    contact_street_address: "",
    contact_street_address_2: "",
    contact_street_city: "",
    contact_street_state: { label: "", value: "" },
    contact_street_zip: "",
    contact_street_country: { label: "", value: "" },
    reason_for_change: "",
  };

  const [data, setData] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);
  const [isSelectLoading, setSelectLoading] = useState(true);
  const [asyncOptions, setOptions] = useState([]);
  const [validationErrors, setValidationError] = useState({});

  const fieldsToValidate = {
    first_name: ValidateRequired,
    last_name: ValidateRequired,
    contact_mailing_address: ValidateRequired,
    contact_mailing_city: ValidateRequired,
    contact_mailing_state: ValidateRequiredSelect,
    contact_mailing_zip: ValidateRequired,
    contact_mailing_country: ValidateRequiredSelect,
    reason_for_change: ValidateRequired,
    contact_phone: ValidatePhoneNumber,
  };

  const {
    first_name,
    last_name,
    contact_title,
    other_name,
    contact_phone,
    contact_extension,
    contact_email,
    contact_mailing_address,
    contact_mailing_address_2,
    contact_mailing_city,
    contact_mailing_state,
    contact_mailing_zip,
    contact_mailing_country,
    street_address_same_as_mailing,
    contact_street_address,
    contact_street_address_2,
    contact_street_city,
    contact_street_state,
    contact_street_zip,
    contact_street_country,
    reason_for_change,
  } = data;

  const HandleSameAsMultiFillCheckBox = (id, value, valuesToChange, data, setData) => {
    setData((data) => ({ ...data, [id]: value }));

    HandleMultiStateUpdate(value, valuesToChange, data, setData);
  };

  const HandleMultiStateUpdate = (value, valuesToChange, data, setData) => {
    for (const property in valuesToChange) {
      let update = { value: "", label: "" };
      if (value) {
        update = data[property];
      } else {
        if (typeof data[property] === "string") {
          update = "";
        } else {
          if (typeof data[property] === "boolean") {
            update = false;
          }
        }
      }
      setData((data) => ({ ...data, [valuesToChange[property]]: update }));
    }
  };

  const getContact = async () => {
    let endpoint = `/v1/contact?contact-id=${validatedParams.contact_id}`;
    const response = await fetchService(baseUrl + endpoint, {
      method: "GET",
      token: accessToken,
    });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const handleSubmit = async () => {
    let [formIsValid, errors] = Validate(data, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        const { baseUrl } = Config.api;
        let endpoint = "/v1/contact";
        let contact_data = {
          ...data,
        };
        contact_data.contact_phone = contact_data.contact_phone
          ? PhoneNumberFormatOnSubmit(contact_data.contact_phone)
          : contact_data.contact_phone;
        contact_data.contact_mailing_state = contact_data.contact_mailing_state.value;
        contact_data.contact_street_state = contact_data.contact_street_state.value;
        contact_data.contact_mailing_country = contact_data.contact_mailing_country.value;
        contact_data.contact_street_country = contact_data.contact_street_country.value;

        if (validatedParams.contact_company_id) {
          let formattedData = JSON.stringify(contact_data);
          const response = await fetchService(baseUrl + endpoint, {
            method: "POST",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/company-details/${validatedParams.contact_company_id}`);
        } else {
          let formattedData = JSON.stringify(contact_data);
          const response = await fetchService(baseUrl + endpoint, {
            method: "PUT",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/contact-details/${validatedParams.contact_id}`);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  useEffect(() => {
    if (validatedParams.contact_company_id) {
      setData((data) => ({ ...data, ["contact_company_id"]: validatedParams.contact_company_id }));
      setLoadingData(false);
    } else {
      const setupContactData = async () => {
        try {
          let contactDetails = await getContact();
          let contact = contactDetails.data[0];
          contact = set_nulls_to_empty_strings(contact);
          contact.contact_phone = PhoneNumberFormatOnLoad(contact.contact_phone);
          contact.street_address_same_as_mailing =
            contact.contact_street_address === contact.contact_mailing_address &&
            contact.contact_street_address_2 === contact.contact_mailing_address_2 &&
            contact.contact_street_city === contact.contact_mailing_city &&
            contact.contact_street_state === contact.contact_mailing_state &&
            contact.contact_street_zip === contact.contact_mailing_zip &&
            contact.contact_street_country === contact.contact_mailing_country;

          contact.contact_mailing_state = {
            label: contact.contact_mailing_state,
            value: contact.contact_mailing_state,
          };
          contact.contact_mailing_country = {
            label: contact.contact_mailing_country,
            value: contact.contact_mailing_country,
          };
          contact.contact_street_state = { label: contact.contact_street_state, value: contact.contact_street_state };
          contact.contact_street_country = {
            label: contact.contact_street_country,
            value: contact.contact_street_country,
          };
          contact.reason_for_change = "";

          setData(contact);
          setLoadingData(false);
        } catch (error) {
          navigate("/error", { state: { error } });
        }
      };
      setupContactData();
    }
  }, []);

  useEffect(() => {
    validationUseEffectHelper("first_name", first_name, fieldsToValidate, validationErrors, setValidationError);
  }, [first_name]);

  useEffect(() => {
    validationUseEffectHelper("last_name", last_name, fieldsToValidate, validationErrors, setValidationError);
  }, [last_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_address",
      contact_mailing_address,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_address]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_city",
      contact_mailing_city,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_city]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_state",
      contact_mailing_state,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_state]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_zip",
      contact_mailing_zip,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_zip]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_country",
      contact_mailing_country,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_country]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  useEffect(() => {
    validationUseEffectHelper("contact_phone", contact_phone, fieldsToValidate, validationErrors, setValidationError);
  }, [contact_phone]);

  useEffect(() => {
    const buildSelectOptions = (options) => {
      return options.map((opt) => ({
        value: opt.name || opt.state_code,
        label: opt.name || opt.state_code,
        ...opt,
      }));
    };
    const fetchOptions = async (endpoint) => {
      if (!isSelectLoading) setSelectLoading(true);
      const { baseUrl } = Config.api;
      const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        const requestId = resp["requestId"];
        throw new Error(requestId);
      }
      const res = await resp["data"];
      setOptions(buildSelectOptions(res));
      setSelectLoading(false);
    };

    (async () => {
      await fetchOptions("/v1/us-states");
    })();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={6}>
              <h2>{validatedParams.contact_id ? " Edit Contact Details" : "New Contact Details"}</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Card heading={"Contact Detail"}>
                    <Row>
                      <Col sm={6}>
                        <Input
                          data-testid={"first_name"}
                          id={"first_name"}
                          type="text"
                          label="First Name"
                          value={first_name}
                          onChange={(e) => {
                            handleChange(e, "first_name", setData, data);
                          }}
                          errorMessage={validationErrors["first_name"]}
                        ></Input>
                      </Col>
                      <Col sm={6}>
                        <Input
                          data-testid={"last_name"}
                          id={"last_name"}
                          type="text"
                          label="Last Name"
                          value={last_name}
                          onChange={(e) => {
                            handleChange(e, "last_name", setData, data);
                          }}
                          errorMessage={validationErrors["last_name"]}
                        ></Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Input
                          data-testid={"contact_title"}
                          id={"contact_title"}
                          type="text"
                          label="Title"
                          value={contact_title}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "contact_title", setData);
                          }}
                        ></Input>
                      </Col>
                      <Col sm={6}>
                        <Input
                          data-testid={"other_name"}
                          id={"other_name"}
                          type="text"
                          label="Other Names"
                          value={other_name}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "other_name", setData);
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Input
                          data-testid={"contact_phone"}
                          id={"contact_phone"}
                          type="text"
                          label="Phone"
                          value={contact_phone}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            FormatPhoneNumber(e);
                            handleChange(e, "contact_phone", setData);
                          }}
                          errorMessage={validationErrors["contact_phone"]}
                        ></Input>
                      </Col>
                      <Col sm={2}>
                        <Input
                          data-testid={"contact_extension"}
                          id={"contact_extension"}
                          type="text"
                          label="Ext"
                          value={contact_extension}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "contact_extension", setData);
                          }}
                        ></Input>
                      </Col>
                      <Col sm={6}>
                        <Input
                          data-testid={"contact_email"}
                          id={"contact_email"}
                          type="text"
                          label="Email"
                          value={contact_email}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "contact_email", setData);
                          }}
                        ></Input>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={12}>
                  <Card heading={"Contact Address"}>
                    <Row>
                      <Col sm={12}>
                        <Input
                          data-testid={"contact_mailing_address"}
                          id={"contact_mailing_address"}
                          type="text"
                          label="Mailing Address"
                          value={contact_mailing_address}
                          onChange={(e) => {
                            handleChange(e, "contact_mailing_address", setData);
                          }}
                          errorMessage={validationErrors["contact_mailing_address"]}
                        ></Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Input
                          data-testid={"contact_mailing_address_2"}
                          id={"contact_mailing_address_2"}
                          type="text"
                          label=""
                          value={contact_mailing_address_2}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "contact_mailing_address_2", setData);
                          }}
                        ></Input>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <Input
                          data-testid={"contact_mailing_city"}
                          id={"contact_mailing_city"}
                          type="text"
                          label="City"
                          value={contact_mailing_city}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "contact_mailing_city", setData);
                          }}
                          errorMessage={validationErrors["contact_mailing_city"]}
                        ></Input>
                      </Col>
                      <Col sm={3}>
                        <div data-testid={"contact_mailing_state"}>
                          <label
                            className="util-margin-top-sm-15 util-margin-top-xs-30"
                            htmlFor="contact_mailing_state"
                          >
                            State
                          </label>
                          <Select
                            id={"contact_mailing_state"}
                            label={"State"}
                            styles={selectStyler({
                              validationErrorsValue: validationErrors["contact_mailing_state"],
                            })}
                            options={asyncOptions}
                            isLoading={isSelectLoading}
                            value={contact_mailing_state["label"] !== "" ? contact_mailing_state : "undefined"}
                            onChange={(e) => {
                              handleSelectChange("contact_mailing_state", e, setData);
                              if (e === null) {
                                handleSelectChange("contact_mailing_country", e, setData);
                              } else {
                                let countryValue = { label: "US", value: "US" };
                                handleSelectChange("contact_mailing_country", countryValue, setData);
                              }
                            }}
                            filterOption={createFilter({ ignoreAccents: false })}
                            isClearable={true}
                          ></Select>
                          <ValidationErrorMessage
                            paramId={"contact_mailing_state"}
                            validationError={validationErrors["contact_mailing_state"]}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <Input
                          data-testid={"contact_mailing_zip"}
                          id={"contact_mailing_zip"}
                          type="text"
                          label="Zip"
                          value={contact_mailing_zip}
                          className="util-margin-top-sm-15 util-margin-top-xs-30"
                          onChange={(e) => {
                            handleChange(e, "contact_mailing_zip", setData);
                          }}
                          errorMessage={validationErrors["contact_mailing_zip"]}
                        ></Input>
                      </Col>
                      <Col sm={3}>
                        <div data-testid={"contact_mailing_country"}>
                          <label
                            className="util-margin-top-sm-15 util-margin-top-xs-30"
                            htmlFor="contact_mailing_country"
                          >
                            Country
                          </label>
                          <Select
                            id={"contact_mailing_country"}
                            label={"Country"}
                            styles={selectStyler({
                              validationErrorsValue: validationErrors["contact_mailing_country"],
                            })}
                            options={[
                              { value: "US", label: "US" },
                              { value: "CA", label: "CA" },
                            ]}
                            value={contact_mailing_country["label"] !== "" ? contact_mailing_country : "undefined"}
                            onChange={(e) => {
                              handleSelectChange("contact_mailing_country", e, setData);
                            }}
                            isClearable={true}
                            filterOption={createFilter({ ignoreAccents: false })}
                          ></Select>
                          <ValidationErrorMessage
                            paramId={"contact_mailing_country"}
                            validationError={validationErrors["contact_mailing_country"]}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                        <Checkbox
                          data-testid={"street_address_same_as_mailing"}
                          id={`street_address_same_as_mailing`}
                          label="Same as Mailing address"
                          className="util-margin-top-sm-15 util-margin-top-xs-30 util-margin-bottom-xs-15"
                          checked={street_address_same_as_mailing}
                          onChange={(e) => {
                            const planSponsorStreetAddress = {
                              contact_mailing_address: "contact_street_address",
                              contact_mailing_address_2: "contact_street_address_2",
                              contact_mailing_city: "contact_street_city",
                              contact_mailing_state: "contact_street_state",
                              contact_mailing_zip: "contact_street_zip",
                              contact_mailing_country: "contact_street_country",
                            };
                            HandleSameAsMultiFillCheckBox(
                              "street_address_same_as_mailing",
                              e.target.checked,
                              planSponsorStreetAddress,
                              data,
                              setData
                            );
                          }}
                          errorMessage={""}
                        ></Checkbox>
                      </Col>
                    </Row>
                    {!street_address_same_as_mailing ? (
                      <>
                        <Row>
                          <Col sm={12}>
                            <Input
                              data-testid={"contact_street_address"}
                              id={"contact_street_address"}
                              type="text"
                              label="Street Address"
                              value={contact_street_address}
                              className="util-margin-top-sm-15 util-margin-top-xs-30"
                              onChange={(e) => {
                                handleChange(e, "contact_street_address", setData);
                              }}
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12}>
                            <Input
                              data-testid={"contact_street_address_2"}
                              id={"contact_street_address_2"}
                              type="text"
                              label=""
                              value={contact_street_address_2}
                              className="util-margin-top-sm-15 util-margin-top-xs-30"
                              onChange={(e) => {
                                handleChange(e, "contact_street_address_2", setData);
                              }}
                            ></Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={3}>
                            <Input
                              data-testid={"contact_street_city"}
                              id={"contact_street_city"}
                              type="text"
                              label="City"
                              value={contact_street_city}
                              className="util-margin-top-sm-15 util-margin-top-xs-30"
                              onChange={(e) => {
                                handleChange(e, "contact_street_city", setData);
                              }}
                            ></Input>
                          </Col>
                          <Col sm={3}>
                            <div data-testid={"contact_street_state"}>
                              <label className="util-margin-top-sm-15 util-margin-top-xs-30" htmlFor="street_state">
                                State
                              </label>
                              <Select
                                isClearable={true}
                                id={"contact_street_state"}
                                label={"State"}
                                styles={selectStyler({})}
                                options={asyncOptions}
                                isLoading={isSelectLoading}
                                value={contact_street_state["label"] !== "" ? contact_street_state : "undefined"}
                                onChange={(e) => {
                                  handleSelectChange("contact_street_state", e, setData);
                                  if (e === null) {
                                    handleSelectChange("contact_street_country", e, setData);
                                  } else {
                                    let countryValue = { label: "US", value: "US" };
                                    handleSelectChange("contact_street_country", countryValue, setData);
                                  }
                                }}
                                filterOption={createFilter({ ignoreAccents: false })}
                              ></Select>
                            </div>
                          </Col>
                          <Col sm={3}>
                            <Input
                              data-testid={"contact_street_zip"}
                              id={"contact_street_zip"}
                              type="text"
                              label="Zip"
                              value={contact_street_zip}
                              className="util-margin-top-sm-15 util-margin-top-xs-30"
                              onChange={(e) => {
                                handleChange(e, "contact_street_zip", setData);
                              }}
                            ></Input>
                          </Col>
                          <Col sm={3}>
                            <div data-testid={"contact_street_country"}>
                              <label className="util-margin-top-sm-15 util-margin-top-xs-30" htmlFor="street_country">
                                Country
                              </label>
                              <Select
                                id={"contact_street_country"}
                                label={"Country"}
                                isClearable={true}
                                styles={selectStyler({})}
                                options={[
                                  { value: "US", label: "US" },
                                  { value: "CA", label: "CA" },
                                ]}
                                value={contact_street_country["label"] !== "" ? contact_street_country : "undefined"}
                                onChange={(e) => {
                                  handleSelectChange("contact_street_country", e, setData);
                                }}
                                filterOption={createFilter({ ignoreAccents: false })}
                              ></Select>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Notes"}>
                <Col sm={12}>
                  <label>Reason For Change</label>
                  <div>
                    <textarea
                      data-testid={"reason_for_change"}
                      style={getTextAreaStyles(validationErrors["reason_for_change"])}
                      value={reason_for_change}
                      onChange={(e) => {
                        handleChange(e, "reason_for_change", setData);
                      }}
                    ></textarea>
                  </div>
                  <ValidationErrorMessage
                    paramId={"reason_for_change"}
                    validationError={validationErrors["reason_for_change"]}
                  />
                </Col>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button
                data-testid={"saveChanges"}
                style={{ marginRight: 15 }}
                onClick={async () => {
                  await handleSubmit();
                }}
              >
                Save Changes
              </Button>
              <Button
                data-testid={"discardChanges"}
                onClick={() => {
                  if (validatedParams.contact_id) {
                    navigate(`/contact-details/${validatedParams.contact_id}`);
                  } else {
                    navigate(`/company-details/${validatedParams.contact_company_id}`);
                  }
                }}
              >
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default ContactDetailsEdit;
