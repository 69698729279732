import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import Select from "react-select";
import Input from "@pds-react/input";
import Button from "@pds-react/button";
import Loading from "../../../../../../Loading/Loading";
import { fetchService } from "../../../../../../../services/fetchService";
import Config from "../../../../../../../config";
import { OnEnterKeyPress } from "../../../../../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import UserContext from "../../../../../../../context/User.context";
import { useNavigate } from "react-router-dom";
("../../../Utilities/ValidationHelpers");
import { handleChange } from "../../../../../../Utilities/FormHelpers";
import { FormatTaxId, FormatTaxIdOnLoad, UnFormatTaxId } from "../../../../../../Utilities/ValidationHelpers";
import selectStyler from "../../../../../../Utilities/selectStyler";

const TransferInvestment = ({
  setCurrentComponent,
  fundGroupInfo,
  otherIds,
  investmentToTransfer,
  setInvestmentToTransfer,
}) => {
  const { fundGroupName } = fundGroupInfo;
  const { trading_account_id, participating_trust_id } = otherIds;
  const initialFilters = {
    trust_name_input: "",
    tax_id_input: "",
  };

  const [filters, setFilters] = useState(initialFilters);
  const [paricipatingTrustOptions, setParicipatingTrustOptions] = useState();
  const [participatingTrustChoice, setParticipatingTrustChoice] = useState({ label: "", value: "" });
  const [tradingAccountOptions, setTradingAccountOptions] = useState();
  const [tradingAccountChoice, setTradingAccountChoice] = useState({ label: "", value: "" });
  const [investments, setInvestments] = useState();
  const [investmentOptions, setInvestmentOptions] = useState();
  const [loadingPT, setLoadingPT] = useState(true);
  const [searchStarted, setSearchStarted] = useState(false);
  const [loadingTA, setLoadingTA] = useState(true);
  const [loadingInvestments, setLoadingInvestments] = useState(true);
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();

  const { trust_name_input, tax_id_input } = filters;

  const formatPTLabel = ({ ptName, taxId, planType }) => (
    <div>
      <p>
        <span style={{ color: "#0076CF" }}>{ptName}</span> | <span style={{ color: "#00C4D9" }}>{taxId}</span> |{" "}
        <span style={{ color: "#9E8BFF" }}>{planType}</span>
      </p>
    </div>
  );

  const formatTALabel = ({ accountType, planName, serviceProvider, transferAgentNum, subAccount }) => (
    <div>
      <p>
        <span style={{ color: "#00A29B" }}>{transferAgentNum}</span> |{" "}
        <span style={{ color: "#616267" }}>{accountType}</span> | <span style={{ color: "#00A29B" }}>{planName}</span> |{" "}
        <span style={{ color: "#0061A0" }}>{serviceProvider}</span> |{" "}
        <span style={{ color: "#00A29B" }}>{subAccount}</span>
      </p>
    </div>
  );

  const formatInvestLabel = ({ fund, shareClass }) => (
    <div>
      <p>
        <span style={{ color: "black" }}>{fund}</span> | <span style={{ color: "#9D9EA4" }}>{shareClass}</span>
      </p>
    </div>
  );

  const handleTransferDiscard = () => {
    setCurrentComponent("");
    setInvestmentToTransfer({ label: "", value: "" });
  };

  const handlePTChoice = (e) => {
    setParticipatingTrustChoice(e);
    setTradingAccountChoice({ label: "", value: "" });
    setInvestmentToTransfer({ label: "", value: "" });
  };

  const handleTAChoice = (e) => {
    setTradingAccountChoice(e);
    setInvestmentToTransfer({ label: "", value: "" });
  };

  const handleInvestmentToTransfer = (e) => {
    setInvestmentToTransfer(e);
  };

  const getSearchResults = async () => {
    try {
      setLoadingPT(true);
      setSearchStarted(true);
      if (trust_name_input !== "" || tax_id_input !== "") {
        let params = new URLSearchParams({
          "participating-trust-name": trust_name_input,
          "plan-tax-id": UnFormatTaxId(tax_id_input),
        });
        let endpoint = `/v1/management-trust?${params}`;
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }

        setParicipatingTrustOptions(
          resp.data.map((plan) => {
            return {
              label: `${plan.participating_trust_name} ${
                plan.plan_tax_id ? FormatTaxIdOnLoad(plan.plan_tax_id) : "No Tax ID"
              } ${plan.plan_type}`,
              value: plan.participating_trust_id,
              ptName: plan.participating_trust_name,
              taxId: plan.plan_tax_id ? FormatTaxIdOnLoad(plan.plan_tax_id) : "No Tax ID",
              planType: plan.plan_type,
            };
          })
        );

        setParticipatingTrustChoice({ label: "", value: "" });
        setTradingAccountOptions([]);
        setTradingAccountChoice({ label: "", value: "" });
        setInvestmentOptions([]);
        setInvestmentToTransfer({ label: "", value: "" });
        setLoadingPT(false);
      } else {
        setLoadingPT(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    const getTradingAccounts = async () => {
      try {
        if (participatingTrustChoice.value) {
          let endpoint = `/v1/investments?participating-trust-id=${participatingTrustChoice.value}`;
          let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          let formattedInvestments = {};
          resp.data.forEach((result) => {
            result.investments.forEach((investment) => {
              if (
                !(
                  investment.fund_group_name === fundGroupName &&
                  result.trading_account["trading_account_id"] === trading_account_id &&
                  investment.participating_trust_id === participating_trust_id
                ) &&
                investment.actual_liquidation_date === null
              ) {
                let key = result.trading_account["trading_account_id"];
                if (key in formattedInvestments) {
                  formattedInvestments[key].push({
                    label: `${investment.investment_account_id} ${investment.fund_group_name} ${investment.share_class_name}`,
                    value: investment.investment_account_id,
                    fund: investment.fund_group_name,
                    shareClass: investment.share_class_name,
                  });
                } else {
                  formattedInvestments[key] = [];
                  formattedInvestments[key].push({
                    label: `${investment.investment_account_id} ${investment.fund_group_name} ${investment.share_class_name}`,
                    value: investment.investment_account_id,
                    fund: investment.fund_group_name,
                    shareClass: investment.share_class_name,
                  });
                }
              }
            });
          });
          setInvestments(formattedInvestments);
          setTradingAccountOptions(
            resp.data.map((tradingAccount) => {
              return {
                label: `${
                  tradingAccount.trading_account.omnibus_name
                    ? tradingAccount.trading_account.omnibus_name
                    : participatingTrustChoice.label.split("-")[0].trim()
                } ${tradingAccount.trading_account.account_type} ${
                  tradingAccount.trading_account.service_provider_name
                } ${tradingAccount.trading_account.transfer_agent_account_number} ${
                  tradingAccount.trading_account.sub_account_number
                    ? tradingAccount.trading_account.sub_account_number
                    : "No Sub Account Number"
                }`,
                value: tradingAccount.trading_account["trading_account_id"],
                accountType: tradingAccount.trading_account.account_type,
                planName: tradingAccount.trading_account.omnibus_name
                  ? tradingAccount.trading_account.omnibus_name
                  : participatingTrustChoice.label.split("-")[0].trim(),
                serviceProvider: tradingAccount.trading_account.service_provider_name,
                transferAgentNum: tradingAccount.trading_account.transfer_agent_account_number,
                subAccount: tradingAccount.trading_account.sub_account_number
                  ? tradingAccount.trading_account.sub_account_number
                  : "No Sub Account Number",
              };
            })
          );
          setLoadingTA(false);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    getTradingAccounts();
  }, [participatingTrustChoice]);

  useEffect(() => {
    if (investments && tradingAccountChoice.value in investments) {
      setInvestmentOptions(investments[tradingAccountChoice.value]);
      setLoadingInvestments(false);
    } else {
      if (tradingAccountChoice.value) {
        setInvestmentOptions([]);
        setLoadingInvestments(false);
      }
    }
  }, [tradingAccountChoice]);

  return (
    <div data-testid={"transferInvestment"}>
      <Row>
        <Col sm={12}>
          <Row>
            <Col lg={4} sm={12}>
              <Input
                id="trust_name_input"
                data-testid={"trust_name_input"}
                label="Participating Trust Name"
                value={trust_name_input}
                onChange={(e) => {
                  handleChange(e, "trust_name_input", setFilters);
                }}
                onKeyDown={(event) => {
                  OnEnterKeyPress(event, getSearchResults);
                }}
              ></Input>
            </Col>
            <Col lg={4} sm={12}>
              <Input
                id="tax_id_input"
                data-testid={"tax_id_input"}
                label="Plan Tax ID"
                value={tax_id_input}
                style={{ display: "inline-block" }}
                onChange={(e) => {
                  FormatTaxId(e);
                  handleChange(e, "tax_id_input", setFilters);
                }}
                onKeyDown={(event) => {
                  OnEnterKeyPress(event, getSearchResults);
                }}
              ></Input>
            </Col>
            <Col lg={4} sm={12}>
              <Button
                className="util-margin-top-sm-25 util-margin-top-xs-25"
                style={{ marginRight: 15 }}
                data-testid={"searchParticipatingTrust"}
                onClick={getSearchResults}
              >
                Search
              </Button>
            </Col>
          </Row>
          {!loadingPT ? (
            <Row>
              <Col className="util-margin-top-sm-15 util-margin-top-xs-30">
                <div data-testid={"participatingTrustSelect"}>
                  <label>Choose Participating Trust</label>
                  <p>
                    <span>Key: </span>
                    <span style={{ color: "#0076CF" }}>Participating Trust Name</span> |{" "}
                    <span style={{ color: "#00C4D9" }}>Tax ID</span> |{" "}
                    <span style={{ color: "#9E8BFF" }}>Plan Type</span>
                  </p>
                  <Select
                    formatOptionLabel={formatPTLabel}
                    options={paricipatingTrustOptions}
                    styles={selectStyler({ validationErrorsValue: "", disabled: false, formattedLabel: true })}
                    value={participatingTrustChoice["label"] !== "" ? participatingTrustChoice : "undefined"}
                    onChange={(e) => {
                      handlePTChoice(e);
                    }}
                  ></Select>
                </div>
              </Col>
            </Row>
          ) : searchStarted ? (
            <Loading />
          ) : (
            <></>
          )}
          {!loadingTA ? (
            <Row>
              <Col className="util-margin-top-sm-15 util-margin-top-xs-30">
                <div data-testid={"tradingAccounttSelect"}>
                  <label>Choose Trading Account:</label>
                  <p>
                    <span>Key: </span>
                    <span style={{ color: "#00A29B" }}>Trading Account Number</span> |{" "}
                    <span style={{ color: "#616267 " }}>Account Type</span> |{" "}
                    <span style={{ color: "#00A29B" }}>Trading Account Name</span> |{" "}
                    <span style={{ color: "#0061A0" }}>Service Provider</span> |{" "}
                    <span style={{ color: "#00A29B" }}>Sub Account Number</span>
                  </p>
                  <Select
                    formatOptionLabel={formatTALabel}
                    options={tradingAccountOptions}
                    styles={selectStyler({ validationErrorsValue: "", disabled: false, formattedLabel: true })}
                    value={tradingAccountChoice["label"] !== "" ? tradingAccountChoice : "undefined"}
                    onChange={(e) => {
                      handleTAChoice(e);
                    }}
                  ></Select>
                </div>
              </Col>
            </Row>
          ) : participatingTrustChoice.value !== "" ? (
            <Loading />
          ) : (
            <></>
          )}
          {!loadingInvestments ? (
            <Row>
              <Col className="util-margin-top-sm-15 util-margin-top-xs-30">
                <div data-testid={"investmentSelect"}>
                  <label>Choose Investment to Transfer To:</label>
                  <p>
                    <span>Key: </span>
                    <span style={{ color: "black" }}>Fund</span> | <span style={{ color: "#9D9EA4" }}>Share Class</span>
                  </p>
                  <Select
                    formatOptionLabel={formatInvestLabel}
                    options={investmentOptions}
                    styles={selectStyler({ validationErrorsValue: "", disabled: false, formattedLabel: true })}
                    value={investmentToTransfer["label"] !== "" ? investmentToTransfer : "undefined"}
                    onChange={(e) => {
                      handleInvestmentToTransfer(e);
                    }}
                  ></Select>
                </div>
              </Col>
            </Row>
          ) : tradingAccountChoice.value !== "" ? (
            <Loading />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Button style={{ marginRight: 15 }} data-testid={"discardTransfer"} onClick={handleTransferDiscard}>
            Discard Transfer
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TransferInvestment;

TransferInvestment.propTypes = {
  setCurrentComponent: PropTypes.func,
  otherIds: PropTypes.object,
  fundGroupInfo: PropTypes.object,
  setInvestmentToTransfer: PropTypes.func,
  investmentToTransfer: PropTypes.object,
};
