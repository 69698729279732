import React, { useContext } from "react";
import Button from "@pds-react/button";
import UserContext from "../../context/User.context";
import Config from "../../config";
import "./Login.scss";

function Auth() {
  const { loginError } = useContext(UserContext);

  const handleLogin = () => {
    const {
      cognito: { tokenUrl, providerId, redirectUrl, clientId, scope },
    } = Config;
    const scopes = encodeURIComponent("aws.cognito.signin.user.admin email openid ") + encodeURIComponent(scope);
    const state = encodeURIComponent("thisisstate");
    const loginUrl = `${tokenUrl}?identity_provider=${providerId}&redirect_uri=${redirectUrl}&response_type=TOKEN&client_id=${clientId}&state=${state}&scope=${scopes}`;
    window.location.assign(loginUrl);
  };

  return (
    <div data-testid="Login" className="Login">
      {loginError ? (
        <h1 data-testid="LoginError" className="h4 text-center text-danger login-error-text">
          {loginError}
        </h1>
      ) : (
        <Button data-testid="LoginButton" onClick={handleLogin} className="center-block" variant="secondary">
          Login
        </Button>
      )}
    </div>
  );
}

export default Auth;
