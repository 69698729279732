import React, { useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import UserContext from "../../../../context/User.context";
import Config from "../../../../config";
import { fetchService } from "../../../../services/fetchService";
import { useNavigate, useParams } from "react-router-dom";
//import Loading from "../../../Loading/Loading";
import NewInvestmentDetailsEdit from "./NewInvestmentDetailsEdit";
import { Validate, ValidateDate, validateGuid, ValidateRequiredSelect } from "../../../Utilities/ValidationHelpers";
import { handleSelectChange, handleChange } from "../../../Utilities/FormHelpers";

const SameAccountNewInvestment = () => {
  const d = new Date();
  const initialData = {
    sales_region: { label: "", value: "" },
    approval_date: `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}`,
    fund_group_name: { label: "", value: "" },
    share_class_name: { label: "", value: "" },
  };

  const [investmentData, setInvestmentData] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);

  const { accessToken } = useContext(UserContext);
  const { baseUrl } = Config.api;
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};

  if (Object.keys(params).length !== 0) {
    validatedParams["participating_trust_id"] = validateGuid(params.participating_trust_id);
    validatedParams["trading_account_id"] = validateGuid(params.trading_account_id);
  }

  const handleSubmit = async () => {
    const [formIsValid, errors] = Validate(investmentData, investmentFieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = `/v1/investment-account`;

        let mappedData = {
          participating_trust_id: validatedParams.participating_trust_id,
          trading_account_id: validatedParams.trading_account_id,
          invested_without_pa: false,
          fund_group_id: investmentData.fund_group_name["value"],
          approval_date: investmentData.approval_date,
          sales_region_id: investmentData.sales_region["value"],
          share_class_name: investmentData.share_class_name["value"],
          reason_for_change: "Initial Creation",
        };

        let formattedData = JSON.stringify(mappedData);

        let response = await fetchService(baseUrl + endpoint, {
          method: "POST",
          body: formattedData,
          token: accessToken,
        });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        setLoadingData(false);
        navigate(`/trust-details/${validatedParams.participating_trust_id}`);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const handleDiscard = () => {
    navigate(`/trust-details/${validatedParams.participating_trust_id}`);
  };

  const initialValidation = {
    fund_group_name: ValidateRequiredSelect,
    approval_date: ValidateDate,
    share_class_name: ValidateRequiredSelect,
    sales_region: ValidateRequiredSelect,
  };

  const [validationErrors, setValidationError] = useState({});
  const [investmentFieldsToValidate] = useState(initialValidation);

  return (
    <div>
      <Row data-testid={"SameAccountNewInvestment"}>
        <Col sm={12}>
          <h2>Add New Investment to Trading Account</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <NewInvestmentDetailsEdit
            handleChange={handleChange}
            setInvestmentData={setInvestmentData}
            handleSelectChange={handleSelectChange}
            handleSubmit={handleSubmit}
            handleDiscard={handleDiscard}
            investmentFieldsToValidate={investmentFieldsToValidate}
            setValidationError={setValidationError}
            investmentData={investmentData}
            validationErrors={validationErrors}
            loadingData={loadingData}
            setLoadingData={setLoadingData}
          ></NewInvestmentDetailsEdit>
        </Col>
      </Row>
    </div>
  );
};

export default SameAccountNewInvestment;
