import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "@pds-react/grid";
import Card from "./../../../Card";
import UserContext from "../../../../../context/User.context";
import Loading from "../../../../Loading/Loading";
import ShareClassOverview from "./CardComponents/ShareClassOverview";
import { multiFetch } from "../../../../../services/multiFetch";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ShareClassDetail from "./CardComponents/ShareClassDetail";
import ShareClassExpense from "./CardComponents/ShareClassExpense";
import ShareClassDates from "./CardComponents/ShareClassDates";
import ShareClassCodes from "./CardComponents/ShareClassCodes";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";
import { validateGuid } from "../../../../Utilities/ValidationHelpers";

const ShareClassDetails = () => {
  const { accessToken } = useContext(UserContext);
  const [shareClassAddlData, setShareClassAddlData] = useState();
  const [shareClassESDLData, setShareClassESDLData] = useState();
  const [historyVersions, setHistoryVersions] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["share_class_id"] = validateGuid(params.share_class_id);
  }
  const navigate = useNavigate();

  const getDates = (date) => {
    if (date === "") {
      return "";
    } else {
      let dmy = date.split("T");
      let result = dmy.splice(0);
      let arr = result[0].split("-");
      let res = arr[1] + "/" + arr[2] + "/" + arr[0];
      return res;
    }
  };

  const handleVersionChange = (shareClass, index) => {
    setShareClassAddlData(shareClass);
    setCurrentIndex(index);
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let shareClassData = await multiFetch(
          [
            `/v1/share-class-esdl?share-class-id=${validatedParams.share_class_id}`,
            `/v1/share-class-additional-detail-history?share-class-id=${
              validatedParams.share_class_id
            }&inserted-at=${historyTimeNow(new Date())}`,
          ],
          accessToken
        );
        if (shareClassData.error) {
          throw new Error(shareClassData.requestId);
        }
        if (shareClassData[0].data.length === 0) {
          throw new Error(shareClassData[0].requestId);
        }
        if (shareClassData[1].data.length === 0) {
          throw new Error(shareClassData[1].requestId);
        }
        let shareClassESDLData = set_nulls_to_empty_strings(shareClassData[0].data[0]);
        let shareClassAddlDetails = set_nulls_to_empty_strings(shareClassData[1].data);

        let shareClassAddlDetail = shareClassData[1].data[0];

        shareClassESDLData.inception_date = getDates(shareClassESDLData.inception_date);
        shareClassESDLData.performance_begin_date = getDates(shareClassESDLData.performance_begin_date);
        shareClassESDLData.soft_close_date = getDates(shareClassESDLData.soft_close_date);
        shareClassESDLData.hard_close_date = getDates(shareClassESDLData.hard_close_date);

        setShareClassESDLData(shareClassESDLData);
        setShareClassAddlData(shareClassAddlDetail);
        setHistoryVersions(shareClassAddlDetails);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col sm={12}>
          <Row>
            <Col sm={5}>
              <h3>Share Class Details</h3>
            </Col>
            <Col>
              <button
                data-testid={"edit_button"}
                id={"edit_button"}
                type="button"
                className="btn btn-primary progress-btn"
                onClick={() => {
                  navigate(`/share-class-details/edit/${validatedParams.share_class_id}`);
                }}
              >
                Edit
              </button>
            </Col>
          </Row>
          <Row>
            <Container fluid={true}>
              <Row>
                <Col sm={12} lg={8} className={"order-sm-2 order-lg-1"}>
                  <ShareClassOverview
                    portfolio_name={shareClassESDLData.portfolio_name}
                    cusip={shareClassESDLData.cusip}
                    share_class_type={shareClassESDLData.share_class_type}
                    transfer_agent_identifier={shareClassESDLData.transfer_agent_identifier}
                    share_class_name={shareClassESDLData.share_class_name}
                    fsa_cit_share_class_code={shareClassESDLData.fsa_cit_share_class_code}
                    minimum_initial_subscription_amount={shareClassESDLData.minimum_initial_subscription_amount}
                    abor_share_class_id={shareClassESDLData.abor_share_class_id}
                    currency_id={shareClassESDLData.currency_id}
                    morning_star_sec_id={shareClassESDLData.morning_star_sec_id}
                    morning_star_feed_profile_id={shareClassESDLData.morning_star_feed_profile_id}
                    currentIndex={currentIndex}
                  />
                </Col>
                <Col sm={12} lg={4} className={"order-sm-1 order-lg-2"}>
                  <Card heading={"History"}>
                    <VersionHistory
                      historyVersions={historyVersions}
                      handleVersionChange={handleVersionChange}
                      currentIndex={currentIndex}
                    ></VersionHistory>
                    <ReasonForChange
                      reason_for_change={shareClassAddlData.reason_for_change}
                      inserted_by={shareClassAddlData.inserted_by}
                      inserted_at={shareClassAddlData.inserted_at}
                    ></ReasonForChange>
                  </Card>
                </Col>
                <Col sm={12} lg={8} className={"order-sm-3 order-lg-3 util-margin-top-sm-15 util-margin-top-xs-30"}>
                  <ShareClassDetail
                    description={shareClassAddlData.description}
                    inv_rtlclss_cd={shareClassAddlData.inv_rtlclss_cd}
                    price_precision={shareClassAddlData.price_precision}
                    display_class={shareClassAddlData.display_class}
                    price_tracking={shareClassAddlData.price_tracking}
                    vintage={shareClassAddlData.vintage}
                    accounting_basis={shareClassAddlData.accounting_basis}
                    accounting_basis_2={shareClassAddlData.accounting_basis_2}
                    class_id={shareClassAddlData.class_id}
                    share_precision={shareClassAddlData.share_precision}
                    short_name={shareClassAddlData.short_name}
                    tier_name={shareClassAddlData.tier_name}
                  />
                </Col>
                <Col sm={12} lg={4} className={"order-sm-3 order-lg-4 util-margin-top-sm-15 util-margin-top-xs-30"}>
                  <ShareClassExpense
                    fee_report_grouping={shareClassAddlData.fee_report_grouping}
                    advisor_fee={shareClassAddlData.advisor_fee}
                    management_headline_fee_bps={shareClassESDLData.management_headline_fee_bps}
                    fee_bps={shareClassAddlData.fee_bps}
                    admin_fee_bps={shareClassESDLData.admin_fee_bps}
                    other_fee={shareClassAddlData.other_fee}
                    ubt_fee={shareClassAddlData.ubt_fee}
                    currentIndex={currentIndex}
                  />
                </Col>
                <Col sm={12} lg={8} className={"order-sm-3 order-lg-4 util-margin-top-sm-15 util-margin-top-xs-30"}>
                  <ShareClassDates
                    inception_date={shareClassESDLData.inception_date}
                    performance_begin_date={shareClassESDLData.performance_begin_date}
                    soft_close_date={shareClassESDLData.soft_close_date}
                    hard_close_date={shareClassESDLData.hard_close_date}
                    termination_notice_date={""}
                    currentIndex={currentIndex}
                  />
                </Col>
                <Col sm={12} lg={4} className={"order-sm-3 order-lg-4 util-margin-top-sm-15 util-margin-top-xs-30"}>
                  <ShareClassCodes
                    ml_code={shareClassAddlData.ml_code}
                    alt_nav_source={shareClassAddlData.alt_nav_source}
                    root_cusip={shareClassAddlData.root_cusip}
                    fidelity_fund_id={shareClassAddlData.fidelity_fund_id}
                  />
                </Col>
              </Row>
            </Container>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default ShareClassDetails;
