import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const ShareClassDetail = ({ ...props }) => {
  return (
    <>
      <Card heading={"Detail"}>
        <Row>
          <Col sm={12}>
            <DataComponent
              id={`description`}
              data-testid={`description`}
              label={"Description"}
              data={props.description}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`inv_rtlclss_cd`}
              data-testid={`inv_rtlclss_cd`}
              label={"Investment Rate Class Code"}
              data={props.inv_rtlclss_cd}
            />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`display_class`}
              data-testid={`display_class`}
              label={"Display Class"}
              data={props.display_class}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`class_id`} data-testid={`class_id`} label={"Class ID"} data={props.class_id} />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`tier_name`} data-testid={`tier_name`} label={"Tier Name"} data={props.tier_name} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`short_name`} data-testid={`short_name`} label={"Short Name"} data={props.short_name} />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent id={`vintage`} data-testid={`vintage`} label={"Vintage"} data={props.vintage} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`accounting_basis`}
              data-testid={`accounting_basis`}
              label={"Accounting Basis"}
              data={props.accounting_basis}
            />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`accounting_basis_2`}
              data-testid={`accounting_basis_2`}
              label={"Accounting Basis 2"}
              data={props.accounting_basis_2}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`price_precision`}
              data-testid={`price_precision`}
              label={"Price Precision"}
              data={props.price_precision}
            />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`price_tracking`}
              data-testid={`price_tracking`}
              label={"Price Tracking"}
              data={props.price_tracking}
            />
          </Col>
          <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
            <DataComponent
              id={`share_precision`}
              data-testid={`share_precision`}
              label={"Share Precision"}
              data={props.share_precision}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ShareClassDetail;

ShareClassDetail.propTypes = {
  description: PropTypes.string,
  inv_rtlclss_cd: PropTypes.string,
  trade_partner: PropTypes.string,
  price_precision: PropTypes.number,
  display_class: PropTypes.string,
  price_tracking: PropTypes.string,
  vintage: PropTypes.string,
  accounting_basis: PropTypes.string,
  accounting_basis_2: PropTypes.string,
  class_id: PropTypes.string,
  share_precision: PropTypes.number,
  short_name: PropTypes.string,
  tier_name: PropTypes.string,
};
