import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import InvestmentAccount from "./CardComponents/InvestmentAccount";
import InvestmentDates from "./CardComponents/InvestmentDates";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import UserContext from "../../../../../context/User.context";
import Config from "../../../../../config";
import { fetchService } from "../../../../../services/fetchService";
import Loading from "../../../../Loading/Loading";
import { validateGuid } from "../../../../Utilities/ValidationHelpers";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";
import { multiFetch } from "../../../../../services/multiFetch";
import TradingAccount from "./CardComponents/TradingAccount";
import AdditionalDetails from "./CardComponents/AdditionalDetails";

const InvestmentDetails = () => {
  const [investmentDetails, setInvestmentDetails] = useState({});
  const [historyVersions, setHistoryVersions] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const { baseUrl } = Config.api;
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["investment_account_id"] = validateGuid(params.investment_account_id);
  }

  const {
    approval_date,
    actual_liquidation_date,
    approved_liquidation_date,
    termination_notice_date,
    fund_group_name,
    omnibus_name,
    share_class_name,
    sales_region,
    client_id,
    trading_account_type,
    service_provider_name,
    sub_account_number,
    trading_account_id,
    invested_without_pa,
    termination_reason,
    exchanged_to,
    account_transferred_to,
    reason_for_change,
    inserted_by,
    inserted_at,
  } = investmentDetails;

  const getDates = (date) => {
    if (date === "") {
      return date;
    }
    let arr = date.split("-");
    let res = `${arr[1]}/${arr[2]}/${arr[0]}`;
    return res;
  };

  const handleVersionChange = async (investment, index) => {
    try {
      setLoadingData(true);
      const investmentIds = [
        `/v1/trading-account?trading-account-id=${investment.trading_account_id}`,
        `/v1/fund-group?fund-group-id=${investment.fund_group_id}`,
        `/v1/sales-region?sales-region-id=${investment.sales_region_id}`,
      ];

      if (investment.termination_code_id) {
        investmentIds.push(`/v1/termination-code?termination-code-id=${investment.termination_code_id}`);
      }

      const investmentData = await multiFetch(investmentIds, accessToken);
      if (investmentData.error) {
        throw new Error(investmentData.requestId);
      }
      const tradingAccount = set_nulls_to_empty_strings(investmentData[0].data[0]);
      const fundGroup = set_nulls_to_empty_strings(investmentData[1].data[0]);
      const salesRegion = set_nulls_to_empty_strings(investmentData[2].data[0]);
      const terminationReason = investmentData[3]
        ? set_nulls_to_empty_strings(investmentData[3].data[0])
        : { termination_reason: "" };

      const tradingAccountIds = [`/v1/account-type?account-type-id=${tradingAccount.account_type_id}`];

      if (!tradingAccount.omnibus_name) {
        tradingAccountIds.push(`/v1/participating-trust?participating-trust-id=${investment.participating_trust_id}`);
      }

      const tradingData = await multiFetch(tradingAccountIds, accessToken);
      if (tradingData.error) {
        throw new Error(tradingData.requestId);
      }
      const accountDetails = set_nulls_to_empty_strings(tradingData[0].data[0]);
      const particpatingTrust = tradingData[1]
        ? set_nulls_to_empty_strings(tradingData[1].data[0])
        : { participating_trust_name: "" };

      let updateData = {
        ...investment,
        fund_group_name: fundGroup.fund_group_name,
        trading_account_type: accountDetails.account_type === "Direct" ? "Direct Traded Account" : "Omnibus",
        sales_region: salesRegion.sales_region,
        trading_account_id: tradingAccount.trading_account_id,
        omnibus_name: tradingAccount.omnibus_name
          ? tradingAccount.omnibus_name
          : particpatingTrust.participating_trust_name,
        termination_reason: terminationReason.termination_reason,
        approval_date: investment.approval_date ? getDates(investment.approval_date) : "",
        actual_liquidation_date: investment.actual_liquidation_date ? getDates(investment.actual_liquidation_date) : "",
        approved_liquidation_date: investment.approved_liquidation_date
          ? getDates(investment.approved_liquidation_date)
          : "",
        termination_notice_date: investment.termination_notice_date ? getDates(investment.termination_notice_date) : "",
        reason_for_change: investment.reason_for_change,
        inserted_by: investment.inserted_by,
        inserted_at: investment.inserted_at,
      };

      setInvestmentDetails(updateData);
      setCurrentIndex(index);
      setLoadingData(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getInvestments = async () => {
    let endpoint = `/v1/investment-account-history?investment-account-id=${
      validatedParams.investment_account_id
    }&inserted-at=${historyTimeNow(new Date())}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  useEffect(() => {
    const setupInvestmentData = async () => {
      try {
        const investments = set_nulls_to_empty_strings(await getInvestments());
        const investment = investments[0];

        const investmentIds = [
          `/v1/trading-account?trading-account-id=${investment.trading_account_id}`,
          `/v1/fund-group?fund-group-id=${investment.fund_group_id}`,
          `/v1/sales-region?sales-region-id=${investment.sales_region_id}`,
        ];

        if (investment.termination_code_id) {
          investmentIds.push(`/v1/termination-code?termination-code-id=${investment.termination_code_id}`);
        }

        const investmentData = await multiFetch(investmentIds, accessToken);
        if (investmentData.error) {
          throw new Error(investmentData.requestId);
        }

        const tradingAccount = set_nulls_to_empty_strings(investmentData[0].data[0]);
        const fundGroup = set_nulls_to_empty_strings(investmentData[1].data[0]);
        const salesRegion = set_nulls_to_empty_strings(investmentData[2].data[0]);
        const terminationReason = investmentData[3]
          ? set_nulls_to_empty_strings(investmentData[3].data[0])
          : { termination_reason: "" };

        const tradingAccountIds = [
          `/v1/account-type?account-type-id=${tradingAccount.account_type_id}`,
          `/v1/service-provider?service-provider-id=${tradingAccount.service_provider_id}`,
        ];

        if (tradingAccount.omnibus_name) {
          tradingAccountIds.push(
            `/v1/linked-investor?participating-trust-id=${investment.participating_trust_id}&trading-account-id=${investment.trading_account_id}`
          );
        } else {
          tradingAccountIds.push(`/v1/participating-trust?participating-trust-id=${investment.participating_trust_id}`);
        }

        const tradingData = await multiFetch(tradingAccountIds, accessToken);
        if (tradingData.error) {
          throw new Error(tradingData.requestId);
        }

        const accountDetails = set_nulls_to_empty_strings(tradingData[0].data[0]);
        const serviceProvider = set_nulls_to_empty_strings(tradingData[1].data[0]);
        const subAccountNumber = tradingData[2].data[0].sub_account_number
          ? set_nulls_to_empty_strings(tradingData[2].data[0])
          : { sub_account_number: "" };
        const particpatingTrust = tradingData[2].data[0].participating_trust_name
          ? set_nulls_to_empty_strings(tradingData[2].data[0])
          : { participating_trust_name: "" };

        let updateData = {
          ...investment,
          fund_group_name: fundGroup.fund_group_name,
          trading_account_type: accountDetails.account_type === "Direct" ? "Direct Traded Account" : "Omnibus",
          sales_region: salesRegion.sales_region,
          trading_account_id: tradingAccount.trading_account_id,
          omnibus_name: tradingAccount.omnibus_name
            ? tradingAccount.omnibus_name
            : particpatingTrust.participating_trust_name,
          service_provider_name: serviceProvider.service_provider_name,
          sub_account_number: subAccountNumber.sub_account_number,
          termination_reason: terminationReason.termination_reason,
          approval_date: investment.approval_date ? getDates(investment.approval_date) : "",
          actual_liquidation_date: investment.actual_liquidation_date
            ? getDates(investment.actual_liquidation_date)
            : "",
          approved_liquidation_date: investment.approved_liquidation_date
            ? getDates(investment.approved_liquidation_date)
            : "",
          termination_notice_date: investment.termination_notice_date
            ? getDates(investment.termination_notice_date)
            : "",
          account_transferred_to: investment.account_transferred_to,
          exchanged_to: investment.exchanged_to,
          invested_without_pa: investment.invested_without_pa,
          reason_for_change: investment.reason_for_change,
          inserted_by: investment.inserted_by,
          inserted_at: investment.inserted_at,
        };

        setInvestmentDetails(updateData);
        setHistoryVersions(investments);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupInvestmentData();
  }, []);

  if (!loadingData) {
    return (
      <div data-testid={`investment-view`}>
        <Row>
          <Col sm={12} lg={3}>
            <h2>Investment Account Details</h2>
          </Col>
          <Col sm={2} lg={2}>
            <button
              data-testid={`participating_trust_button`}
              id={`participating_trust_button`}
              type="button"
              className="btn btn-primary progress-btn"
              onClick={() => {
                navigate(`/trust-details/${validateGuid(investmentDetails.participating_trust_id)}`);
              }}
            >
              Participating Trust Details
            </button>
          </Col>
          <Col sm={1} lg={1}></Col>
          <Col sm={2} lg={2}>
            <button
              data-testid={`edit_button`}
              id={`edit_button`}
              type="button"
              className="btn btn-primary progress-btn"
              onClick={() => {
                navigate(`/investment-details/edit/${validateGuid(validatedParams.investment_account_id)}`);
              }}
            >
              Edit Investment Account
            </button>
          </Col>
          <Col sm={3} lg={4}></Col>
        </Row>
        <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Col sm={5} lg={5}>
            <Row>
              <Col id={"investment_account"} data-testid={"investment_account"}>
                <InvestmentAccount
                  fund_group_name={fund_group_name}
                  share_class={share_class_name}
                  sales_region={sales_region}
                  invested_without_pa={invested_without_pa}
                  client_id={client_id}
                  participating_trust_id={investmentDetails.participating_trust_id}
                />
              </Col>
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Col>
                <TradingAccount
                  omnibus_name={omnibus_name}
                  trading_account_id={trading_account_id}
                  trading_account_type={trading_account_type}
                  service_provider_name={service_provider_name}
                  sub_account_number={sub_account_number}
                  currentIndex={currentIndex}
                ></TradingAccount>
              </Col>
            </Row>
          </Col>
          <Col sm={4} lg={4}>
            <Row>
              <Col id={"investment_dates"} data-testid={"investment_dates"}>
                <InvestmentDates
                  investment_approval_date={approval_date}
                  investment_actual_liquidation_date={actual_liquidation_date}
                  investment_approved_liquidation_date={approved_liquidation_date}
                  investment_termination_date={termination_notice_date}
                />
              </Col>
            </Row>
            <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Col>
                <AdditionalDetails
                  termination_reason={termination_reason}
                  account_transferred_to={account_transferred_to}
                  exchanged_to={exchanged_to}
                ></AdditionalDetails>
              </Col>
            </Row>
          </Col>
          <Col sm={3} lg={3}>
            <Row style={{ height: "100%" }}>
              <Col>
                <Card heading={"History"}>
                  <VersionHistory
                    historyVersions={historyVersions}
                    handleVersionChange={handleVersionChange}
                    currentIndex={currentIndex}
                  ></VersionHistory>
                  <ReasonForChange
                    reason_for_change={reason_for_change}
                    inserted_by={inserted_by}
                    inserted_at={inserted_at}
                  ></ReasonForChange>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default InvestmentDetails;

InvestmentDetails.propTypes = {};
