import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "@pds-react/grid";
import Card from "./../../../Card";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Button from "@pds-react/button";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Loading from "../../../../Loading/Loading";
import Checkbox from "@pds-react/checkbox";
import Input from "@pds-react/input";
import { multiFetch } from "../../../../../services/multiFetch";
import { handleChange, handleSelectChange, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import {
  HandleCheckboxChange,
  HandleSameAsMultiFillCheckBox,
} from "../../../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import {
  getTextAreaStyles,
  Validate,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
  FormatTaxId,
  FormatTaxIdOnLoad,
  validateGuid,
} from "../../../../Utilities/ValidationHelpers";
import selectStyler from "../../../../Utilities/selectStyler";

const TradingAccountDetailsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["trading_account_id"] = validateGuid(params.trading_account_id);
  }
  const initialTradingData = {
    account_type: { label: "", value: "" },
    plan_tax_id: "",
    bin_number: "",
    omnibus_name: "",
    transfer_agent_account_number: "",
    wire_instructions: "",
    fif: false,
    settlement_service_provider: { label: "", value: "" },
    service_provider_same_for_settlement: false,
    service_provider: { label: "", value: "" },
    contact_company: { label: "", value: "" },
    reason_for_change: "",
  };

  const [tradingData, setTradingData] = useState(initialTradingData);
  const [loadingData, setLoadingData] = useState(true);
  const [accountTypeOptions, setAccountTypeOptions] = useState();
  const [serviceProviderOptions, setServiceProviderOptions] = useState();
  const [validationErrors, setValidationError] = useState({});

  const fieldsToValidate = {
    omnibus_name: ValidateRequired,
    service_provider: ValidateRequiredSelect,
    settlement_service_provider: ValidateRequiredSelect,
    reason_for_change: ValidateRequired,
  };

  if (tradingData.account_type.label === "Sub-Account") {
    fieldsToValidate.contact_company = ValidateRequiredSelect;
    fieldsToValidate.plan_tax_id = ValidateRequired;
  }

  const {
    account_type,
    plan_tax_id,
    bin_number,
    omnibus_name,
    transfer_agent_account_number,
    wire_instructions,
    settlement_service_provider,
    fif,
    service_provider_same_for_settlement,
    service_provider,
    contact_company,
    reason_for_change,
  } = tradingData;

  const handleSPChange = (id, e, setData) => {
    if (e === null) {
      e = { value: "", label: "" };
    }
    const value = e.value;
    const label = e.label;
    const tradingNsccNumber = e.tradingNsccNumber;
    const serviceProviderName = e.serviceProviderName;
    setData((data) => ({
      ...data,
      [id]: {
        label: label,
        value: value,
        tradingNsccNumber: tradingNsccNumber,
        serviceProviderName: serviceProviderName,
      },
    }));
  };

  const handleSave = async () => {
    let [formIsValid, errors] = Validate(tradingData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = "/v1/trading-account";

        let trading_data = {};

        trading_data.trading_account_id = params.trading_account_id;
        trading_data.bin_number = bin_number;
        trading_data.wire_instructions = wire_instructions;
        trading_data.fif = fif;
        trading_data.service_provider_id = service_provider.value;
        trading_data.settlement_service_provider_id = settlement_service_provider.value;
        trading_data.reason_for_change = reason_for_change;

        if (account_type.label === "Sub-Account") {
          trading_data.account_type_id = account_type.value;
          trading_data.omnibus_name = omnibus_name;
          trading_data.contact_company_id = contact_company.value;
          trading_data.plan_tax_id = plan_tax_id;
        }

        if (!validatedParams.trading_account_id) {
          let formattedData = JSON.stringify(trading_data);
          let response = await fetchService(baseUrl + endpoint, {
            method: "POST",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/trading-account-details/${resp.data.trading_account_id}`);
        } else {
          let formattedData = JSON.stringify(trading_data);
          let response = await fetchService(baseUrl + endpoint, {
            method: "PUT",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/trading-account-details/${validatedParams.trading_account_id}`);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const getTradingAccountData = async () => {
    let endpoint = `/v1/trading-account?trading-account-id=${validatedParams.trading_account_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data[0];
  };

  const getInvestmentAccountData = async (tradingAccountId) => {
    let endpoint = `/v1/investment-account?trading-account-id=${tradingAccountId}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    return resp.data[0];
  };

  const getParticipatingTrustData = async (participatingTrustId) => {
    let endpoint = `/v1/participating-trust?participating-trust-id=${participatingTrustId}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    return resp.data[0];
  };

  const getAccountTypeOptions = async () => {
    let endpoint = `/v1/account-type`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  const getOtherData = async (ids) => {
    try {
      let urls = [
        `/v1/service-provider?service-provider-id=${ids.serviceProviderId}`,
        `/v1/service-provider?service-provider-id=${ids.settlementServiceProviderId}`,
        `/v1/account-type?account-type-id=${ids.tradingAccountTypeId}`,
      ];
      if (ids.contactCompanyId) {
        urls.push(`/v1/contact-company?contact-company-id=${ids.contactCompanyId}`);
      }

      let multiData = await multiFetch(urls, accessToken);
      if (multiData.error) {
        throw new Error(multiData.requestId);
      }
      if (multiData[3]) {
        return {
          service_provider: multiData[0].data[0],
          settlement_service_provider: multiData[1].data[0],
          account_type: multiData[2].data[0],
          contact_company: multiData[3].data[0],
        };
      } else {
        return {
          service_provider: multiData[0].data[0],
          settlement_service_provider: multiData[1].data[0],
          account_type: multiData[2].data[0],
        };
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const buildAccountTypeOptions = (options) => {
    return options.map((opt) => {
      if (opt.account_type.toUpperCase() === "DIRECT") {
        return { value: opt.account_type_id, label: "Direct Traded Account" };
      } else {
        return { value: opt.account_type_id, label: "Sub-Account" };
      }
    });
  };

  const buildServiceProviderOptions = (options) => {
    return options.map((opt) => {
      return {
        tradingNsccNumber: opt.trading_nscc_number,
        label: opt.trading_nscc_number
          ? `[${opt.trading_nscc_number}] - ${opt.service_provider_name}`
          : opt.service_provider_name,
        serviceProviderName: opt.service_provider_name,
        value: opt.service_provider_id,
      };
    });
  };

  const buildCompanyOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_company_id,
      label: opt.company_name,
      ...opt,
    }));
  };

  const loadSponsorCompanies = async (inputValue, callback) => {
    const { baseUrl } = Config.api;

    const endpoint = `/v1/contact-company?company-name=${inputValue}`;

    if (inputValue.length > 2) {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        const requestId = resp["requestId"];
        throw new Error(requestId);
      }
      return callback(buildCompanyOptions(resp.data));
    } else {
      return callback([]);
    }
  };

  const formatSpLabel = ({ tradingNsccNumber, serviceProviderName }) => (
    <div>
      <p>
        <span style={{ color: "#0076CF" }}>{tradingNsccNumber}</span> |{" "}
        <span style={{ color: "#00C4D9" }}>{serviceProviderName}</span>
      </p>
    </div>
  );

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let tradingAccountData = await getTradingAccountData();

        let participatingTrustName, participatingTrustPlanTaxId;

        if (!tradingAccountData.omnibus_name) {
          let investmentAccountData = await getInvestmentAccountData(tradingAccountData.trading_account_id);
          let participatingTrustData = await getParticipatingTrustData(investmentAccountData.participating_trust_id);

          participatingTrustName = participatingTrustData.participating_trust_name;
          participatingTrustPlanTaxId = participatingTrustData.plan_tax_id ? participatingTrustData.plan_tax_id : "";
        }

        let otherIds = {
          serviceProviderId: tradingAccountData.service_provider_id,
          settlementServiceProviderId: tradingAccountData.settlement_service_provider_id,
          tradingAccountTypeId: tradingAccountData.account_type_id,
        };

        if (tradingAccountData.contact_company_id) {
          otherIds.contactCompanyId = tradingAccountData.contact_company_id;
        }

        let otherData = await getOtherData(otherIds);

        let updateData = {};

        tradingAccountData = set_nulls_to_empty_strings(tradingAccountData);
        let serviceProviderData = set_nulls_to_empty_strings(otherData.service_provider);
        let settlementServiceProviderData = set_nulls_to_empty_strings(otherData.settlement_service_provider);
        let accountTypeData = set_nulls_to_empty_strings(otherData.account_type);
        let contactCompanyData;
        if (otherData.contact_company) {
          contactCompanyData = set_nulls_to_empty_strings(otherData.contact_company);
        }

        updateData.omnibus_name = tradingAccountData.omnibus_name
          ? tradingAccountData.omnibus_name
          : participatingTrustName;
        updateData.account_type =
          accountTypeData.account_type.toUpperCase() === "OMNIBUS"
            ? { label: "Sub-Account", value: accountTypeData.account_type_id }
            : { label: "Direct Traded Account", value: accountTypeData.account_type_id };
        updateData.fif = tradingAccountData.fif;
        updateData.plan_tax_id = FormatTaxIdOnLoad(
          accountTypeData.account_type.toUpperCase() === "OMNIBUS"
            ? tradingAccountData.plan_tax_id
            : participatingTrustPlanTaxId
        );
        updateData.bin_number = tradingAccountData.bin_number;
        updateData.transfer_agent_account_number = tradingAccountData.transfer_agent_account_number;
        updateData.wire_instructions = tradingAccountData.wire_instructions;
        updateData.settlement_service_provider = {
          label: settlementServiceProviderData.service_provider_name,
          value: settlementServiceProviderData.service_provider_id,
          tradingNsccNumber: settlementServiceProviderData.trading_nscc_number,
          serviceProviderName: settlementServiceProviderData.service_provider_name,
        };
        updateData.service_provider = {
          label: serviceProviderData.service_provider_name,
          value: serviceProviderData.service_provider_id,
          tradingNsccNumber: serviceProviderData.trading_nscc_number,
          serviceProviderName: serviceProviderData.service_provider_name,
        };
        updateData.service_provider_same_for_settlement =
          serviceProviderData.service_provider_name === settlementServiceProviderData.service_provider_name;
        updateData.contact_company = contactCompanyData
          ? { label: contactCompanyData.company_name, value: contactCompanyData.contact_company_id }
          : { label: "", value: "" };
        updateData.reason_for_change = "";

        setTradingData(updateData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    if (Object.keys(validatedParams).length !== 0) {
      setupAPIData();
    } else {
      const setNewTradingAccountData = async () => {
        try {
          const accountTypeOptions = await getAccountTypeOptions();
          for (const index in accountTypeOptions) {
            if (accountTypeOptions[index].account_type.toUpperCase() === "OMNIBUS") {
              setTradingData((tradingData) => ({
                ...tradingData,
                account_type: {
                  label: "Sub-Account",
                  value: accountTypeOptions[index].account_type_id,
                },
              }));
            }
          }
        } catch (error) {
          navigate("/error", { state: { error } });
        }
      };
      setNewTradingAccountData();
      setLoadingData(false);
    }
  }, []);

  useEffect(() => {
    const getOptions = async () => {
      try {
        let urls = ["/v1/service-provider", "/v1/account-type"];
        let results = await multiFetch(urls, accessToken);
        if (results.error) {
          throw new Error(results.requestId);
        }
        if (results[0].data.length === 0) {
          throw new Error(results.requestId);
        }
        if (results[1].data.length === 0) {
          throw new Error(results.requestId);
        }
        setServiceProviderOptions(buildServiceProviderOptions(results[0].data));
        setAccountTypeOptions(buildAccountTypeOptions(results[1].data));
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };

    getOptions();
  }, []);

  useEffect(() => {
    validationUseEffectHelper("omnibus_name", omnibus_name, fieldsToValidate, validationErrors, setValidationError);
  }, [omnibus_name]);

  useEffect(() => {
    validationUseEffectHelper("plan_tax_id", plan_tax_id, fieldsToValidate, validationErrors, setValidationError);
  }, [plan_tax_id]);

  useEffect(() => {
    if (account_type.label === "Sub-Account") {
      validationUseEffectHelper(
        "contact_company",
        contact_company,
        fieldsToValidate,
        validationErrors,
        setValidationError
      );
    }
  }, [contact_company]);

  useEffect(() => {
    validationUseEffectHelper(
      "service_provider",
      service_provider,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [service_provider]);

  useEffect(() => {
    validationUseEffectHelper(
      "settlement_service_provider",
      settlement_service_provider,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [settlement_service_provider]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  if (!loadingData) {
    return (
      <div>
        <Col sm={12}>
          <Row>
            <Col sm={12}>
              <h3>
                {Object.keys(validatedParams).length !== 0
                  ? "Edit Trading Account Details"
                  : "New Trading Account Details"}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card heading={"Trading Account Detail"}>
                <Row>
                  <Col sm={6} lg={3} data-testid={"account_type"}>
                    <label>Type</label>
                    <Select
                      isClearable={true}
                      datatestid={`account_type`}
                      id={`account_type`}
                      inputId={`account_type_input`}
                      styles={selectStyler({ validationErrorsValue: validationErrors["account_type"], disabled: true })}
                      options={accountTypeOptions}
                      value={account_type["label"] !== "" ? account_type : "undefined"}
                      isDisabled={true}
                    ></Select>
                  </Col>
                  <Col sm={6} lg={9}>
                    <Input
                      id={`omnibus_name`}
                      data-testid={`omnibus_name`}
                      type="text"
                      label="Trading Account Name"
                      value={omnibus_name}
                      onChange={(e) => {
                        handleChange(e, "omnibus_name", setTradingData);
                      }}
                      errorMessage={validationErrors["omnibus_name"]}
                      disabled={account_type.label !== "Sub-Account"}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                    <label htmlFor="contact_company">Company Name</label>
                    <div data-testid={`contact_company`} id={`contact_company`}>
                      <AsyncSelect
                        name="contact_company"
                        inputId="contact_company"
                        value={contact_company["label"] !== "" ? contact_company : "undefined"}
                        placeholder={"Search..."}
                        styles={
                          account_type.label === "Sub-Account"
                            ? selectStyler({ validationErrorsValue: validationErrors["contact_company"] })
                            : selectStyler({ disabled: true })
                        }
                        loadOptions={loadSponsorCompanies}
                        onChange={(e) => {
                          handleSelectChange("contact_company", e, setTradingData);
                        }}
                        isDisabled={account_type.label !== "Sub-Account"}
                      ></AsyncSelect>
                      <ValidationErrorMessage
                        paramId={"contact_company"}
                        validationError={validationErrors["contact_company"]}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Input
                      id={`plan_tax_id`}
                      data-testid={`plan_tax_id`}
                      type="text"
                      label="Tax ID"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={plan_tax_id}
                      onChange={(e) => {
                        FormatTaxId(e);
                        handleChange(e, "plan_tax_id", setTradingData);
                      }}
                      errorMessage={validationErrors["plan_tax_id"]}
                      disabled={account_type.label !== "Sub-Account"}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Input
                      id={`bin_number`}
                      data-testid={`bin_number`}
                      type="text"
                      label="Bank Identification Number (BIN)"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      value={bin_number}
                      onChange={(e) => {
                        handleChange(e, "bin_number", setTradingData);
                      }}
                    ></Input>
                  </Col>
                  <Col sm={6}>
                    {Object.keys(validatedParams).length !== 0 ? (
                      <Input
                        id={`transfer_agent_account_number`}
                        data-testid={`transfer_agent_account_number`}
                        type="text"
                        label="Transfer Agent Account Number"
                        className="util-margin-top-sm-15 util-margin-top-xs-30"
                        value={transfer_agent_account_number}
                        onChange={(e) => {
                          handleChange(e, "transfer_agent_account_number", setTradingData);
                        }}
                        disabled={true}
                        errorMessage={validationErrors["transfer_agent_account_number"]}
                      ></Input>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <label>Wire Instructions</label>
                    <div id={"wire_instructions"}>
                      <textarea
                        data-testid={"wire_instructions"}
                        style={{
                          resize: "none",
                          height: 200,
                          width: "-webkit-fill-available",
                        }}
                        value={wire_instructions}
                        onChange={(e) => {
                          handleChange(e, "wire_instructions", setTradingData);
                        }}
                      ></textarea>
                    </div>
                    <Checkbox
                      data-testid={`fif`}
                      id={`fif`}
                      label="Fund in/of Funds (0bps) Trading Account"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      defaultChecked={fif}
                      onChange={(e) => {
                        HandleCheckboxChange(e, "fif", tradingData, setTradingData);
                      }}
                      errorMessage={""}
                    ></Checkbox>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Service Provider Detail"}>
                <Row>
                  <Col sm={12} lg={9}>
                    <label>Service Provider</label>
                    <div data-testid={"service_provider"}>
                      <Select
                        id={"service_provider"}
                        options={serviceProviderOptions}
                        formatOptionLabel={formatSpLabel}
                        value={service_provider["label"] !== "" ? service_provider : "undefined"}
                        onChange={(e) => {
                          handleSPChange("service_provider", e, setTradingData);
                          if (e === null || e.label !== settlement_service_provider.label) {
                            setTradingData((tradingData) => ({
                              ...tradingData,
                              service_provider_same_for_settlement: false,
                            }));
                          }
                        }}
                        isClearable={true}
                        styles={selectStyler({
                          validationErrorsValue: validationErrors["service_provider"],
                        })}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"service_provider"}
                        validationError={validationErrors["service_provider"]}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={9}>
                    <Checkbox
                      data-testid={"service_provider_same_for_settlement"}
                      id={`service_provider_same_for_settlement`}
                      label="Service Provider is the same for Settlement"
                      className="util-margin-top-sm-15 util-margin-top-xs-30"
                      checked={service_provider_same_for_settlement}
                      onChange={(e) => {
                        HandleSameAsMultiFillCheckBox(
                          "service_provider_same_for_settlement",
                          e.target.checked,
                          { service_provider: "settlement_service_provider" },
                          tradingData,
                          setTradingData
                        );
                      }}
                    ></Checkbox>
                  </Col>
                </Row>
                <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Col sm={12} lg={9}>
                    <label>Service Provider for Settlement</label>
                    <div data-testid={"settlement_service_provider"}>
                      <Select
                        id={"settlement_service_provider"}
                        options={serviceProviderOptions}
                        formatOptionLabel={formatSpLabel}
                        value={settlement_service_provider["label"] !== "" ? settlement_service_provider : "undefined"}
                        onChange={(e) => {
                          handleSPChange("settlement_service_provider", e, setTradingData);
                          if (e === null || e.label !== service_provider.label) {
                            setTradingData((tradingData) => ({
                              ...tradingData,
                              service_provider_same_for_settlement: false,
                            }));
                          }
                        }}
                        isClearable={true}
                        styles={selectStyler({
                          validationErrorsValue: validationErrors["settlement_service_provider"],
                        })}
                        isDisabled={service_provider_same_for_settlement}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"settlement_service_provider"}
                        validationError={validationErrors["settlement_service_provider"]}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Notes"}>
                <Row>
                  <Col sm={12}>
                    <label>Reason For Change</label>
                    <div id={"notes"}>
                      <textarea
                        data-testid={"notes"}
                        style={getTextAreaStyles(validationErrors["reason_for_change"])}
                        value={reason_for_change}
                        onChange={(e) => {
                          handleChange(e, "reason_for_change", setTradingData);
                        }}
                      ></textarea>
                    </div>
                    <ValidationErrorMessage
                      paramId={"reason_for_change"}
                      validationError={validationErrors["reason_for_change"]}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button
                style={{ marginRight: 15 }}
                data-testid={"save_button"}
                id={"save_button"}
                onClick={() => {
                  handleSave();
                }}
              >
                Save Changes
              </Button>
              <Button
                data-testid={"discard_button"}
                id={"discard_button"}
                onClick={() => {
                  if (Object.keys(validatedParams).length !== 0) {
                    navigate(`/trading-account-details/${validatedParams.trading_account_id}`);
                  } else {
                    navigate(`/service-provider-trading-account-management`);
                  }
                }}
              >
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default TradingAccountDetailsEdit;
