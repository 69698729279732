import React, { useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import "../NewParticipationAgreement.scss";
import { jsPDF } from "jspdf";
import { Navigate, useLocation } from "react-router-dom";

function Confirmation() {
  const location = useLocation();
  const [formmatedCusips, setFormattedCusips] = useState([]);
  const [pdfCusips, setPdfCusips] = useState([]);
  let date = new Date().toLocaleDateString();

  const generatePDF = () => {
    const pdf = new jsPDF();
    pdf.setDrawColor(3, 95, 164);
    pdf.setFillColor(3, 95, 164);
    pdf.rect(0, 0, 220, 15, "FD");
    const img = new Image();
    img.src = "https://www.principalcdn.com/css/horizon/v2/logo-company-name-white.png";
    img.onload = () => {
      pdf.addImage(img, "PNG", 3, 3);
    };
    pdf.setFontSize(20);
    pdf.text(`New Participating Trust - Effective ${date}`, 3, 25);
    pdf.setFontSize(12);
    pdf.text(`Participating Trust Name: ${location.state.trust_info.participating_trust_name}`, 3, 35);
    pdf.text(
      `Plan Sponsor Name: ${
        location.state.plan_sponsor.company.company_name.label
          ? location.state.plan_sponsor.company.company_name.label
          : `No Plan Sponsor Chosen`
      }`,
      3,
      45
    );
    pdf.line(3, 50, 200, 50);
    pdf.setFontSize(20);
    pdf.text("Service Providers", 3, 60);
    pdf.setFontSize(12);
    pdf.text(`Trading Service Provider: ${location.state.service_provider.label}`, 3, 70);
    pdf.text(
      `NSCC #: ${
        location.state.service_provider.trading_nscc_number ? location.state.service_provider.trading_nscc_number : ""
      }`,
      3,
      80
    );
    pdf.text(`Settlement Service Provider: ${location.state.service_provider_for_settlement.label}`, 3, 90);
    pdf.text(
      `NSCC #: ${
        location.state.service_provider_for_settlement.trading_nscc_number
          ? location.state.service_provider_for_settlement.trading_nscc_number
          : ""
      }`,
      3,
      100
    );
    pdf.line(3, 105, 200, 105);
    pdf.setFontSize(20);
    pdf.text("Investments", 3, 115);
    pdf.setFontSize(12);
    let space = 115;
    let newSpace = 0;
    if (location.state.investments.length > 3) {
      pdf.addPage();
      pdf.setPage(1);
    }
    location.state.investments.forEach((investment, index) => {
      if (index <= 2) {
        space = space + 10;
        pdf.text(`Portfolio: ${investment.fund.label}`, 3, space);
        space = space + 10;
        pdf.text(`Share Class: ${investment.share_class.label}`, 3, space);
        space = space + 10;
        if (pdfCusips[index].length === 2) {
          pdf.text(`CUSIP: ${pdfCusips[index][0]}`, 3, space);
          space = space + 10;
          pdf.text(`${pdfCusips[index][1]}`, 3, space);
        } else {
          pdf.text(`CUSIP: ${pdfCusips[index][0]}`, 3, space);
        }
        space = space + 10;
        pdf.text(
          location.state.data.contract_id
            ? `Trading Account: ${location.state.data.contract_id}`
            : `Service Provider Sub Account: ${location.state.data.service_provider_sub_acct}`,
          3,
          space
        );
        pdf.line(3, space + 3, 90, space + 3);
      } else {
        pdf.setPage(2);
        newSpace = newSpace + 10;
        pdf.text(`Portfolio: ${investment.fund.label}`, 3, newSpace);
        newSpace = newSpace + 10;
        pdf.text(`Share Class: ${investment.share_class.label}`, 3, newSpace);
        newSpace = newSpace + 10;
        if (pdfCusips[index].length === 2) {
          pdf.text(`CUSIP: ${pdfCusips[index][0]}`, 3, newSpace);
          newSpace = newSpace + 10;
          pdf.text(`${pdfCusips[index][1]}`, 3, newSpace);
        } else {
          pdf.text(`CUSIP: ${pdfCusips[index][0]}`, 3, newSpace);
        }
        newSpace = newSpace + 10;
        pdf.text(
          location.state.data.contract_id
            ? `Trading Account: ${location.state.data.contract_id}`
            : `Service Provider Sub Account: ${location.state.data.service_provider_sub_acct}`,
          3,
          newSpace
        );
        if (index < location.state.investments.length - 1) {
          pdf.line(3, newSpace + 3, 90, newSpace + 3);
        }
      }
    });
    pdf.save("confirmation.pdf");
  };

  useEffect(() => {
    console.log(location.state.data);
    if (location.state) {
      if (location.state.data.cusips.length > 0) {
        let formattedCusipsTemp = [];
        let pdfCusipsTemp = [];
        let passedCusips = location.state.data.cusips;
        for (let i = 0; i < passedCusips.length; i++) {
          formattedCusipsTemp.push(passedCusips[i].cusips.toString().replaceAll(",", ", "));
          if (passedCusips[i].cusips.length > 5) {
            let subset1 = passedCusips[i].cusips.slice(0, 5).toString().replaceAll(",", ", ");
            let subset2 = passedCusips[i].cusips.slice(5).toString().replaceAll(",", ", ");
            pdfCusipsTemp.push([subset1, subset2]);
          } else {
            pdfCusipsTemp.push([passedCusips[i].cusips.toString().replaceAll(",", ", ")]);
          }
        }
        setFormattedCusips(formattedCusipsTemp);
        setPdfCusips(pdfCusipsTemp);
      }
    }
  }, []);

  if (location.state) {
    return (
      <div>
        <Row>
          <Col style={{ textAlign: "right" }}>
            <button
              data-testid={"pdf-button"}
              type="button"
              onClick={generatePDF}
              className="btn btn-primary progress-btn"
            >
              Save
            </button>
          </Col>
        </Row>
        <div id={"confirmation"}>
          <Row>
            <Col sm={12} lg={12}>
              <h4 data-testId={"part_trust"}>{`New Participating Trust - Effective ${date}`}</h4>
            </Col>
            <Col>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">Participating Trust Name:</label>
                  <span>{location.state.trust_info.participating_trust_name}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">Plan Sponsor Name</label>
                  {location.state.plan_sponsor.company.company_name.label !== "" ? (
                    <span>{location.state.plan_sponsor.company.company_name.label}</span>
                  ) : (
                    <span>No Plan Sponsor Chosen</span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col sm={12} lg={12}>
              <h4 data-testId={"trading_account"}>Trading Account</h4>
            </Col>
            <Col>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">Trading Account Name:</label>
                  <span>
                    {location.state.trading_account_name.label
                      ? location.state.trading_account_name.label
                      : location.state.trust_info.participating_trust_name}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">Trading Account #:</label>
                  <span>
                    {location.state.service_provider_sub_acct
                      ? location.state.service_provider_sub_acct
                      : location.state.data.contract_id}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">Trading Service Provider:</label>
                  <span>{location.state.service_provider.label}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">NSCC #:</label>
                  <span>
                    {location.state.service_provider.trading_nscc_number
                      ? location.state.service_provider.trading_nscc_number
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">Settlement Service Provider</label>
                  <span>{location.state.service_provider_for_settlement.label}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="util-padding-top-10 util-margin-right-15">NSCC #:</label>
                  <span>
                    {location.state.service_provider_for_settlement.trading_nscc_number
                      ? location.state.service_provider_for_settlement.trading_nscc_number
                      : ""}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col sm={12} lg={12}>
              <h4>Investments</h4>
            </Col>
            {location.state.investments.map((investment, index) => (
              <Col key={index} sm={12} lg={12}>
                <Row>
                  <Col>
                    <label className="util-padding-top-10 util-margin-right-15">{`Investment ${index + 1}`}</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="util-padding-top-10 util-margin-right-15">Portfolio:</label>
                    <span>{investment.fund.label}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="util-padding-top-10 util-margin-right-15">Share Class:</label>
                    <span>{investment.share_class.label}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="util-padding-top-10 util-margin-right-15">CUSIPs:</label>
                    <span>{formmatedCusips[index] ? formmatedCusips[index] : ""}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="util-padding-top-10 util-margin-right-15">
                      {location.state.data.contract_id ? "Trading Account #" : "Service Provider Sub Account #"}
                    </label>
                    <span>
                      {location.state.data.contract_id
                        ? location.state.data.contract_id
                        : location.state.data.service_provider_sub_acct}
                    </span>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/not-found" replace />;
  }
}

export default Confirmation;
