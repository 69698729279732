import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "@pds-react/grid";
import Card from "./../../../Card";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Loading from "../../../../Loading/Loading";
import TradingAccountDetail from "./CardComponents/TradingAccountDetail";
import ServiceProviderDetail from "./CardComponents/ServiceProviderDetail";
import { multiFetch } from "../../../../../services/multiFetch";
import { set_nulls_to_empty_strings, historyTimeNow } from "../../../../Utilities/FormHelpers";
import { FormatTaxIdOnLoad, validateGuid } from "../../../../Utilities/ValidationHelpers";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";

const TradingAccountDetails = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);

  const initialTradingData = {
    participating_trust_id: "",
    account_type: "",
    plan_tax_id: "",
    bin_number: "",
    omnibus_name: "",
    transfer_agent_account_number: "",
    wire_instructions: "",
    fif: false,
    settlement_service_provider: "",
    settlement_service_provider_id: "",
    service_provider: "",
    service_provider_id: "",
    contact_company: "",
    reason_for_change: "",
  };
  const [tradingData, setTradingData] = useState(initialTradingData);
  const [historyVersions, setHistoryVersions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["trading_account_id"] = validateGuid(params.trading_account_id);
  }
  const {
    omnibus_name,
    participating_trust_id,
    account_type,
    plan_tax_id,
    bin_number,
    transfer_agent_account_number,
    wire_instructions,
    fif,
    settlement_service_provider,
    settlement_service_provider_id,
    service_provider,
    service_provider_id,
    contact_company,
    reason_for_change,
    inserted_by,
    inserted_at,
  } = tradingData;

  const handleVersionChange = async (tradingAccountData, index) => {
    try {
      setLoadingData(true);
      let participatingTrustName, participatingTrustId, participatingPlanTaxId;

      if (!tradingAccountData.omnibus_name) {
        let investmentAccountData = await getInvestmentAccountData(tradingAccountData.trading_account_id);
        let participatingTrustData = await getParticipatingTrustData(investmentAccountData.participating_trust_id);
        participatingTrustName = participatingTrustData.participating_trust_name;
        participatingTrustId = participatingTrustData.participating_trust_id;
        participatingPlanTaxId = participatingTrustData.plan_tax_id ? participatingTrustData.plan_tax_id : "";
      }
      let otherIds = {
        serviceProviderId: tradingAccountData.service_provider_id,
        settlementServiceProviderId: tradingAccountData.settlement_service_provider_id,
        tradingAccounttypeId: tradingAccountData.account_type_id,
        contactCompanyId: tradingAccountData.contact_company_id,
      };
      let otherData = await getOtherData(otherIds);

      let updateData = {};

      tradingAccountData = set_nulls_to_empty_strings(tradingAccountData);
      let serviceProviderData = set_nulls_to_empty_strings(otherData.service_provider);
      let settlementServiceProviderData = set_nulls_to_empty_strings(otherData.settlement_service_provider);
      let accountTypeData = set_nulls_to_empty_strings(otherData.account_type);
      let contactCompanyData;
      if (otherData.contact_company) {
        contactCompanyData = set_nulls_to_empty_strings(otherData.contact_company);
      }
      updateData.omnibus_name = tradingAccountData.omnibus_name
        ? tradingAccountData.omnibus_name
        : participatingTrustName;
      updateData.participating_trust_id = participatingTrustId;
      updateData.account_type =
        accountTypeData.account_type.toUpperCase() === "DIRECT"
          ? "Direct Traded Account"
          : accountTypeData.account_type;
      updateData.plan_tax_id = FormatTaxIdOnLoad(
        accountTypeData.account_type.toUpperCase() === "OMNIBUS"
          ? tradingAccountData.plan_tax_id
          : participatingPlanTaxId
      );
      updateData.bin_number = tradingAccountData.bin_number;
      updateData.transfer_agent_account_number = tradingAccountData.transfer_agent_account_number;
      updateData.wire_instructions = tradingAccountData.wire_instructions;
      updateData.fif = tradingAccountData.fif;
      updateData.settlement_service_provider = settlementServiceProviderData.service_provider_name;
      updateData.settlement_service_provider_id = settlementServiceProviderData.service_provider_id;
      updateData.service_provider = serviceProviderData.service_provider_name;
      updateData.service_provider_id = serviceProviderData.service_provider_id;
      updateData.contact_company = contactCompanyData ? contactCompanyData.company_name : "";
      updateData.reason_for_change = tradingAccountData.reason_for_change;
      updateData.inserted_by = tradingAccountData.inserted_by;
      updateData.inserted_at = tradingAccountData.inserted_at;
      setTradingData(updateData);
      setCurrentIndex(index);
      setLoadingData(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getTradingAccountData = async () => {
    let endpoint = `/v1/trading-account-history?trading-account-id=${
      validatedParams.trading_account_id
    }&inserted-at=${historyTimeNow(new Date())}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      const requestId = resp["requestId"];
      throw new Error(requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data;
  };

  const getOtherData = async (ids) => {
    try {
      let urls = [
        `/v1/service-provider?service-provider-id=${ids.serviceProviderId}`,
        `/v1/service-provider?service-provider-id=${ids.settlementServiceProviderId}`,
        `/v1/account-type?account-type-id=${ids.tradingAccounttypeId}`,
      ];
      if (ids.contactCompanyId) {
        urls.push(`/v1/contact-company?contact-company-id=${ids.contactCompanyId}`);
      }

      let multiData = await multiFetch(urls, accessToken);
      if (multiData.error) {
        throw new Error(multiData.requestId);
      }
      if (multiData[3]) {
        return {
          service_provider: multiData[0].data[0],
          settlement_service_provider: multiData[1].data[0],
          account_type: multiData[2].data[0],
          contact_company: multiData[3].data[0],
        };
      } else {
        return {
          service_provider: multiData[0].data[0],
          settlement_service_provider: multiData[1].data[0],
          account_type: multiData[2].data[0],
        };
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getInvestmentAccountData = async (tradingAccountId) => {
    let endpoint = `/v1/investment-account?trading-account-id=${tradingAccountId}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    return resp.data[0];
  };

  const getParticipatingTrustData = async (participatingTrustId) => {
    let endpoint = `/v1/participating-trust?participating-trust-id=${participatingTrustId}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    return resp.data[0];
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let tradingAccountsData = await getTradingAccountData();
        let participatingTrustName, participatingTrustId, participatingPlanTaxId;

        let tradingAccountData = tradingAccountsData[0];

        if (!tradingAccountData.omnibus_name) {
          let investmentAccountData = await getInvestmentAccountData(tradingAccountData.trading_account_id);
          let participatingTrustData = await getParticipatingTrustData(investmentAccountData.participating_trust_id);
          participatingTrustName = participatingTrustData.participating_trust_name;
          participatingTrustId = participatingTrustData.participating_trust_id;
          participatingPlanTaxId = participatingTrustData.plan_tax_id ? participatingTrustData.plan_tax_id : "";
        }
        let otherIds = {
          serviceProviderId: tradingAccountData.service_provider_id,
          settlementServiceProviderId: tradingAccountData.settlement_service_provider_id,
          tradingAccounttypeId: tradingAccountData.account_type_id,
          contactCompanyId: tradingAccountData.contact_company_id,
        };
        let otherData = await getOtherData(otherIds);

        let updateData = {};

        tradingAccountData = set_nulls_to_empty_strings(tradingAccountData);
        let serviceProviderData = set_nulls_to_empty_strings(otherData.service_provider);
        let settlementServiceProviderData = set_nulls_to_empty_strings(otherData.settlement_service_provider);
        let accountTypeData = set_nulls_to_empty_strings(otherData.account_type);
        let contactCompanyData;
        if (otherData.contact_company) {
          contactCompanyData = set_nulls_to_empty_strings(otherData.contact_company);
        }
        updateData.omnibus_name = tradingAccountData.omnibus_name
          ? tradingAccountData.omnibus_name
          : participatingTrustName;
        updateData.participating_trust_id = participatingTrustId;
        updateData.account_type =
          accountTypeData.account_type.toUpperCase() === "DIRECT"
            ? "Direct Traded Account"
            : accountTypeData.account_type;
        updateData.plan_tax_id = FormatTaxIdOnLoad(
          accountTypeData.account_type.toUpperCase() === "OMNIBUS"
            ? tradingAccountData.plan_tax_id
            : participatingPlanTaxId
        );
        updateData.bin_number = tradingAccountData.bin_number;
        updateData.transfer_agent_account_number = tradingAccountData.transfer_agent_account_number;
        updateData.wire_instructions = tradingAccountData.wire_instructions;
        updateData.fif = tradingAccountData.fif;
        updateData.settlement_service_provider = settlementServiceProviderData.service_provider_name;
        updateData.settlement_service_provider_id = settlementServiceProviderData.service_provider_id;
        updateData.service_provider = serviceProviderData.service_provider_name;
        updateData.service_provider_id = serviceProviderData.service_provider_id;
        updateData.contact_company = contactCompanyData ? contactCompanyData.company_name : "";
        updateData.reason_for_change = tradingAccountData.reason_for_change;
        updateData.inserted_by = tradingAccountData.inserted_by;
        updateData.inserted_at = tradingAccountData.inserted_at;

        setTradingData(updateData);
        setHistoryVersions(tradingAccountsData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col sm={12}>
          <Row>
            <Col sm={5}>
              <h3>Trading Account Details</h3>
            </Col>
            <Col>
              <button
                data-testid={"edit_button"}
                id={"edit_button"}
                type="button"
                className="btn btn-primary progress-btn"
                onClick={() => {
                  navigate(`/trading-account-details/edit/${validateGuid(validatedParams.trading_account_id)}`);
                }}
              >
                Edit
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Row>
                <Col sm={12}>
                  <TradingAccountDetail
                    participating_trust_id={participating_trust_id}
                    account_type={account_type}
                    contact_company={contact_company}
                    plan_tax_id={plan_tax_id}
                    fif={fif}
                    bin_number={bin_number}
                    omnibus_name={omnibus_name}
                    transfer_agent_account_number={transfer_agent_account_number}
                    wire_instructions={wire_instructions}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <ServiceProviderDetail
                    service_provider={service_provider}
                    service_provider_id={service_provider_id}
                    settlement_service_provider={settlement_service_provider}
                    settlement_service_provider_id={settlement_service_provider_id}
                  />
                </Col>
              </Row>
              {currentIndex === 0 ? (
                <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Col sm={12}>
                    <Card heading={"Reporting Links"}>
                      <Row>
                        <Col sm={12}>
                          <a id={"trading_account_balance_link"} href="/">
                            Trading Account Balance
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                          <a id={"trading_account_transaction_link"} href="/">
                            Trading Account Transactions
                          </a>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={3}>
              <Row style={{ height: "100%" }}>
                <Col sm={12}>
                  <Card heading={"History"}>
                    <VersionHistory
                      historyVersions={historyVersions}
                      handleVersionChange={handleVersionChange}
                      currentIndex={currentIndex}
                    ></VersionHistory>
                    <ReasonForChange
                      reason_for_change={reason_for_change}
                      inserted_by={inserted_by}
                      inserted_at={inserted_at}
                    ></ReasonForChange>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default TradingAccountDetails;
