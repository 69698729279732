import React from "react";
import "./NewParticipationAgreement.scss";
import PropTypes from "prop-types";

function ParticipationAgreementNav({ step }) {
  const { id } = step;
  return (
    <div data-testid="ParticipationAgreementNav" className="pa-nav-container">
      <div className={id === "trust-info" ? "pa-nav-div-current pa-nav-top-child" : "pa-nav-div pa-nav-top-child"}>
        <p className={id === "trust-info" ? "pa-current-step" : "pa-default-step"}>Trust Info</p>
      </div>
      <div
        className={id === "plan-sponsor" ? "pa-nav-div-current pa-nav-middle-child" : "pa-nav-div pa-nav-middle-child"}
      >
        <p className={id === "plan-sponsor" ? "pa-current-step" : "pa-default-step"}>Plan Sponsor</p>
      </div>
      <div
        className={
          id === "fiduciary-info" ? "pa-nav-div-current pa-nav-middle-child" : "pa-nav-div pa-nav-middle-child"
        }
      >
        <p className={id === "fiduciary-info" ? "pa-current-step" : "pa-default-step"}>Fiduciary Info</p>
      </div>
      <div
        className={
          id === "trading-account" ? "pa-nav-div-current pa-nav-middle-child" : "pa-nav-div pa-nav-middle-child"
        }
      >
        <p className={id === "trading-account" ? "pa-current-step" : "pa-default-step"}>Trading Account</p>
      </div>
      <div
        className={
          id === "investment-info" ? "pa-nav-div-current pa-nav-middle-child" : "pa-nav-div pa-nav-middle-child"
        }
      >
        <p className={id === "investment-info" ? "pa-current-step" : "pa-default-step"}>Investment Info</p>
      </div>
      <div className={id === "groupings" ? "pa-nav-div-current pa-nav-middle-child" : "pa-nav-div pa-nav-middle-child"}>
        <p className={id === "groupings" ? "pa-current-step" : "pa-default-step"}>Groupings</p>
      </div>
      <div className={id === "review" ? "pa-nav-div-current pa-nav-bottom-child" : "pa-nav-div pa-nav-bottom-child"}>
        <p className={id === "review" ? "pa-current-step" : "pa-default-step"}>Review</p>
      </div>
    </div>
  );
}

ParticipationAgreementNav.propTypes = {
  step: PropTypes.object,
  navigation: PropTypes.object,
};

export default ParticipationAgreementNav;
