import React from "react";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";
import Card from "../../../../Card";
import PropTypes from "prop-types";

const TradingAccount = ({ ...props }) => {
  const {
    omnibus_name,
    trading_account_id,
    trading_account_type,
    service_provider_name,
    sub_account_number,
    currentIndex,
  } = props;
  return (
    <>
      <Card heading={"Trading Account"}>
        <Row>
          <Col>
            <DataComponent
              id={"trading_account_name"}
              label="Trading Account Name"
              data={omnibus_name}
              editLink={trading_account_id !== "" ? `/trading-account-details/edit/${trading_account_id}` : ""}
              detailsLink={trading_account_id !== "" ? `/trading-account-details/${trading_account_id}` : ""}
            />
          </Col>
          <Col>
            <DataComponent id={"trading_account_type"} label="Type" data={trading_account_type} />
          </Col>
        </Row>
        {currentIndex === 0 ? (
          <Row>
            <Col>
              <DataComponent id={"service_provider_name"} label="Service Provider" data={service_provider_name} />
            </Col>
            <Col>
              <DataComponent id={"sub_account_number"} label="Sub Account Number" data={sub_account_number} />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default TradingAccount;

TradingAccount.propTypes = {
  omnibus_name: PropTypes.string,
  trading_account_id: PropTypes.string,
  trading_account_type: PropTypes.string,
  service_provider_name: PropTypes.string,
  sub_account_number: PropTypes.string,
  currentIndex: PropTypes.number,
};
