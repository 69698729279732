import PropTypes from "prop-types";

const modalStyles = ({ center }) => {
  const modalStyle = {
    content: {
      top: "35%",
      left: "50%",
      right: "30%",
      bottom: "auto",
      marginRight: "-50%",
      width: "30%",
      transform: "translate(-40%, -10%)",
      textAlign: center ? "center" : undefined,
      zIndex: 1,
    },
    overlay: {
      zIndex: 2,
      background: "rgba(0, 0, 0, 0.7)",
    },
  };
  return modalStyle;
};

modalStyles.propTypes = {
  center: PropTypes.bool,
};

export default modalStyles;
