import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import { Col, Row } from "@pds-react/grid";
import DataComponent from "../../../../DataComponent";

const ShareClassDates = ({ ...props }) => {
  return (
    <>
      <Card heading={"Dates"}>
        {props.currentIndex === 0 ? (
          <>
            <Row>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`inception_date`}
                  data-testid={`inception_date`}
                  label={"Inception Date"}
                  data={props.inception_date}
                />
              </Col>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`soft_close_date`}
                  data-testid={`soft_close_date`}
                  label={"Soft Close Date"}
                  data={props.soft_close_date}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`performance_begin_date`}
                  data-testid={`performance_begin_date`}
                  label={"Performance Begin Date"}
                  data={props.performance_begin_date}
                />
              </Col>
              <Col sm={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`hard_close_date`}
                  data-testid={`hard_close_date`}
                  label={"Hard Close Date"}
                  data={props.hard_close_date}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} offset={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                <DataComponent
                  id={`termination_notice_date`}
                  data-testid={`termination_notice_date`}
                  label={"Termination Notice Date"}
                  data={props.termination_notice_date}
                />
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default ShareClassDates;

ShareClassDates.propTypes = {
  inception_date: PropTypes.string,
  performance_begin_date: PropTypes.string,
  soft_close_date: PropTypes.string,
  hard_close_date: PropTypes.string,
  termination_notice_date: PropTypes.string,
  currentIndex: PropTypes.number,
};
