import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import PropTypes from "prop-types";
import "../NewParticipationAgreement.scss";
import Checkbox from "@pds-react/checkbox";
import Select, { createFilter } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import UserContext from "../../../context/User.context";
import Config from "../../../config";
import Input from "@pds-react/input";
import {
  Validate,
  FormatPhoneNumber,
  PhoneNumberFormatOnLoad,
  ValidatePhoneNumber,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../Utilities/ValidationHelpers";
import selectStyler from "../../Utilities/selectStyler";
import { fetchService } from "../../../services/fetchService";
import ValidationErrorMessage from "../../Utilities/ValidationErrorMessage";
import HeaderAndButtons from "./Shared/HeaderAndButtons";
import { set_nulls_to_empty_strings } from "../../Utilities/FormHelpers";
import { new_pa } from "../Data";

export const PlanSponsorForm = ({ setForm, formData, navigation }) => {
  const { accessToken } = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const [asyncOptions, setOptions] = useState([]);
  const [validationErrors, setValidationError] = useState({});
  const [fieldsToValidate, setFieldsToValidate] = useState({});
  const [companyStreetSameAsMailing, setCompanyStreetSameAsMailing] = useState(false);
  const [contactStreetSameAsMailing, setContactStreetSameAsMailing] = useState(false);
  const [contactAddressSameAsCompany, setContactAddressSameAsCompany] = useState(false);

  const { plan_sponsor } = formData;
  const { company, contact } = plan_sponsor;
  const {
    company_name,
    phone_number,
    phone_extension,
    email,
    company_mailing_address,
    company_mailing_address_2,
    company_mailing_city,
    company_mailing_state,
    company_mailing_zip,
    company_mailing_country,
    company_street_address,
    company_street_address_2,
    company_street_city,
    company_street_state,
    company_street_zip,
    company_street_country,
  } = company;
  const {
    contact_name,
    first_name,
    last_name,
    contact_title,
    other_name,
    contact_phone,
    contact_extension,
    contact_email,
    contact_mailing_address,
    contact_mailing_address_2,
    contact_mailing_city,
    contact_mailing_state,
    contact_mailing_zip,
    contact_mailing_country,
    contact_street_address,
    contact_street_address_2,
    contact_street_city,
    contact_street_state,
    contact_street_zip,
    contact_street_country,
  } = contact;

  const { previous, next } = navigation;

  function handleNestedChange(e, sectionKey, id) {
    let value;
    if (e === null) {
      value = { label: "", value: "" };
    } else {
      if ("target" in e) {
        value = e.target.value;
      } else {
        value = e;
      }
    }
    const newFormData = { ...formData };
    newFormData["plan_sponsor"][sectionKey][id] = value;
    setForm(newFormData);
  }

  function handleCompanyNameSelect(id, e) {
    let newValue;
    const newFormData = { ...formData };
    newFormData["plan_sponsor"] = JSON.parse(JSON.stringify(new_pa))["plan_sponsor"];
    setFieldsToValidate({});
    setValidationError({});

    if (e === null) {
      // clearing the field
      newValue = { value: "", label: "" };
    } else {
      newValue = e;
    }

    if ("value" in newValue && newValue.value !== "" && newValue.value !== null) {
      // choosing an existing record
      let company = set_nulls_to_empty_strings(newValue);
      company.phone_number = PhoneNumberFormatOnLoad(company.phone_number);
      company.company_mailing_state = {
        label: company.company_mailing_state,
        value: company.company_mailing_state,
      };
      company.company_mailing_country = {
        label: company.company_mailing_country,
        value: company.company_mailing_country,
      };
      company.company_street_state = { label: company.company_street_state, value: company.company_street_state };
      company.company_street_country = {
        label: company.company_street_country,
        value: company.company_street_country,
      };
      newFormData["plan_sponsor"]["company"] = company;
    }
    newFormData["plan_sponsor"]["company"][id] = { value: newValue.value, label: newValue.label };
    setForm(newFormData);
  }

  function handleContactSelect(id, e) {
    const newFormData = { ...formData };
    let newValue;
    newFormData["plan_sponsor"]["contact"] = JSON.parse(JSON.stringify(new_pa))["plan_sponsor"]["contact"];
    if (e === null) {
      // clearing the field
      newValue = { value: "", label: "" };
    } else {
      newValue = JSON.parse(JSON.stringify(e));
      const names = newValue.label.split(" ");
      const firstName = names.slice(0, names.length - 1).join(" ");
      const secondName = names.slice(-1).join("");
      if (names.length > 1) {
        newFormData["plan_sponsor"]["contact"]["first_name"] = firstName;
        newFormData["plan_sponsor"]["contact"]["last_name"] = secondName;
      } else {
        newFormData["plan_sponsor"]["contact"]["first_name"] = names[0];
      }
    }

    if ("value" in newValue && newValue.value !== "" && newValue.value !== null) {
      // choosing an existing record
      let contact = set_nulls_to_empty_strings(newValue);
      contact.contact_phone = PhoneNumberFormatOnLoad(contact.contact_phone);
      contact.contact_mailing_state = {
        label: contact.contact_mailing_state,
        value: contact.contact_mailing_state,
      };
      contact.contact_mailing_country = {
        label: contact.contact_mailing_country,
        value: contact.contact_mailing_country,
      };
      contact.contact_street_state = {
        label: contact.contact_street_state,
        value: contact.contact_street_state,
      };
      contact.contact_street_country = {
        label: contact.contact_street_country,
        value: contact.contact_street_country,
      };

      newFormData["plan_sponsor"]["contact"] = contact;
    }
    newFormData["plan_sponsor"]["contact"][id] = { value: newValue.value, label: newValue.label };
    setForm(newFormData);
  }

  useEffect(() => {
    if (company_name.value === "" && company_name.label === "") {
      setFieldsToValidate({});
      setValidationError({});
    } else {
      const newFieldsToValidate = { ...fieldsToValidate };
      const newValidationErrors = { ...validationErrors };
      if (contact_name.value !== "" && contact_name.label !== "") {
        newFieldsToValidate["first_name"] = ValidateRequired;
        newFieldsToValidate["last_name"] = ValidateRequired;
        newFieldsToValidate["contact_phone"] = ValidatePhoneNumber;
        newFieldsToValidate["contact_mailing_address"] = ValidateRequired;
        newFieldsToValidate["contact_mailing_city"] = ValidateRequired;
        newFieldsToValidate["contact_mailing_state"] = ValidateRequiredSelect;
        newFieldsToValidate["contact_mailing_zip"] = ValidateRequired;
        newFieldsToValidate["contact_mailing_country"] = ValidateRequiredSelect;
      } else {
        delete newFieldsToValidate.first_name;
        delete newFieldsToValidate.last_name;
        delete newFieldsToValidate.contact_phone;
        delete newFieldsToValidate.contact_mailing_address;
        delete newFieldsToValidate.contact_mailing_city;
        delete newFieldsToValidate.contact_mailing_state;
        delete newFieldsToValidate.contact_mailing_zip;
        delete newFieldsToValidate.contact_mailing_country;
        delete newValidationErrors.first_name;
        delete newValidationErrors.last_name;
        delete newValidationErrors.contact_phone;
        delete newValidationErrors.contact_mailing_address;
        delete newValidationErrors.contact_mailing_city;
        delete newValidationErrors.contact_mailing_state;
        delete newValidationErrors.contact_mailing_zip;
        delete newValidationErrors.contact_mailing_country;
      }
      newFieldsToValidate["phone_number"] = ValidatePhoneNumber;
      newFieldsToValidate["company_mailing_address"] = ValidateRequired;
      newFieldsToValidate["company_mailing_city"] = ValidateRequired;
      newFieldsToValidate["company_mailing_state"] = ValidateRequiredSelect;
      newFieldsToValidate["company_mailing_zip"] = ValidateRequired;
      newFieldsToValidate["company_mailing_country"] = ValidateRequiredSelect;
      newFieldsToValidate["contact_name"] = ValidateRequiredSelect;

      setValidationError(newValidationErrors);
      setFieldsToValidate(newFieldsToValidate);
    }
  }, [company_name, contact_name]);

  useEffect(() => {
    validationUseEffectHelper("phone_number", phone_number, fieldsToValidate, validationErrors, setValidationError);
  }, [phone_number]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_address",
      company_mailing_address,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_address]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_city",
      company_mailing_city,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_city]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_state",
      company_mailing_state,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_state]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_zip",
      company_mailing_zip,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_zip]);

  useEffect(() => {
    validationUseEffectHelper(
      "company_mailing_country",
      company_mailing_country,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [company_mailing_country]);

  useEffect(() => {
    validationUseEffectHelper("contact_name", contact_name, fieldsToValidate, validationErrors, setValidationError);
  }, [contact_name]);

  useEffect(() => {
    validationUseEffectHelper("first_name", first_name, fieldsToValidate, validationErrors, setValidationError);
  }, [first_name]);

  useEffect(() => {
    validationUseEffectHelper("last_name", last_name, fieldsToValidate, validationErrors, setValidationError);
  }, [last_name]);

  useEffect(() => {
    validationUseEffectHelper("contact_phone", contact_phone, fieldsToValidate, validationErrors, setValidationError);
  }, [contact_phone]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_address",
      contact_mailing_address,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_address]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_city",
      contact_mailing_city,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_city]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_state",
      contact_mailing_state,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_state]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_zip",
      contact_mailing_zip,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_zip]);

  useEffect(() => {
    validationUseEffectHelper(
      "contact_mailing_country",
      contact_mailing_country,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [contact_mailing_country]);

  useEffect(() => {
    const newValue =
      company_street_address === company_mailing_address &&
      company_street_address_2 === company_mailing_address_2 &&
      company_street_city === company_mailing_city &&
      company_street_state.value === company_mailing_state.value &&
      company_street_zip === company_mailing_zip &&
      company_street_country.value === company_mailing_country.value;
    if (newValue !== companyStreetSameAsMailing) {
      setCompanyStreetSameAsMailing(newValue);
    }
  }, [
    company_street_address,
    company_mailing_address,
    company_street_address_2,
    company_mailing_address_2,
    company_street_city,
    company_mailing_city,
    company_street_state,
    company_mailing_state,
    company_street_zip,
    company_mailing_zip,
    company_street_country,
    company_mailing_country,
  ]);

  useEffect(() => {
    const newValue =
      contact_street_address === contact_mailing_address &&
      contact_street_address_2 === contact_mailing_address_2 &&
      contact_street_city === contact_mailing_city &&
      contact_street_state.value === contact_mailing_state.value &&
      contact_street_zip === contact_mailing_zip &&
      contact_street_country.value === contact_mailing_country.value;
    if (newValue !== contactStreetSameAsMailing) {
      setContactStreetSameAsMailing(newValue);
    }
  }, [
    contact_street_address,
    contact_mailing_address,
    contact_street_address_2,
    contact_mailing_address_2,
    contact_street_city,
    contact_mailing_city,
    contact_street_state,
    contact_mailing_state,
    contact_street_zip,
    contact_mailing_zip,
    contact_street_country,
    contact_mailing_country,
  ]);

  useEffect(() => {
    const newValue =
      company_mailing_address === contact_mailing_address &&
      company_mailing_address_2 === contact_mailing_address_2 &&
      company_mailing_city === contact_mailing_city &&
      company_mailing_state.value === contact_mailing_state.value &&
      company_mailing_zip === contact_mailing_zip &&
      company_mailing_country.value === contact_mailing_country.value &&
      company_street_address === contact_street_address &&
      company_street_address_2 === contact_street_address_2 &&
      company_street_city === contact_street_city &&
      company_street_state.value === contact_street_state.value &&
      company_street_zip === contact_street_zip &&
      company_street_country.value === contact_street_country.value;
    if (newValue !== contactAddressSameAsCompany) {
      setContactAddressSameAsCompany(newValue);
    }
  }, [
    company_mailing_address,
    contact_mailing_address,
    company_mailing_address_2,
    contact_mailing_address_2,
    company_mailing_city,
    contact_mailing_city,
    company_mailing_state,
    contact_mailing_state,
    company_mailing_zip,
    contact_mailing_zip,
    company_mailing_country,
    contact_mailing_country,
    company_street_address,
    contact_street_address,
    company_street_address_2,
    contact_street_address_2,
    company_street_city,
    contact_street_city,
    company_street_state,
    contact_street_state,
    company_street_zip,
    contact_street_zip,
    company_street_country,
    contact_street_country,
  ]);

  let contactDisabled = company_name.label === "" || contact_name.value !== null;
  let sponsorDisabled = company_name.value !== null || company_name.value === "";

  const goToNextPageCheck = () => {
    const flattened_form_data = { ...company, ...contact };
    let [formIsValid, errors] = Validate(flattened_form_data, fieldsToValidate);
    setValidationError(errors);
    if (formIsValid) {
      next();
    }
  };

  useEffect(() => {
    const buildSelectOptions = (options) => {
      return options.map((opt) => ({
        value: opt.name || opt.state_code,
        label: opt.name || opt.state_code,
        ...opt,
      }));
    };
    const fetchOptions = (endpoint, inputValue) => {
      if (!isLoading) setLoading(true);

      const { baseUrl } = Config.api;

      fetchService(baseUrl + endpoint, { method: "GET", token: accessToken }).then(async (response) => {
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp["requestId"];
          throw new Error(requestId);
        }
        const res = await resp["data"];
        setOptions(buildSelectOptions(res, inputValue));
        setLoading(false);
      });
    };

    fetchOptions("/v1/us-states");
  }, []);

  const buildSelectOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_company_id,
      label: opt.company_name,
      ...opt,
    }));
  };

  const buildContactOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_id,
      label: `${opt.first_name} ${opt.last_name}`,
      ...opt,
    }));
  };

  const loadSponsorCompanies = async (inputValue, callback) => {
    const { baseUrl } = Config.api;
    const endpoint = `/v1/contact-company?company-name=${inputValue}`;

    if (inputValue.length > 2) {
      await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken }).then(async (response) => {
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp["requestId"];
          throw new Error(requestId);
        }
        const res = await resp["data"];
        return callback(buildSelectOptions(res));
      });
    } else {
      return callback([]);
    }
  };

  const loadSponsorContacts = async (inputValue, callback) => {
    const { baseUrl } = Config.api;
    if (company_name.value === null) {
      return callback([]);
    }
    const endpoint = `/v1/contact?contact-name=${inputValue}&contact-company-id=${company_name.value}`;

    if (inputValue.length > 2) {
      await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken }).then(async (response) => {
        let resp = await response.json();
        if (200 !== response.status) {
          const requestId = resp["requestId"];
          throw new Error(requestId);
        }
        const res = await resp["data"];
        return callback(buildContactOptions(res));
      });
    } else {
      return callback([]);
    }
  };

  return (
    <form>
      <HeaderAndButtons
        prev_label={"Trust Info"}
        prev_funct={previous}
        next_label={"Fiduciary Info"}
        next_funct={goToNextPageCheck}
        header_label={"Plan Sponsor Information"}
      />
      <Row>
        <Col
          sm={3}
          lg={3}
          id="plan_sponsor_company"
          data-testid={"plan_sponsor_company"}
          style={{ textAlign: "center", top: "50%", transform: "translateY(+30%)" }}
        >
          <label>Plan Sponsor Entity Name</label>
        </Col>
        <Col sm={9} lg={9}>
          <div data-testid={"company_name"}>
            <AsyncCreatableSelect
              openMenuOnClick={true}
              style={{ cursor: "not-allowed" }}
              //cacheOptions
              isClearable={true}
              id={"company_name"}
              styles={selectStyler({ validationError: validationErrors["company_name"] })}
              loadOptions={loadSponsorCompanies}
              getNewOptionData={(inputValue, optionLabel) => {
                return { label: optionLabel, value: null };
              }}
              value={company_name["label"] !== "" ? company_name : "undefined"}
              onChange={(event) => handleCompanyNameSelect("company_name", event)}
            ></AsyncCreatableSelect>
            <ValidationErrorMessage paramId={"company_name"} validationError={validationErrors["company_name"]} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"phone_number"}
            id={"phone_number"}
            type="text"
            label="Phone"
            value={phone_number}
            onChange={(e) => {
              FormatPhoneNumber(e);
              handleNestedChange(e, "company", "phone_number");
            }}
            errorMessage={validationErrors["phone_number"]}
            disabled={sponsorDisabled}
          ></Input>
        </Col>
        <Col sm={2} lg={2} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"phone_extension"}
            id={"phone_extension"}
            type="text"
            label="Ext"
            value={phone_extension}
            onChange={(e) => handleNestedChange(e, "company", "phone_extension")}
            errorMessage={validationErrors["phone_extension"]}
            disabled={sponsorDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={9} lg={9} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"email"}
            id={"email"}
            type="text"
            label="Email"
            value={email}
            onChange={(e) => {
              handleNestedChange(e, "company", "email");
            }}
            disabled={sponsorDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"company_mailing_address"}
            id={"company_mailing_address"}
            type="text"
            label="Mailing Address"
            value={company_mailing_address}
            onChange={(e) => {
              handleNestedChange(e, "company", "company_mailing_address");
            }}
            errorMessage={validationErrors["company_mailing_address"]}
            disabled={sponsorDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"company_mailing_address_2"}
            id={"company_mailing_address_2"}
            type="text"
            label=""
            value={company_mailing_address_2}
            onChange={(e) => {
              handleNestedChange(e, "company", "company_mailing_address_2");
            }}
            disabled={sponsorDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={5} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"company_mailing_city"}
            id={"company_mailing_city"}
            type="text"
            label="City"
            value={company_mailing_city}
            onChange={(e) => {
              handleNestedChange(e, "company", "company_mailing_city");
            }}
            disabled={sponsorDisabled}
            errorMessage={validationErrors["company_mailing_city"]}
          ></Input>
        </Col>
        <Col
          sm={6}
          lg={3}
          className="util-margin-top-sm-15 util-margin-top-xs-30"
          style={{ cursor: company_name.value === "" ? "not-allowed" : "default" }}
        >
          <label htmlFor="company_mailing_state">State</label>
          <div data-testid={"company_mailing_state"}>
            <Select
              isClearable={true}
              id={"company_mailing_state"}
              label={"State"}
              styles={selectStyler({
                validationErrorsValue: validationErrors["company_mailing_state"],
                disabled: sponsorDisabled,
              })}
              options={asyncOptions}
              isLoading={isLoading}
              value={company_mailing_state["label"] !== "" ? company_mailing_state : "undefined"}
              onChange={(e) => {
                handleNestedChange(e, "company", "company_mailing_state");
                if (e === null) {
                  handleNestedChange(e, "company", "company_mailing_country");
                } else {
                  let countryValue = { target: { value: { label: "US", value: "US" } } };
                  handleNestedChange(countryValue, "company", "company_mailing_country");
                }
              }}
              filterOption={createFilter({ ignoreAccents: false })}
              isDisabled={sponsorDisabled}
            ></Select>
            <ValidationErrorMessage
              paramId={"company_mailing_state"}
              validationError={validationErrors["company_mailing_state"]}
            />
          </div>
        </Col>
        <Col sm={6} lg={2} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"company_mailing_zip"}
            id={"company_mailing_zip"}
            type="text"
            label="Zip"
            value={company_mailing_zip}
            onChange={(e) => {
              handleNestedChange(e, "company", "company_mailing_zip");
            }}
            disabled={sponsorDisabled}
            errorMessage={validationErrors["company_mailing_zip"]}
          ></Input>
        </Col>
        <Col
          sm={6}
          lg={2}
          className="util-margin-top-sm-15 util-margin-top-xs-30"
          style={{ cursor: company_name.value === "" ? "not-allowed" : "default" }}
        >
          <label htmlFor="company_mailing_country">Country</label>
          <div data-testid={"company_mailing_country"}>
            <Select
              isClearable={true}
              id={"company_mailing_country"}
              styles={selectStyler({
                validationErrorsValue: validationErrors["company_mailing_country"],
                disabled: sponsorDisabled,
              })}
              options={[
                { value: "US", label: "US" },
                { value: "CA", label: "CA" },
              ]}
              label={"Country"}
              value={company_mailing_country["label"] !== "" ? company_mailing_country : "undefined"}
              onChange={(e) => {
                handleNestedChange(e, "company", "company_mailing_country");
              }}
              filterOption={createFilter({ ignoreAccents: false })}
              isDisabled={sponsorDisabled}
            ></Select>
            <ValidationErrorMessage
              paramId={"company_mailing_country"}
              validationError={validationErrors["company_mailing_country"]}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Checkbox
            data-testid={"company_street_same_as_mailing"}
            id={`company_street_same_as_mailing`}
            label="Street Address is same as Mailing Address"
            className="util-margin-top-sm-15 util-margin-top-xs-30"
            checked={companyStreetSameAsMailing}
            onChange={(e) => {
              const newFormData = { ...formData };
              if (e.target.checked) {
                newFormData["plan_sponsor"]["company"]["company_street_address"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_address"];
                newFormData["plan_sponsor"]["company"]["company_street_address_2"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_address_2"];
                newFormData["plan_sponsor"]["company"]["company_street_city"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_city"];
                newFormData["plan_sponsor"]["company"]["company_street_state"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_state"];
                newFormData["plan_sponsor"]["company"]["company_street_zip"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_zip"];
                newFormData["plan_sponsor"]["company"]["company_street_country"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_country"];
              } else {
                const companyReset = JSON.parse(JSON.stringify(new_pa))["plan_sponsor"]["company"];
                newFormData["plan_sponsor"]["company"]["company_street_address"] =
                  companyReset["company_street_address"];
                newFormData["plan_sponsor"]["company"]["company_street_address_2"] =
                  companyReset["company_street_address_2"];
                newFormData["plan_sponsor"]["company"]["company_street_city"] = companyReset["company_street_city"];
                newFormData["plan_sponsor"]["company"]["company_street_state"] = companyReset["company_street_state"];
                newFormData["plan_sponsor"]["company"]["company_street_zip"] = companyReset["company_street_zip"];
                newFormData["plan_sponsor"]["company"]["company_street_country"] =
                  companyReset["company_street_country"];
              }
              setForm(newFormData);
              setCompanyStreetSameAsMailing(e.target.checked);
            }}
            disabled={sponsorDisabled}
            errorMessage={""}
          ></Checkbox>
        </Col>
      </Row>
      {companyStreetSameAsMailing === false ? (
        <div>
          <Row>
            <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"company_street_address"}
                id={"company_street_address"}
                type="text"
                label="Street Address"
                value={company_street_address}
                onChange={(e) => {
                  handleNestedChange(e, "company", "company_street_address");
                }}
                disabled={sponsorDisabled}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"company_street_address_2"}
                id={"company_street_address_2"}
                type="text"
                label=""
                value={company_street_address_2}
                onChange={(e) => {
                  handleNestedChange(e, "company", "company_street_address_2");
                }}
                disabled={sponsorDisabled}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={5} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"company_street_city"}
                id={"company_street_city"}
                type="text"
                label="City"
                value={company_street_city}
                onChange={(e) => {
                  handleNestedChange(e, "company", "company_street_city");
                }}
                disabled={sponsorDisabled}
              ></Input>
            </Col>
            <Col
              sm={6}
              lg={3}
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              style={{ cursor: company_name.value === "" ? "not-allowed" : "default" }}
            >
              <label htmlFor="company_street_state">State</label>
              <div data-testid={"company_street_state"}>
                <Select
                  isClearable={true}
                  data-testid={"company_street_state"}
                  id={"company_street_state"}
                  label={"State"}
                  styles={selectStyler({
                    disabled: sponsorDisabled,
                  })}
                  options={asyncOptions}
                  isLoading={isLoading}
                  value={company_street_state["label"] !== "" ? company_street_state : "undefined"}
                  onChange={(e) => {
                    handleNestedChange(e, "company", "company_street_state");
                    if (e === null) {
                      handleNestedChange(e, "company", "company_street_country");
                    } else {
                      let countryValue = { target: { value: { label: "US", value: "US" } } };
                      handleNestedChange(countryValue, "company", "company_street_country");
                    }
                  }}
                  isDisabled={sponsorDisabled}
                ></Select>
              </div>
            </Col>
            <Col sm={6} lg={2} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"company_street_zip"}
                id={"company_street_zip"}
                type="text"
                label="Zip"
                value={company_street_zip}
                onChange={(e) => {
                  handleNestedChange(e, "company", "company_street_zip");
                }}
                disabled={sponsorDisabled}
              ></Input>
            </Col>
            <Col
              sm={6}
              lg={2}
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              style={{ cursor: company_name.value === "" ? "not-allowed" : "default" }}
            >
              <label htmlFor="company_street_country">Country</label>
              <Select
                isClearable={true}
                data-testid={"company_street_country"}
                id={"company_street_country"}
                styles={selectStyler({
                  disabled: sponsorDisabled,
                })}
                options={[
                  { value: "US", label: "US" },
                  { value: "CA", label: "CA" },
                ]}
                label={"Country"}
                value={company_street_country["label"] !== "" ? company_street_country : "undefined"}
                onChange={(e) => {
                  handleNestedChange(e, "company", "company_street_country");
                }}
                filterOption={createFilter({ ignoreAccents: false })}
                isDisabled={sponsorDisabled}
              ></Select>
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}
      <hr></hr>
      <Row>
        <Col
          sm={3}
          lg={3}
          id="existing_contact_name"
          style={{ textAlign: "center", top: "50%", transform: "translateY(+30%)" }}
        >
          <label>Plan Sponsor Contact</label>
        </Col>
        <Col sm={9} lg={9}>
          <div data-testid={"contact_name"}>
            <AsyncCreatableSelect
              //cacheOptions
              isClearable={true}
              openMenuOnClick={true}
              id={"contact_name"}
              styles={selectStyler({
                validationErrorsValue: validationErrors["contact_name"],
                disabled: company_name.label === "",
              })}
              getNewOptionData={(inputValue, optionLabel) => {
                return { label: optionLabel, value: null };
              }}
              loadOptions={loadSponsorContacts}
              value={contact_name["label"] !== "" ? contact_name : "undefined"}
              onChange={(e) => {
                handleContactSelect("contact_name", e);
              }}
              isDisabled={company_name.label === ""}
            ></AsyncCreatableSelect>
            <ValidationErrorMessage paramId={"contact_name"} validationError={validationErrors["contact_name"]} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"first_name"}
            id={"first_name"}
            type="text"
            label="First Name"
            value={first_name}
            onChange={(e) => {
              handleNestedChange(e, "contact", "first_name");
            }}
            disabled={contactDisabled}
            errorMessage={validationErrors["first_name"]}
          ></Input>
        </Col>
        <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"last_name"}
            id={"last_name"}
            type="text"
            label="Last Name"
            value={last_name}
            onChange={(e) => {
              handleNestedChange(e, "contact", "last_name");
            }}
            disabled={contactDisabled}
            errorMessage={validationErrors["last_name"]}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"contact_title"}
            id={"contact_title"}
            type="text"
            label="Title"
            value={contact_title}
            onChange={(e) => {
              handleNestedChange(e, "contact", "contact_title");
            }}
            disabled={contactDisabled}
          ></Input>
        </Col>
        <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"other_name"}
            id={"other_name"}
            type="text"
            label="Other Names"
            value={other_name}
            onChange={(e) => {
              handleNestedChange(e, "contact", "other_name");
            }}
            disabled={contactDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"contact_phone"}
            id={"contact_phone"}
            type="text"
            label="Phone"
            value={contact_phone}
            onChange={(e) => {
              FormatPhoneNumber(e);
              handleNestedChange(e, "contact", "contact_phone");
            }}
            errorMessage={validationErrors["contact_phone"]}
            disabled={contactDisabled}
          ></Input>
        </Col>
        <Col sm={2} lg={2} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"contact_extension"}
            id={"contact_extension"}
            type="text"
            label="Ext"
            value={contact_extension}
            onChange={(e) => {
              handleNestedChange(e, "contact", "contact_extension");
            }}
            errorMessage={validationErrors["contact_extension"]}
            disabled={contactDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col sm={9} lg={9} className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Input
            data-testid={"contact_email"}
            id={"contact_email"}
            type="text"
            label="Email"
            value={contact_email}
            onChange={(e) => {
              handleNestedChange(e, "contact", "contact_email");
            }}
            disabled={contactDisabled}
          ></Input>
        </Col>
      </Row>
      <Row>
        <Col className="util-margin-top-sm-15 util-margin-top-xs-30">
          <Checkbox
            data-testid={"company_contact_same_mailing"}
            id={`company_contact_same_mailing`}
            label="Contact Address same as Company Address"
            className="util-margin-top-sm-15 util-margin-top-xs-30"
            checked={contactAddressSameAsCompany}
            onChange={(e) => {
              const newFormData = { ...formData };
              if (e.target.checked) {
                //set contact mailing to company mailing
                newFormData["plan_sponsor"]["contact"]["contact_mailing_address"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_address"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_address_2"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_address_2"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_city"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_city"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_state"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_state"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_zip"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_zip"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_country"] =
                  newFormData["plan_sponsor"]["company"]["company_mailing_country"];
                //set contact street to company mailing
                newFormData["plan_sponsor"]["contact"]["contact_street_address"] =
                  newFormData["plan_sponsor"]["company"]["company_street_address"];
                newFormData["plan_sponsor"]["contact"]["contact_street_address_2"] =
                  newFormData["plan_sponsor"]["company"]["company_street_address_2"];
                newFormData["plan_sponsor"]["contact"]["contact_street_city"] =
                  newFormData["plan_sponsor"]["company"]["company_street_city"];
                newFormData["plan_sponsor"]["contact"]["contact_street_state"] =
                  newFormData["plan_sponsor"]["company"]["company_street_state"];
                newFormData["plan_sponsor"]["contact"]["contact_street_zip"] =
                  newFormData["plan_sponsor"]["company"]["company_street_zip"];
                newFormData["plan_sponsor"]["contact"]["contact_street_country"] =
                  newFormData["plan_sponsor"]["company"]["company_street_country"];
              } else {
                const contactReset = JSON.parse(JSON.stringify(new_pa))["plan_sponsor"]["contact"];
                newFormData["plan_sponsor"]["contact"]["contact_street_address"] =
                  contactReset["contact_street_address"];
                newFormData["plan_sponsor"]["contact"]["contact_street_address_2"] =
                  contactReset["contact_street_address_2"];
                newFormData["plan_sponsor"]["contact"]["contact_street_city"] = contactReset["contact_street_city"];
                newFormData["plan_sponsor"]["contact"]["contact_street_state"] = contactReset["contact_street_state"];
                newFormData["plan_sponsor"]["contact"]["contact_street_zip"] = contactReset["contact_street_zip"];
                newFormData["plan_sponsor"]["contact"]["contact_street_country"] =
                  contactReset["contact_street_country"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_address"] =
                  contactReset["contact_mailing_address"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_address_2"] =
                  contactReset["contact_mailing_address_2"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_city"] = contactReset["contact_mailing_city"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_state"] = contactReset["contact_mailing_state"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_zip"] = contactReset["contact_mailing_zip"];
                newFormData["plan_sponsor"]["contact"]["contact_mailing_country"] =
                  contactReset["contact_mailing_country"];
              }
              setForm(newFormData);
              setContactStreetSameAsMailing(false);
              setContactAddressSameAsCompany(e.target.checked);
            }}
            disabled={contactDisabled}
            errorMessage={""}
          ></Checkbox>
        </Col>
      </Row>
      {!contactAddressSameAsCompany ? (
        <>
          <Row>
            <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"contact_mailing_address"}
                id={"contact_mailing_address"}
                type="text"
                label="Mailing Address"
                value={contact_mailing_address}
                onChange={(e) => {
                  handleNestedChange(e, "contact", "contact_mailing_address");
                }}
                disabled={contactDisabled}
                errorMessage={validationErrors["contact_mailing_address"]}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"contact_mailing_address_2"}
                id={"contact_mailing_address_2"}
                type="text"
                label=""
                value={contact_mailing_address_2}
                onChange={(e) => {
                  handleNestedChange(e, "contact", "contact_mailing_address_2");
                }}
                disabled={contactDisabled}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col sm={6} lg={5} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"contact_mailing_city"}
                id={"contact_mailing_city"}
                type="text"
                label="City"
                value={contact_mailing_city}
                onChange={(e) => {
                  handleNestedChange(e, "contact", "contact_mailing_city");
                }}
                disabled={contactDisabled}
                errorMessage={validationErrors["contact_mailing_city"]}
              ></Input>
            </Col>
            <Col
              sm={6}
              lg={3}
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              style={{
                cursor: company_name.label === "" || contact_name.label ? "not-allowed" : "default",
              }}
            >
              <label htmlFor="contact_mailing_state">State</label>
              <div data-testid={"contact_mailing_state"}>
                <Select
                  isClearable={true}
                  id={"contact_mailing_state"}
                  label={"State"}
                  styles={selectStyler({
                    validationErrorsValue: validationErrors["contact_mailing_state"],
                    disabled: contactDisabled,
                  })}
                  options={asyncOptions}
                  isLoading={isLoading}
                  value={contact_mailing_state["label"] !== "" ? contact_mailing_state : "undefined"}
                  onChange={(e) => {
                    handleNestedChange(e, "contact", "contact_mailing_state");
                    if (e === null) {
                      handleNestedChange(e, "contact", "contact_mailing_country");
                    } else {
                      let countryValue = { target: { value: { label: "US", value: "US" } } };
                      handleNestedChange(countryValue, "contact", "contact_mailing_country");
                    }
                  }}
                  isDisabled={contactDisabled}
                ></Select>
                <ValidationErrorMessage
                  paramId={"contact_mailing_state"}
                  validationError={validationErrors["contact_mailing_state"]}
                />
              </div>
            </Col>
            <Col sm={6} lg={2} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Input
                data-testid={"contact_mailing_zip"}
                id={"contact_mailing_zip"}
                type="text"
                label="Zip"
                value={contact_mailing_zip}
                onChange={(e) => {
                  handleNestedChange(e, "contact", "contact_mailing_zip");
                }}
                disabled={contactDisabled}
                errorMessage={validationErrors["contact_mailing_zip"]}
              ></Input>
            </Col>
            <Col
              sm={6}
              lg={2}
              className="util-margin-top-sm-15 util-margin-top-xs-30"
              style={{
                cursor: company_name.label === "" || contact_name.label ? "not-allowed" : "default",
              }}
            >
              <label htmlFor="contact_mailing_country">Country</label>
              <div data-testid={"contact_mailing_country"}>
                <Select
                  isClearable={true}
                  id={"contact_mailing_country"}
                  styles={selectStyler({
                    validationErrorsValue: validationErrors["contact_mailing_country"],
                    disabled: contactDisabled,
                  })}
                  options={[
                    { value: "US", label: "US" },
                    { value: "CA", label: "CA" },
                  ]}
                  label={"Country"}
                  value={contact_mailing_country["label"] !== "" ? contact_mailing_country : "undefined"}
                  onChange={(e) => {
                    handleNestedChange(e, "contact", "contact_mailing_country");
                  }}
                  filterOption={createFilter({ ignoreAccents: false })}
                  isDisabled={contactDisabled}
                ></Select>
                <ValidationErrorMessage
                  paramId={"contact_mailing_country"}
                  validationError={validationErrors["contact_mailing_country"]}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              lg={12}
              className={
                contactStreetSameAsMailing === true
                  ? "util-margin-top-sm-15 util-margin-top-xs-30 util-margin-bottom-sm-30"
                  : "util-margin-top-sm-15 util-margin-top-xs-30"
              }
            >
              <Checkbox
                data-testid={"contact_street_same_as_mailing"}
                id={`contact_street_same_as_mailing`}
                label="Street Address is same as Mailing Address"
                className="util-margin-top-sm-15 util-margin-top-xs-30"
                checked={contactStreetSameAsMailing}
                onChange={(e) => {
                  const newFormData = { ...formData };
                  if (e.target.checked) {
                    newFormData["plan_sponsor"]["contact"]["contact_street_address"] =
                      newFormData["plan_sponsor"]["contact"]["contact_mailing_address"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_address_2"] =
                      newFormData["plan_sponsor"]["contact"]["contact_mailing_address_2"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_city"] =
                      newFormData["plan_sponsor"]["contact"]["contact_mailing_city"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_state"] =
                      newFormData["plan_sponsor"]["contact"]["contact_mailing_state"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_zip"] =
                      newFormData["plan_sponsor"]["contact"]["contact_mailing_zip"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_country"] =
                      newFormData["plan_sponsor"]["contact"]["contact_mailing_country"];
                  } else {
                    const contactReset = JSON.parse(JSON.stringify(new_pa))["plan_sponsor"]["contact"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_address"] =
                      contactReset["contact_street_address"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_address_2"] =
                      contactReset["contact_street_address_2"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_city"] = contactReset["contact_street_city"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_state"] =
                      contactReset["contact_street_state"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_zip"] = contactReset["contact_street_zip"];
                    newFormData["plan_sponsor"]["contact"]["contact_street_country"] =
                      contactReset["contact_street_country"];
                  }
                  setForm(newFormData);
                  setContactStreetSameAsMailing(e.target.checked);
                }}
                disabled={contactDisabled}
                errorMessage={""}
              ></Checkbox>
            </Col>
          </Row>
          {!contactStreetSameAsMailing ? (
            <div>
              <Row>
                <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Input
                    data-testid={"contact_street_address"}
                    id={"contact_street_address"}
                    type="text"
                    label="Street Address"
                    value={contact_street_address}
                    onChange={(e) => {
                      handleNestedChange(e, "contact", "contact_street_address");
                    }}
                    disabled={contactDisabled}
                  ></Input>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Input
                    data-testid={"contact_street_address_2"}
                    id={"contact_street_address_2"}
                    type="text"
                    label=""
                    value={contact_street_address_2}
                    onChange={(e) => {
                      handleNestedChange(e, "contact", "contact_street_address_2");
                    }}
                    disabled={contactDisabled}
                  ></Input>
                </Col>
              </Row>
              <Row className="util-margin-bottom-sm-30">
                <Col sm={6} lg={5} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Input
                    data-testid={"contact_street_city"}
                    id={"contact_street_city"}
                    type="text"
                    label="City"
                    value={contact_street_city}
                    onChange={(e) => {
                      handleNestedChange(e, "contact", "contact_street_city");
                    }}
                    disabled={contactDisabled}
                  ></Input>
                </Col>
                <Col
                  sm={6}
                  lg={3}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  style={{
                    cursor: company_name.label === "" || contact_name.label ? "not-allowed" : "default",
                  }}
                >
                  <label htmlFor="contact_street_state">State</label>
                  <div data-testid={"contact_street_state"}>
                    <Select
                      isClearable={true}
                      id={"contact_street_state"}
                      label={"State"}
                      styles={selectStyler({
                        disabled: contactDisabled,
                      })}
                      options={asyncOptions}
                      isLoading={isLoading}
                      value={contact_street_state["label"] !== "" ? contact_street_state : "undefined"}
                      onChange={(e) => {
                        handleNestedChange(e, "contact", "contact_street_state");
                        if (e === null) {
                          handleNestedChange(e, "contact", "contact_street_country");
                        } else {
                          let countryValue = { target: { value: { label: "US", value: "US" } } };
                          handleNestedChange(countryValue, "contact", "contact_street_country");
                        }
                      }}
                      isDisabled={contactDisabled}
                    ></Select>
                  </div>
                </Col>
                <Col sm={6} lg={2} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Input
                    data-testid={"contact_street_zip"}
                    id={"contact_street_zip"}
                    type="text"
                    label="Zip"
                    value={contact_street_zip}
                    onChange={(e) => {
                      handleNestedChange(e, "contact", "contact_street_zip");
                    }}
                    disabled={contactDisabled}
                  ></Input>
                </Col>
                <Col
                  sm={6}
                  lg={2}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  style={{
                    cursor: company_name.label === "" || contact_name.label ? "not-allowed" : "default",
                  }}
                >
                  <label htmlFor="contact_street_country">Country</label>
                  <div data-testid={"contact_street_country"}>
                    <Select
                      isClearable={true}
                      id={"contact_street_country"}
                      styles={selectStyler({
                        disabled: contactDisabled,
                      })}
                      options={[
                        { value: "US", label: "US" },
                        { value: "CA", label: "CA" },
                      ]}
                      label={"Country"}
                      value={contact_street_country["label"] !== "" ? contact_street_country : "undefined"}
                      onChange={(e) => {
                        handleNestedChange(e, "contact", "contact_street_country");
                      }}
                      filterOption={createFilter({ ignoreAccents: false })}
                      isDisabled={contactDisabled}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </form>
  );
};

PlanSponsorForm.propTypes = {
  setForm: PropTypes.func,
  formData: PropTypes.object,
  navigation: PropTypes.object,
};

export default PlanSponsorForm;
