// noinspection DuplicatedCode

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Card from "../../../Card";
import Checkbox from "@pds-react/checkbox";
import DataComponent from "../../../DataComponent";
import Loading from "../../../../Loading/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { historyTimeNow, set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import { validateGuid } from "../../../../Utilities/ValidationHelpers";
import ParticipatingTrustsComponent from "./CardComponents/ParticipatingTrustsComponent";
import CompaniesComponent from "./CardComponents/CompaniesComponent";
import VersionHistory from "../../../VersionHistory/VersionHistory";
import ReasonForChange from "../../../VersionHistory/ReasonForChange";

const ContactDetails = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["contact_id"] = validateGuid(params.contact_id);
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [participatingTrusts, setParticipatingTrusts] = useState([]);

  const [companies, setCompanies] = useState([
    {
      company_name: "",
      company_mailing_city: "",
      company_mailing_state: "",
      actions: <></>,
    },
  ]);

  const [historyVersions, setHistoryVersions] = useState([]);

  const [contactData, setContactData] = useState({
    first_name: "",
    last_name: "",
    contact_title: "",
    other_name: "",
    contact_phone: "",
    contact_extension: "",
    contact_email: "",
    contact_mailing_address: "",
    contact_mailing_address_2: "",
    contact_mailing_city: "",
    contact_mailing_state: "",
    contact_mailing_zip: "",
    contact_mailing_country: "",
    street_same_as_mailing_address: false,
    contact_street_address: "",
    contact_street_address_2: "",
    contact_street_city: "",
    contact_street_state: "",
    contact_street_zip: "",
    contact_street_country: "",
    reason_for_change: "",
  });
  const [loadingData, setLoadingData] = useState(true);

  const {
    first_name,
    last_name,
    contact_title,
    other_name,
    contact_phone,
    contact_extension,
    contact_email,
    contact_mailing_address,
    contact_mailing_address_2,
    contact_mailing_city,
    contact_mailing_state,
    contact_mailing_zip,
    contact_mailing_country,
    street_address_same_as_mailing,
    contact_street_address,
    contact_street_address_2,
    contact_street_city,
    contact_street_state,
    contact_street_zip,
    contact_street_country,
    reason_for_change,
    inserted_by,
    inserted_at,
  } = contactData;

  const getContact = async () => {
    let endpoint = `/v1/contact-history?contact-id=${validatedParams.contact_id}&inserted-at=${historyTimeNow(
      new Date()
    )}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getParticipatingTrusts = async () => {
    let endpoint = `/v1/participating-trust-by-contact?contact-id=${validatedParams.contact_id}`;
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    resp.data = set_nulls_to_empty_strings(resp.data);
    const trusts = resp.data.map((trust, index) => ({
      actions: (
        <span>
          <Link
            id={`trust_details_edit_${index}_link`}
            data-testid={`trust_details_edit_${index}_link`}
            to={`/trust-details/edit/${trust.participating_trust_id}`}
          >
            Edit
          </Link>
          <span> | </span>
          <Link
            id={`trust_details_${index}_link`}
            data-testid={`trust_details_${index}_link`}
            to={`/trust-details/${trust.participating_trust_id}`}
          >
            Details
          </Link>
        </span>
      ),
      ...trust,
    }));
    setParticipatingTrusts(trusts);
  };

  const getCompanies = async (contact_company_id) => {
    let endpoint = `/v1/contact-company?contact-company-id=${contact_company_id}`;
    const response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    resp.data = set_nulls_to_empty_strings(resp.data);
    const companiesResult = resp.data.map((company, index) => ({
      actions: (
        <span>
          <Link
            id={`company_details_edit_${index}_link`}
            data-testid={`company_details_edit_${index}_link`}
            to={`/company-details/edit/${company.contact_company_id}`}
          >
            Edit
          </Link>
          <span> | </span>
          <Link
            id={`company_details_${index}_link`}
            data-testid={`company_details_${index}_link`}
            to={`/company-details/${company.contact_company_id}`}
          >
            Details
          </Link>
        </span>
      ),
      ...company,
    }));
    setCompanies(companiesResult);
  };

  const handleVersionChange = (contact, index) => {
    contact.street_address_same_as_mailing =
      contact.contact_street_address === contact.contact_mailing_address &&
      contact.contact_street_address_2 === contact.contact_mailing_address_2 &&
      contact.contact_street_city === contact.contact_mailing_city &&
      contact.contact_street_state === contact.contact_mailing_state &&
      contact.contact_street_zip === contact.contact_mailing_zip &&
      contact.contact_street_country === contact.contact_mailing_country;

    setCurrentIndex(index);
    setContactData(contact);
  };

  useEffect(() => {
    const setupContactData = async () => {
      try {
        const contactDetails = await getContact();

        contactDetails.data.forEach((contactVersion, index) => {
          contactDetails.data[index] = set_nulls_to_empty_strings(contactVersion);
        });
        let contact = contactDetails.data[0];

        contact.street_address_same_as_mailing =
          contact.contact_street_address === contact.contact_mailing_address &&
          contact.contact_street_address_2 === contact.contact_mailing_address_2 &&
          contact.contact_street_city === contact.contact_mailing_city &&
          contact.contact_street_state === contact.contact_mailing_state &&
          contact.contact_street_zip === contact.contact_mailing_zip &&
          contact.contact_street_country === contact.contact_mailing_country;

        setContactData(contact);
        setHistoryVersions(contactDetails.data);
        await getParticipatingTrusts();
        await getCompanies(contact.contact_company_id);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupContactData();
  }, []);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row>
            <Col sm={6}>
              <h2>Contact Details</h2>
            </Col>
            <Col>
              <button
                data-testid={"edit_button"}
                id={"edit_button"}
                type="button"
                className="btn btn-primary progress-btn"
                onClick={() => {
                  navigate(`/contact-details/edit/${validateGuid(validatedParams.contact_id)}`);
                }}
              >
                Edit
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Row>
                <Col sm={12}>
                  <Card heading={"Contact Detail"}>
                    <Row>
                      <Col sm={6}>
                        <DataComponent id={"first_name"} label="First Name" data={first_name} />
                      </Col>
                      <Col sm={6}>
                        <DataComponent id={"last_name"} label="Last Name" data={last_name} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <DataComponent id={"contact_title"} label="Title" data={contact_title} />
                      </Col>
                      <Col sm={6}>
                        <DataComponent id={"other_name"} label="Other Names" data={other_name} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <DataComponent id={"contact_phone"} label="Phone" data={contact_phone} />
                      </Col>
                      <Col sm={2}>
                        <DataComponent id={"contact_extension"} label="Ext" data={contact_extension} />
                      </Col>
                      <Col sm={6}>
                        <DataComponent id={"contact_email"} label="Email" data={contact_email} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
                <Col sm={12}>
                  <Card heading={"Contact Address"}>
                    <Row>
                      <Col sm={12}>
                        <DataComponent
                          id={"contact_mailing_address"}
                          label="Mailing Address"
                          data={contact_mailing_address}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <DataComponent id={"contact_mailing_address_2"} data={contact_mailing_address_2} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <DataComponent id={"contact_mailing_city"} label={"City"} data={contact_mailing_city} />
                      </Col>
                      <Col sm={3}>
                        <DataComponent id={"contact_mailing_state"} label={"State"} data={contact_mailing_state} />
                      </Col>
                      <Col sm={3}>
                        <DataComponent id={"contact_mailing_zip"} label={"Zip"} data={contact_mailing_zip} />
                      </Col>
                      <Col sm={3}>
                        <DataComponent
                          id={"contact_mailing_country"}
                          label={"Country"}
                          data={contact_mailing_country}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} lg={12} className="util-margin-top-sm-15 util-margin-top-xs-30">
                        <Checkbox
                          data-testid={"street_address_same_as_mailing"}
                          id={`street_address_same_as_mailing`}
                          label="Same as Mailing address"
                          className="util-margin-top-sm-15 util-margin-top-xs-30 util-margin-bottom-xs-15"
                          checked={street_address_same_as_mailing}
                          disabled={true}
                          errorMessage={""}
                        ></Checkbox>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <DataComponent
                          id={"contact_street_address"}
                          label="Street Address"
                          data={contact_street_address}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <DataComponent id={"contact_street_address_2"} data={contact_street_address_2} />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <DataComponent id={"contact_street_city"} label={"City"} data={contact_street_city} />
                      </Col>
                      <Col sm={3}>
                        <DataComponent id={"contact_street_state"} label={"State"} data={contact_street_state} />
                      </Col>
                      <Col sm={3}>
                        <DataComponent id={"contact_street_zip"} label={"Zip"} data={contact_street_zip} />
                      </Col>
                      <Col sm={3}>
                        <DataComponent id={"contact_street_country"} label={"Country"} data={contact_street_country} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={3}>
              <Row style={{ height: "100%" }}>
                <Col sm={12}>
                  <Card heading={"History"}>
                    <VersionHistory
                      historyVersions={historyVersions}
                      handleVersionChange={handleVersionChange}
                      currentIndex={currentIndex}
                    ></VersionHistory>
                    <ReasonForChange
                      reason_for_change={reason_for_change}
                      inserted_at={inserted_at}
                      inserted_by={inserted_by}
                    ></ReasonForChange>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {currentIndex === 0 ? (
            <>
              <Row>
                <CompaniesComponent companies={companies} />
              </Row>
              <Row>
                <ParticipatingTrustsComponent data={participatingTrusts} />
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default ContactDetails;
