import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const DataComponent = ({ id, label, data, editLink, detailsLink }) => {
  return (
    <div data-testid={id} id={id} className="util-margin-top-sm-10 util-margin-top-xs-10">
      <div style={{ display: "inline" }}>
        <p style={{ display: "inline" }}>
          <b>{label}</b>
        </p>

        {!(editLink || detailsLink) || editLink === "" || detailsLink === "" ? (
          <></>
        ) : (
          <p style={{ display: "inline" }}>
            {" - "}
            <span>
              <Link data-testid={id + "_Edit"} to={editLink}>
                Edit
              </Link>
            </span>
            <span> | </span>
            <span>
              <Link data-testid={id + "_Details"} to={detailsLink}>
                Details
              </Link>
            </span>
          </p>
        )}
      </div>
      <div className="util-margin-top-sm-10 util-margin-top-xs-10">
        <p className={"keep-white-space"}>{data}</p>
      </div>
    </div>
  );
};

export default DataComponent;

DataComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.node,
  editLink: PropTypes.string,
  detailsLink: PropTypes.string,
};
