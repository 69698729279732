import React from "react";
import PropTypes from "prop-types";
import Card from "../../../../Card";
import DataComponent from "../../../../DataComponent";

const Specifications = ({ ...props }) => {
  const { service_provider_description } = props;

  return (
    <>
      <Card heading={"Specifications"}>
        <DataComponent
          id={`service_provider_description`}
          label="Description"
          data={service_provider_description}
          data-testid={`service_provider_description`}
        />
      </Card>
    </>
  );
};

export default Specifications;

Specifications.propTypes = {
  service_provider_description: PropTypes.string,
};
