import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "@pds-react/grid";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import Checkbox from "@pds-react/checkbox";
import Input from "@pds-react/input";
import Config from "../../../../config";
import { fetchService } from "../../../../services/fetchService";
import UserContext from "../../../../context/User.context";
import { useNavigate } from "react-router-dom";
import { HandleSameAsMultiFillCheckBox } from "../../../NewParticipationAgreement/Steps/Helpers/FormHelpers";
import {
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../Utilities/ValidationHelpers";
import ValidationErrorMessage from "../../../Utilities/ValidationErrorMessage";
import { multiFetch } from "../../../../services/multiFetch";
import selectStyler from "../../../Utilities/selectStyler";
import { set_nulls_to_empty_strings } from "../../../Utilities/FormHelpers";
import Loading from "../../../Loading/Loading";
import Card from "../../Card";

const NewTradingDetailsEdit = ({
  initialTradingData,
  tradingData,
  setTradingData,
  handleSelectChange,
  handleChange,
  setValidationError,
  validationErrors,
  tradingFieldsToValidate,
  setTradingFieldsToValidate,
  loadingData,
  setLoadingData,
}) => {
  const {
    service_provider,
    service_provider_same_for_settlement,
    service_provider_for_settlement,
    service_provider_bin,
    service_provider_sub_acct,
    trading_account_relationship,
    // fif,
    trading_account_name,
    trading_wire_instructions,
  } = tradingData;

  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);
  const navigate = useNavigate();

  const [accountTypeOptions, setAccountTypeOptions] = useState();
  const [serviceProviderOptions, setServiceProviderOptions] = useState();
  const [accountDisabled, setAccountDisabled] = useState(false);
  const [omniInvisible, setOmniInvisible] = useState(true);
  const [subAccountDisabled, setSubAccountDisabled] = useState(false);

  const handleServiceChange = (id, value) => {
    if (value === null) {
      value = { value: "", label: "" };
    }
    setTradingData((tradingData) => ({
      ...tradingData,
      [id]: {
        label: value.label,
        value: value.value,
        ...value,
      },
    }));
  };

  const handleTradingNameChange = async (id, e) => {
    const newFormData = { ...tradingData };
    let value = e;
    set_nulls_to_empty_strings(value);
    if (e === null) {
      value = { value: "", label: "" };
      const formReset = JSON.parse(JSON.stringify(initialTradingData));
      newFormData.trading_account_name = formReset.trading_account_name;
      newFormData.trading_wire_instructions = formReset.trading_wire_instructions;
      newFormData.service_provider = formReset.service_provider;

      newFormData.fif = formReset.fif;
      newFormData.service_provider_same_for_settlement = formReset.service_provider_same_for_settlement;
      newFormData.service_provider_for_settlement = formReset.service_provider_for_settlement;
      newFormData.service_provider_bin = formReset.service_provider_bin;
    } else {
      setLoadingData(true);
      const urls = [
        `${baseUrl}/v1/service-provider?service-provider-id=${value.service_provider_id}`,
        `${baseUrl}/v1/service-provider?service-provider-id=${value.settlement_service_provider_id}`,
      ];

      const parsedProviderTradingData = await multiFetch(urls, accessToken);
      const service_provider_response = parsedProviderTradingData[0].data[0];
      const service_provider_settlement_response = parsedProviderTradingData[1].data[0];

      newFormData.trading_account_name = { label: value.omnibus_name, value: value.trading_account_id };
      newFormData.service_provider_bin = value.bin_number;
      newFormData.service_provider = {
        label: service_provider_response.service_provider_name,
        value: service_provider_response.service_provider_id,
      };
      newFormData.service_provider_for_settlement = {
        label: service_provider_settlement_response.service_provider_name,
        value: service_provider_settlement_response.service_provider_id,
      };
      newFormData.trading_wire_instructions = value.wire_instructions;
      setLoadingData(false);
    }
    newFormData[id] = value;
    setTradingData(newFormData);
  };

  const handleAccountRelationshipChange = (e) => {
    const newFormData = { ...tradingData };
    let value = e;
    if (e === null) {
      value = { value: "", label: "" };
    }
    newFormData.trading_account_relationship = value;
    if (!service_provider_sub_acct) {
      const formReset = JSON.parse(JSON.stringify(initialTradingData));
      newFormData.trading_account_name = formReset.trading_account_name;
      newFormData.trading_wire_instructions = formReset.trading_wire_instructions;
      newFormData.service_provider = formReset.service_provider;
      newFormData.service_provider_sub_acct = formReset.service_provider_sub_acct;
      newFormData.service_provider_same_for_settlement = formReset.service_provider_same_for_settlement;
      newFormData.service_provider_for_settlement = formReset.service_provider_for_settlement;
      newFormData.service_provider_bin = formReset.service_provider_bin;
    }
    setTradingData(newFormData);

    let newValidationErrors = { ...validationErrors };
    let newFieldsToValidate = { ...tradingFieldsToValidate };

    if (value.label.trim() === "Direct Traded Account") {
      setAccountDisabled(false);
      setOmniInvisible(true);
      delete newValidationErrors.service_provider_sub_acct;
      delete newValidationErrors.trading_account_name;
      newFieldsToValidate["service_provider"] = ValidateRequiredSelect;
      newFieldsToValidate["service_provider_for_settlement"] = ValidateRequiredSelect;

      delete newFieldsToValidate.service_provider_sub_acct;
      delete newFieldsToValidate.trading_account_name;
    } else {
      if (value.label.trim() === "Sub-Account") {
        setAccountDisabled(true);
        setOmniInvisible(false);

        delete newValidationErrors.service_provider;
        delete newValidationErrors.service_provider_for_settlement;
        newFieldsToValidate["service_provider_sub_acct"] = ValidateRequired;
        newFieldsToValidate["trading_account_name"] = ValidateRequiredSelect;
        delete newFieldsToValidate.service_provider;
        delete newFieldsToValidate.service_provider_for_settlement;
      } else {
        //RESET ALL VALUES
        setAccountDisabled(true);
        setOmniInvisible(true);
        delete newValidationErrors.service_provider_sub_acct;
        delete newValidationErrors.trading_account_name;
        delete newValidationErrors.service_provider;
        delete newValidationErrors.service_provider_for_settlement;

        delete newFieldsToValidate.service_provider_sub_acct;
        delete newFieldsToValidate.trading_account_name;
        delete newFieldsToValidate.service_provider;
        delete newFieldsToValidate.service_provider_for_settlement;
      }
    }
    setValidationError(newValidationErrors);
    setTradingFieldsToValidate(newFieldsToValidate);
  };

  const buildAccountTypeOptions = (options) => {
    return options.map((opt) => {
      if (opt.account_type === "Direct") {
        return { value: opt.account_type_id, label: "Direct Traded Account" };
      } else {
        return { value: opt.account_type_id, label: "Sub-Account" };
      }
    });
  };

  const fetchAccountTypes = async (endpoint) => {
    try {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      if (!trading_account_relationship.value) {
        if (service_provider_sub_acct) {
          resp.data.forEach((result) => {
            if (result.account_type === "Omnibus") {
              handleAccountRelationshipChange({ label: "Sub-Account", value: result.account_type_id });
            }
            setSubAccountDisabled(true);
          });
        } else {
          resp.data.forEach((result) => {
            if (result.account_type === "Direct") {
              handleSelectChange(
                "trading_account_relationship",
                { label: "Direct Traded Account", value: result.account_type_id },
                setTradingData
              );
            }
          });
        }
      }

      setAccountTypeOptions(buildAccountTypeOptions(resp.data));
      setLoadingData(false);
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const buildAccountNameOptions = (options) => {
    let ops = [];
    options.map((opt) => {
      if (opt.omnibus_name !== null)
        ops.push({
          value: opt.trading_account_id,
          label: opt.transfer_agent_account_number
            ? `[${opt.transfer_agent_account_number}] -- ${opt.omnibus_name}`
            : opt.omnibus_name,
          ...opt,
        });
    });
    return ops;
  };

  const loadAccountNameOptions = async (input, callback) => {
    let endpoint = `/v1/trading-account-name-or-number?name-or-number=${input}`;

    if (input.length > 2) {
      try {
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        return callback(buildAccountNameOptions(resp.data));
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const getserviceProviderData = async () => {
    try {
      let endpoint = "/v1/service-provider";
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        const requestId = resp["requestId"];
        throw new Error(requestId);
      }
      let res = await resp["data"];
      setServiceProviderOptions(
        res.map((result) => {
          return {
            label: result.trading_nscc_number
              ? `[${result.trading_nscc_number}] -- ${result.service_provider_name}`
              : result.service_provider_name,
            value: result.service_provider_id,
            trading_nscc_number: result.trading_nscc_number,
            service_provider_name: result.service_provider_name,
          };
        })
      );
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    fetchAccountTypes("/v1/account-type");
  }, []);

  useEffect(() => {
    getserviceProviderData();
  }, []);

  useEffect(() => {
    validationUseEffectHelper(
      "trading_account_relationship",
      trading_account_relationship,
      tradingFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [trading_account_relationship]);

  useEffect(() => {
    validationUseEffectHelper(
      "service_provider_for_settlement",
      service_provider_for_settlement,
      tradingFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [service_provider_for_settlement]);

  useEffect(() => {
    validationUseEffectHelper(
      "service_provider",
      service_provider,
      tradingFieldsToValidate,
      validationErrors,
      setValidationError
    );

    if (service_provider_same_for_settlement) {
      setTradingData((tradingData) => ({ ...tradingData, service_provider_for_settlement: service_provider }));
    }
  }, [service_provider]);

  useEffect(() => {
    validationUseEffectHelper(
      "trading_account_name",
      trading_account_name,
      tradingFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [trading_account_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "service_provider_sub_acct",
      service_provider_sub_acct,
      tradingFieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [service_provider_sub_acct]);

  useEffect(() => {
    if (trading_account_relationship.label === "Sub-Account") {
      setAccountDisabled(true);
      setOmniInvisible(false);
      if (service_provider_sub_acct) {
        setSubAccountDisabled(true);
      }
    }
  }, []);
  if (!loadingData) {
    return (
      <div data-testid={"tradingAccount"}>
        <Row>
          <Col sm={12}>
            <Card heading={"Trading Account"}>
              <Row>
                <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <label>Trading Account Relationship</label>
                  <div data-testid={"trading_account_relationship"}>
                    <Select
                      isClearable={true}
                      datatestid={`trading_account_relationship`}
                      id={`trading_account_relationship`}
                      inputId={`trading_account_relationship_input`}
                      styles={selectStyler({
                        validationErrorsValue: validationErrors["trading_account_relationship"],
                        disabled: subAccountDisabled,
                      })}
                      isDisabled={subAccountDisabled}
                      options={accountTypeOptions}
                      value={trading_account_relationship["label"] !== "" ? trading_account_relationship : "undefined"}
                      onChange={(e) => {
                        handleAccountRelationshipChange(e);
                      }}
                    ></Select>
                    <ValidationErrorMessage
                      paramId="trading_account_relationship"
                      validationError={validationErrors["trading_account_relationship"]}
                    />
                  </div>
                </Col>
                {omniInvisible ? (
                  <></>
                ) : (
                  <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                    <label>Trading Account Name</label>
                    <div data-testid={"trading_account_name"}>
                      <AsyncSelect
                        isClearable={true}
                        id={`trading_account_name`}
                        inputId={`trading_account_name_input`}
                        loadOptions={loadAccountNameOptions}
                        styles={selectStyler({
                          validationErrorsValue: validationErrors["trading_account_name"],
                        })}
                        value={trading_account_name["label"] !== "" ? trading_account_name : "undefined"}
                        onChange={(e) => {
                          handleTradingNameChange("trading_account_name", e);
                        }}
                      ></AsyncSelect>
                    </div>
                    <ValidationErrorMessage
                      paramId="trading_account_name"
                      validationError={validationErrors["trading_account_name"]}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Input
                    data-testid={"service_provider_bin"}
                    id={"service_provider_bin"}
                    type="text"
                    label="Bank Identification Number (BIN)"
                    value={service_provider_bin}
                    onChange={(e) => handleChange(e, "service_provider_bin", setTradingData)}
                    disabled={accountDisabled}
                  ></Input>
                </Col>
                {omniInvisible ? (
                  <></>
                ) : (
                  <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                    <Input
                      data-testid={`sub_account_number`}
                      id={`sub_account_number`}
                      type="text"
                      label="Service Provider Sub Account #"
                      value={service_provider_sub_acct}
                      onChange={(e) => handleChange(e, "service_provider_sub_acct", setTradingData)}
                      errorMessage={validationErrors["service_provider_sub_acct"]}
                      disabled={omniInvisible || subAccountDisabled}
                    ></Input>
                  </Col>
                )}
              </Row>
              {/* <Row>
                <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Checkbox
                    data-testid={`fif`}
                    id={`fif`}
                    label="Fund in/of Funds (0bps) Trading Account"
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    value={trading_account_relationship === "Direct Traded Account" ? fif : false}
                    disabled={true}
                    errorMessage={""}
                  ></Checkbox>
                </Col>
              </Row> */}
              <Row>
                <Col
                  sm={6}
                  lg={6}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  data-testid={"service_provider"}
                >
                  <label>Service Provider</label>
                  <Select
                    data-testid={"service_provider"}
                    id={"service_provider"}
                    options={serviceProviderOptions}
                    value={service_provider["label"] !== "" ? service_provider : "undefined"}
                    onChange={(e) => handleServiceChange("service_provider", e)}
                    isClearable={true}
                    styles={selectStyler({
                      validationErrorsValue: validationErrors["service_provider"],
                      disabled: accountDisabled,
                    })}
                    isDisabled={accountDisabled}
                  ></Select>
                  <ValidationErrorMessage
                    paramId="service_provider"
                    validationError={validationErrors["service_provider"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} lg={6} className="util-margin-top-sm-15 util-margin-top-xs-30">
                  <Checkbox
                    data-testid={"service_provider_same_for_settlement"}
                    id={`service_provider_same_for_settlement`}
                    label="Service Provider is the same for Settlement"
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    checked={service_provider_same_for_settlement}
                    onChange={(e) => {
                      HandleSameAsMultiFillCheckBox(
                        "service_provider_same_for_settlement",
                        e.target.checked,
                        { service_provider: "service_provider_for_settlement" },
                        tradingData,
                        setTradingData
                      );
                    }}
                    disabled={accountDisabled}
                    errorMessage={""}
                  ></Checkbox>
                </Col>
              </Row>
              <Row>
                <Col
                  sm={6}
                  lg={6}
                  className="util-margin-top-sm-15 util-margin-top-xs-30"
                  data-testid={"service_provider_for_settlement"}
                >
                  <label>Service Provider for Settlement</label>
                  <Select
                    id={"service_provider_for_settlement"}
                    options={serviceProviderOptions}
                    value={
                      service_provider_for_settlement["label"] !== "" ? service_provider_for_settlement : "undefined"
                    }
                    onChange={(e) => handleSelectChange("service_provider_for_settlement", e, setTradingData)}
                    isDisabled={service_provider_same_for_settlement || accountDisabled}
                    isClearable={true}
                    filterOption={createFilter({ ignoreAccents: false })}
                    styles={selectStyler({
                      validationErrorsValue: validationErrors["service_provider_for_settlement"],
                      disabled: service_provider_same_for_settlement || accountDisabled,
                    })}
                  ></Select>
                  <ValidationErrorMessage
                    paramId="service_provider_for_settlement"
                    validationError={validationErrors["service_provider_for_settlement"]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={6}>
                  <div className="util-margin-top-sm-15 util-margin-top-xs-30">
                    <label htmlFor={`wire_instructions`}>Wire Instructions</label>
                  </div>
                  <div className="util-margin-top-sm-15 util-margin-top-xs-30">
                    <textarea
                      data-testid={`wire_instructions`}
                      id={`wire_instructions`}
                      value={trading_wire_instructions}
                      style={{
                        resize: "none",
                        height: 200,
                        width: "-webkit-fill-available",
                        color: accountDisabled ? "#C7C7C7" : "inherit",
                      }}
                      onChange={(e) => handleChange(e, "trading_wire_instructions", setTradingData)}
                      disabled={accountDisabled}
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

NewTradingDetailsEdit.propTypes = {
  initialTradingData: PropTypes.object,
  setTradingData: PropTypes.func,
  tradingData: PropTypes.object,
  handleSelectChange: PropTypes.func,
  handleChange: PropTypes.func,
  setValidationError: PropTypes.func,
  validationErrors: PropTypes.object,
  tradingFieldsToValidate: PropTypes.object,
  setTradingFieldsToValidate: PropTypes.func,
  loadingData: PropTypes.bool,
  setLoadingData: PropTypes.func,
};

export default NewTradingDetailsEdit;
