import React, { useEffect, useContext, useState } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import UserContext from "../../../../../context/User.context";
import Config from "../../../../../config";
import { fetchService } from "../../../../../services/fetchService";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../Loading/Loading";
import Input from "@pds-react/input";
import Select from "react-select";
import Button from "@pds-react/button";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import selectStyler from "../../../../Utilities/selectStyler.js";
import {
  FormatDol,
  getTextAreaStyles,
  Validate,
  validateGuid,
  ValidateRequired,
  ValidateRequiredSelect,
  validationUseEffectHelper,
} from "../../../../Utilities/ValidationHelpers";
import { handleSelectChange, handleChange } from "../../../../Utilities/FormHelpers";
import { set_nulls_to_empty_strings } from "../../../../Utilities/FormHelpers";
import { multiFetch } from "../../../../../services/multiFetch";

const PortfolioDetailsEdit = () => {
  const d = new Date();
  const initialData = {
    portfolio_name: "",
    investment_category: "",
    dol: "",
    fund_group_name: { label: "", value: "" },
    dec_of_trust_name: { label: "", value: "" },
    dda: "",
    inv_nmbr: "",
    mandate: "",
    large_trade_threshold: "",
    pm_contact_email: "",
    operating_expense_cap: "",
    annual_operating_expense: "",
    annual_operating_expense_date: `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
      "0" + d.getDate()
    ).slice(-2)}`,
    reason_for_change: "",
  };

  const [portfolioData, setPortfolioData] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);
  const [fundGroupOptions, setFundGroupOptions] = useState();
  const [decOfTrustOptions, setDOTOptions] = useState();

  const { accessToken } = useContext(UserContext);
  const { baseUrl } = Config.api;
  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};

  if (Object.keys(params).length !== 0) {
    if (params.portfolio_id !== undefined) {
      validatedParams["portfolio_id"] = validateGuid(params.portfolio_id);
    }
    if (params.additional_details_portfolio_id !== undefined) {
      validatedParams["additional_details_portfolio_id"] = validateGuid(params.additional_details_portfolio_id);
    }
  }

  const {
    portfolio_name,
    investment_category,
    dol,
    fund_group_name,
    dec_of_trust_name,
    dda,
    inv_nmbr,
    mandate,
    large_trade_threshold,
    pm_contact_email,
    operating_expense_cap,
    annual_operating_expense,
    annual_operating_expense_date,
    reason_for_change,
  } = portfolioData;

  const initialValidation = {
    dol: ValidateRequired,
    fund_group_name: ValidateRequiredSelect,
    dec_of_trust_name: ValidateRequiredSelect,
    dda: ValidateRequired,
    reason_for_change: ValidateRequired,
  };

  const [validationErrors, setValidationError] = useState({});
  const [fieldsToValidate] = useState(initialValidation);

  const getOtherData = async (ids) => {
    try {
      let urls = [
        `/v1/fund-group?fund-group-id=${ids.fundGroupId}`,
        `/v1/dec-of-trust?dec-of-trust-id=${ids.decOfTrustId}`,
      ];

      let multiData = await multiFetch(urls, accessToken);
      if (multiData.error) {
        throw new Error(multiData.requestId);
      }

      return { fund_group: multiData[0].data[0], dec_of_trust: multiData[1].data[0] };
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const getDates = (date) => {
    let dmy = date.split("T");
    let result = dmy.splice(0);
    let res = result[0];
    return res;
  };

  const getPortfolioESDLDetails = async (dataURL) => {
    let endpoint = `/v1/portfolio-esdl?portfolio-id=${dataURL}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const getPortfolioAdditionalDetails = async () => {
    let endpoint = `/v1/portfolio-additional-detail?portfolio-id=${validatedParams.additional_details_portfolio_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp;
  };

  const handleSubmit = async () => {
    const [formIsValid, errors] = Validate(portfolioData, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = `/v1/portfolio-additional-detail`;

        let mappedData = {
          portfolio_id: validatedParams.additional_details_portfolio_id
            ? validatedParams.additional_details_portfolio_id
            : validatedParams.portfolio_id,
          fund_group_id: portfolioData.fund_group_name["value"],
          dec_of_trust_id: portfolioData.dec_of_trust_name["value"],
          investment_category: portfolioData.investment_category,
          dol: portfolioData.dol,
          dda: portfolioData.dda,
          inv_nmbr: portfolioData.inv_nmbr,
          mandate: portfolioData.mandate,
          large_trade_threshold: portfolioData.large_trade_threshold,
          pm_contact_email: portfolioData.pm_contact_email,
          operating_expense_cap: portfolioData.operating_expense_cap,
          annual_operating_expense: portfolioData.annual_operating_expense,
          annual_operating_expense_date: portfolioData.annual_operating_expense_date,
          reason_for_change: portfolioData.reason_for_change,
        };

        if (validatedParams.portfolio_id) {
          let formattedData = JSON.stringify(mappedData);
          const response = await fetchService(baseUrl + endpoint, {
            method: "POST",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/portfolio-details/${validatedParams.portfolio_id}`);
        } else {
          let formattedData = JSON.stringify(mappedData);
          const response = await fetchService(baseUrl + endpoint, {
            method: "PUT",
            body: formattedData,
            token: accessToken,
          });
          let resp = await response.json();
          if (200 !== response.status) {
            throw new Error(resp.requestId);
          }
          navigate(`/portfolio-details/${validatedParams.additional_details_portfolio_id}`);
        }
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        if (validatedParams.portfolio_id) {
          let portfolioDetails = await getPortfolioESDLDetails(validatedParams.portfolio_id);
          setPortfolioData((data) => ({
            ...data,
            ["portfolio_id"]: validatedParams.portfolio_id,
            ["portfolio_name"]: portfolioDetails.data[0].portfolio_name,
          }));
        } else {
          let portfolioDetails = await getPortfolioESDLDetails(validatedParams.additional_details_portfolio_id);
          let portfolioAdditionalDetails = await getPortfolioAdditionalDetails();

          const portfolioESDL = set_nulls_to_empty_strings(portfolioDetails.data[0]);
          const portfolioAdditional = set_nulls_to_empty_strings(portfolioAdditionalDetails.data[0]);
          let otherIds = {
            fundGroupId: portfolioAdditional.fund_group_id,
            decOfTrustId: portfolioAdditional.dec_of_trust_id,
          };
          let otherData = await getOtherData(otherIds);

          const updateData = {
            portfolio_name: portfolioESDL.portfolio_name,
            investment_category: portfolioAdditional.investment_category,
            dol: portfolioAdditional.dol,
            dda: portfolioAdditional.dda,
            inv_nmbr: portfolioAdditional.inv_nmbr,
            mandate: portfolioAdditional.mandate,
            fund_group_name: {
              label: otherData.fund_group.fund_group_name,
              value: otherData.fund_group.fund_group_id,
            },
            dec_of_trust_name: {
              label: otherData.dec_of_trust.dec_of_trust_name,
              value: otherData.dec_of_trust.dec_of_trust_id,
            },
            large_trade_threshold: portfolioAdditional.large_trade_threshold,
            pm_contact_email: portfolioAdditional.pm_contact_email,
            operating_expense_cap: getDates(portfolioAdditional.operating_expense_cap),
            annual_operating_expense: getDates(portfolioAdditional.annual_operating_expense),
            annual_operating_expense_date: getDates(portfolioAdditional.annual_operating_expense_date),
            reason_for_change: "",
          };
          setPortfolioData(updateData);
        }
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    setupAPIData();
  }, []);

  const handleDiscard = () => {
    if (validatedParams.additional_details_portfolio_id) {
      navigate(`/portfolio-details/${validatedParams.additional_details_portfolio_id}`);
    }
    if (validatedParams.portfolio_id) {
      navigate(`/portfolio-and-share-class-management`);
    }
  };

  const buildFundGroupOptions = (options) => {
    return options.map((result) => ({
      label: result.fund_group_name,
      value: result.fund_group_id,
    }));
  };

  const buildDOTOptions = (options) => {
    return options.map((result) => ({
      label: result.dec_of_trust_name,
      value: result.dec_of_trust_id,
    }));
  };

  useEffect(() => {
    const getOptions = async () => {
      try {
        let urls = ["/v1/fund-group", "/v1/dec-of-trust"];
        let results = await multiFetch(urls, accessToken);
        if (results.error) {
          throw new Error(results.requestId);
        }
        setFundGroupOptions(buildFundGroupOptions(results[0].data));
        setDOTOptions(buildDOTOptions(results[1].data));
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };
    getOptions();
  }, []);

  useEffect(() => {
    validationUseEffectHelper("dol", dol, fieldsToValidate, validationErrors, setValidationError);
  }, [dol]);

  useEffect(() => {
    validationUseEffectHelper("dda", dda, fieldsToValidate, validationErrors, setValidationError);
  }, [dda]);

  useEffect(() => {
    validationUseEffectHelper(
      "fund_group_name",
      fund_group_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [fund_group_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "dec_of_trust_name",
      dec_of_trust_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [dec_of_trust_name]);

  useEffect(() => {
    validationUseEffectHelper(
      "reason_for_change",
      reason_for_change,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reason_for_change]);

  if (!loadingData) {
    return (
      <div>
        <Col>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col>
              <h3>
                {validatedParams.additional_details_portfolio_id ? " Edit Portfolio Details" : "New Portfolio Details"}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card heading="Details">
                <Row>
                  <Col>
                    <Input
                      id={`portfolio_name`}
                      data-testid={`portfolio_name`}
                      type="text"
                      label="Portfolio Name"
                      value={portfolio_name}
                      disabled={true}
                      onChange={(e) => {
                        handleChange(e, "portfolio_name", setPortfolioData);
                      }}
                      errorMessage={validationErrors["portfolio_name"]}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={`investment_category`}
                      data-testid={`investment_category`}
                      type="text"
                      label="Investment Category"
                      value={investment_category}
                      onChange={(e) => {
                        handleChange(e, "investment_category", setPortfolioData);
                      }}
                      errorMessage={validationErrors["investment_category"]}
                    ></Input>
                  </Col>
                  <Col>
                    <Input
                      id={`dol`}
                      data-testid={`dol`}
                      type="text"
                      label="DOL Number"
                      value={dol}
                      onChange={(e) => {
                        FormatDol(e);
                        handleChange(e, "dol", setPortfolioData);
                      }}
                      errorMessage={validationErrors["dol"]}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div data-testid={"fund_group"}>
                      <label className="util-margin-top-sm-15 util-margin-top-xs-30">Fund Group</label>
                      <Select
                        data-testid={"fund_group"}
                        id={"fund_group"}
                        value={fund_group_name["label"] !== "" ? fund_group_name : "undefined"}
                        options={fundGroupOptions}
                        styles={selectStyler({ validationErrorsValue: validationErrors["fund_group_name"] })}
                        isClearable={true}
                        onChange={(e) => {
                          handleSelectChange("fund_group_name", e, setPortfolioData);
                        }}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"fund_group_name"}
                        validationError={validationErrors["fund_group_name"]}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div data-testid={"dec_of_trust_name"}>
                      <label className="util-margin-top-sm-15 util-margin-top-xs-30">Dec Of Trust</label>
                      <Select
                        data-testid={"dec_of_trust_name"}
                        id={"dec_of_trust_name"}
                        value={dec_of_trust_name["label"] !== "" ? dec_of_trust_name : "undefined"}
                        options={decOfTrustOptions}
                        styles={selectStyler({ validationErrorsValue: validationErrors["dec_of_trust_name"] })}
                        isClearable={true}
                        onChange={(e) => {
                          handleSelectChange("dec_of_trust_name", e, setPortfolioData);
                        }}
                      ></Select>
                      <ValidationErrorMessage
                        paramId={"dec_of_trust_name"}
                        validationError={validationErrors["dec_of_trust_name"]}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={`dda`}
                      data-testid={`dda`}
                      type="text"
                      label="DDA"
                      value={dda}
                      onChange={(e) => {
                        handleChange(e, "dda", setPortfolioData);
                      }}
                      errorMessage={validationErrors["dda"]}
                    ></Input>
                  </Col>
                  <Col>
                    <Input
                      id={`inv_nmbr`}
                      data-testid={`inv_nmbr`}
                      type="text"
                      label="Investment Number(INV_NMBR)"
                      value={inv_nmbr}
                      onChange={(e) => {
                        handleChange(e, "inv_nmbr", setPortfolioData);
                      }}
                      errorMessage={validationErrors["inv_nmbr"]}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={`mandate`}
                      data-testid={`mandate`}
                      type="text"
                      label="Mandate"
                      value={mandate}
                      onChange={(e) => {
                        handleChange(e, "mandate", setPortfolioData);
                      }}
                      errorMessage={validationErrors["mandate"]}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={`large_trade_threshold`}
                      data-testid={`large_trade_threshold`}
                      type="text"
                      label="Large Trade Threshhold"
                      value={large_trade_threshold}
                      onChange={(e) => {
                        handleChange(e, "large_trade_threshold", setPortfolioData);
                      }}
                      errorMessage={validationErrors["large_trade_threshold"]}
                    ></Input>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={`pm_contact_email`}
                      data-testid={`pm_contact_email`}
                      type="text"
                      label="PM Contact Email"
                      value={pm_contact_email}
                      onChange={(e) => {
                        handleChange(e, "pm_contact_email", setPortfolioData);
                      }}
                      errorMessage={validationErrors["pm_contact_email"]}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col>
              <Card heading="Expense Detail">
                <Row>
                  <Col>
                    <Input
                      id={`operating_expense_cap`}
                      data-testid={`operating_expense_cap`}
                      type="text"
                      label="Operating Expense Cap"
                      value={operating_expense_cap}
                      onChange={(e) => {
                        handleChange(e, "operating_expense_cap", setPortfolioData);
                      }}
                      errorMessage={validationErrors["operating_expense_cap"]}
                    ></Input>
                  </Col>
                  <Col>
                    <Input
                      data-testid={`annual_operating_expense_date`}
                      id={`annual_operating_expense_date`}
                      type="date"
                      label="Annual Operating Expense Date"
                      maxLength="10"
                      value={annual_operating_expense_date}
                      onChange={(e) => {
                        handleChange(e, "annual_operating_expense_date", setPortfolioData);
                      }}
                    ></Input>
                    <ValidationErrorMessage
                      paramId="annual_operating_expense_date"
                      validationError={validationErrors["annual_operating_expense_date"]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      id={`annual_operating_expense`}
                      data-testid={`annual_operating_expense`}
                      type="text"
                      label="Annual Operating Expense"
                      value={annual_operating_expense}
                      onChange={(e) => {
                        handleChange(e, "annual_operating_expense", setPortfolioData);
                      }}
                      errorMessage={validationErrors["annual_operating_expense"]}
                    ></Input>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col>
              <Card heading="Notes">
                <Row>
                  <Col>
                    <label>Reason For Change</label>
                    <div>
                      <textarea
                        id={"reason_for_change"}
                        data-testid={"reason_for_change"}
                        value={reason_for_change}
                        style={getTextAreaStyles(validationErrors["reason_for_change"])}
                        onChange={(e) => {
                          handleChange(e, "reason_for_change", setPortfolioData);
                        }}
                        //disabled={true}
                      ></textarea>
                      <ValidationErrorMessage
                        paramId={"reason_for_change"}
                        validationError={validationErrors["reason_for_change"]}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button onClick={handleSubmit} style={{ marginRight: 15 }} data-testid={"saveChanges"}>
                Save Changes
              </Button>
              <Button onClick={handleDiscard} data-testid={"discardChanges"}>
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading></Loading>;
  }
};

export default PortfolioDetailsEdit;
