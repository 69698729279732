import sbx from "./envrionments/sbx";
import sbx_dr from "./envrionments/sbxDr";
import nonprod from "./envrionments/nonprod";
import nonprod_dr from "./envrionments/nonprodDr";
import prd from "./envrionments/prd";
import prod_dr from "./envrionments/prdDr";
import local from "./envrionments/local";

const config =
  process.env.REACT_APP_ENV === "prd"
    ? prd
    : process.env.REACT_APP_ENV === "prd-dr"
    ? prod_dr
    : process.env.REACT_APP_ENV === "nonprd"
    ? nonprod
    : process.env.REACT_APP_ENV === "sbx"
    ? sbx
    : process.env.REACT_APP_ENV === "sbx-dr"
    ? sbx_dr
    : process.env.REACT_APP_ENV === "nonprd-dr"
    ? nonprod_dr
    : local;

export default config;
