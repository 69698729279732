import { fetchService } from "./fetchService";

export const multiFetch = async (urls, accessToken) => {
  let data = Promise.all(
    urls.map(async (url) => {
      let response = await fetchService(url, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (200 !== response.status) {
        return Promise.reject(new Error(resp.requestId));
      }
      return Promise.resolve(resp);
    })
  );
  return data;
};
