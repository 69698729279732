import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "@pds-react/grid";
import Card from "../../../Card";
import { fetchService } from "../../../../../services/fetchService";
import Config from "../../../../../config";
import UserContext from "../../../../../context/User.context";
import Checkbox from "@pds-react/checkbox";
import Input from "@pds-react/input";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Button from "@pds-react/button";
import Loading from "../../../../Loading/Loading";
import { set_nulls_to_empty_strings, handleChange, handleSelectChange } from "../../../../Utilities/FormHelpers";
import { useNavigate, useParams } from "react-router-dom";
import ValidationErrorMessage from "../../../../Utilities/ValidationErrorMessage";
import {
  Validate,
  ValidatePlanTax,
  ValidateRequired,
  ValidateRequiredSelect,
  FormatTaxId,
  ValidateDolPlanNumber,
  FormatTaxIdOnLoad,
  validationUseEffectHelper,
  getTextAreaStyles,
  validateGuid,
} from "../../../../Utilities/ValidationHelpers";
import { multiFetch } from "../../../../../services/multiFetch";
import selectStyler from "../../../../Utilities/selectStyler";

const TrustDetailsEdit = () => {
  const { baseUrl } = Config.api;
  const { accessToken } = useContext(UserContext);

  const params = useParams();
  const navigate = useNavigate();
  const validatedParams = {};
  if (Object.keys(params).length !== 0) {
    validatedParams["participating_trust_id"] = validateGuid(params.participating_trust_id);
  }

  const initialData = {
    participating_trust_name: "",
    plan_tax_id: "",
    planDetailsPlanType: { label: "", value: "" },
    dol_plan_number: "",
    agrees_to_email: false,
    agrees_to_third_party_disclosure: false,
    allows_statements: false,
    groupings: [],
    planCompany: { value: "test", label: "test" },
    planContact: { value: "test2", label: "test2" },
    fidCompany: { value: "", label: "" },
    fidContact: { value: "", label: "" },
    reasonForChange: "",
  };

  const initialFieldsToValidate = {
    participating_trust_name: ValidateRequired,
    planDetailsPlanType: ValidateRequiredSelect,
    plan_tax_id: ValidatePlanTax,
    dol_plan_number: ValidateDolPlanNumber,
    reasonForChange: ValidateRequired,
    groupings: [],
  };

  const [data, setParticipatingTrustData] = useState(initialData);
  const [loadingData, setLoadingData] = useState(true);
  const [groupCategory, setGroupCategory] = useState();
  const [planTypeOptions, setPlanTypes] = useState();
  const [currentGroupNames, setGroupNames] = useState();
  const [currentCompany, setCurrentCompany] = useState();
  const [isLoading, setGroupLoading] = useState(false);
  const [validationErrors, setValidationError] = useState({ groupings: [] });
  const [fieldsToValidate, setFieldsToValidate] = useState(initialFieldsToValidate);
  const [fidSameasPlan, setFidSameAsPlan] = useState(false);

  const {
    participating_trust_name,
    plan_tax_id,
    planDetailsPlanType,
    dol_plan_number,
    agrees_to_email,
    agrees_to_third_party_disclosure,
    allows_statements,
    groupings,
    planCompany,
    planContact,
    fidCompany,
    fidContact,
    reasonForChange,
  } = data;

  const handleSubmit = async () => {
    let [formIsValid, errors] = Validate(data, fieldsToValidate);
    setValidationError((validationErrors) => ({ ...validationErrors, ...errors }));
    if (formIsValid) {
      try {
        setLoadingData(true);
        let endpoint = `/v1/participating-trust-details`;

        let mappedGroups = [];

        if (groupings.length > 0) {
          groupings.map((grouping) => {
            let newGroup = { aggregation_group_id: grouping.group_name.value };
            mappedGroups.push(newGroup);
          });
        }

        let mappedData = {
          participating_trust_id: validatedParams.participating_trust_id,
          participating_trust_name: participating_trust_name,
          plan_tax_id: plan_tax_id,
          plan_type_id: planDetailsPlanType.value,
          dol_plan_number: dol_plan_number,
          agrees_to_email: agrees_to_email,
          agrees_to_third_party_disclosure: agrees_to_third_party_disclosure,
          allows_statements: allows_statements,
          plan_sponsor_contact_id: planContact.value,
          fiduciary_contact_id: fidContact.value,
          groupings: mappedGroups,
          reason_for_change: reasonForChange,
        };

        let formattedData = JSON.stringify(mappedData);

        let response = await fetchService(baseUrl + endpoint, {
          method: "PUT",
          body: formattedData,
          token: accessToken,
        });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }
        navigate(`/trust-details/${validatedParams.participating_trust_id}`);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    }
  };

  const handleAddGroupings = (groupings) => {
    let newGroupings = groupings.map((grouping) => Object.assign({}, grouping));
    let newGrouping = {
      group_type: {
        label: "",
        value: "",
      },
      group_name: {
        label: "",
        value: "",
      },
    };
    newGroupings.push(newGrouping);

    let newFieldsToValidate = { ...fieldsToValidate };
    let newGroupingValidations = [];
    let newGroupingValidation = { group_type: ValidateRequiredSelect, group_name: ValidateRequiredSelect };

    for (let i = 0; i < groupings.length + 1; i++) {
      newGroupingValidations.push(newGroupingValidation);
    }

    newFieldsToValidate.groupings = newGroupingValidations;
    setFieldsToValidate(newFieldsToValidate);
    setParticipatingTrustData((data) => ({ ...data, groupings: newGroupings }));
  };

  const handleDeleteGroupings = (groupings, index) => {
    let newFieldsToValidate = { ...fieldsToValidate };
    let newGroupingValidations = newFieldsToValidate.groupings.map((grouping) => Object.assign({}, grouping));
    let newGroupings = groupings.map((grouping) => Object.assign({}, grouping));

    newGroupings.splice(index, 1);

    newGroupingValidations.splice(index, 1);

    newFieldsToValidate.groupings = newGroupingValidations;
    setFieldsToValidate(newFieldsToValidate);
    setParticipatingTrustData((data) => ({ ...data, groupings: newGroupings }));
  };

  const handleFidChange = (id, value) => {
    const newFieldsToValidate = { ...fieldsToValidate };
    const newValidationErrors = { ...validationErrors };
    if (value === null) {
      value = { value: "", label: "" };
      delete newFieldsToValidate.fidContact;
      setFieldsToValidate(newFieldsToValidate);

      delete newValidationErrors.fidContact;
      setValidationError(newValidationErrors);
    } else {
      newFieldsToValidate.fidContact = ValidateRequiredSelect;
      setFieldsToValidate(newFieldsToValidate);
    }
    setParticipatingTrustData({
      ...data,
      [id]: {
        label: value.label,
        value: value.value,
      },
      ["fidContact"]: {
        label: "",
        value: "",
      },
    });
  };

  const handleSelectPlanCompany = (id, value) => {
    const newFieldsToValidate = { ...fieldsToValidate };
    const newValidationErrors = { ...validationErrors };
    if (value === null) {
      value = { value: "", label: "" };

      delete newFieldsToValidate.planContact;
      setFieldsToValidate(newFieldsToValidate);

      delete newValidationErrors.planContact;
      setValidationError(newValidationErrors);
    } else {
      newFieldsToValidate.planContact = ValidateRequiredSelect;
      setFieldsToValidate(newFieldsToValidate);
    }
    setParticipatingTrustData({
      ...data,
      [id]: {
        label: value.label,
        value: value.value,
      },
      ["planContact"]: {
        label: "",
        value: "",
      },
    });
  };

  const handlePlanContactChange = (id, value) => {
    if (value === null) {
      value = { value: "", label: "" };
    }
    setParticipatingTrustData({
      ...data,
      [id]: {
        label: value.label,
        value: value.value,
      },
    });
  };

  const handleCheckboxChange = (e, id) => {
    setParticipatingTrustData((data) => ({ ...data, [id]: e.target.checked }));
  };

  const handleGroupTypeChange = (e, groupings, index) => {
    if (groupings.length > 0) {
      if (e === null) {
        e = { label: "", value: "" };
      }
      let newGroupings = groupings.map((grouping) => Object.assign({}, grouping));
      newGroupings[index]["group_type"] = e;
      newGroupings[index]["group_name"] = { label: "", value: "" };
      setParticipatingTrustData((data) => ({ ...data, groupings: newGroupings }));
    }
  };

  const handleGroupNameChange = (e, groupings, index) => {
    if (e === null) {
      e = { label: "", value: "" };
    }
    let newGroupings = groupings.map((grouping) => Object.assign({}, grouping));
    newGroupings[index]["group_name"] = e;
    setParticipatingTrustData((data) => ({ ...data, groupings: newGroupings }));
  };

  const getPlanDetailsData = async () => {
    let endpoint = `/v1/participating-trust?participating-trust-id=${validatedParams.participating_trust_id}`;
    let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
    let resp = await response.json();
    if (200 !== response.status) {
      throw new Error(resp.requestId);
    }
    if (resp.data.length === 0) {
      throw new Error(resp.requestId);
    }
    return resp.data[0];
  };

  const groupNameDropdown = (options) => {
    return options.map((opt) => ({
      value: opt.aggregation_group_id,
      label: opt.aggregation_group_name,
      ...opt,
    }));
  };

  const loadGroupNameOptions = async (currentGroupCat) => {
    try {
      setGroupNames([]);
      setGroupLoading(true);
      if (currentGroupCat === null || currentGroupCat === undefined || currentGroupCat === "") {
        setGroupLoading(false);
      } else {
        let endpoint = `/v1/aggregation-group?aggregation-group-category-id=${currentGroupCat}`;
        let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
        let resp = await response.json();
        if (200 !== response.status) {
          throw new Error(resp.requestId);
        }

        setGroupNames(groupNameDropdown(resp.data));
        setGroupLoading(false);
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  const buildCompanyOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_company_id,
      label: opt.company_name,
    }));
  };

  const loadSponsorCompanies = async (inputValue, callback) => {
    const { baseUrl } = Config.api;

    const endpoint = `/v1/contact-company?company-name=${inputValue}`;

    if (inputValue.length > 2) {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      const res = await resp["data"];
      return callback(buildCompanyOptions(res));
    } else {
      return callback([]);
    }
  };

  const buildContactOptions = (options) => {
    return options.map((opt) => ({
      value: opt.contact_id,
      label: `${opt.first_name} ${opt.last_name}`,
    }));
  };

  const loadSponsorContacts = async (inputValue, callback) => {
    const { baseUrl } = Config.api;
    const endpoint = `/v1/contact?contact-name=${inputValue}&contact-company-id=${currentCompany}`;

    if (inputValue.length > 2) {
      let response = await fetchService(baseUrl + endpoint, { method: "GET", token: accessToken });
      let resp = await response.json();
      if (resp.data.length === 0) {
        throw new Error(resp.requestId);
      }
      if (200 !== response.status) {
        throw new Error(resp.requestId);
      }
      const res = await resp["data"];
      return callback(buildContactOptions(res));
    } else {
      return callback([]);
    }
  };

  const getRemainingData = async (ids) => {
    try {
      let urls = [
        `/v1/groupings?participating-trust-id=${ids.participating_trust_id}`,
        `/v1/plan-type?plan-type-id=${ids.plan_type_id}`,
      ];

      if (ids.fiduciary_contact_id) {
        urls.push(`/v1/contact?contact-id=${ids.fiduciary_contact_id}`);
      }

      if (ids.plan_sponsor_contact_id) {
        urls.push(`/v1/contact?contact-id=${ids.plan_sponsor_contact_id}`);
      }

      let multiData = await multiFetch(urls, accessToken);
      if (multiData.error) {
        throw new Error(multiData.requestId);
      }

      let companyURLs = [];

      if (ids.plan_sponsor_contact_id && ids.fiduciary_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[2].data[0].contact_company_id}`);
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[3].data[0].contact_company_id}`);
      } else if (ids.plan_sponsor_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[2].data[0].contact_company_id}`);
      } else if (ids.fiduciary_contact_id) {
        companyURLs.push(`/v1/contact-company?contact-company-id=${multiData[2].data[0].contact_company_id}`);
      }

      let companyData = await multiFetch(companyURLs, accessToken);
      if (companyData.error) {
        throw new Error(companyData.requestId);
      }

      if (ids.fiduciary_contact_id && ids.plan_sponsor_contact_id) {
        return {
          groupingsData: multiData[0].data,
          planTypeData: multiData[1].data[0],
          fidContactData: multiData[2].data[0],
          planContactData: multiData[3].data[0],
          fidCompanyData: companyData[0].data[0],
          planCompanyData: companyData[1].data[0],
        };
      } else if (ids.fiduciary_contact_id) {
        return {
          groupingsData: multiData[0].data,
          planTypeData: multiData[1].data[0],
          fidContactData: multiData[2].data[0],
          fidCompanyData: companyData[0].data[0],
        };
      } else if (ids.plan_sponsor_contact_id) {
        return {
          groupingsData: multiData[0].data,
          planTypeData: multiData[1].data[0],
          planContactData: multiData[2].data[0],
          planCompanyData: companyData[0].data[0],
        };
      } else {
        return {
          groupingsData: multiData[0].data,
          planTypeData: multiData[1].data[0],
        };
      }
    } catch (error) {
      navigate("/error", { state: { error } });
    }
  };

  useEffect(() => {
    const setupAPIData = async () => {
      try {
        let planDetails = await getPlanDetailsData();

        let otherIds = {
          fiduciary_contact_id: planDetails.fiduciary_contact_id,
          plan_sponsor_contact_id: planDetails.plan_sponsor_contact_id,
          participating_trust_id: planDetails.participating_trust_id,
          plan_type_id: planDetails.plan_type_id,
        };

        let remainingData = await getRemainingData(otherIds);

        planDetails = set_nulls_to_empty_strings(planDetails);

        let groupingsData = set_nulls_to_empty_strings(remainingData.groupingsData);
        let planTypeData = set_nulls_to_empty_strings(remainingData.planTypeData);
        let fidContactData;
        if (remainingData.fidContactData) {
          fidContactData = set_nulls_to_empty_strings(remainingData.fidContactData);
        }
        let planContactData;
        if (remainingData.planContactData) {
          planContactData = set_nulls_to_empty_strings(remainingData.planContactData);
        }
        let fidCompanyData;
        if (remainingData.fidCompanyData) {
          fidCompanyData = set_nulls_to_empty_strings(remainingData.fidCompanyData);
        }
        let planCompanyData;
        if (remainingData.planCompanyData) {
          planCompanyData = set_nulls_to_empty_strings(remainingData.planCompanyData);
        }

        let formattedGroupings = [];
        let groupingValidations = [];
        if (groupingsData.length > 0) {
          groupingsData.forEach((grouping) => {
            let newGrouping = {
              group_type: {
                label: grouping.aggregation_group_category_name,
                value: grouping.aggregation_group_category_id,
              },
              group_name: {
                label: grouping.aggregation_group_name,
                value: grouping.aggregation_group_id,
              },
              aggregation_group_control_number: grouping.aggregation_group_control_number,
            };

            groupingValidations.push({ group_type: ValidateRequiredSelect, group_name: ValidateRequiredSelect });
            formattedGroupings.push(newGrouping);
          });
        }

        const newParticipationData = {
          participating_trust_name: planDetails.participating_trust_name,
          plan_tax_id: FormatTaxIdOnLoad(planDetails.plan_tax_id),
          planDetailsPlanType: { label: planTypeData.plan_type, value: planTypeData.plan_type_id },
          dol_plan_number: planDetails.dol_plan_number,
          agrees_to_email: planDetails.agrees_to_email,
          agrees_to_third_party_disclosure: planDetails.agrees_to_third_party_disclosure,
          allows_statements: planDetails.allows_statements,
          groupings: formattedGroupings,
          planCompany: planCompanyData
            ? { label: planCompanyData.company_name, value: planCompanyData.contact_company_id }
            : { label: "", value: "" },
          planContact: planContactData
            ? {
                label: planContactData.first_name + " " + planContactData.last_name,
                value: planContactData.contact_id,
              }
            : { label: "", value: "" },
          fidCompany: fidCompanyData
            ? { label: fidCompanyData.company_name, value: fidCompanyData.contact_company_id }
            : { label: "", value: "" },
          fidContact: fidContactData
            ? {
                label: fidContactData.first_name + " " + fidContactData.last_name,
                value: fidContactData.contact_id,
              }
            : { label: "", value: "" },
          reasonForChange: "",
        };

        let newFieldsToValidate = { ...fieldsToValidate };

        if (planDetails.plan_sponsor_contact_id) {
          newFieldsToValidate["planContact"] = ValidateRequiredSelect;
        }

        if (planDetails.fiduciary_contact_id) {
          newFieldsToValidate["fidContact"] = ValidateRequiredSelect;
        }

        if (groupings.length > 0) {
          newFieldsToValidate[groupings] = groupingValidations;
        }

        if (newFieldsToValidate["planContact"] || newFieldsToValidate["fidContact"] || groupings.length > 0) {
          setFieldsToValidate(newFieldsToValidate);
        }

        setParticipatingTrustData(newParticipationData);
        setLoadingData(false);
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };

    setupAPIData();
  }, []);

  useEffect(() => {
    const getOptions = async () => {
      try {
        let urls = ["/v1/aggregation-group-category", "/v1/plan-type"];
        let results = await multiFetch(urls, accessToken);
        if (results.error) {
          throw new Error(results.requestId);
        }

        setGroupCategory(
          results[0].data.map((grouping) => {
            return { label: grouping.aggregation_group_category_name, value: grouping.aggregation_group_category_id };
          })
        );

        setPlanTypes(
          results[1].data.map((planType) => {
            return { label: planType.plan_type, value: planType.plan_type_id };
          })
        );
      } catch (error) {
        navigate("/error", { state: { error } });
      }
    };

    getOptions();
  }, []);

  useEffect(() => {
    const newValidationErrors = { ...validationErrors };
    const groupingValidationErrors = [];
    if (groupings.length < 2) {
      groupingValidationErrors.push({ group_type: null, group_name: null });
    } else {
      groupings.forEach((grouping, index) => {
        const fieldsToValidateForGrouping = fieldsToValidate.groupings[index];
        const individualGroupingErrors = {};
        for (const groupingKey in fieldsToValidateForGrouping) {
          const validationFunct = fieldsToValidateForGrouping[groupingKey];
          if (groupingKey in grouping && grouping[groupingKey].value !== "") {
            individualGroupingErrors[groupingKey] = validationFunct(grouping[groupingKey]);
          }
        }
        groupingValidationErrors.push(individualGroupingErrors);
      });
    }

    newValidationErrors.groupings = groupingValidationErrors;
    setValidationError(newValidationErrors);
  }, [groupings]);

  useEffect(() => {
    validationUseEffectHelper(
      "participating_trust_name",
      participating_trust_name,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [participating_trust_name]);

  useEffect(() => {
    validationUseEffectHelper("plan_tax_id", plan_tax_id, fieldsToValidate, validationErrors, setValidationError);
  }, [plan_tax_id]);

  useEffect(() => {
    validationUseEffectHelper(
      "planDetailsPlanType",
      planDetailsPlanType,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [planDetailsPlanType]);

  useEffect(() => {
    validationUseEffectHelper(
      "dol_plan_number",
      dol_plan_number,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [dol_plan_number]);

  useEffect(() => {
    validationUseEffectHelper("planContact", planContact, fieldsToValidate, validationErrors, setValidationError);
  }, [planContact]);

  useEffect(() => {
    validationUseEffectHelper("fidContact", fidContact, fieldsToValidate, validationErrors, setValidationError);
  }, [fidContact]);

  useEffect(() => {
    validationUseEffectHelper(
      "reasonForChange",
      reasonForChange,
      fieldsToValidate,
      validationErrors,
      setValidationError
    );
  }, [reasonForChange]);

  useEffect(() => {
    const newValue = fidCompany.value === planCompany.value && fidContact.value === planContact.value;
    if (newValue !== fidSameasPlan) {
      setFidSameAsPlan(newValue);
    }
  }, [planContact, fidContact, planCompany, fidCompany]);

  if (!loadingData) {
    return (
      <div id={"trust_edit"} data-testid={"trust_edit"}>
        <Col>
          <Row>
            <Col sm={12}>
              <h2>Edit Participating Trust Detail</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card heading={"Participating Trust"}>
                <Col sm={4}>
                  <Input
                    data-testid={"trust_name"}
                    id={"trust_name"}
                    type="text"
                    label="Name of Participating Trust"
                    value={participating_trust_name}
                    onChange={(e) => {
                      handleChange(e, "participating_trust_name", setParticipatingTrustData);
                    }}
                    errorMessage={validationErrors["participating_trust_name"]}
                  ></Input>
                  <Input
                    data-testid={"plan_tax_id"}
                    id={"plan_tax_id"}
                    type="text"
                    label="Plan Tax ID (EIN)"
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    value={plan_tax_id}
                    onChange={(e) => {
                      FormatTaxId(e);
                      handleChange(e, "plan_tax_id", setParticipatingTrustData);
                    }}
                    errorMessage={validationErrors["plan_tax_id"]}
                  ></Input>
                </Col>
                <Col sm={4}>
                  <label htmlFor="plan_type">Plan Type</label>
                  <span data-testid={"plan_type"} id={"plan_type"}>
                    <Select
                      inputId={`plan_type`}
                      label="Plan Type"
                      options={planTypeOptions}
                      value={planDetailsPlanType["label"] !== "" ? planDetailsPlanType : "undefined"}
                      styles={selectStyler({
                        validationErrorsValue: validationErrors["planDetailsPlanType"],
                      })}
                      onChange={(e) => handleSelectChange("planDetailsPlanType", e, setParticipatingTrustData)}
                      isClearable={true}
                      isLoading={isLoading}
                    ></Select>
                  </span>
                  <ValidationErrorMessage
                    paramId={"planDetailsPlanType"}
                    validationError={validationErrors["planDetailsPlanType"]}
                  />
                  <Input
                    data-testid={"dol_number"}
                    id={"dol_number"}
                    type="text"
                    label="DOL Plan Number"
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    value={dol_plan_number}
                    onChange={(e) => {
                      handleChange(e, "dol_plan_number", setParticipatingTrustData);
                    }}
                    errorMessage={validationErrors["dol_plan_number"]}
                  ></Input>
                </Col>
                <Col sm={4}>
                  <Row>
                    <Col>
                      <Checkbox
                        data-testid={`plan_email_communications_consent`}
                        id={`plan_email_communications_consent`}
                        label="Consents to email communications"
                        name={"plan_email_communications_consent"}
                        className="util-margin-top-sm-15 util-margin-top-xs-30"
                        defaultChecked={agrees_to_email}
                        onClick={(e) => {
                          handleCheckboxChange(e, "agrees_to_email");
                        }}
                      ></Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        data-testid={`plan_third_party_consent`}
                        id={`plan_third_party_consent`}
                        label="Consents to third party disclosure"
                        className="util-margin-top-sm-15 util-margin-top-xs-30"
                        defaultChecked={agrees_to_third_party_disclosure}
                        onClick={(e) => {
                          handleCheckboxChange(e, "agrees_to_third_party_disclosure");
                        }}
                      ></Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        data-testid={`plan_allow_statements_consent`}
                        id={`plan_allow_statements_consent`}
                        label="Allows statements"
                        className="util-margin-top-sm-15 util-margin-top-xs-30"
                        defaultChecked={allows_statements}
                        onClick={(e) => {
                          handleCheckboxChange(e, "allows_statements");
                        }}
                      ></Checkbox>
                    </Col>
                  </Row>
                </Col>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={6}>
              <Card heading={"Plan Sponsor Company"} style={{ height: "100%" }}>
                <Col sm={12}>
                  <label htmlFor="plan_company">Company Name</label>
                  <div data-testid={`plan_company`} id={`plan_company`}>
                    <AsyncSelect
                      name="plan_company"
                      inputId="plan_company"
                      value={planCompany["label"] !== "" ? planCompany : "undefined"}
                      styles={selectStyler({ validationErrorsValue: null })}
                      loadOptions={loadSponsorCompanies}
                      onChange={(e) => {
                        handleSelectPlanCompany("planCompany", e);
                      }}
                      isClearable={true}
                    ></AsyncSelect>
                  </div>

                  <label className="util-margin-top-sm-15 util-margin-top-xs-30">Contact Name</label>
                  <div data-testid={`plan_contact`} id={`plan_contact`}>
                    <AsyncSelect
                      value={planContact["label"] !== "" ? planContact : "undefined"}
                      isClearable={true}
                      openMenuOnClick={true}
                      styles={selectStyler({
                        validationErrorsValue: validationErrors["planContact"],
                      })}
                      loadOptions={loadSponsorContacts}
                      onFocus={() => {
                        setCurrentCompany(planCompany.value);
                      }}
                      onChange={(e) => {
                        handlePlanContactChange("planContact", e);
                      }}
                    ></AsyncSelect>
                  </div>
                  <ValidationErrorMessage paramId={"planContact"} validationError={validationErrors["planContact"]} />
                </Col>
              </Card>
            </Col>
            <Col sm={6}>
              <Card heading={"Fiduciary Company"}>
                <Col sm={12}>
                  <label>Company Name</label>
                  <div data-testid={`fid_company`} id={`fid_company`}>
                    <AsyncSelect
                      value={fidCompany["label"] !== "" ? fidCompany : "undefined"}
                      styles={selectStyler({})}
                      isClearable={true}
                      loadOptions={loadSponsorCompanies}
                      onChange={(e) => {
                        handleFidChange("fidCompany", e);
                      }}
                    ></AsyncSelect>
                  </div>

                  <label className="util-margin-top-sm-15 util-margin-top-xs-30">Contact Name</label>
                  <div data-testid={`fid_contact`} id={`fid_contact`}>
                    <AsyncSelect
                      value={fidContact["label"] !== "" ? fidContact : "undefined"}
                      isClearable={true}
                      styles={selectStyler({ validationErrorsValue: validationErrors["fidContact"] })}
                      loadOptions={loadSponsorContacts}
                      onFocus={() => {
                        setCurrentCompany(fidCompany.value);
                      }}
                      onChange={(e) => {
                        handleSelectChange("fidContact", e, setParticipatingTrustData);
                      }}
                    ></AsyncSelect>
                  </div>
                  <ValidationErrorMessage paramId={"fidContact"} validationError={validationErrors["fidContact"]} />
                  <Checkbox
                    data-testid={`fid_same_as_plan_sponsor`}
                    id={`fid_same_as_plan_sponsor`}
                    label="Fiduciary Same as Plan Sponsor"
                    className="util-margin-top-sm-15 util-margin-top-xs-30"
                    checked={fidSameasPlan}
                    onChange={(e) => {
                      const newData = { ...data };
                      const newFieldsToValidate = { ...fieldsToValidate };
                      if (e.target.checked) {
                        newData.fidCompany = newData.planCompany;
                        newData.fidContact = newData.planContact;
                        if (newData.fidCompany.value) {
                          newFieldsToValidate.fidContact = ValidateRequiredSelect;
                        }
                      } else {
                        newData.fidCompany = { value: "", label: "" };
                        newData.fidContact = { value: "", label: "" };
                        delete newFieldsToValidate.fidContact;
                      }
                      setFieldsToValidate(newFieldsToValidate);
                      setParticipatingTrustData(newData);
                      setFidSameAsPlan(e.target.checked);
                    }}
                  ></Checkbox>
                </Col>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Groupings"}>
                <Col>
                  {groupings.map((grouping, index) => (
                    <Row
                      key={`grouping_${index}`}
                      style={{ border: "1px solid rgba(0, 0, 0, 0.25)", borderRadius: "10px 10px 10px 10px" }}
                      className="util-margin-top-sm-15"
                    >
                      <Col sm={10} className="util-margin-top-sm-15 util-margin-top-xs-30 util-margin-bottom-sm-15">
                        <Row>
                          <Col sm={12} lg={6}>
                            <label>Grouping Category</label>
                            <div id={`grouping_cat_${index}`} data-testid={`grouping_cat_${index}`}>
                              <Select
                                id={`grouping_cat_${index}`}
                                data-testid={`grouping_cat_${index}`}
                                isClearable={true}
                                options={groupCategory}
                                styles={selectStyler({
                                  validationErrorsValue:
                                    validationErrors["groupings"][index] !== undefined
                                      ? validationErrors["groupings"][index]["group_type"]
                                      : "",
                                })}
                                value={grouping["group_type"]["label"] !== "" ? grouping["group_type"] : "undefined"}
                                onChange={(e) => {
                                  handleGroupTypeChange(e, groupings, index);
                                }}
                              ></Select>
                            </div>
                            <ValidationErrorMessage
                              paramId={`groupings[${index}].group_type`}
                              validationError={
                                validationErrors["groupings"][index] !== undefined
                                  ? validationErrors["groupings"][index]["group_type"]
                                  : ""
                              }
                            />
                          </Col>
                          <Col sm={12} lg={6}>
                            <label>Group Name</label>
                            <span data-testid={`group_name_${index}`} id={`group_name_${index}`}>
                              <Select
                                isLoading={isLoading}
                                isClearable={true}
                                styles={selectStyler({
                                  validationErrorsValue:
                                    validationErrors["groupings"][index] !== undefined
                                      ? validationErrors["groupings"][index]["group_name"]
                                      : "",
                                })}
                                onFocus={() => {
                                  loadGroupNameOptions(grouping["group_type"]["value"]);
                                }}
                                value={grouping["group_name"]["label"] !== "" ? grouping["group_name"] : "undefined"}
                                onChange={(e) => handleGroupNameChange(e, groupings, index)}
                                options={currentGroupNames}
                              ></Select>
                            </span>
                            <ValidationErrorMessage
                              paramId={`groupings[${index}].group_name`}
                              validationError={
                                validationErrors["groupings"][index] !== undefined
                                  ? validationErrors["groupings"][index]["group_name"]
                                  : ""
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                      {groupings.length > 0 ? (
                        <Col sm={2} className="util-margin-top-sm-40 util-margin-top-xs-30 util-margin-bottom-xs-10">
                          <Button
                            data-testid={`delete_button_${index}`}
                            onClick={() => {
                              handleDeleteGroupings(groupings, index);
                            }}
                          >
                            Delete Grouping
                          </Button>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  ))}
                  <Row>
                    <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
                      <Button
                        data-testid={"add_grouping"}
                        id={"add_grouping"}
                        onClick={() => {
                          handleAddGroupings(groupings);
                        }}
                      >
                        Add Grouping
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Card>
            </Col>
          </Row>
          <Row className="util-margin-top-sm-15 util-margin-top-xs-30">
            <Col sm={12}>
              <Card heading={"Notes"}>
                <Col sm={12}>
                  <label>Reason For Change</label>
                  <div id={"notes"}>
                    <textarea
                      data-testid={"notes"}
                      style={getTextAreaStyles(validationErrors["reasonForChange"])}
                      value={reasonForChange}
                      onChange={(e) => {
                        handleChange(e, "reasonForChange", setParticipatingTrustData);
                      }}
                    ></textarea>
                  </div>
                  <ValidationErrorMessage
                    paramId={"reasonForChange"}
                    validationError={validationErrors["reasonForChange"]}
                  />
                </Col>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={12} style={{ textAlign: "right" }} className="util-margin-top-sm-15 util-margin-top-xs-30">
              <Button style={{ marginRight: 15 }} onClick={handleSubmit} data-testid={`save_button`} id={`save_button`}>
                Save Changes
              </Button>
              <Button
                data-testid={`discard_button`}
                id={`discard_button`}
                onClick={() => {
                  navigate(`/trust-details/${validatedParams.participating_trust_id}`);
                }}
              >
                Discard Changes
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default TrustDetailsEdit;
